import React, { useState, useEffect } from 'react';
import {
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonList,
  IonItem,
  IonLabel,
  IonSpinner,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import styles from './SearchModal.module.scss';
import { removeAccents } from '../../../../utils/utils';
interface StandardModalProps {
  isOpen: boolean;
  handler: any;
  closeModal: () => void;
  content: any;
  title: string;
  searchFunction?: Function;
  placeholder?: string;
}

const SearchModal: React.FC<StandardModalProps> = ({
  isOpen,
  handler,
  closeModal,
  content,
  title,
  searchFunction,
  placeholder = '',
}) => {
  const [searchText, setSearchText] = useState('');
  const [dataList, setDataList] = useState<string[] | undefined>(undefined);
  const [searchDataList, setSearchDataList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const [disabledInput, setDisabledInput] = useState<boolean>(false)
  

  const selectAndClose = (data: string) => {
    handler(data);
    closeModal();
  };


  const customSearch = async () => {
    const text = removeAccents(searchText);
    const data = await searchFunction(text);
    data?.length > 0 ? setShowMessage(false) : setShowMessage(true);
    setSearchDataList(data);
    setDisabledInput(false);
    setIsLoading(false);
  };


  useEffect(() => {
    setDataList(content);
    setSearchDataList(content);
    if (content) {
      setIsLoading(false);
    }
  }, [content]);


  useEffect(() => {
    let stopTypingFc;
    if (!searchFunction) {
      dataList &&
        setSearchDataList(
          dataList.filter((x) => {
            let a = removeAccents(x);
            return a.toUpperCase().includes(searchText.toUpperCase());
          })
        );
      setIsLoading(false);
    } else {
        if (searchText?.length > 2) {
          setIsLoading(true);
          stopTypingFc = setTimeout(() => {
            setDisabledInput(true);
            customSearch();
          }, 800);
        } else {
          setIsLoading(false);
          setShowMessage(false);
          setSearchDataList([]);
        }   
    }
    return () => {clearTimeout(stopTypingFc)}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);


  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={closeModal}
      id="searchModal"
      cssClass={styles.searchModal}
      onWillPresent={() => setSearchText('') }
    >
      <IonHeader className="ion-no-border">
        <IonToolbar color="light" className={styles.titleToolbar}>
          <IonLabel color="primary" className="ion-margin-start">
            {title}
          </IonLabel>
          <IonButton
            onClick={closeModal}
            slot="end"
            size="small"
            fill="clear"
            shape="round"
            className="ion-margin-end"
          >
            <IonIcon icon={close} slot="icon-only" />
          </IonButton>
        </IonToolbar>
        <IonToolbar color="light" className={styles.searchToolbar}>
          <IonSearchbar
            mode="ios"
            value={searchText}
            disabled={disabledInput}
            onIonChange={(e) => {
              if (e.detail.value !== searchText) {
                setSearchText(e.detail.value);
              }
            }}
            placeholder={placeholder}
          />
        </IonToolbar>
      </IonHeader>

      {!isLoading ? (
        <IonContent>
          {searchDataList?.length > 0 ? (
            <IonList>
              {searchDataList.map((data: any) => (
                <IonItem
                  lines="full"
                  key={data}
                  onClick={() => selectAndClose(data)}
                >
                  <IonLabel className="ion-text-center">{data}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          ) : showMessage && (
            <IonItem className="ion-text-center ion-margin-top" lines="none">
              <IonLabel className="ion-margin-top">
                No se encontraron coincidencias
              </IonLabel>
            </IonItem>
          )}
        </IonContent>
      ) : (
        <div
          className="ion-text-center"
          style={{
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {' '}
          <IonSpinner />
        </div>
      )}
    </IonModal>
  );
};

export default SearchModal;
