import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { environmentUrls } from '../../services/environment/environmentService';
import { routes } from '../home/router/routes';
import AuthHtml from './components/auth-html/AuthHtml';

const StartLogin: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const login_hint = searchParams.get('login_hint'); //ver el uso de esto (si tiene que matchear)
  const history = useHistory();
  const environmentData = localStorage.getItem('environment');


  const redirectComponents = useMemo(() => {
    console.log("DATA DEL ENTORNO",environmentData);
    return {
      state: { A: '/login/gestiones-online', B: (JSON.parse(environmentData) as environmentUrls).redirectRegistrationCallback },
      clientId: (JSON.parse(environmentData) as environmentUrls).clientId,
      redirectURI: (JSON.parse(environmentData) as environmentUrls).redirectURI,
      urlIBM: (JSON.parse(environmentData) as environmentUrls).urlIBM,
    };
  }, [environmentData]);

  const valditations = useMemo(() => {
    return {
      optionsA: ['G', 'D', 'F', 'S'],
      optionsB: ['OVM', 'OVA'],
    };
  }, []);

  const getOriginalLoginHint = useCallback((loginHint: string) => {
    let originalLoginHint;
    if (loginHint) {
      const firstDecode = decodeURIComponent(loginHint?.replace(/\+/g, ' '));
      originalLoginHint = decodeURIComponent(firstDecode.substring(1, firstDecode.length - 1));
      return JSON.parse(originalLoginHint.replace('"{','{').replace('}"','}'));
    }
    return {};
  }, []);

  const validateLoginHint = useCallback(
    (loginHint: any) => {
      const { optionsA, optionsB } = valditations;
      const loginHintObj = JSON.parse(JSON.stringify(loginHint));
      return optionsA.some((option) => option === loginHintObj?.A) && optionsB.some((option) => option === loginHintObj?.B);
    },
    [valditations]
  );

  const getFinalAuthUrl = useCallback(() => {
    const urlAuth = redirectComponents.urlIBM + 
    '?client_id=' + redirectComponents.clientId + 
    '&redirect_uri=' + redirectComponents.redirectURI + '&scope=openid&response_type=code&response_mode=form_post';
    const stateURL = '&state=' + encodeURIComponent(JSON.stringify(redirectComponents.state));
    const loginHintURL = '&login_hint=' + login_hint;
    return urlAuth + stateURL + loginHintURL;
  }, [redirectComponents, login_hint]);

  useEffect(() => {
    const loginHintObj = getOriginalLoginHint(login_hint);
    if (validateLoginHint(loginHintObj)) {
      console.log('validado');
      window.location.href = getFinalAuthUrl();
    } else {
      console.log('no validado');
      history.push(routes.root);
    }
  }, [getFinalAuthUrl, getOriginalLoginHint, history, login_hint, validateLoginHint]);

  return <AuthHtml />;
};

export default StartLogin;
