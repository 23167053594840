import { IGroup } from '../../../../../store/menu/types/MenuResponse';
import { links } from './links';

const {
  inicio,
  cartilla,
  quieroSerSocio,
  miCartilla,
  informacionAlSocio,
  saludYBienestar,
  novedades,
  misTurnos,
  reintegros,
  datosDeContacto,
  empadronamiento,
  autorizaciones,
  facturacion,
  activaTuCredencial,
  credencialProvisoriaMovil,
  pagoEnLinea,
  asistenciaMedica,
  asistenciaMedicaDeUrgencias,
  riesgoDeVida,
  atencionAlSocio,
} = links;

export const linksWeb: Array<IGroup> = [
  {
    title: '',
    items: [
      inicio,
      cartilla,
      quieroSerSocio,
      miCartilla,
      informacionAlSocio,
      saludYBienestar,
      novedades,
      misTurnos,
    ],
  },
  {
    title: 'GESTIONES ONLINE',
    items: [
      reintegros,
      datosDeContacto,
      empadronamiento,
      autorizaciones,
      facturacion,
      activaTuCredencial,
      credencialProvisoriaMovil,
      pagoEnLinea,
    ],
  },
  {
    title: 'CONTACTANOS',
    items: [
      asistenciaMedica,
      asistenciaMedicaDeUrgencias,
      riesgoDeVida,
      atencionAlSocio,
    ],
  },
];

export default linksWeb;