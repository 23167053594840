import React, { useEffect, useState } from 'react'
import { IonContent, IonList, IonPage } from '@ionic/react';
import styles from './VouchersPage.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import pagoEnLineaService from '../../services/PagoEnLineaService';
import { useHistory } from 'react-router';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import { routes as pagoEnLineaRoutes } from '../../router/routes';
import Header from '../../../../../shared/components/layout/header/Header';
import analyticsService from '../../../../../services/analytics/analytics';
import { ErrorModal } from '../../components/ErrorModal/ErrorModal';
import TramiteFooter from '../../../../../shared/components/tramite-footer/TramiteFooter';
import { isWeb } from '../../../../../shared/utils/utils';
import VoucherInformationModal from '../../components/VoucherInformationModal/VoucherInformationModal';
import VoucherCard from './components/VoucherCard/VoucherCard';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import NavigationButton, { NavigationButtonSkeleton } from '../../../../../shared/components/buttons/navigation/NavigationButton';
import { VoucherSkeleton } from '../../components/Voucher/Voucher';
import { Comprobante } from '../../../../../store/pagoEnLinea/types/PagoEnLineaTypes';

const VouchersPage: React.FC = () => {
    const { user } = useSelector((state: RootState) => state.user);
    const [comprobantes, setComprobantes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalVoucher, setModalVoucher] = useState(null);
    const history = useHistory();
    const [openModalError, setOpenModalError] = useState(false);

    const onDismissModal = () => history.replace(pagoEnLineaRoutes.root);

    const onPressNewPay = () => {
        history.push(pagoEnLineaRoutes.payments)
    };

    const onViewVoucherPress = (comprobante: Comprobante) => {
        setModalVoucher(comprobante);
        setShowModal(true);
    };

    useEffect(() => {
        analyticsService.trackEvent('gestiones online', 'menu principal', 'pago en línea - mis pagos');
    }, []);

    useEffect(() => {
        const callGetComprobantes = async () => {
            const { listaComprobantes, objetoMensaje } = await pagoEnLineaService.getComprobantes(user.contrato);

            if (listaComprobantes?.length > 0) {
                analyticsService.trackPageView("/informacion-al-socio/tramites-on-line/pago-en-linea/documentos-pendientes-de-pago");
            } else {
                setOpenModalError(true);
            }
            if (objetoMensaje.codigo === 200) {
                setComprobantes(listaComprobantes)
            }
            setIsLoading(false)
        }
        callGetComprobantes();
    }, [user.contrato]);

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles.vouchersPage}>
                <PageTitle lines>MIS PAGOS EN LINEA</PageTitle>
                <IonList>
                    {!isLoading ?
                        comprobantes?.map((comprobante, i) => (
                            <VoucherCard key={i} voucher={comprobante} onView={onViewVoucherPress} />
                        )) :
                        Array.from({ length: 3 }).map((_, index) => <VoucherSkeleton type='comprobante' key={index} />)
                    }
                </IonList>
            </IonContent>
            <VoucherInformationModal showModal={showModal} setShowModal={setShowModal} voucher={modalVoucher} />
            <TramiteFooter gradientColor='light'>
                {!isLoading ?
                    <NavigationButton data-testid="buttonSiguiente" color='light' onClick={onPressNewPay}>Nuevo pago</NavigationButton>
                    :
                    <NavigationButtonSkeleton color='light' />
                }
                {isWeb() && <Footer />}
            </TramiteFooter>
            <ErrorModal
                isOpen={openModalError}
                onDidDismiss={onDismissModal}
                errorCode={'403'}
            />
        </IonPage>
    )
};

export default VouchersPage;