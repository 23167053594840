import React from 'react';
import { IonToolbar } from '@ionic/react';

import { isIos } from '../../../utils/utils';
import styles from './Footer.module.scss';
import { FOOTER_COPYRIGHT } from '../../../globalConstants';

const Footer: React.FC = () => {

  let style:string[] = [styles.footerBase];
  
  if (!isIos()){
    style = [...style, styles.footerNoIos];
  } 

  return (
  <IonToolbar className={[isIos() ? styles.toolbarIos : ''].join('')}>
    <div className={style.join(' ')} id="id-footer">{FOOTER_COPYRIGHT}</div>
  </IonToolbar>
  )
};

export default Footer;