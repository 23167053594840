import React from 'react';
import { IonModal, IonContent, IonHeader, IonToolbar, IonTitle, IonButton, IonButtons, IonIcon } from '@ionic/react';
import styles from './StandardModalPopup.module.scss';
import { close } from 'ionicons/icons'

interface ModalProps {
    id?: string;
    isOpen: boolean;
    title: string;
    content: any;
    handler: any;
}

const StandardModalPopup: React.FC<ModalProps> = ({ id, isOpen, title, content, handler }) => {
    return (
        <IonModal isOpen={isOpen} id={ id ? id : "modal"} cssClass={[styles["sc-ion-modal-md-h"],styles["standard-modal"]].join(' ')}>
            <IonHeader>
                <IonToolbar color='primary'>
                    <IonTitle size="large" id='modal-title'>
                        <div className="ion-text-wrap">
                            {title}
                        </div>
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={handler} className="button-modal-close" id='close-modal-btn'>
                            <IonIcon className="icon-modal-close" icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent id="content-content">
                {content}
            </IonContent>
        </IonModal>
    );
};

export default StandardModalPopup;

