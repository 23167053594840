import { isWeb } from "../../../shared/utils/utils";
import { cartillaRoutes } from "../cartilla/router/routes";
import { routes } from "../router/routes";
import { HomeLinks } from "../types/HomeLink";


const homeButtonsData: HomeLinks = {
    quieroSerSocio: {
        title: "Quiero ser Socio",
        url: routes.quieroSerSocio,
        externalLink: true,
    },
    cartilla: {
        title: "Cartilla Médica",
        url: cartillaRoutes.root,
        externalLink: isWeb()
    },
    gestionesOnline: {
        title: "Gestiones Online",
        url: routes.gestionesOnline,
        externalLink: false,
        id: "destiones-online-btn"
    },
    telefonos: {
        title: "Contactanos",
        url: routes.contactanos,
        externalLink: false,
        id: "contactanos-btn"
    },
    informacionSocio: {
        title: "Información al Socio",
        url: routes.informacionSocio,
        externalLink: true
    },
    saludYBienestar: {
        title: "Salud y Bienestar",
        url: routes.saludYBienestar,
        externalLink: true,
        blank: true
    },
    novedades: {
        title: "Novedades",
        url: routes.novedades,
        externalLink: true,
        blank: true
    },
    cap: {
        title: "Centros de Atención Personalizada",
        url: cartillaRoutes.caps,
        id: "centros-btn"
    },
    ayuda: {
        title: "Ayuda",
        url: routes.ayuda,
        id: "ayuda-btn"
    }
}

export default homeButtonsData