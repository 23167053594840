import { IonItem, IonIcon, IonSelect } from '@ionic/react';
import React, { ReactNode } from 'react';
import FormErrorMessage from '../form-error-message/FormErrorMessage';
import './SelectForm.scss';


export interface SelectFormProps {
    label: string;
    name?: string;
    header: string;
    subHeader?: string
    register?: any;
    errors?: string;
    labelIcon?: string;
    labelIconOnClick?: (e: any) => void;
    placeHolder: string;
    value?: any;
    onChange?: (e: any) => void | undefined;
    children: Array<ReactNode> | undefined
    compareWith?: any;
}


const SelectForm: React.FC<SelectFormProps> = props => {

    const { name, register, header, label, labelIcon, placeHolder, labelIconOnClick, onChange, errors, value, compareWith } = props

    const customActionSheetOptions = {
        header: header,
        cssClass: 'selectActionSheet'
    };

    return (
        <>
            <IonItem className={['select-form-tramite', 'ion-text-center', errors && 'error-validation'].join(' ')}>
                <label>
                    {label}
                    {labelIcon && <IonIcon color='secondary' icon={labelIcon} slot='end' onClick={labelIconOnClick} />}
                </label>
                <IonSelect
                    mode={'ios'}
                    name={name}
                    ref={register}
                    interfaceOptions={customActionSheetOptions}
                    interface="action-sheet"
                    placeholder={placeHolder}
                    cancelText="x"
                    onIonChange={onChange}
                    value={value}
                    compareWith={compareWith}
                >
                    {props.children}
                </IonSelect>
            </IonItem>
            {errors && <FormErrorMessage>{errors}</FormErrorMessage>}
        </>
    );
}

export default SelectForm;