import { IonTitle } from '@ionic/react';
import React from 'react';
import { isIos } from '../../../../shared/utils/utils';
import styles from './AuthTitle.module.scss';

const AuthTitle: React.FC = ({children}) => {
	return (
		<IonTitle data-testid="auth-title-id" color="primary" className={[styles.titleBase, isIos() ? styles.titleIos: ''].join(' ')}  >
			{children}
		</IonTitle>
	);
};

export default AuthTitle;