
import { IItem } from '../menu/types/MenuResponse';
import { PhoneAction, PHONE_SET_SHOW, PHONE_SET_UNSHOW } from './phoneTypes';

export const setShowPhones = (phones: Array<IItem>): PhoneAction => ({
    payload: phones,
    type: PHONE_SET_SHOW
})

export const setUnShowPhones = (): PhoneAction => ({
    type: PHONE_SET_UNSHOW
})