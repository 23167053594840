import { IonIcon, IonThumbnail, IonToast, IonToolbar } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { IonLabel, IonItem } from '@ionic/react';
import { IonButton } from '@ionic/react';
import { useSelector } from 'react-redux';
import { personCircle, ellipse } from 'ionicons/icons';
import { RootState } from '../../../../../../store';
import styles from './MenuUser.module.scss';
import menuStyles from '../../Menu.module.scss';
import { useHistory } from 'react-router';
import { authRoutes } from '../../../../../../modules/auth/router/routes';
import { useEffect } from 'react';
import datosDeContactoService from '../../../../../../modules/tramites/datos-contacto/services/DatosContactoService';
import { isWeb } from '../../../../../utils/utils';
import PlanSelector from '../plan-selector/PlanSelector';

const MenuUser = ({ onSetOpenPlanSelectorAlert }) => {
  const history = useHistory();
  const {
    user: { user, contratos },
    datosContacto: { numeroDeSolicitud }
  } = useSelector((state: RootState) => state);
  const [pendingProcessDatosContacto, setPendingProcessDatosContacto] = useState<boolean>(false);
  const [showChangedEmailToast, setShowChangedEmailToast] = useState(false);
  const isFirstRender = useRef<boolean>(true);


  useEffect(() => {
    if (!user) return;
    (async () => {
      if (isFirstRender.current) {
        setPendingProcessDatosContacto(await fetchPendingProcess());
        isFirstRender.current = false;
      } else if (numeroDeSolicitud) {
        setPendingProcessDatosContacto(await fetchPendingProcess());
      }
    })();
  }, [user, numeroDeSolicitud]);


  const handleLogOut = () => history.push(authRoutes.logout);


  const fetchPendingProcess = async () => {
    try {
      const { data: buscarTramiteResponse } =
        await datosDeContactoService.buscarTramites(!isWeb());
      return buscarTramiteResponse?.pendingProcess ?? false;
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      <IonToolbar color="primary" className={menuStyles.menu__toolbar}>
        <IonItem color="primary" lines='none' onClick={() => setShowChangedEmailToast(pendingProcessDatosContacto)}>
          <IonThumbnail>
            <IonIcon
              icon={personCircle}
              className={styles.menu__toolbarTitleIcon}
            />
          </IonThumbnail>
          <IonLabel className={[menuStyles.menu__toolbarTitle, 'ion-text-wrap'].join(' ')}>
            <h3>
              {' '}
              {`${user?.nombres} ${user?.apellidos}`}
            </h3>
            <p className={menuStyles.email}>{user?.email}</p>
          </IonLabel>
          {pendingProcessDatosContacto && (
            <IonIcon
              color="success"
              icon={ellipse}
              className={styles.menu__toolbarNotification}
            />
          )}
        </IonItem>

        {contratos?.length > 1 && <PlanSelector openPlanSelectorAlert={onSetOpenPlanSelectorAlert} />}

      </IonToolbar>
      <IonToolbar color="primary" className={`${menuStyles.menu__toolbar} ${styles.marginTop}`}>
        <IonItem color="primary" lines='none'>
          <IonButton
            className={menuStyles.menu__button}
            color="secondary"
            expand="block"
            onClick={handleLogOut}
          >
            Cerrar sesión
          </IonButton>
        </IonItem>
      </IonToolbar>
      <IonToast
        isOpen={showChangedEmailToast}
        onDidDismiss={() => setShowChangedEmailToast(false)}
        message="Tenés un trámite en proceso que estamos gestionando, próximamente verás reflejados los ultimos cambios. Podés consultar su estado en la sección Mis Trámites en la web de OSDE."
        mode="ios"
        position="top"
        duration={8000}
        buttons={[{ text: 'Cerrar', role: 'cancel' }]}
      />
    </>
  );
};

export default MenuUser;
