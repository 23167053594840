import { IonAlert, IonButton, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonModal, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import styles from './VoucherInformationModal.module.scss';
import { getPaymentIcon } from '../../helpers/getPaymentMethods';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { downloadFile } from '../../../../../shared/utils/utils';
import Loading from '../../../../../shared/components/popups/loading/Loading';
import { LOADING } from '../../../../../shared/globalConstants';
import analyticsService from '../../../../../services/analytics/analytics';
import { Comprobante } from '../../../../../store/pagoEnLinea/types/PagoEnLineaTypes';
import pagoEnLineaService from '../../services/PagoEnLineaService';
import { close } from 'ionicons/icons';
import TramiteFooter from '../../../../../shared/components/tramite-footer/TramiteFooter';

interface Params {
  showModal: boolean,
  setShowModal: Function,
  voucher: Comprobante
}

const VoucherInformationModal: React.FC<Params> = ({ showModal, setShowModal, voucher }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messages, setMessages] = useState(null);
  const { user } = useSelector((state: RootState) => state.user);


  useEffect(() => {
    analyticsService.trackEvent('pagos en línea', 'mis pagos', 'ver comprobante');
  }, []);


  useEffect(() => {
    setMessages(separateMensajeDePago(voucher?.mensajeDePago));
  }, [voucher])


  const separateMensajeDePago = (mensajeDePago: string) => (
    <>
      {mensajeDePago?.split('-').map((item, index) => (
        <React.Fragment key={index}>{item} <br /></React.Fragment>
      ))}
    </>
  );


  const onPressDownload = async () => {
    setIsLoading(true);
    analyticsService.trackEvent('pagos en línea', 'mis pagos', 'descargar');
    try {
      const {
        documentoArray,
        objetoMensaje,
      } = await pagoEnLineaService.getComprobantePDF(
        voucher?.siteTransactionId,
        user.contrato
      );

      if (objetoMensaje.codigo !== 200) {
        setShowAlert(true)
      }

      const fileName = `OSDE_${voucher.siteTransactionId}.pdf`;
      downloadFile(fileName, documentoArray, 'application/pdf');
    } catch (e) {
      console.error('Error al obtener el comprobante: ', e)
    } finally {
      setIsLoading(false)
    }
  };


  return (
    <IonModal isOpen={showModal}>
      <IonHeader>
        <IonToolbar>
          <IonButton onClick={() => setShowModal(false)} slot="end" fill="clear" size="small" shape="round">
            <IonIcon icon={close}  />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonPage >
          {voucher && (
            <>
              <IonContent data-testid={`voucher-information-${voucher.contratoSocio}`}>
                <IonGrid>
                  <IonRow className={styles.rowVoucher}>
                    <IonCol size="12">
                      <IonCardSubtitle className={styles.title} mode="md">
                        Nro socio: {voucher && voucher.contratoSocio}
                      </IonCardSubtitle>
                    </IonCol>
                    <IonCol size="12">
                      <IonCardTitle
                        className={styles.title}
                        color="secondary"
                        mode="md"
                      >
                        PAGASTE
                      </IonCardTitle>
                    </IonCol>
                    <IonCol size="12">
                      <IonCardTitle
                        className={styles.title}
                        color="primary"
                        mode="md"
                      >
                        {
                          messages
                        }
                      </IonCardTitle>
                    </IonCol>
                  </IonRow>
                  <IonRow className={styles.rowVoucher}>
                    <IonCol size="12">
                      <IonCardTitle
                        className={styles.title}
                        color="secondary"
                        mode="md"
                      >
                        TOTAL
                      </IonCardTitle>
                    </IonCol>
                    <IonCol size="12">
                      <IonCardTitle
                        className={styles.title}
                        color="primary"
                        style={{
                          fontSize: '10vw',
                        }}
                        mode="md"
                      >
                        {/* <NumberFormat  value={voucher.importe} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalSeparator={'.'}/> */}
                        <IonTitle
                          className={styles.title}
                          color="primary"
                          // mode="md"
                          size="large"
                        >
                          {voucher.importe}
                        </IonTitle>
                      </IonCardTitle>
                    </IonCol>
                  </IonRow>
                  <IonRow className={styles.rowVoucher}>
                    <IonCol size="12">
                      <IonCardTitle
                        className={styles.title}
                        color="secondary"
                        mode="md"
                      >
                        MEDIO DE PAGO
                      </IonCardTitle>
                    </IonCol>
                    <IonCol size="12" className={styles.colCard}>
                      <IonImg src={getPaymentIcon(voucher.medioDePago)} />
                    </IonCol>
                  </IonRow>
                  <IonRow className={styles.rowVoucher}>
                    <IonCol size="12">
                      <IonCardSubtitle className={styles.title} mode="md">
                        Número de comprobante
                      </IonCardSubtitle>
                    </IonCol>
                    <IonCol size="12">
                      <IonCardSubtitle className={styles.title} mode="md">
                        {voucher && voucher.siteTransactionId}
                      </IonCardSubtitle>
                    </IonCol>
                    <IonCol size="12">
                      <IonCardSubtitle className={styles.title} mode="md">
                        {voucher && voucher.fecha}
                      </IonCardSubtitle>
                    </IonCol>
                  </IonRow>
                  <IonRow className={styles.rowVoucher}>
                    <IonCol size="12">
                      <IonCardSubtitle className={styles.title} mode="md">
                        En el resumen de tu tarjeta aparecera
                      </IonCardSubtitle>
                    </IonCol>
                    <IonCol size="12">
                      <IonCardSubtitle className={styles.title} mode="md">
                        con la referencia "OSDE"
                      </IonCardSubtitle>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
              <TramiteFooter>
                <IonToolbar>
                  <IonButton
                    color="primary"
                    expand="block"
                    onClick={onPressDownload}
                  >
                    DESCARGAR
                  </IonButton>
                </IonToolbar>
              </TramiteFooter>
            </>
          )}
        </IonPage>

        <Loading isOpen={isLoading} message={LOADING} />

        <IonAlert
          isOpen={showAlert}
          header={'Error'}
          message={'Lo sentimos, hubo un error al descargar el comprobante. Por favor, intentelo nuevamente.'}
          buttons={[{
            text: 'Aceptar',
            role: 'cancel',
            cssClass: 'primary',
          }]}
        />

      </IonContent>
    </IonModal>
  );
};

export default VoucherInformationModal;