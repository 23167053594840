import { IonPopover } from '@ionic/react';
import React from 'react';
import styles from './Popover.module.scss';
import "./PopoverGlobal.scss";

interface Props {
    event?: any;
    isOpen: boolean;
    showInFullScreen?: boolean;
    onDidDismiss?: (e: any) => void
}

 
const Popover: React.FC<Props> = props => {

    return (
        <IonPopover
            event={props.event}
            mode='ios'
            isOpen={props.isOpen}
            cssClass={[styles.popover, props.showInFullScreen ? 'fullscreen-popover' : null]}
            onDidDismiss={props.onDidDismiss}
        >
            {props.children}
        </IonPopover>
    );
}

export default Popover;