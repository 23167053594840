import React from "react";
import { bannerSlide } from "../../services/HomeService";
import Banner from "../banner/Banner";
import DownloadBanner from "../download-banner/DownloadBanner";
import InstallBanner from "../install-banner/InstallBanner";

const Banners: React.FC<{banners:bannerSlide[]}> = ({banners}) => {

  const _banners = banners?.map((elem, i) => (
    <Banner
      key={i}
      backgroundImg={elem.imgUrl}
      link={elem.link}
      titulo={elem.titulo}
    />
  ));

  return (
    <>
      {_banners}
      <DownloadBanner />
      <InstallBanner />
    </>
  );
};

export default Banners;
