import { IonItem } from '@ionic/react';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import styles from '../stepper/Stepper.module.scss'

export interface StepperProps {
    currentNumber: number,
    stepsLenght: number
}

export interface Steps {
    number: number,
    completed: boolean
}

const Stepper: React.FC<StepperProps> = ({ currentNumber, stepsLenght }) => {
    const [steps, setSteps] = useState<Steps[]>([]);

    useEffect(() => {
        const updatedSteps = [];
        for (var stepNumber = 1; stepNumber <= stepsLenght; stepNumber++) {
            const step: Steps = {
                completed: stepNumber < currentNumber,
                number: stepNumber
            }
            updatedSteps.push(step)
        }
        setSteps(() => updatedSteps)
    }, [currentNumber, stepsLenght])

    return (
        <IonItem lines='none' className={[styles["progress-bar"], 'ion-no-border'].join(' ')}>
            {steps.map((step: Steps, index: number) => {
                return step.number !== stepsLenght &&
                    <Fragment key={index}>
                        <div className={styles["step"]} >
                            <div className={[styles.bullet, step.completed && styles.active1, step.number === currentNumber && styles.active2].join(' ')}>
                                {
                                    step.completed ?
                                        <div className={[styles.check, 'fas fa-check'].join(' ')} /> :
                                        <span>{step.number}</span>
                                }
                            </div>
                        </div>
                        {(step.number !== stepsLenght - 1) && 
                            <div className={[styles['space-lines'], step.completed && styles['space-lines-success']].join(' ')} key={index} />}
                    </Fragment>
            })}
        </IonItem>
    )
}

export default Stepper;