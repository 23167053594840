import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { menuController } from '@ionic/core';
import { authRoutes } from '../router/routes';
import AuthHtml from '../components/auth-html/AuthHtml';

interface Params {
  token: string;
}

const AuthPageLegacy: React.FC = () => {
  const { token } = useParams<Params>(); // home/tokenLegacy/?nullundefined
  const history = useHistory();

  const fireLogin = async () =>{
    setTimeout(() => {
      if (
        (window as any).webkit &&
        (window as any).webkit.messageHandlers &&
        (window as any).webkit.messageHandlers.cordova_iab &&
        (window as any).webkit.messageHandlers.cordova_iab.postMessage
      ) {
        (window as any).webkit.messageHandlers.cordova_iab.postMessage(
          JSON.stringify({
            event: 'auth',
            data: {
              token: token,
            },
          })
        );
      } else {
        console.log("No existe el webkit");
      }
    }, 2000);
  }

  useEffect(() => {
    menuController.close();
    menuController.swipeGesture(false);
    if (token) {
      localStorage.setItem('token', token);
      fireLogin();
    } else {
      // este caso no puede pasar nunca, verificar si se puede quitar
      console.log("ESTO NO DEBERIA PASAR NUNCA O NO ENTENDIMOS NADA");
      history.push(authRoutes.error);
    }

    return () => {
      menuController.swipeGesture(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthHtml />
  );
};

export default AuthPageLegacy;