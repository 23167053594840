import AxiosInstance, { headerDefault } from '../AxiosService';
import { authRoutes } from '../../modules/auth/router/routes';
import { routes } from '../../modules/home/router/routes';
import {  isLocalDev } from '../../shared/utils/utils';
import { ContratoFullData, CodigoContrato, ProxyUserResponseData, User } from '../../store/users/types/User';

import { contratosMock, userDataMock } from '../../store/users/helpers/getUserDataMock';
const baseUrlService = '/CloudIdentityService';

interface AuthService {
  getRedirectAuthority: (forcedRedirectURL?: string) => Promise<string>;
  loginWithCINew: (code?) => Promise<{user: User, contratos: ContratoFullData[]}>;
  checkActiveSession: (refreshToken?) => Promise<boolean>;
  getUserFullData: (refreshToken?) => Promise<{user: User, contratos: ContratoFullData[]}>;
  getLogoutUrl: () => Promise<string>;
  logout: () => Promise<void>;
  getRefreshToken: () => string;
  verifyUrlAuth: (tokenUrl: string, redirectAfterLoggedInUrl: string) => { pathname: string, state?: unknown};
  contratos: (contrato: CodigoContrato) => Promise<boolean>
};

/**
 * Metodo que consulta al proxy el redirect authority del IDM
 *
 * @returns {string} Url del IDM
 */
const getRedirectAuthority = async (forcedRedirectURL?: string) => {
  let urlGetRedirect = `${baseUrlService}/getRedirectAuthority?appType=webMobile&relativePath=${forcedRedirectURL || window.location.pathname}`;
  const resp = await AxiosInstance.get(urlGetRedirect);
  const { response: url_idm } = await resp.data;
  return url_idm;
};

const verifyUrlAuth = (tokenUrl: string, redirectAfterLoggedInUrl: string) => {
  if (tokenUrl.includes('token')) { /// auth/token/ouasy7y1uh21
    if (tokenUrl.split('/').length > 2) { // auth / token / ouasy7y1uh21
      return {
        pathname: `${authRoutes.token}/${tokenUrl.split('/')[tokenUrl.split('/').length - 1]}`, /// home/token/ouasy7y1uh21
        state: redirectAfterLoggedInUrl
      };
    } else {
    return { pathname: authRoutes.error };
    }
  };
  if (tokenUrl.includes('close')) {
    return { pathname: routes.root };
  };
};

const loginWithCINew = async (code?) => {
  try {
    const isRefactor = false;
    let isAvailable = true;
    const url = code
      ? `/loginService/loginWithCINew?token=${code}&isRefactor=${isRefactor}&loginRefactorIAB=${!isAvailable}`
      : "/loginService/loginWithCINew";
    const resp = await AxiosInstance.get(url);
    const fullData: ProxyUserResponseData = await resp.data;
    if (!['2 510'].includes(fullData.usuarioInternet.plan)){
      logout()
      window.location.href = 'https://www.osde.com.ar'
    }
    setLocalStorage(fullData);
    const { contratos, usuarioInternet: user} = fullData;
    return {contratos, user};
  } catch (error) {
    console.error('Ocurrio un error al loguearse: ',error);
    return null;
  };
};

/**
 * Metodo que consulta al proxy si hay una sesion activa
 *
 * @returns {boolean} Sesion activa
 */
const checkActiveSession = async (refreshToken?) => {
  if (isLocalDev()){
    return true;
  }
  const refreshToken2 = refreshToken ? refreshToken : localStorage.getItem('refreshToken');
  const url = `/loginService/checkActiveSessionNew${refreshToken2 ? `?refreshToken=${refreshToken2}` : ''}`;
  try {
    const resp = await AxiosInstance.get(url);
    const { response: isSessionActive } = await resp.data;
    return isSessionActive;
  } catch (error) {
    return false;
  };
};

/**
 * Metodo que consulta al proxy los datos del usuario logueado
 *
 * @returns {User} Datos del usuario
 */
const getUserFullData = async (refreshToken?) => {
  if (isLocalDev()){
    return {contratos: contratosMock, user: userDataMock};
  }
  const refreshToken2 = refreshToken ? refreshToken : localStorage.getItem('refreshToken');
  const url = `/loginService/getUserFullData${refreshToken2 ? `?refreshToken=${refreshToken2}` : ''}`;
  try {
    const resp = await AxiosInstance.get(url);
    const fullData: ProxyUserResponseData = await resp.data;
    if (!fullData) return;
    setLocalStorage(fullData);
    const {contratos, usuarioInternet: user} = fullData;
    return {contratos, user};
  } catch (error) {
    console.log("error al intentar buscar el usuario ", error);
    return undefined;
  };
};

/**
 * Metodo que setea el contrato elegido en el usuario
 *
 * @returns {ProxyContratosResponseData} Mensaje y código de resultado
 */
const contratos = async (requestData: CodigoContrato) => {
  try {
    const resp = await AxiosInstance.post('/contratos/', {
      body: requestData,
      header: makeRequestHeader()
    });
    return await resp.data;
  } catch (error) {
    console.log(error)
    return null;
  }
}

/**
 * Metodo que consulta al proxy la url del logout del IDM
 *
 * @returns {string} Url del logout del IDM
 */
const getLogoutUrl = async () => {
  const resp = await AxiosInstance.get(`${baseUrlService}/getLogoutUrl`);
  const { response: logout_url } = await resp.data;
  localStorage.removeItem('usuarioCartilla');
  localStorage.removeItem("refreshTokenDateLimit");
  return logout_url;
};

/**
 * Metodo que desloguea al usuario del proxy
 *
 *
 */
const logout = async () => {
  await AxiosInstance.get("/loginService/logout");
  localStorage.removeItem('usuarioCartilla');
  localStorage.removeItem("refreshTokenDateLimit");
};

const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

const setLocalStorage = (proxyUserResponse: ProxyUserResponseData) => {
  localStorage.setItem('refreshToken', proxyUserResponse.usuarioInternet.refreshToken);
  localStorage.setItem('usuarioCartilla', JSON.stringify(proxyUserResponse.usuarioInternet));
  localStorage.setItem('refreshTokenDateLimit', (Date.now() + proxyUserResponse.usuarioInternet.refreshTokenTimeLeft).toString());
}

const makeRequestHeader = () => {
  return {...headerDefault, refreshToken: authService.getRefreshToken()}
}

/**
 * Metodo que consulta al proxy el redirect authority del IDM
 *
 * @returns {AuthService} getRedirectAuthority
 * @returns {AuthService} checkActiveSession
 * @returns {AuthService} getUserFullData
 * @returns {AuthService} getLogoutUrl
 * @returns {AuthService} logout
 */
const authService: AuthService = {
  getRedirectAuthority,
  loginWithCINew: loginWithCINew,
  checkActiveSession,
  getUserFullData,
  contratos,
  getLogoutUrl,
  logout,
  getRefreshToken,
  verifyUrlAuth
};

export default authService;