import { IonContent, IonFooter, IonItemDivider, IonPage } from '@ionic/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import Header from '../../../../../shared/components/layout/header/Header';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { isWeb } from '../../../../../shared/utils/utils';
import { RootState } from '../../../../../store';
import AntecedentesCheckList from '../../components/antecedentes-checklist/AntecedentesCheckList';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import styles from '../antecedentes-salud-page/AntecedentesSaludPage.module.scss';
import { routes as empadronamientoRoutes } from '../../../empadronamiento/router/routes';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import analyticsService from '../../../../../services/analytics/analytics';
import { routes as HomeRoutes } from '../../../../home/router/routes'
import AntecedenteItem from '../../components/antecedente-item/AntecedenteItem';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';
import { setNingunAntecedente } from '../../../../../store/empadronamiento/actions';
import { useCallback } from 'react';

const AntecedentesSaludPage: React.FC = () => {
  const antecedentes = useSelector(
    (state: RootState) => state.empadronamiento.antecedentes
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { ningunAntecedente } = useSelector((state: RootState) => state.empadronamiento);

  useEffect(() => {
    if (antecedentes?.length === 0) {
      history.push(HomeRoutes.root);
    } else {
      analyticsService.trackPageViewForm('empadronamiento anticonceptivos > 1. selección de beneficiaria', '/tramites/empadronamiento-anticonceptivos/antecedentes', 0, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickNext = () => {
    dispatch(SetStepperNavigation(Animations.next))
    history.push(empadronamientoRoutes.verificar)
  }

  const handleCheckNingunAntecedente = useCallback((checked) => dispatch(setNingunAntecedente(checked)), [dispatch]);

  return (
    <IonPage>
      <Header showBackButton={true} />
      <IonContent className={`${styles.antecedentesContent} ion-no-padding`}>

          <PageTitle>
            Empadronamiento para Anticonceptivos
          </PageTitle>

          <Stepper stepsLenght={4} currentNumber={2} />

          <StepTitle>Antecedentes de Salud</StepTitle>

          <AntecedentesCheckList
            title="Antecedentes"
            antecedentes={antecedentes.filter(
              (antecedente) => antecedente.codigo <= 4
            )}
          />

          <AntecedentesCheckList
            title="Anticonceptivos"
            antecedentes={antecedentes.filter(
              (antecedente) => antecedente.codigo > 4
            )}
          />

          <IonItemDivider />

          <AntecedenteItem 
            antecedente={{descripcion: "Ninguna de las anteriores"}}
            checkedValue={ningunAntecedente}
            checkedAction={handleCheckNingunAntecedente}
          />

      </IonContent>

      <IonFooter mode="md" className="ion-no-border">
        <NavigationButton
          disabled={!ningunAntecedente}
          onClick={onClickNext}
        >
          Siguiente
        </NavigationButton>
        {isWeb() && <Footer />}
      </IonFooter>
    </IonPage>
  );
};

export default AntecedentesSaludPage;