
import {
  ADD_TALON,
  PagoEnLineaAction,
  REMOVE_TALON,
  SET_PAYNUMBER,
  SET_PAYMENT_METHOD,
  SET_GET_FORM,
  SET_TOKEN,
  SET_FIRST_SIX_NUMBER,
  SET_EMAIL,  
  SET_POST_FORM_RESPONSE,
  SET_VOUCHER,
  SET_SHOW_INITIAL_MESSAGE,
  UNSET_PAGO
} from './types/actionTypes';
import { SET_UNAVAILABLE } from './types/actionTypes';
import { Comprobante, GetFormResponse, PaymentMethod, PostFormResponse, Talon } from './types/PagoEnLineaTypes';

export const setUnavailable = (): PagoEnLineaAction => {
  return {
    type: SET_UNAVAILABLE,
    payload: false,
  };
};

export const setPayNumber = (PayNumber: string): PagoEnLineaAction => ({
  type: SET_PAYNUMBER,
  payload: PayNumber,
});

export const addTalon = (talon: Talon): PagoEnLineaAction => ({
  type: ADD_TALON,
  payload: talon,
});

export const removeTalon = (talon: string): PagoEnLineaAction => ({
  type: REMOVE_TALON,
  payload: talon,
});

export const setPaymentCard = (payment: PaymentMethod): PagoEnLineaAction => ({
  type: SET_PAYMENT_METHOD,
  payload: payment,
});

export const setGetForm = (resp: GetFormResponse): PagoEnLineaAction => ({
  type: SET_GET_FORM,
  payload: resp,
});

export const setToken = (token: string): PagoEnLineaAction => ({
  type: SET_TOKEN,
  payload: token
})

export const setFirstSixNumbers = (firstSixNumbers: string): PagoEnLineaAction => ({
  type: SET_FIRST_SIX_NUMBER,
  payload: firstSixNumbers
})

export const setEmail = (email: string): PagoEnLineaAction => ({
  type: SET_EMAIL,
  payload: email
})

export const setPostFormResponse = (resp: PostFormResponse): PagoEnLineaAction => ({
  type: SET_POST_FORM_RESPONSE,
  payload: resp,
});

export const setVoucher = (voucher: Comprobante): PagoEnLineaAction => ({
  type: SET_VOUCHER,
  payload: voucher
})

export const SetShowInitialMessage = (showMessage: boolean): PagoEnLineaAction => ({
  type: SET_SHOW_INITIAL_MESSAGE,
  payload: showMessage
})

export const unSetPago = (): PagoEnLineaAction => ({
  type: UNSET_PAGO
})