import { InAppBrowser } from "@ionic-native/in-app-browser";
import { updateCookies } from "../../inAppBrowser/helpers/iabBrowserHelpers";

/**
 * Metodo que limpia las cookies de usuario.
 * @author cgarrido
 */
export const cleanUserCookies = async() => {
    updateCookies("https://google.com/", "SID=;domain=.google.com;path=/"); 
    updateCookies("https:facebook.com/", "c_user=;domain=.facebook.com;path=/");
    let ref = InAppBrowser.create("https://m.facebook.com/", '_blank', {
        hidden: 'yes',
        location: 'yes',
        clearcache: 'yes',
    });
    ref.on('loadstop').subscribe(() => {
        ref = InAppBrowser.create("https://m.facebook.com/", '_blank', {
            hidden: 'yes',
            location: 'yes',
            clearcache: 'no',
        });
        ref.close();
    });   
};