import { IonRow, IonCol, isPlatform } from "@ionic/react";
import { getUrlStore } from "../../../../shared/utils/utils";
import React from "react";
import styles from "./DownloadBanner.module.scss";
import bannerStyles from "../banner/Banner.module.scss";

const isAndroid = () => isPlatform('android');
const isIphone = () => isPlatform('iphone');
const isIos = () => isPlatform('ios');

const DownloadBanner: React.FC = () => {
  const getImagePlatform = () => {
    let res = "";
    if (isAndroid()) {
      res = `${process.env.PUBLIC_URL}/assets/home/android-download-logo.png`;
    }
    if (isIphone() || isIos()) {
      res =  `${process.env.PUBLIC_URL}/assets/home/ios-download-logo.png`;
    }
    return res;
  };

  const image = getImagePlatform();
  const url = getUrlStore();

  return isAndroid() || isIphone() || isIos() ? (
    <IonRow>
      <IonCol className='ion-no-padding'>
        <div className={styles["app-store-banner"]}>
          <div className={styles["title-store-banner"]}>
            Descargá la aplicación y llevá la cartilla siempre con vos.
          </div>
          <div className={bannerStyles["logo-store"]}>
            <a className={styles["logo-store-banner"]} href={url}>
              <img src={image} alt="banner" />
            </a>
          </div>
        </div>
      </IonCol>
    </IonRow>
  ) : null;
};

export default DownloadBanner;
