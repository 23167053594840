import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { setHttpError } from '../../../../../store/ui/uiActions';
import AlertPopup from '../alert/AlertPopup';

const HttpErrorAlert: React.FC = () => {
    const { httpError } = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();
    return (
        <AlertPopup
            isOpen={httpError?.showAlert}
            title={httpError?.title}
            message={httpError?.message}
            buttons={[{ text: 'Aceptar', handler: () => { dispatch(setHttpError(null)); httpError?.alertConfirmAction?.() } }]}
        />
    );
};

export default HttpErrorAlert;