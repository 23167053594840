import { IonText, IonList, IonPage, IonContent, IonFooter } from '@ionic/react';
import React, { useEffect } from 'react';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import styles from './StepDatosContacto.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { setDatosSocio } from '../../../../../store/autorizaciones/AutorizacionesActions';
import InputForm from '../../../../../shared/components/form/inputForm/InputForm';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import { isNaNOnKeyPress, isNaNOnPaste, isWeb } from '../../../../../shared/utils/utils';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import { useHistory, useLocation } from 'react-router';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import Header from '../../../../../shared/components/layout/header/Header';
import {routes as AutorizacionesRoutes} from '../../router/routes';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import { routes as homeRoutes } from '../../../../home/router/routes';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';


const StepDatosContacto: React.FC = () => {  
    const {datosSocio, obtainedData, stepDetails} = useSelector((state: RootState) => state.autorizaciones);
    const {isKeyboardOn} = useSelector((state: RootState) => state.ui);
    const history = useHistory();
    const dispatch = useDispatch();
    const {state} = useLocation<{from: string}>();

    useEffect(() => {
        if(!stepDetails){
            history.push(homeRoutes.root);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { handleSubmit, formState, control } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: datosSocio?.emailContacto ?? obtainedData?.email ?? '',
            telefono: datosSocio?.telefonoContacto ?? obtainedData?.telefono ?? ''
        }
    });

    const getPageIndex = (route : string): number => {
      const currentStep = stepDetails?.editOrder?.find(step => step.path === route);
      return currentStep?.page;
    }

    const { isValid } = formState;

    const onSubmit = (dataForm: any) => {
        dispatch(SetStepperNavigation(Animations.next))
        const datosUpdated = {...datosSocio !};
        datosUpdated.telefonoContacto = dataForm.telefono;
        datosUpdated.emailContacto = dataForm.email;

        dispatch(setDatosSocio(datosUpdated));
        if(state?.from){
            history.goBack();
        } else {
            history.push(AutorizacionesRoutes.verificarDatos);
        }
    }

    const footer = () => (
        <IonFooter className='ion-no-border'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <NavigationButton text='Datos Obligatorios(*)' type='submit' disabled={!isValid}>SIGUIENTE</NavigationButton>
            </form> 
            {isWeb() && <Footer />}
        </IonFooter>
    )
    
    return (
        <IonPage>
            <Header showBackButton={true} /> 
            <IonContent>
                <PageTitle>{stepDetails?.titleAndLenght?.title}</PageTitle> 

                {getPageIndex(AutorizacionesRoutes.datosContacto) && 
                    <Stepper currentNumber={getPageIndex(AutorizacionesRoutes.datosContacto)} stepsLenght={stepDetails?.titleAndLenght?.lenghtOfPages}></Stepper>
                }
                
                <StepTitle>
                    Datos de contacto
                </StepTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonList className='ion-margin-horizontal'>

                        <Controller
                            render={({onChange, value}) => (
                                <InputForm 
                                    label='Email (*)' 
                                    onChange={(e) => {
                                        onChange(e.detail.value)                               
                                    }}
                                    value={value}
                                    maxLength={50}
                                />
                            )}
                            name="email"
                            rules={{required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}}
                            control={control}
                        />

                        <Controller 
                            render={({onChange, value}) => (
                                <InputForm 
                                    label='Teléfono (*)'
                                    onChange={(e) => {
                                        onChange(e.detail.value)
                                    }}
                                    type='tel'
                                    value={value}
                                    onKeyPress={(e) => isNaNOnKeyPress(e) && e.preventDefault()}
                                    onPaste={(e) => isNaNOnPaste(e) && e.preventDefault()}
                                    maxLength={16}
                                />
                            )}
                            rules={{required: true}}
                            name='telefono'
                            control={control}
                            inputMode={'numeric'}
                        />

                        <IonText className={styles['text-size']} >
                            Tené en cuenta que los datos ingresados no modifican tu información de contacto existente en OSDE.
                        </IonText>   
                    
                    </IonList>          
                </form>

                {isKeyboardOn && footer()}
            </IonContent>
            {!isKeyboardOn && footer()}                        
        </IonPage>
    );
};

export default StepDatosContacto;