import { IonCol, IonContent, IonFooter, IonGrid, IonItem, IonLabel, IonPage, IonRadio, IonRadioGroup, IonRow, IonText } from '@ionic/react';
import { timeOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import AlertPopup from '../../../../../shared/components/popups/alerts/alert/AlertPopup';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import DateTime from '../../../../../shared/components/form/date-time/DateTime';
import TextareaForm from '../../../../../shared/components/form/textarea-form/TextareaForm';
import Header from '../../../../../shared/components/layout/header/Header';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { isDateGreater, isWeb } from '../../../../../shared/utils/utils';
import { RootState } from '../../../../../store';
import { setDetalle, setTraslado } from '../../../../../store/autorizaciones/AutorizacionesActions';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import {routes as AutorizacionesRoutes} from '../../router/routes';
import styles from './StepTrasladosPage.module.scss'
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import analyticsService from '../../../../../services/analytics/analytics';
import { routes as homeRoutes } from '../../../../home/router/routes';
import { Traslado } from '../../../../../store/autorizaciones/types/AutorizacionesData';
import { SetStepperNavigation} from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';
import GenericAlert from '../../../../../shared/components/popups/alerts/generic-alert/GenericAlert';
import PopUp72horas from '../../components/72horas-popUp/PopUp72horas';
interface TrasladoFormValidate{
    fecha: string,
    hora: string,
    medico: boolean | '',
    espera: boolean | '',
    observaciones: string
}

const StepTrasladosPage: React.FC =() => {

    const [openExpiredDateModal, setExpiredDateOpenModal] = useState<boolean>(false);
    const [openWarningModal, setOpenWarningModal] = useState<boolean>(false)
    const {traslado, stepDetails, detalle } = useSelector((state: RootState) => state.autorizaciones);
    const {isKeyboardOn} = useSelector((state: RootState) => state.ui);
    const { state } = useLocation<{from: string}>();    
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if(!stepDetails){
            history.push(homeRoutes.root);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const { handleSubmit, control, formState } = useForm<TrasladoFormValidate>({
        mode: 'onChange',
        defaultValues:{
            fecha: traslado?.fechaTraslado ?? '',
            hora: traslado?.horaTraslado ?? '',
            medico: traslado?.conAmbulancia ?? '',
            espera: traslado?.conEsperaRegreso ?? '',
            observaciones: detalle ?? ''
        }
    });


    const { isValid } = formState;


    const goToNextPage = () => {
        if(state?.from){
            history.goBack();
        } else {
            return history.push(AutorizacionesRoutes.domicilioOrigenDestino)
        }
    }


    const onSubmit = (dataForm: any) => {
        if(new Date(dataForm.fecha).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)){
            setExpiredDateOpenModal(true);
            return;
        }
        const { medico: conAmbulancia, espera: conEsperaRegreso, fecha: fechaTraslado , hora: horaTraslado, observaciones } = dataForm;
        const trasladoUpdated: Traslado  = { conAmbulancia, conEsperaRegreso, fechaTraslado, horaTraslado };

        dispatch(SetStepperNavigation(Animations.next))
        dispatch(setTraslado(trasladoUpdated));
        dispatch(setDetalle(observaciones));

        if(!isDateGreater(dataForm.fecha.split("T")[0])){
            analyticsService.trackPageView('/gestiones-online/autorizaciones?m=72hs-habiles');
            setOpenWarningModal(true);
            return;
        }

        goToNextPage();
       
    }

    const footer = () => (
        <IonFooter className='ion-no-border'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <NavigationButton disabled={!isValid} type='submit' >Siguiente</NavigationButton>
            </form>
            {isWeb() && <Footer /> }
        </IonFooter>
    )


    return ( 
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles.traslados}>

                <PageTitle>{stepDetails?.titleAndLenght?.title}</PageTitle>

                <Stepper currentNumber={2} stepsLenght={stepDetails?.titleAndLenght?.lenghtOfPages} />

                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonGrid >
                        <div className= {styles['ion-margin-horizontal']}>
                            <IonRow>
                                <IonCol>
                                    <Controller 
                                        render={({onChange, value}) => (
                                            <DateTime 
                                                label="Fecha del traslado(*)" 
                                                onChange={(e: any) => {onChange(e.detail.value)}}
                                                name="fecha" 
                                                value={value}
                                                max={(new Date().getFullYear() + 5) + "-12-31"}
                                                min={(new Date().toISOString())}
                                            />
                                        )}
                                        name="fecha"
                                        control={control}
                                        rules={{required: true}}
                                    />
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonText>
                                        En caso de que el traslado se repita en distintas fechas, por favor, indicalas en el campo Observaciones 
                                    </IonText>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <Controller 
                                        render={({onChange, value}) => (    
                                            <DateTime 
                                                label="Horario de destino(*)" 
                                                icon={timeOutline}
                                                onChange={(e: any) => 
                                                    onChange(e.detail.value)
                                                }
                                                name="hora"  
                                                placeholder="--:--" 
                                                format={"HH:mm"} 
                                                value={value}
                                            />
                                        )}
                                        name="hora"
                                        rules={{required:true}}
                                        control={control}  
                                    />
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonText>
                                        HH:MM
                                    </IonText>
                                </IonCol>
                            </IonRow>

                            <IonRow className="ion-margin-top">
                                <IonCol className={styles["row-text"]}>
                                    <IonText>
                                        Tipo de ambulacia (*)
                                    </IonText>
                                </IonCol>
                            </IonRow>

                            <Controller
                                render={({onChange, value}) => (
                                    <IonRadioGroup
                                        value={value}
                                        onIonChange={e => { onChange(e.detail.value) }}
                                        >   
                                        <IonRow>
                                            <IonCol>
                                                <IonItem lines="none" className={[styles["radio-item"], value && styles["radio-selected"]].join(' ') }>
                                                    <IonLabel>Con medico</IonLabel>
                                                    <IonRadio value={true} />
                                                </IonItem>
                                            </IonCol>
                                            <IonCol>
                                                <IonItem lines="none"  className={[styles["radio-item"], (value === false) && styles["radio-selected"]].join(' ')}>
                                                    <IonLabel>Sin medico</IonLabel>
                                                    <IonRadio value={false} />
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                    </IonRadioGroup>
                                )}
                                control={control}
                                name='medico'
                                rules={{validate: value => typeof value === 'boolean' }}
                                defaultValue={undefined}
                            />

                            <IonRow className="ion-margin-top">
                                <IonCol className={styles["row-text"]}>
                                    <IonText>
                                        Con espera y regreso (*)
                                    </IonText>
                                </IonCol>
                            </IonRow>

                            <Controller 
                                render={({onChange, value}) => (
                                    <IonRadioGroup
                                        value={value}
                                        onIonChange={e => {onChange(e.detail.value)}}
                                        >   
                                        <IonRow>
                                            <IonCol>
                                                <IonItem lines="none" className={[styles["radio-item"], value && styles["radio-selected"]].join(' ')}>
                                                    <IonLabel>Si</IonLabel>
                                                    <IonRadio value={true} />
                                                </IonItem>
                                            </IonCol>
                                            <IonCol>
                                                <IonItem lines="none" className={[styles["radio-item"], (value === false) && styles["radio-selected"]].join(' ')}>
                                                    <IonLabel>No</IonLabel>
                                                    <IonRadio value={false} />
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                    </IonRadioGroup>
                                )}
                                control={control}
                                name='espera'
                                rules={{validate: value => typeof value === 'boolean' }}
                                defaultValue={undefined}
                            />

                            <IonRow>
                                <IonCol>
                                    <Controller 
                                        render={({onChange, value}) => 
                                            <TextareaForm 
                                                onChange={onChange}
                                                value={value}
                                                name="observaciones" 
                                                label="Observaciones"
                                                placeholder="Cantidad máxima 200 caracteres" 
                                            /> 
                                        }
                                        control={control}
                                        name='observaciones'
                                    />
                                </IonCol>
                            </IonRow>
                        </div>
                    </IonGrid>
                </form>
                
                {isKeyboardOn && footer()}

            </IonContent>
            
            {!isKeyboardOn && footer()}                         

            <AlertPopup
                isOpen={openExpiredDateModal}
                message="La fecha de realización debe ser igual o mayor al día de hoy"
                title="Aviso"
                buttons={[
                    {text: 'Aceptar', handler:() => setExpiredDateOpenModal(false)}
                ]}
            />

            <GenericAlert
                isOpen={openWarningModal}
                title='Aviso'
                buttons={[
                    { children: 'Continuar', onClick: () => { setOpenWarningModal(false); goToNextPage(); }},
                    { children: 'Volver', onClick: () => setOpenWarningModal(false)},
                ]}
            >
                <PopUp72horas />
            </GenericAlert>
 
        </IonPage>
    );
}
 
export default StepTrasladosPage