import { IonButton, IonSkeletonText, IonText, IonThumbnail, IonToolbar } from '@ionic/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ProgressBar from '../progress-bar/ProgressBar';
import styles from './NavigationButton.module.scss';

export interface INavigationButtonProps extends React.ComponentPropsWithoutRef<typeof IonToolbar> {
  id?: string;
  ["data-testid"]?: string;
  text?: string;
  type?: 'submit';
  onClick?: () => void;
  disabled?: boolean;
  isFinished?: boolean; //obligatoria para mostrar ProgressBar
  success?: boolean; //obligatoria para mostrar ProgressBar
  reset?: boolean;
};

const NavigationButton: React.FC<INavigationButtonProps> = (props) => {
  const { id, "data-testid": dataTestid, onClick, children, disabled, text, type, isFinished, success, reset, ...rest } = props;
  const [disabledButton, setDisabledButton] = useState<boolean>();
  const [startProgress, setStartProgress] = useState(false);
  const [showProgress] = useState(props.hasOwnProperty('isFinished') && props.hasOwnProperty('success'));

  useEffect(() => {
    setDisabledButton(disabled)
  }, [disabled]);

  return (
    <IonToolbar className={`${styles.navigationToolbar} ion-text-center`} {...rest}>
      {text && <IonText className={styles.disclaimer}>{text}</IonText>}
      <IonButton
        mode='md'
        id={id || 'id-nav'}
        data-testid={dataTestid || 'id-nav'}
        className={`${styles.navigationButton} ${showProgress && styles.showProgress} ion-text-uppercase`}
        type={type}
        disabled={disabledButton}
        onClick={() => { onClick?.(); showProgress && setStartProgress(true); }}
      >
        {showProgress &&
          <ProgressBar
            isFinished={isFinished}
            success={success}
            reset={reset}
            setDisabledButton={setDisabledButton}
            startProgress={startProgress}
            setStartProgress={setStartProgress}
          />
        }
        {!startProgress && children}
      </IonButton>
    </IonToolbar>
  );
};

export default NavigationButton;

export const NavigationButtonSkeleton: React.FC<INavigationButtonProps> = (props) => (
  <IonToolbar className={`${styles.navigationToolbar} ion-text-center`} {...props}>
    <IonThumbnail className={styles.navigationButton}>
      <IonSkeletonText animated />
    </IonThumbnail>
  </IonToolbar>
);