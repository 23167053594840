import {
  IonButton,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header from '../../../../../shared/components/layout/header/Header';
import { RootState } from '../../../../../store';
import CorrectMark from '../../assets/img/check-final-1.png';
import styles from './ConfirmedPaymentPage.module.scss';
import { unSetPago } from '../../../../../store/pagoEnLinea/actions';
import analyticsService from '../../../../../services/analytics/analytics';
import { routes } from '../../../../home/router/routes';
import TramiteFooter from '../../../../../shared/components/tramite-footer/TramiteFooter';
import VoucherInformationModal from '../../components/VoucherInformationModal/VoucherInformationModal';


const ConfirmedPaymentPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { payNumber, voucher } = useSelector((state: RootState) => state.pagoEnLinea);

  useEffect(() => {
    if (!payNumber) {
      history.replace(routes.gestionesOnline);
    } else {
      analyticsService.trackPageViewForm("trackPageviewForm", "/gestiones-online/pago-en-linea/formulario-enviado", "pagos en línea - 3. confirmación", 1);
    }
  }, [history, payNumber]);

  useEffect(() => {
    return () => { dispatch(unSetPago()) }
  }, [dispatch]);

  const onViewVoucher = () => {
    setShowModal(true);
  };

  const goToHome = () => {
    history.push(routes.gestionesOnline);
  }

  return (
    <IonPage>
      <Header showBackButton={false} />
      <section className={`${styles.page} ion-padding-top`}>
        <div className={styles['image-container']}>
          <IonImg src={CorrectMark} />
        </div>

        <IonItem className={styles['text-light-blue']} lines="none">
          <IonLabel className="ion-text-wrap">
            TU PAGO FUE REALIZADO EN FORMA EXITOSA
          </IonLabel>
        </IonItem>

        <IonItem className={styles['blue-text-comprobante']} lines="none">
          <IonLabel className="ion-text-wrap">
            El número de comprobante de pago es <b>{payNumber}</b>
          </IonLabel>
        </IonItem>

        <IonItem
          className={[
            styles['blue-text-comprobante'],
            styles['blue-text'],
          ].join(' ')}
          lines="none"
        >
          <IonLabel className="ion-text-wrap">
            En caso de haber abonado luego de la fecha de vencimiento, los
            intereses seran incluidos en el próximo período.
          </IonLabel>
        </IonItem>
      </section>
      <TramiteFooter>
        <IonToolbar>
          <IonButton color="secondary" expand="block" onClick={onViewVoucher}>
            VER COMPROBANTE
          </IonButton>
        </IonToolbar>
        <IonToolbar>
          <IonButton
            expand="block"
            color="secondary"
            fill="clear"
            onClick={goToHome}
          >
            VOLVER AL INICIO
          </IonButton>
        </IonToolbar>
      </TramiteFooter>

      <VoucherInformationModal 
        showModal={showModal} 
        setShowModal={() => setShowModal(false)} 
        voucher={voucher} />

    </IonPage>
  );
};

export default ConfirmedPaymentPage;