import {
  EmpadronamientoAction,
  SET_ACEPTA_TERMINOS,
  SET_EMPADRONAMIENTO_GETFORM,
  SET_NINGUN_ANTECEDENTE,
  SET_NUMERO_TRAMITE,
  SET_SOCIA_EMPADRONAMIENTO,
  UNSET_EMPADRONAMIENTO,
} from './actionsType';
import { EmpadronamientoState} from '../../modules/tramites/empadronamiento/types/EmpadronamientoTypes';


const initialState: EmpadronamientoState = {
  antecedentes: [],
  listaSocias: [],
  sociaElegida: null,
  numeroTramite: null,
  aceptaTerminos: false,
  ningunAntecedente: false
};


export const empadronamientoReducer = (
  state: EmpadronamientoState = initialState,
  action: EmpadronamientoAction
) => {
  switch (action.type) {
    case SET_EMPADRONAMIENTO_GETFORM: {
      return {
        ...state,
        ...action.payload
      };
    }
    case SET_SOCIA_EMPADRONAMIENTO: {
      return {
        ...state,
        sociaElegida: action.payload
      };
    }
    case SET_ACEPTA_TERMINOS: {
      return {
        ...state,
        aceptaTerminos: action.payload
      };
    }
    case SET_NINGUN_ANTECEDENTE: {
      return {
        ...state,
        ningunAntecedente: action.payload
      };
    }
    case SET_NUMERO_TRAMITE: {
      return {
        ...state,
        numeroTramite: action.payload
      };
    }
    case UNSET_EMPADRONAMIENTO: {
      return initialState
    }
    default:
      return { ...state };
  }
};