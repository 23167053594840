import { IonContent, IonFooter, IonImg, IonList, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import InputForm from '../../../../../shared/components/form/inputForm/InputForm';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import styles from './StepDatosFacturacion3Page.module.scss'
import { documentTextOutline } from 'ionicons/icons';
import DateTime from '../../../../../shared/components/form/date-time/DateTime';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { IDComprobantes, ReintegroDataType } from '../../../../../store/reintegros/types/ReintegrosData';
import { setNuevoReintegro } from '../../../../../store/reintegros/ReintegrosActions';
import Popover from '../../components/popover/Popover';
import caeImg from '../../assets/img/nro-cae.png'
import importeImg from '../../assets/img/importe.png'
import MaskedInputForm from '../../../../../shared/components/form/Masked-input-form/MaskedInputForm';
import { useHistory, useLocation } from 'react-router';
import Header from '../../../../../shared/components/layout/header/Header';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { routes } from '../../router/routes';
import { routes as homeRoutes } from '../../../../home/router/routes';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import { ionDateTimeToISOString, isIos, isNaNOnKeyPress, isNaNOnPaste, isWeb } from '../../../../../shared/utils/utils';
import analyticsService from '../../../../../services/analytics/analytics';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';


const StepDatosFacturacion3Page: React.FC = () => {

    const nuevoReintegro = useSelector((state: RootState) => state.reintegros.nuevoReintegro);
    const {isKeyboardOn} = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation<{ from: string }>();
    const [showCAEPopover, setShowCAEPopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });
    const [showImportePopover, setShowImportePopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });

    useEffect(() => {
        if (!nuevoReintegro) {
            history.push(homeRoutes.gestionesOnline);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    interface FacturacionFormProperties {
        cae: string,
        vencimientoCAE: string,
        importe: string,
        sesiones?: string
    }

    const { control, handleSubmit, errors, clearErrors } = useForm<FacturacionFormProperties>({
        mode: 'onSubmit',
        defaultValues: {
            cae: nuevoReintegro?.cae ?? '',
            vencimientoCAE: nuevoReintegro?.fechaVencimientoCae ?? '',
            importe: nuevoReintegro?.importe ?? '',
            sesiones: nuevoReintegro?.cantidadSesiones?.toString() ?? ''
        }
    });

    useEffect(() => {
        if (errors.cae)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.cae}`, routes.datosFacturacion3);
        if (errors.importe)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.importe}`, routes.datosFacturacion3);
        if (errors.sesiones)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.sesiones}`, routes.datosFacturacion3);
        if (errors.vencimientoCAE)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.vencimientoCAE}`, routes.datosFacturacion3);
    }, [errors])

    const onSubmit = (dataForm: any) => {
        const nuevoReintegroUpdated: ReintegroDataType = {
            ...nuevoReintegro!,
            cae: dataForm.cae,
            fechaVencimientoCae: ionDateTimeToISOString(dataForm.vencimientoCAE),
            importe: dataForm.importe.replace('＄ ', ''),
        };
        if (nuevoReintegro?.tipoPractica?.sesionesMaximas !== 0) {
            nuevoReintegroUpdated.cantidadSesiones = +dataForm.sesiones;
        }
        dispatch(SetStepperNavigation(Animations.next))
        dispatch(setNuevoReintegro(nuevoReintegroUpdated));
        (state?.from === routes.verificaDatos) ? history.goBack() : history.push(routes.archivosAdjuntos);
    }

    const maxValue = (value: string): string => {
        if (+value <= 0) {
            return '';
        }
        const enteros = value.substr(0, value.length - 3);
        const decimales = value.substr(value.length - 3);
        if (enteros.length > 7) {
            const aux = enteros.substr(0, 7);
            return aux + decimales;
        }
        return value;
    }

    const footer = () => (
        <IonFooter className='ion-no-border'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <NavigationButton text='Datos obligatorios(*)' type='submit'>SIGUIENTE</NavigationButton>
            </form>
            {isWeb() && <Footer />}
        </IonFooter>
    )

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles.facturacion3}>

                <PageTitle>
                    Reintegros online
                </PageTitle>

                <Stepper stepsLenght={nuevoReintegro?.tipoComprobante?.id === IDComprobantes.Ticket ? 6 : 7} currentNumber={4} />

                <StepTitle>
                    Datos de facturación
                </StepTitle>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                    <IonList className='ion-margin'>

                        <Controller
                            control={control}
                            name='cae'
                            rules={{
                                required: { value: true, message: "Por favor, completá el número de CAE" },
                                minLength: { value: 14, message: "Número de CAE incompleto" }
                            }}
                            render={(props) => (
                                <InputForm
                                    label='Código autorización CAE (*)'
                                    labelIcon={documentTextOutline}
                                    labelIconOnClick={e => setShowCAEPopover({ open: true, event: e.nativeEvent })}
                                    placeHolder='00000000000000'
                                    maxLength={14}
                                    inputmode='numeric'
                                    onChange={(e) => { clearErrors(["cae"]); props.onChange(e.detail.value) }}
                                    onKeyPress={(e) => isNaNOnKeyPress(e) && e.preventDefault()}
                                    onPaste={e => isNaNOnPaste(e) && e.preventDefault()}
                                    errors={errors.cae?.message}
                                    value={props.value}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name='vencimientoCAE'
                            rules={{
                                required: { value: true, message: 'Por favor, completá la fecha de vencimiento de CAE' },
                            }}
                            render={(props) => (
                                <DateTime
                                    label='Fecha de vencimiento de CAE (*)'
                                    onChange={(e) => { clearErrors(["vencimientoCAE"]); props.onChange(e.detail.value) }}
                                    error={errors.vencimientoCAE?.message}
                                    value={props.value}
                                    max={(new Date().getFullYear() + 10) + "-12-31"} />
                            )}
                        />


                        <Controller
                            control={control}
                            name='importe'
                            rules={{
                                required: { value: true, message: 'Por favor, completá el importe total' },
                            }}
                            render={(props) => (
                                <MaskedInputForm
                                    label='Importe total (*)'
                                    labelIcon={documentTextOutline}
                                    labelIconOnClick={e => setShowImportePopover({ open: true, event: e.nativeEvent })}
                                    errors={errors.importe?.message}
                                    numberFormat={{
                                        onValueChange: (values) => { clearErrors(["importe"]); props.onChange(maxValue(values.value)) },
                                        placeholder: '＄ 0.00',
                                        value: props.value,
                                        thousandSeparator: '',
                                        decimalSeparator: '.',
                                        decimalScale: 2,
                                        fixedDecimalScale: true,
                                        allowNegative: false,
                                        prefix: '＄ ',
                                        type: isIos() ? 'tel' : 'text',
                                        isNumericString: true
                                    }}
                                />
                            )}
                        />

                        {nuevoReintegro?.tipoPractica?.sesionesMaximas !== 0 &&
                            <Controller
                                control={control}
                                name='sesiones'
                                rules={{
                                    required: { value: true, message: 'Por favor, completá la cantidad de sesiones' },
                                    validate: { maxValue: value => +value <= 20 || 'El valor ingresado no puede ser mayor a 20' }
                                }}
                                render={(props) => (
                                    <InputForm
                                        label='Cantidad de sesiones (*)'
                                        placeHolder='Cantidad de sesiones máximas: 20'
                                        maxLength={2}
                                        inputmode='numeric'
                                        errors={errors.sesiones?.message}
                                        onChange={(e) => { clearErrors(["sesiones"]); props.onChange(e.detail.value) }}
                                        onKeyPress={(e) => { !(/[0-9]/.test(e.key)) && e.preventDefault() }}
                                        onPaste={e => !(/[0-9]/.test(e.clipboardData.getData('Text'))) && e.preventDefault()}
                                        value={props.value}
                                    />
                                )}
                            />
                        }

                    </IonList>
                </form>
                {isKeyboardOn && footer()}
            </IonContent>

            {!isKeyboardOn && footer()}

            <Popover
                event={showCAEPopover.event}
                isOpen={showCAEPopover.open}
                onDidDismiss={e => setShowCAEPopover({ open: false, event: undefined })}>
                <IonImg src={caeImg} />
            </Popover>

            <Popover
                event={showImportePopover.event}
                isOpen={showImportePopover.open}
                onDidDismiss={e => setShowImportePopover({ open: false, event: undefined })}>
                <IonImg src={importeImg} />
            </Popover>
        </IonPage>
    );
}


export default StepDatosFacturacion3Page;