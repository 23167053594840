import { IonPage, IonContent, IonRow, IonCol, IonGrid, IonSpinner } from '@ionic/react';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { useCountdown } from '../../../hooks/useCountdown';
import authService from '../../../services/auth/AuthService';
import { RootState } from '../../../store';
import { unSetBrowser } from '../../../store/inAppBrowser/actions';
import { setRedirectAfterLoggedInUrl, setUser, startLogin } from '../../../store/users/actions/user';
import { routes } from '../../home/router/routes';
import AuthHeader from '../components/auth-header/AuthHeader';
import AuthText from '../components/auth-text/AuthText';
import AuthTitle from '../components/auth-title/AuthTitle';
import { options } from '../../home/contactanos/types/contactosTypes';
import { navigateToExternalLink } from '../../../shared/utils/utils';
import environmentService from '../../../services/environment/environmentService';

interface Params {
  path: string;
  subpath?: string;
}

const LoginPage: React.FC = () => {
  const history = useHistory();
  const { path, subpath } = useParams<Params>();// login/gestiones-online/autorizaciones
  const { state, search, ...rest } = useLocation<options>(); //esto es por si son links externos que tienen que estar authenticados, primero los mandamos aca para loguearse
  const { user: { user, redirectLoginWithCIError }, inAppBrowser: { browserRef } } = useSelector((state: RootState) => state);
  const isFirstRender = useRef<boolean>(true);
  const { beginCountdown: beginInactivityCountdown } = useCountdown(180, () => closeInAppBrowser());
  const dispatch = useDispatch();

  console.log(search);
  console.log(rest)
  console.log(path)

  const checkSession = useCallback(async () => {
    const finalPath = (path || '') + (subpath ? `/${subpath}` : ''); // contactanos
    if (user) {
      if(state?.target) {
        navigateToExternalLink(state.target, state.externalURL); //externalURL = `https://www.osde.com.ar/cartilla/?caps#1`
        history.replace(routes.root);
        return;
      }
      if (search) {
        const urlCaps = environmentService.getCurrent().urlWebMobile +  '/' + path + '/' + search;
        navigateToExternalLink(null, urlCaps);        
        return
      }
      history.replace(`/${finalPath}`);
      return;
    }
    const isActive = await authService.checkActiveSession();
    if (isActive) {
      const userFullData = (await authService.getUserFullData());
      if (userFullData) {
        dispatch(setUser(userFullData));
      }
    } else {
        let redirectPathname = '';
        if(state?.externalURL) {
          redirectPathname = '/' + state?.externalURL.split('/').slice(3).join('/');
        } else if (search) {
          redirectPathname= '/' + path + '/' + search;
        }
        dispatch(setRedirectAfterLoggedInUrl(`/${finalPath}`)); // contactanos
        dispatch(startLogin(redirectPathname));
    }
  }, [path, subpath, user, state, search, history, dispatch]);


  useEffect(() => {
    checkSession();
  }, [checkSession]);

  useEffect(() => {
    if (redirectLoginWithCIError){
      history.replace(redirectLoginWithCIError);
      dispatch(setRedirectAfterLoggedInUrl(null));
    }
  },[redirectLoginWithCIError, dispatch, history]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (user) {
      return;
    }
    if (!browserRef) {
      history.replace(routes.root);
    }
  }, [browserRef, history, user, beginInactivityCountdown])


  const closeInAppBrowser = () => {
    browserRef?.close();
    dispatch(unSetBrowser());
  };


  return (
    <IonPage>
      <IonContent className='ion-text-center'>
        <IonGrid>
          <AuthHeader>
            <IonSpinner color="primary" />
          </AuthHeader>
          <IonRow>
            <IonCol>
              <AuthTitle>
                AGUARDÁ UN MOMENTO
              </AuthTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <AuthText>
                Estamos cargando la página...
              </AuthText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;