import axios from "axios";
import environmentService from "./environment/environmentService";

const instance = axios.create({
  baseURL: environmentService.getCurrent().staticContent
});

// Custom instance defaults
instance.defaults.headers.post["Content-Type"] = "application/json";

// #region interceptors
instance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
// #endregion

export default instance;
