import { useEffect } from "react";
import environmentService from "../services/environment/environmentService";

export const useResolutionResize = () => {
    const RedirectToWeb = (() => {
        window.location.href = environmentService.getCurrent().urlHomeHubspot
    });

    const isWebMobile  = (userAgent:string): boolean => (userAgent.indexOf("Mobi") > -1)


    const switchToWeb = () => {
        const {userAgent} = navigator; 
        !isWebMobile(userAgent) && RedirectToWeb();
    }

    useEffect(() => {
        switchToWeb();
        window.addEventListener('resize', switchToWeb);   
        return () => { 
            window.removeEventListener('resize', switchToWeb) 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

