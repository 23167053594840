import React from 'react';
import AutorizacionesPage from '../AutorizacionesPage';
import SeleccionarSocioPage from '../Pages/seleccionar-socio-page/SeleccionarSocioPage';
import StepArchivosAdjuntos from '../Pages/step-adjuntar-archivo/StepArchivosAdjuntos';
import StepDatosContacto from '../Pages/step-datos-contacto/StepDatosContacto';
import StepDomicilioOrigenDestino from '../Pages/step-domicilio-origen-destino/StepDomicilioOrigenDestino';
import StepFinalizarTramitePage from '../Pages/step-finalizar-tramite/StepFinalizarTramitePage';
import StepPracticasEstudiosPage from '../Pages/step-practicas-estudios/StepPracticasEstudiosPage';
import StepTrasladosPage from '../Pages/step-traslados/StepTrasladosPage';
import InternacionSecondPage from '../Pages/step-internacion/InternacionSecondPage';
import StepVerificarDatos from '../Pages/step-verificar-datos/StepVerificarDatos';
import PrivateRoute from '../../../../shared/components/privateRoute/PrivateRoute';
import StepInformacionMedicamentosPage from '../Pages/step-informacion-medicamentos/StepInformacionMedicamentosPage';
import Animations from '../../../../shared/components/animations/Animations';

export const rootRoute = '/gestiones-online/autorizaciones';
export const routes = {
  root: rootRoute,
  seleccionarSocio: `${rootRoute}/seleccion-socio`,
  adjuntarArchivo: `${rootRoute}/pedido-medico`,
  datosContacto: `${rootRoute}/datos-de-contacto`,
  domicilioOrigenDestino: `${rootRoute}/domicilio-origen-destino`,
  stepInternacion: `${rootRoute}/datos-de-intervencion`,
  stepTraslado: `${rootRoute}/traslado`,
  stepPracticas: `${rootRoute}/practicas`,
  stepInformacionMedicamentos: `${rootRoute}/informacion-medicamentos`,
  verificarDatos: `${rootRoute}/resumen`,
  finalizarTramite: `${rootRoute}/formulario-enviado`
};

const AutorizacionesRoutes:React.ReactNode[] = [
  <PrivateRoute
    key={rootRoute}
    path={rootRoute}
    exact
    animation={Animations.fadeIn}
    component={AutorizacionesPage}
  />,
  <PrivateRoute
    key={routes.seleccionarSocio}
    path={routes.seleccionarSocio}
    exact
    component={SeleccionarSocioPage}
  />,
  <PrivateRoute
    key={routes.adjuntarArchivo}
    path={routes.adjuntarArchivo}
    exact
    component={StepArchivosAdjuntos}
  />,
  <PrivateRoute
    key={routes.datosContacto}
    path={routes.datosContacto}
    exact
    component={StepDatosContacto}
  />,
  <PrivateRoute
    key={routes.domicilioOrigenDestino}
    path={routes.domicilioOrigenDestino}
    exact
    component={StepDomicilioOrigenDestino}
  />,
  <PrivateRoute
    key={routes.finalizarTramite}
    path={routes.finalizarTramite}
    exact
    component={StepFinalizarTramitePage}
  />,
  <PrivateRoute
    key={routes.stepInternacion}
    path={routes.stepInternacion}
    exact
    component={InternacionSecondPage}
  />,
  <PrivateRoute
    key={routes.stepTraslado}
    path={routes.stepTraslado}
    exact
    component={StepTrasladosPage}
  />,
  <PrivateRoute
    key={routes.stepPracticas}
    path={routes.stepPracticas}
    exact
    component={StepPracticasEstudiosPage}
  />,
  <PrivateRoute
    key={routes.verificarDatos}
    path={routes.verificarDatos}
    exact
    component={StepVerificarDatos}
  />,
  <PrivateRoute
    key={routes.stepInformacionMedicamentos}
    path={routes.stepInformacionMedicamentos}
    exact
    animation={Animations.fadeIn}
    component={StepInformacionMedicamentosPage}
  />
]

export default AutorizacionesRoutes;