import { IonGrid, IonCol, IonRow, IonLabel, IonItem, IonList } from "@ionic/react";
import React from "react";
import StepTitle from "../stepper/step-title/StepTitle";
import PageTitle from "../page-title/PageTitle";
import styles from "./TramiteHomeMenu.module.scss";

interface listData {
  nombre: string;
  onClick?: () => void;
}

interface TramiteHomeMenuProps {
  title: string;
  subtitle: string;
  list: listData[];
}

const TramiteHomeMenu: React.FC<TramiteHomeMenuProps> = ({ title, subtitle, list }) => (
    <>
      <IonGrid className={[styles['select-grid'], 'ion-no-padding'].join(' ')}>
        <IonRow className={[styles["row-title-main"], "ion-align-items-center"].join(' ')}>
          <IonCol>
            <PageTitle>
              {title}
            </PageTitle>
          </IonCol>
        </IonRow>

        <StepTitle>
          {subtitle}
        </StepTitle>
      </IonGrid>

      <IonList className={[styles['tramites-home-list']].join(' ')}>
        {list?.map((data, index) => {
          return (
            <IonItem key={index} className='ion-text-center ion-no-padding' onClick={data.onClick}>
              <IonLabel>
                {data.nombre}
              </IonLabel>
            </IonItem>
          );
        })}
      </IonList>
    </>
  )

export default TramiteHomeMenu;