export interface ReintegrosDataType {
    id: number,
    aptoServicio: boolean,
    listaTipoPracticas: TipoPracticaDataType[],
    cuentaCredito: CuentaCreditoDataType,
    listaIntegrantes: IntegranteReintegrosDataType[],
    listaTipoComprobante: TipoSimpleDataType[],
    correoElectronico: string,
    correoEstado: CorreoEstado,
    usuarioHabilitado: boolean,
    listaTipoDocumento: TipoSimpleDataType[]
}


export interface ReintegroDataType {
    cantidadSesiones?: number | '',
    tipoPractica: TipoPracticaDataType | null,
    comprobantePrestacion: ComprobanteDataType |  null,
    comprobanteFacturacion: ComprobanteDataType | null,
    tipoDocumento: TipoSimpleDataType | null,   // stepFacturacion1
    numeroDocumento: string,  // stepFacturacion1
    tipoComprobante: TipoSimpleDataType | null, //stepFacturacion2
    integrante: IntegranteReintegrosDataType | null, // stepSocios
    profesional: SearchProfesionalDataType | null,  // stepFacturacion1
    facturado: IntegranteReintegrosDataType | null,
    nroComprobantePrefijo: string, // stepFacturacion2
    nroComprobante: string, //stepFacturacion2
    fechaEmision: string, //stepFacturacion2
    cae: string, //stepFacturacion3
    fechaVencimientoCae: string, //stepFacturacion3
    importe: string, //stepFacturacion3
    correoElectronico: string,
    emailChanged: boolean,
    isNative: boolean
}


export interface TipoPracticaDataType {
    id: number,
    nombre: string,
    sesionesMaximas: number,
    pedidoMedico: boolean,
    pedidoMedicoObligatorio: boolean,
    tieneFactura: boolean,
    tieneTicket: boolean
}

export interface CuentaCreditoDataType {
    tieneCuentaCredito: boolean,
    numero: string,
    banco: string,
    numeroFormateado: string
}

export interface IntegranteReintegrosDataType {
    filial: string,
    afiliado: string,
    beneficiario: string,
    nombre: string
}

export interface TipoSimpleDataType {
    id: number,
    descripcion: string
}

export interface CorreoEstado {
    id: number,
    descripcion: string,
    editable: boolean
}

export interface ComprobanteDataType {
    base64?: string,
    nombre?: string
}

export interface ProfesionalDataType { //ver, es igual a la de abajo
    codigo: string,
    razonSocial: string | null
}

export interface SearchProfesionalDataType {
    cuit: string,
    razonSocial: string | null
}

export interface TramiteReintegroDataType {
    error: boolean,
    errorField: string,
    idTramite: number
}

export enum IDComprobantes {
    Ticket
}