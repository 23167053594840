export interface MenuResponse {
  response: {
    groups: Array<IGroup>;
  };
}

export interface IGroup {
  items: Array<IItem>;
  title: string;
}

export interface IItem {
  id?: string;
  name: string;
  type: MenuType;
  items?: Array<IItem> | null;
  link?: string;
}

export enum MenuType {
  picker= 'picker',
  tel= 'tel',
  href= 'href',
  blank= 'blank',
  whatsapp= 'whatsapp',
  path= 'path',
}
export interface ILinks {
  inicio: IItem;
  cartilla: IItem;
  buscarPorCercania: IItem;
  buscarPorEspecialidad: IItem;
  buscarPorNombre: IItem;
  osdeViajaConVos: IItem;
  quieroSerSocio: IItem;
  miCartilla: IItem;
  informacionAlSocio: IItem;
  saludYBienestar: IItem;
  novedades: IItem;
  misTurnos: IItem;
  reintegros: IItem;
  datosDeContacto: IItem;
  empadronamiento: IItem;
  autorizaciones: IItem;
  facturacion: IItem;
  activaTuCredencial: IItem;
  credencialProvisoriaMovil: IItem;
  pagoEnLinea: IItem;
  asistenciaMedica: IItem;
  riesgoDeVida: IItem;
  asistenciaMedicaDeUrgencias: IItem;
  atencionAlSocio: IItem;
  whatsApp: IItem;
}