import { IonContent, IonFooter, IonPage, IonText } from '@ionic/react';
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useHistory } from 'react-router';
import { unsetReintegros } from '../../../../../store/reintegros/ReintegrosActions';
import Header from '../../../../../shared/components/layout/header/Header';
import { ReintegroDataType } from '../../../../../store/reintegros/types/ReintegrosData';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import { isWeb } from '../../../../../shared/utils/utils';
import analyticsService from '../../../../../services/analytics/analytics';
import { routes } from '../../router/routes';
import { routes as homeRoutes } from '../../../../home/router/routes';
import Check from '../../../../../shared/components/stepper/final-step/check/Check';
import SuccessTitle from '../../../../../shared/components/stepper/final-step/success-title/SuccessTitle';
import SuccessText from '../../../../../shared/components/stepper/final-step/success-text/SuccessText';
import SuccessDivider from '../../../../../shared/components/stepper/final-step/success-divider/SuccessDivider';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';


const StepFinalPage: React.FC = () => {

    const { nuevoReintegro, obtainedData: { idTramite } }: { nuevoReintegro?: ReintegroDataType, obtainedData?: { idTramite?: number } }
        = useSelector((state: RootState) => state.reintegros.obtainedData ? state.reintegros : { nuevoReintegro: undefined, obtainedData: { idTramite: 0 } });
    const nroTramite = useRef(idTramite);
    const dispatch = useDispatch();
    const history = useHistory();

    const sendTrackAnalytics = () => {
        const {tipoPractica, importe} = nuevoReintegro;
        const analyticsObj = {
            practica: tipoPractica,
            importe: importe,
            nroTramite: idTramite
        }
        analyticsService.trackPageView(routes.finalizarTramite, JSON.stringify(analyticsObj));
    }


    useEffect(() => {
        if(!idTramite){
            history.push(homeRoutes.root);
        } else {
            sendTrackAnalytics();
            dispatch(unsetReintegros());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    const onClickNext = () => {
        dispatch(SetStepperNavigation(Animations.next))
        history.push(homeRoutes.root, false)
    }

    return (
        <IonPage>
            <Header showBackButton={false} />
            <IonContent>
                
                <div className="ion-text-center ion-padding-top">
                    <Check />
                </div>

                <SuccessTitle>
                    Tu trámite está en proceso
                </SuccessTitle>

                <SuccessDivider />

                <SuccessText>
                    Recibimos correctamente tu pedido de reintegro.
                </SuccessText>

                <SuccessDivider />

                <SuccessText>
                    Tu número de trámite es: <b><IonText color='dark'>{nroTramite.current}</IonText></b>
                </SuccessText>

                <SuccessDivider />

                <SuccessText noLines>
                    En los próximos dias nos contactaremos con vos para brindarte una respuesta.
                </SuccessText>
            </IonContent>

            <IonFooter className='ion-no-border'>
                <NavigationButton onClick={onClickNext} >FINALIZAR</NavigationButton>
                {isWeb() && <Footer /> }
            </IonFooter>
        </IonPage>
    );
}


export default StepFinalPage;