import { IonButton } from "@ionic/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { LocalhostLoginAlert } from "../../../../shared/components/popups/alerts/localhost-login-alert/LocalhostLoginAlert";
import { isWebLocal } from "../../../../shared/utils/utils";
import { RootState } from "../../../../store";
import { routes } from "../../router/routes";
import styles from './HomeMenuButton.module.scss';

interface HomeMenuButtonProps {
  title: string;
  class: string;
  url: string;
  isWeb?: boolean;
  externalLink?: boolean;
  blank? : boolean
}

const HomeMenuButton: React.FC<HomeMenuButtonProps> = (props) => {
  const history = useHistory();
  const [showLocalhostLoginAlert, setShowLocalhostLoginAlert] = useState(false);
  const user = useSelector((state: RootState) => state.user.user);
  
  const onClick = () => {
    if (props.externalLink) {
      if(props?.blank) {
        window.open(props.url, '_blank');
      } else {
        window.location.href = props.url
      }
    } else {
      if (!user && isWebLocal() && props.url === routes.gestionesOnline) {
        setShowLocalhostLoginAlert(true);
      } else {
        history.push(props.url)
      }
    }
  };

  return (
    <>
      <IonButton
        className={[styles[props.class], styles['home-menu-button'], props.isWeb && styles['web-height'], 'ion-text-wrap'].join(' ')}
        expand="full"
        onClick={onClick}>
        {props.title}
      </IonButton>
      <LocalhostLoginAlert isOpen={showLocalhostLoginAlert} setIsOpen={setShowLocalhostLoginAlert}/>
    </>
  );
};

export default HomeMenuButton;