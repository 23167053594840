import React from 'react'
import PrivateRoute from '../../../../shared/components/privateRoute/PrivateRoute'
import DatosContactoPage from '../DatosContactoPage'
import EditAddressPage from '../pages/editAddress/EditAddressPage';
import EndPage from '../pages/end/EndPage';
import Animations from '../../../../shared/components/animations/Animations';

export const rootRoute = '/gestiones-online/datos-de-contacto'
export const routes = {
    editAddress: `${rootRoute}/edit/:type/:typeFrom`,
    endPage: `${rootRoute}/end`
}

const DatosContactoRoutes:React.ReactNode[] = [
   
    <PrivateRoute 
        key={rootRoute}
        component={DatosContactoPage}
        exact
        animation={Animations.fadeIn}
        path={rootRoute}
    />,
    <PrivateRoute 
        key={routes.editAddress}
        component={EditAddressPage}
        exact
        animation={Animations.fadeIn}
        path={routes.editAddress}
    />,
    <PrivateRoute 
        key={routes.endPage}
        component={EndPage}
        exact
        animation={Animations.fadeIn}
        path={routes.endPage}
    />
]

export default DatosContactoRoutes
