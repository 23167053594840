import { useState } from "react"

export const useForm = ( { initialState, initialValidations } ) => {
    
    const [values, setValues] = useState(initialState);
    const [validations] = useState(initialValidations)
    const [errors, setErrors] = useState({})

    const changeValue = (key: string, value: string ) =>{
        setErrors(prevState => ({
            ...prevState,
            [`${key}Error`]: (!value || 0 !== value.length) && validations[key] && !validations[key](value)
        }))

        setValues(prevState => (
           { ...prevState,
            [key]: value}
        ))
    }

    const reset = () => {
        setValues(initialState);
    }

    const handleInputChange = ({ target }) => {
        const {id, value} = target;

        if (values[id] === value) return;

        setErrors({
            ...errors,
            [`${id}Error`]: (!value || 0 !== value.length) && validations[id] && !validations[id](value)
        })

        setValues({
            ...values,
            [id]: value
        })
    }

    return[values, errors, handleInputChange, setErrors,  reset, changeValue];
}