import React, { useEffect, useRef, useState } from 'react';
import { getError } from '../../helpers/getErrors';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonImg, IonCardTitle, IonCardSubtitle, IonToolbar, IonButton, IonModal } from '@ionic/react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import TramiteFooter from '../../../../../shared/components/tramite-footer/TramiteFooter';
import { setPostFormResponse } from '../../../../../store/pagoEnLinea/actions';
import styles from './ErrorModal.module.scss';
import { OverlayEventDetail } from '@ionic/core';
import { isWeb } from '../../../../../shared/utils/utils';
import { routes as homeRoutes } from '../../../../home/router/routes';

interface ErrorModalProps {
  isOpen: boolean;
  onDidDismiss?: (event: CustomEvent<OverlayEventDetail<any>>) => void;
  errorCode: string;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({ isOpen, onDidDismiss, errorCode }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const errorModalDismissed = useRef<boolean>(true);

  useEffect(() => {
    setError(getError(errorCode));
  }, [errorCode]);

  const goToGestionesOnline = () => {
    errorModalDismissed.current = false;
    history.replace(homeRoutes.gestionesOnline);
  };

  const onDismissModal = (event) => {
    if (errorModalDismissed.current && !isWeb()) onDidDismiss?.(event);
  };

  const handleAction = () => {
    dispatch(setPostFormResponse({
      idTransaccion: null,
      observacion: { codigo: null, descripcion: '' }
    }))
    errorModalDismissed.current = false;
    error?.action(history)
  }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={(e) => onDismissModal(e)}>
      <IonPage>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <div className={styles.imageContainer}>
                  <IonImg src={error?.img} />
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="12">
                <IonCardTitle className="ion-text-center" color="secondary">
                  {error?.textError}
                </IonCardTitle>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="12">
                <IonCardSubtitle className="ion-text-center" color="primary">
                  {error?.secondText}
                </IonCardSubtitle>
              </IonCol>
            </IonRow>

            {errorCode === '404' && (
              <IonRow className="ion-margin-top">
                <IonCol size="12">
                  <IonCardSubtitle className={`ion-text-center ${styles.textGrey}`}>
                    Para otros tramites ingresá a
                  </IonCardSubtitle>
                </IonCol>

                <IonCol size="12">
                  <div
                    className={`ion-text-center ${styles.textLightBlue} gestionesButton`}
                    onClick={goToGestionesOnline}
                  >
                    Gestiones online
                  </div>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </IonContent>

        {errorCode !== '404' && (
          <TramiteFooter>
            <IonToolbar>
              <IonButton expand="block" onClick={handleAction}>
                {error?.button}
              </IonButton>
            </IonToolbar>
          </TramiteFooter>
        )}
      </IonPage>
    </IonModal>
  );
};