import axios from "axios";
import environmentService from "./environment/environmentService";


const instance = axios.create({
  baseURL: environmentService.getCurrent().baseUrlProxy,
  withCredentials: true
});

// default for all axios post requests
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


// defaults for all requests from this instance
// instance.defaults.headers.common["Authorization"] = "AUTH_TOKEN";


// defaults for this instance post requests
instance.defaults.headers.post["Content-Type"] = "application/json";



// interceptor para agregar headers y refreshToken a post requests
instance.interceptors.request.use((request) => {
  if (request.method === 'post') {
    request.data.header = {
      ...headerDefault,
      refreshToken: localStorage.getItem('refreshToken'),
    };
  }
  return request;
},
  (error) => {
    console.log('requestInterceptor', error);
    return Promise.reject(error);
  }
);



export default instance;


export const headerDefault = {
  userName: 'DEFAULT',
  appName: 'GRGCARTMOV',
  credentials: '1',
  version: 'v2',
  refreshToken: '',
}