import { InAppBrowserObject } from '@ionic-native/in-app-browser';
import { BrowserAction, SET_BROWSER_REF, UNSET_ERROR_MESSAGE, SET_ERROR_MESSAGE } from './actionsType';


export const setBrowser = (browser: InAppBrowserObject): BrowserAction => ({
    type: SET_BROWSER_REF,
    payload: browser    
})

export const unSetBrowser = (): BrowserAction => ({
    type: SET_BROWSER_REF,
    payload: null
})

export const setMessageError = (message: string ): BrowserAction => ({
    type: SET_ERROR_MESSAGE,
    payload: message    
})

export const unSetMessageError = (): BrowserAction => ({
    type: UNSET_ERROR_MESSAGE,
    payload: null
})