import { IException } from "../../services/Exceptions";
import { IAnimation } from "../../shared/components/animations/Animations";

export const SET_RESET_SCREENSAVER_TIME = "SET_RESET_SCREENSAVER_TIME";
export const SET_RESET_INACTIVITY_LOGOUT = "SET_RESET_INACTIVITY_LOGOUT";
export const SET_STEPPER_NAVIGATION = "SET_STEPPER_NAVIGATION";
export const SET_UPDATE_AVAILABLE = "SET_UPDATE_AVAILABLE";
export const SET_SHOW_SPLASH = "SET_SHOW_SPLASH";
export const SET_KEYBOARD = "SET_KEYBOARD";
export const SET_HTTP_ERROR = 'SET_HTTP_ERROR';


interface SetResetScreenSaverTime {
    type: typeof SET_RESET_SCREENSAVER_TIME;
    payload: boolean | null;
}

interface SetResetInactivityLogout {
    type: typeof SET_RESET_INACTIVITY_LOGOUT;
    payload: boolean | null;
}

interface SetStepperNavigation {
    type: typeof SET_STEPPER_NAVIGATION;
    payload: IAnimation | null;
}

interface SetUpdateAvailable {
    type: typeof SET_UPDATE_AVAILABLE,
    payload: boolean
}

interface SetShowSplash {
    type: typeof SET_SHOW_SPLASH,
    payload: boolean
}

interface SetKeyboard {
    type: typeof SET_KEYBOARD,
    payload: boolean
} 

interface SetHttpError {
    type: typeof SET_HTTP_ERROR,
    payload: IException
} 


export type UIAction = SetResetScreenSaverTime | SetStepperNavigation | SetResetInactivityLogout | SetUpdateAvailable | SetShowSplash | SetKeyboard | SetHttpError;