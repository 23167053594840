interface CookiesService {
  setCookie: (arg0: string) => void;
  getCookie: (cookieName: string) => string ;
}

const authService: CookiesService = {
  getCookie: (cookieName) => {
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  setCookie: (cookieName: string) => {
    document.cookie = `${cookieName}=false;path=/`;
  }
};

export default authService;
