import { isPlatform } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import environmentService from '../../services/environment/environmentService';
import { Target } from '../../modules/home/contactanos/types/contactosTypes';

//#region Platform & Environment

export const isWeb = () => !Capacitor.isNative;

export const isIos = () => isPlatform('ios');

export const isAndroid = () => isPlatform('android');

export const getPlatform = (): "android" | "ios" | "" => isPlatform("android") ? "android" : isPlatform("ios") ? "ios" : "";

export const isProduction = () => environmentService.getCurrent().baseUrlProxy.includes('https://m.osde.com.ar');

export const isWebProduction = () => isWeb() && window.location.hostname.includes('m.osde.com.ar');

export const isWebTesting = () => isWeb() && window.location.hostname.includes('tm.osde.ar');

export const isWebPreProd = () => isWeb() && window.location.hostname.includes('prem.osde.ar');

export const isWebDev = () => isWeb() && window.location.hostname.includes('10.214.33.234');

export const isWebLocal = () => isWeb() && (window.location.hostname.includes('localhost') || window.location.hostname.includes("192.168"));

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
//#endregion



//#region File utils

export const sizeBase64FileString = (base64File: string): number => {
  const stringLength = base64File.length;
  //cada carácter in el base64 representa 6 bits, entonces para obtener el tamaño del archivo en bytes
  //necesitamos dividir la longitud del string en 4 (por que 4 caracteres son 24bits o 3 bytes)
  //y después multiplicarlo por 3 para obtener el total de bytes.
  const sizeInBytes = (stringLength / 4) * 3;
  //para obtener el total del archivo en kiloBytes, dividimos el numero de bytes por 1024
  return sizeInBytes / 1024;
};

export const tamanoMaximoArchivos = 5;

export const getFileExtension = (fileName : string) : string => fileName.split('.').pop();

export const downloadFile = (fileName: string, data: string, type: string) => {
  if (isIos() && isSafari()){
    downloadFileSafari(data,fileName,type);
  } else {
    downloadFileChrome(data,fileName,type);
  }
};

const downloadFileChrome = (data, fileName, type) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = `data:${type};base64,${data}`;
  downloadLink.download = fileName;
  downloadLink.click();
}

const downloadFileSafari = async (data, fileName, fileType): Promise<void> => {
    let arraybuffer = _base64ToArrayBuffer(data);             
    let blob = new Blob( [arraybuffer], { type: fileType });
    let url = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
}

/**
* It transform a string in base64 to an ArrayBuffer.
* @param base64 string 
*/
const  _base64ToArrayBuffer = (base64: string) : ArrayBuffer => {
  // decode base64 string, remove space for IE compatibility
  var binary_string = window.atob(base64.replace(/\s/g, ''));
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result?.toString().split(',')[1]);
  reader.onerror = error => reject(error);
});

//#endregion

//#region Form Fuctions

/**
 * Esta funcion retorna una mascara (mask) que se suplanta de derecha a izquierda por el valor ingresado (value).
 * @param value: Valor en el cual se desean agregar caracteres a la izquierda.
 * @param mask: Mascara que se mostrara a la izquierda del value a menos que este lo iguale o supere en largo.
 */
export const fillMask = (value: string, mask: string): string => {
  if (!value) return;
  return (mask + value).slice(-mask.length);
}


export const formatCuit = (value: string) => {
  if (!value) return '';
  const cleanValue = value.replace(/[^\d]/g, '');
  const mask = cleanValue.length === 11 ? '##-########-#' : '##-#######-#';
  let i = 0;
  let lastReplacedIndex = -1;
  const filledMask = mask.replace(/#/g, (_, j) => {
    if (i >= cleanValue.length) {
      return '#';
    }
    lastReplacedIndex = j;
    return cleanValue[i++];
  });
  return (filledMask.substring(0, lastReplacedIndex + 1));
}

export const cuilValidator = (cuil: string):boolean => {
  if (cuil.length !== 11) {
    return false;
  }

  const [checkDigit, ...rest] = cuil
    .split('')
    .map(Number)
    .reverse();

  const total = rest.reduce(
    (acc, cur, index) => acc + cur * (2 + (index % 6)),
    0,
  );

  const mod11 = 11 - (total % 11);

  if (mod11 === 11) {
    return checkDigit === 0;
  }

  if (mod11 === 10) {
    return false;
  }

  return checkDigit === mod11;
}


export const isDateGreater = (d2: any) => {
  const d1 = new Date();
  return +new Date(d2) > d1.setDate(d1.getDate() + (2))
}


export const isLocalDev = () => {
  return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && booleanStringToBoolean(process.env.REACT_APP_IS_FULL_MOCK);
}

/**
 * Funcion que devuelve true si la fecha @param date se encuentra comprendida entre 
 * el dia actual y @param years años atras
 * @param date fecha
 * @param years años
 */
export const validateMinDate = (date: any, years: number) => {
  const yearAgoTime = new Date().setFullYear(new Date().getFullYear() - years);
  return (new Date(date).getTime() - yearAgoTime) > 0;
}


/**
 * Funcion que formatea la fecha a ISOString con la hora seteada en 0:00
 * @param date Fecha en formato string de ion date time
 * @returns Fecha con ISO format y hora seteada en 0:00
 */
export const ionDateTimeToISOString = (date: string) => {
  const auxDate = new Date(date);
  auxDate.setHours(0, 0, 0, 0);
  return auxDate.toISOString();
}


export const isNaNOnKeyPress = (event) => {
  return isNaN(+event.key.replace(' ', ''));
}

export const isNaNOnPaste = (event) => {
  return isNaN(+event.clipboardData.getData('Text').replace(' ', ''));
}

//#endregion



//#region Misc

/**
 * Esta funcion retorna un string numerico separando miles con punto y decimales con coma
 * @param nStr: tipo any que se va a convertir en un string numerico separando miles con punto y decimales con coma
 */
export const formatImport = (nStr: any) => {
  nStr += '';
  let x = nStr.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? ',' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1.$2');
  }
  return x1 + x2;
};

export const getUrlStore = () => {
  let res = "";
  const isAndroid = ()=> isPlatform('android');
  const isIphone = ()=> isPlatform('iphone');
  
   if (isAndroid()) {
     res = `https://play.google.com/store/apps/details?id=ar.com.osde.ads`;
   }
   if (isIphone() || isIos()) {
     res = `https://apps.apple.com/ar/app/osde-movil/id1470295613`;
   }
  return res;
};

export const removeAccents = (text: string) => {
  if (!text) {
    return '';
  }
  const accent_map = {
    á: 'a',
    é: 'e',
    è: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    ü: 'u',
    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U',
    Ü: 'U'
  };
  let ret = '';
  for (let i = 0; i < text.length; i++) {
    ret += accent_map[text.charAt(i)] || text.charAt(i);
  }
  return ret;
};

/**
 * Esta funcion retorna un string en el cual se reemplazan letras con caracteres especiales como (á à ü â ñ ç) por su version normalizada.
 * @param text: string a analizar
 * @returns string normalizado
 */
export const removeSpecialCharacters = (text: string): string => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');


/**
 * Esta funcion retorna un boolean si el string pasado es "true | false" sino lanza una exception
 * @param data: string a analizar
 * @returns bolean
 */
const booleanStringToBoolean = (data?: string): boolean => {
  if (data === undefined) throw Error('El valor es undefined y debe ser true o false');
  let value = data.toLowerCase()?.trim();
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      throw Error(`El valor es ${data} y debe ser true o false`);
  }
};



/**
 * Esta funcion retorna true si los objetos recibidos tienen los mismos parametros y 
 * los valores de dichos parametros son iguales
 * @param obj1
 * @param obj2
 * @returns bolean
 */
 export const isEqual = (obj1, obj2) => {
  if(!obj1 || !obj2){
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

export const cleanLoginData = () => {
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('refreshTokenDateLimit');
}

export const navigateToExternalLink = (target: Target, link: string) => {
  target === '_blank' ? window.open(link || '/') : window.location.href = link || '/'
};

//#endregion