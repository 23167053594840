import { applyMiddleware, combineReducers, createStore } from "redux";
import { userReducer } from "./users/userReducer";
import {datosContactoReducer} from './datos-contacto/datosContactoReducer'
import thunk from 'redux-thunk';
import { phoneReducer } from "./popUpTelefonos/phoneReducer";
import { AutorizacionesReducer } from "./autorizaciones/AutorizacionesReducer";
import { ReintegrosReducer } from "./reintegros/ReintegrosReducer";
import { pagoEnLineaReducer } from "./pagoEnLinea/pagoEnLineaReducer";
import { inAppBrowserReducer } from "./inAppBrowser/reducer";
import { composeWithDevTools } from 'redux-devtools-extension';
import { empadronamientoReducer } from "./empadronamiento/empadronamientoReducer";
import { uiReducer } from "./ui/uiReducer";
import { UNSET_TRAMITES } from "./users/types/actions";

const appReducer = combineReducers({
    user: userReducer,
    datosContacto: datosContactoReducer,
    phonePopUp: phoneReducer,
    autorizaciones: AutorizacionesReducer,
    reintegros: ReintegrosReducer,
    pagoEnLinea: pagoEnLineaReducer,
    inAppBrowser: inAppBrowserReducer,
    empadronamiento: empadronamientoReducer,
    ui: uiReducer
});

const rootReducer = (state, action) => {
  if (action.type === UNSET_TRAMITES) {
    // we keep a reference of the keys we want to maintain
    // other keys will be passed as undefined and this will call
    // reducers with an initial state
    const { user, ui, phonePopUp, inAppBrowser } = state;
    
    state = { user, ui, phonePopUp, inAppBrowser };
  }

  return appReducer(state, action);
};


export const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunk),
    // other store enhancers if any
  ));

export type RootState = ReturnType<typeof rootReducer>