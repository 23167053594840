import { IonCheckbox, IonContent, IonFooter, IonIcon, IonLabel, IonPage, IonText, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import AlertPopup from '../../../../../shared/components/popups/alerts/alert/AlertPopup';
import StandardModal from '../../../../../shared/components/popups/modals/standard-modal/StandardModal';
import SociosList from '../../../../../shared/components/stepper/socios-list/SociosList';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import ProteccionDatos from '../../../../../shared/components/texts/proteccion-datos/ProteccionDatos';
import { RootState } from '../../../../../store';
import { setNuevoReintegro } from '../../../../../store/reintegros/ReintegrosActions';
import { IDComprobantes, ReintegroDataType } from '../../../../../store/reintegros/types/ReintegrosData';
import TerminosReintegros from '../../components/terminos-reintegros/TerminosReintegros';
import { informationCircle } from 'ionicons/icons';
import styles from './StepSeleccionarSocioPage.module.scss';
import Popover from '../../components/popover/Popover';
import Header from '../../../../../shared/components/layout/header/Header';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { routes } from '../../router/routes';
import { routes as homeRoutes } from '../../../../home/router/routes';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import { isAndroid, isWeb } from '../../../../../shared/utils/utils';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';


const StepSeleccionarSocioPage: React.FC = () => {
    const { reintegros, nuevoReintegro } = useSelector((state: RootState) => state.reintegros);
    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation<{ from: string }>();
    const [agreeTerms, setAgreeTerms] = useState(state?.from ? true : false);
    const [disabledButton, setDisabledButton] = useState(false)
    const [selectedSocio, setSelectedSocio] = useState<string>(nuevoReintegro?.integrante?.beneficiario);
    const [showTerminosModal, setShowTerminosModal] = useState(false);
    const [showProteccionDatosModal, setShowProteccionDatosModal] = useState(false);
    const [showAvisoTerminosAlert, setShowAvisoTerminosAlert] = useState(false);
    const [showPagoPendienteAlert, setShowPagoPendienteAlert] = useState(false);
    const [showNoSociosAlert, setShowNoSociosAlert] = useState(false);
    const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });


    useEffect(() => {
        if(!reintegros){
            history.replace(homeRoutes.root);
        } else if (!reintegros.listaIntegrantes || reintegros.listaIntegrantes.length === 0) {
            setShowNoSociosAlert(true);
        } else if (reintegros && !reintegros.aptoServicio) {
            setShowPagoPendienteAlert(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        setDisabledButton(!(selectedSocio && agreeTerms));
    }, [selectedSocio, agreeTerms])


    const goToNextStep = () => {        
        const selected = reintegros?.listaIntegrantes.find((i) => i.beneficiario === selectedSocio);
        const nuevoReintegroUpdated: ReintegroDataType = { ...nuevoReintegro!, integrante: { ...selected! }, facturado: { ...selected! } };
        dispatch(SetStepperNavigation(Animations.next))
        dispatch(setNuevoReintegro(nuevoReintegroUpdated));
        (state?.from === routes.verificaDatos) ? history.goBack() :
            history.push(nuevoReintegro?.tipoComprobante?.id === IDComprobantes.Ticket ? routes.datosTicket1 : routes.datosFacturacion1);        
    }

const configButtons = {
  titulo: 'Tenemos un problema',
  message: `En este momento el trámite no está disponible.<br/>
  Podés intentarlo más tarde o realizarlo desde la app OSDE.`,
  buttons: [
    {
      text: 'Entendido',
      handler: () => {
        setShowNoSociosAlert(false)
        history.push(homeRoutes.gestionesOnline)
      },
    },

  ],
}

if (isAndroid()){
    const buttonDeepLink = { 
        text: 'App OSDE',
        handler: () => {
          window.location.href = "ads://login";
        },
      }
      configButtons.message = `En este momento el trámite no está disponible.<br/>
      Podés intentarlo más tarde o realizarlo desde nuestra aplicación`
      configButtons.buttons.unshift(buttonDeepLink)
}

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles['step-socios-reintegros']}>

                <PageTitle>
                    Reintegros online
                </PageTitle>

                <Stepper stepsLenght={nuevoReintegro?.tipoComprobante?.id === IDComprobantes.Ticket ? 6 : 7} currentNumber={1} />

                <StepTitle>
                    Quien recibió la práctica
                </StepTitle>

                {reintegros?.cuentaCredito &&
                    <div className={styles.cuenta}>
                        <p><IonText color='medium'>Cuenta de acreditación automática de reintegros</IonText></p>
                        <p>
                            <IonText color='dark'><b>{`- Cuenta ${reintegros.cuentaCredito.numeroFormateado} - ${reintegros.cuentaCredito.banco}`}</b></IonText>
                            <IonIcon color='secondary' icon={informationCircle} slot='end' onClick={e => setShowPopover({ open: true, event: e.nativeEvent })} />
                        </p>
                    </div>}

                {reintegros?.listaIntegrantes && <SociosList setSelectedSocio={setSelectedSocio} list={reintegros.listaIntegrantes} selectedSocio={selectedSocio} />}

            </IonContent>
            <IonFooter className='ion-no-border'>
                <IonToolbar>
                    <div className={styles.checkbox}>
                        <IonCheckbox checked={agreeTerms} onIonChange={e => setAgreeTerms(e.detail.checked)} />
                        <IonLabel onClick={() => setShowTerminosModal(true)}>Términos y condiciones</IonLabel>
                    </div>
                    <IonLabel onClick={() => setShowProteccionDatosModal(true)} className={styles['datos-personales']}>
                        Protección de los datos personales. Ley 25.326 (*)
                    </IonLabel>
                </IonToolbar>

                <NavigationButton onClick={() => { goToNextStep() }} disabled={disabledButton}>SIGUIENTE</NavigationButton>
                {isWeb() && <Footer /> }
            </IonFooter>

            <Popover
                event={showPopover.event}
                isOpen={showPopover.open}
                onDidDismiss={e => setShowPopover({ open: false, event: undefined })}>
                <p>Podés modificar la cuenta para la acreditación automática de reintegros, a través de Gestiones Online en nuestro sitio WEB</p>
            </Popover>

            <AlertPopup
                isOpen={showNoSociosAlert}
                title={configButtons.titulo}  
                message={configButtons.message}
                buttons={configButtons.buttons}>
            </AlertPopup>

            <AlertPopup
                isOpen={showPagoPendienteAlert}
                title='Importante'
                message='No es posible gestionar tu solicitud porque se registran saldos pendientes de pago en tu asociación.'
                buttons={[
                    { text: 'Aceptar', handler: () => { setShowPagoPendienteAlert(false); history.push(homeRoutes.gestionesOnline) } }
                ]}>
            </AlertPopup>

            <AlertPopup
                isOpen={showAvisoTerminosAlert}
                title='Aviso'
                message='Para continuar con el trámite, por favor, aceptá los términos y condiciones.'
                buttons={[
                    { text: 'Aceptar', handler: () => setShowAvisoTerminosAlert(false) }
                ]}>
            </AlertPopup>

            <StandardModal
                isOpen={showTerminosModal}
                title='TÉRMINOS Y CONDICIONES'
                subtitle='Reintegros online'
                content={<TerminosReintegros />}
                handler={() => setShowTerminosModal(false)}>
            </StandardModal>

            <StandardModal
                isOpen={showProteccionDatosModal}
                title='PROTECCIÓN DE LOS DATOS PERSONALES'
                subtitle='Ley 25.326'
                content={<ProteccionDatos />}
                handler={() => setShowProteccionDatosModal(false)}>
            </StandardModal>
        </IonPage>
    )
}

export default StepSeleccionarSocioPage;