import { IonCol, IonInput, IonItem, IonLabel, IonListHeader, IonNote, IonRow } from '@ionic/react';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from '../../../../../../../hooks/useForm';
import SearchModal from '../../../../../../../shared/components/popups/modals/search-modal/SearchModal';
import { DomicilioDataType, domicilioType, typeFromEnum } from '../../../../../../../store/datos-contacto/types/DatosContactoData';
import datosDeContactoService from '../../../../services/DatosContactoService';
import { RootState } from '../../../../../../../store';
import { useSelector } from 'react-redux';
import analyticsService from '../../../../../../../services/analytics/analytics';
import { isNaNOnKeyPress, isNaNOnPaste } from '../../../../../../../shared/utils/utils';


enum Provincias {
  Capital = 'CAPITAL FEDERAL',
}

enum Localidades {
  CABA = 'CIUDAD AUTONOMA BUENOS AIRES'
}

interface EditAddressInfoModel {
  type: domicilioType;
  domicilio: DomicilioDataType;
  setDomicilio: (data: DomicilioDataType) => void;
  setIsFormValid: Dispatch<SetStateAction<boolean>>
  domicilioEqual?: boolean;
  typeFrom?: typeFromEnum;
}

const EditAddressInfo: React.FC<EditAddressInfoModel> = ({
  type,
  domicilio,
  setDomicilio,
  setIsFormValid,
  domicilioEqual,
  typeFrom
}) => {
  const { datosContacto } = useSelector((state: RootState) => state.datosContacto);

  const [showProvinciaModal, setShowProvinciaModal] = useState(false);
  const [showLocalidadModal, setShowLocalidadModal] = useState(false);
  const [showCalleModal, setShowCalleModal] = useState(false);
  const [provincias, setProvincias] = useState<string[]>([]);
  const [localidades, setLocalidades] = useState<string[]>([]);
  const [calles, setCalles] = useState<string[]>([]);
  const domicilioCopy = type === 'residencia' ? { ...datosContacto.residencia } : { ...datosContacto.correspondencia }

  const validateNoEmpty = (data: string) => data.length > 0;

  const formInitialState = {
    localidad: domicilio.localidad,
    provincia: domicilio.provincia,
    numero: domicilio.numero,
    calle: domicilio.calle,
    codigoPostal: domicilio.codigoPostal
  }

  const validationsForm = {
    provincia: validateNoEmpty,
    localidad: validateNoEmpty,
    numero: validateNoEmpty,
    calle: validateNoEmpty,
    codigoPostal: validateNoEmpty
  }

  const formProps = {
    initialState: formInitialState,
    initialValidations: validationsForm
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [values, errors, handleInputChange, setErrors, reset, changeValue] = useForm(formProps);

  useEffect(() => {
    let isFormValid = true;
    for (let key in errors) {
      if (errors[key]) {
        isFormValid = false;
        break;
      };
    };
    setIsFormValid(() => isFormValid);

    if (errors?.provinciaError) {
      analyticsService.trackEventFormNot('trackEventForm', `datos de contacto > domicilio de ${type}`, 'error - provincia: Debe elegir una opción', 0);
    }
    if (errors?.localidadError) {
      analyticsService.trackEventFormNot('trackEventForm', `datos de contacto > domicilio de ${type}`, 'error - localidad: Este campo no puede quedar en blanco, ni contener caracteres especiales', 0);
    }
    if (errors?.calleError) {
      analyticsService.trackEventFormNot('trackEventForm', `datos de contacto > domicilio de ${type}`, 'error - calle: Este campo no puede quedar en blanco', 0);
    }
    if (errors?.numeroError) {
      analyticsService.trackEventFormNot('trackEventForm', `datos de contacto > domicilio de ${type}`, 'error - numero: Este campo no puede quedar en blanco, ni contener caracteres especiales', 0);
    }
    if (errors?.codigoPostalError) {
      analyticsService.trackEventFormNot('trackEventForm', `datos de contacto > domicilio de ${type}`, 'error - codigo postal: Este campo no puede quedar en blanco, ni contener caracteres especiales', 0);
    }
  }, [errors, setIsFormValid, type]);


  const openProvincia = async () => {
    const { data } = await datosDeContactoService.getProvincias();
    setProvincias(data);
    setShowProvinciaModal(true);
  };


  const openLocalidad = async () => {
    setShowLocalidadModal(true);
  };


  const searchLocalidades = async (value) => {
    const { data } = await datosDeContactoService.getPredictivo({
      provincia: domicilio.provincia,
      localidad: value,
      calle: '',
    });
    let result = data.filter((item, index) => {
      return data.indexOf(item) === index;
    })
    setLocalidades(result);
    return result;
  };


  const searchCalle = async (value) => {
    const { data } = await datosDeContactoService.getPredictivo({
      provincia: domicilio.provincia,
      localidad: domicilio.localidad,
      calle: value,
    });
    let result = data.filter((item, index) => {
      return data.indexOf(item) === index;
    })
    setCalles(result);
    return result;
  };


  const changeProvincia = (data) => {
    const localidad = data === Provincias.Capital ? Localidades.CABA : ''
    changeValue('provincia', data);
    if (data === Provincias.Capital) {
      changeValue('localidad', Localidades.CABA);
    } else {
      data !== domicilio.provincia && changeValue('localidad', '');
    }
    data !== domicilioCopy.provincia && changeValue('calle', '')
    setDomicilio({
      ...domicilio,
      provincia: data,
      provinciaChanged: domicilioCopy.provincia !== data,
      localidad: data !== domicilio.provincia ? localidad : domicilio.localidad,
      localidadChanged: domicilioCopy.provincia !== data,
      calle: data !== domicilioCopy.provincia ? '' : domicilio.calle
    });
  };


  const changeLocalidad = (data) => {
    setDomicilio({
      ...domicilio,
      localidad: data,
      localidadChanged: data !== domicilioCopy.localidad,
      calle: data !== domicilioCopy.localidad ? '' : domicilio.calle
    });
    changeValue('localidad', data);
    data !== domicilioCopy.localidad && changeValue('calle', '')
  };


  const changeCalle = (data) => {
    setDomicilio({
      ...domicilio,
      calle: data,
      calleChanged: data !== domicilioCopy.calle,
    });
    changeValue('calle', data);
  };


  const typeDomicilio = () => {
    if (domicilioEqual && (typeFrom === 'editar')) {
      return type + ' y Correspondencia';
    } else {
      return type;
    }
  };


  return (
    <>
      <IonRow>
        <IonListHeader color="light">
          <IonLabel color="tertiary">Domicilio de {typeDomicilio()}</IonLabel>
        </IonListHeader>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <IonItem button lines="full" onClick={openProvincia} detail={false}>
            <IonRow style={{ width: '100%' }}>
              <IonCol size="4">
                <IonLabel id="id-provincia" color="primary">
                  Provincia *:{' '}
                </IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonLabel className="">{domicilio.provincia}</IonLabel>
              </IonCol>
            </IonRow>
          </IonItem>
          {errors?.provinciaError &&
            <IonNote color="danger" >
              El campo no puede estar vacio
            </IonNote>
          }
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <IonItem
            detail={false}
            button
            lines="full"
            onClick={openLocalidad}
            disabled={!domicilio.provincia || domicilio.provincia === Provincias.Capital}
          >
            <IonRow style={{ width: '100%' }}>
              <IonCol size="4">
                <IonLabel id="id-localidad" color="primary">
                  Localidad *:{' '}
                </IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonLabel>{domicilio.localidad === Localidades.CABA ? Provincias.Capital : domicilio.localidad}</IonLabel>
              </IonCol>
            </IonRow>
          </IonItem>
          {errors?.localidadError &&
            <IonNote color="danger" >
              El campo no puede estar vacio
            </IonNote>
          }
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <IonItem
            detail={false}
            button
            lines="full"
            onClick={() => {
              setShowCalleModal(true);
            }}
            disabled={!(domicilio?.localidad)}
          >
            <IonRow style={{ width: '100%' }}>
              <IonCol size="4">
                <IonLabel id="id-calle" color="primary">
                  Calle *:{' '}
                </IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonLabel>{domicilio.calle}</IonLabel>
              </IonCol>
            </IonRow>
          </IonItem>
          {errors?.calleError &&
            <IonNote color="danger">
              El campo no puede estar vacio
            </IonNote>
          }
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="6">
          <IonItem lines="full">
            <IonLabel position="floating" color="primary">
              Numero*
            </IonLabel>
            <IonInput
              value={domicilio.numero}
              onIonChange={(e) => {
                handleInputChange(e);
                setDomicilio({
                  ...domicilio,
                  numero: e.detail.value,
                  numeroChanged: e.detail.value !== domicilioCopy.numero,
                });
              }}
              id={'numero'}
              inputMode={'numeric'}
              type='tel'
              maxlength={5}
              onKeyPress={(e) => isNaNOnKeyPress(e) && e.preventDefault()}
              onPaste={e => isNaNOnPaste(e) && e.preventDefault()}
              onKeyDown={(e) => e.key === 'Tab' && e.preventDefault()}
            />
          </IonItem>
          {errors?.numeroError &&
            <IonNote color="danger" className='ion-text-md-wrap' >
              El campo no puede estar vacio
            </IonNote>
          }
        </IonCol>
        <IonCol size="6">
          <IonItem lines="full">
            <IonLabel position="floating" color="primary">
              Piso
            </IonLabel>
            <IonInput
              value={domicilio.piso}
              onIonChange={(e) => {
                setDomicilio({
                  ...domicilio,
                  piso: e.detail.value,
                  pisoChanged: e.detail.value !== domicilioCopy.piso,
                });
              }}
              type='text'
              maxlength={5}
            />
          </IonItem>
        </IonCol>
        <IonRow>
          <IonCol size="6">
            <IonItem lines="full">
              <IonLabel position="floating" color="primary">
                Depto.
              </IonLabel>
              <IonInput
                value={domicilio.departamento}
                onIonChange={(e) => {
                  setDomicilio({
                    ...domicilio,
                    departamento: e.detail.value,
                    departamentoChanged: e.detail.value !== domicilioCopy.departamento
                  });
                }}
                type='text'
                maxlength={10}
              />
            </IonItem>
          </IonCol>
          <IonCol size="6">
            <IonItem lines="full">
              <IonLabel id="id-cp" position="floating" color="primary">
                C.P. *
              </IonLabel>
              <IonInput
                value={domicilio.codigoPostal}
                onIonChange={(e) => {
                  handleInputChange(e);
                  setDomicilio({
                    ...domicilio,
                    codigoPostal: e.detail.value,
                    codigoPostalChanged: e.detail.value !== domicilioCopy.codigoPostal
                  });
                }}
                id='codigoPostal'
                type='text'
                maxlength={10}
              />
            </IonItem>
            {errors?.codigoPostalError &&
              <IonNote color="danger" >
                El campo no puede estar vacio
              </IonNote>
            }
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <IonItem lines="full">
              <IonLabel position="floating" color="primary">
                Barrio
              </IonLabel>
              <IonInput
                value={domicilio.barrio}
                onIonChange={(e) => {
                  setDomicilio({
                    ...domicilio,
                    barrio: e.detail.value,
                    barrioChanged: e.detail.value !== domicilioCopy.barrio
                  });
                }}
              />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonRow>
      {provincias?.length > 0 && (
        <SearchModal
          isOpen={showProvinciaModal}
          closeModal={() => {
            setShowProvinciaModal(false);
          }}
          content={provincias}
          handler={changeProvincia}
          title="Seleccioná la provincia"
        />
      )}
      {localidades && (
        <SearchModal
          isOpen={showLocalidadModal}
          closeModal={() => {
            setShowLocalidadModal(false);
          }}
          content={localidades}
          handler={changeLocalidad}
          searchFunction={searchLocalidades}
          title="Seleccioná la localidad"
          placeholder="Ingresá mínimo 3 letras"
        />
      )}
      {calles && (
        <SearchModal
          isOpen={showCalleModal}
          closeModal={() => {
            setShowCalleModal(false);
          }}
          content={calles}
          handler={changeCalle}
          searchFunction={searchCalle}
          title="Seleccioná la calle"
          placeholder="Ingresá mínimo 3 letras"
        />
      )}
    </>
  );
};

export default EditAddressInfo;