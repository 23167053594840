import { IonLabel, IonIcon, IonButton } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';
import analyticsService from '../../../../../services/analytics/analytics';
import { IContacto, ContactoType } from '../../types/contactosTypes';
import styles from './Contacto.module.scss';
import { authRoutes } from '../../../../auth/router/routes';
import { navigateToExternalLink } from '../../../../../shared/utils/utils';

const Contacto: React.FC<IContacto> = ({ className, label, link, phoneInfo, type, icon, options }) => {
  const history = useHistory();

  const trackEvent = () => {
    analyticsService.trackEvent('llamar', phoneInfo, label?.split('.').join(''));
  };

  const clickHandler = () => {
    if (type === ContactoType.None) return;
    trackEvent();
    switch (type) {
      case ContactoType.Telefono:
        window.location.href = `tel:${link}`;
        break;
      case ContactoType.TelefonoWhatsApp:
        window.location.href = `https://wa.me/${link}`;
        break;
      case ContactoType.Path:
        history.push(link);
        break;
      case ContactoType.Link:
        const { requiresAuthentication, target } = options || {};
        requiresAuthentication ? history.push(authRoutes.login, { target, externalURL: link }) : navigateToExternalLink(target, link);
        break;
    }
  };

  return (
    <IonButton className={`${styles.contactoButton} ${styles[className]}`} onClick={clickHandler} id={label}>
      {icon && <IonIcon icon={icon} />}
      <IonLabel className={type === ContactoType.Link || type === ContactoType.Path ? styles.webLinkButton : ''}>{label}</IonLabel>
    </IonButton>
  );
};

export default Contacto;
