import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { menuController } from '@ionic/core';
import { authRoutes } from '../router/routes';
import authService from '../../../services/auth/AuthService';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/users/actions/user';
import AuthHtml from '../components/auth-html/AuthHtml';

interface Params {
  token: string;
}

const AuthPage: React.FC = () => {
  const { token } = useParams<Params>();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const fireLogin = async () => {
    const userFullData = await authService.loginWithCINew(token);
    if (userFullData) {
      dispatch(setUser(userFullData));
    }
    setTimeout(() => {
      history.replace(location.state);
    }, 3000);
  }

  useEffect(() => {
    menuController.close();
    menuController.swipeGesture(false);
    if (token) {
      localStorage.setItem('token', token);
      fireLogin();
    } else {
      history.push(authRoutes.error);
    }

    return () => {
      menuController.swipeGesture(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthHtml />
  );
};

export default AuthPage;