import React from 'react';
import PrivateRoute from '../../../../shared/components/privateRoute/PrivateRoute';
import ConfirmedPaymentPage from '../pages/ConfirmedPaymentPage/ConfirmedPaymentPage';
import ConfirmPayPage from '../pages/ConfirmPayPage/ConfirmPayPage';
import PaymentFormPage from '../pages/PaymentForm/PaymentFormPage';
import SelectCardPage from '../pages/SelectCardPage/SelectCardPage';
import VouchersPage from '../pages/VouchersPage/VouchersPage';
import PagoEnLineaPage from '../PagoEnLineaPage';
import PaymentsPage from '../pages/PaymentsPage/PaymentsPage';
import Animations from '../../../../shared/components/animations/Animations';
///gestiones-online/pago-en-linea/seleccion-medio-de-pago

export const rootRoute = '/gestiones-online/pago-en-linea';

export const routes = {
  root: rootRoute,
  payments: `${rootRoute}/pagos`,
  selectCard: `${rootRoute}/seleccion-medio-de-pago`,
  paymentInformation: `${rootRoute}/payment-information`,
  confirmPay: `${rootRoute}/confirmar-pago`,
  payConfirmed: `${rootRoute}/pago-confirmado`,
  vouchers: `${rootRoute}/comprobantes`,
};

const PagoEnLineaRoutes = [
  <PrivateRoute
    key={rootRoute}
    exact
    path={routes.root}
    component={PagoEnLineaPage}
    animation={Animations.fadeIn}
  />,
  <PrivateRoute
    key={routes.selectCard}
    exact
    path={routes.selectCard}
    component={SelectCardPage}
    animation={Animations.fadeIn}
  />,
  <PrivateRoute
    key={routes.payConfirmed}
    exact
    path={routes.payConfirmed}
    component={ConfirmedPaymentPage}
    animation={Animations.fadeIn}
  />,
  <PrivateRoute
    key={routes.confirmPay}
    exact
    path={routes.confirmPay}
    component={ConfirmPayPage}
    animation={Animations.fadeIn}
  />,
  <PrivateRoute
    key={routes.paymentInformation}
    exact
    path={routes.paymentInformation}
    component={PaymentFormPage}
    animation={Animations.fadeIn}
  />,
  <PrivateRoute
    key={routes.vouchers}
    exact
    path={routes.vouchers}
    component={VouchersPage}
    animation={Animations.fadeIn}
  />,
  <PrivateRoute
    key={routes.payments}
    exact
    path={routes.payments}
    component={PaymentsPage}
    animation={Animations.fadeIn}
  />,
].map((link) => link);

export default PagoEnLineaRoutes;
