import React from 'react';
import { Redirect, Route } from 'react-router';
import PrivateRoute from '../../../../shared/components/privateRoute/PrivateRoute';
import EmpadronamientoPage from '../EmpadronamientoPage';
import AntecedentesSaludPage from '../pages/antecedentes-salud-page/AntecedentesSaludPage';
import ResultadoPage from '../pages/resultado/ResultadoPage';
import VerificarPage from '../pages/verificar/VerificarPage';

export const rootRoute = `/gestiones-online/empadronamiento`;

export const routes = {
  root: rootRoute,
  seleccionBeneficiaria: `${rootRoute}/seleccion-de-beneficiaria`,
  antecedentesSalud: `${rootRoute}/antecedentes`,
  verificar: `${rootRoute}/resumen`,
  resultado: `${rootRoute}/formulario-enviado`
};

const EmpadronamientoRoutes = [
  <Route key='empadronamiento' path={rootRoute} exact render={() => <Redirect to={routes.seleccionBeneficiaria} />} />,
  <PrivateRoute
    key={routes.seleccionBeneficiaria}
    exact
    path={routes.seleccionBeneficiaria}
    component={EmpadronamientoPage}
  />,
  <PrivateRoute
    key={routes.antecedentesSalud}
    exact
    path={routes.antecedentesSalud}
    component={AntecedentesSaludPage}
  />,
  <PrivateRoute
    key={routes.verificar}
    exact
    path={routes.verificar}
    component={VerificarPage}
  />,
  <PrivateRoute
    key={routes.resultado}
    exact
    path={routes.resultado}
    component={ResultadoPage}
  />,
];

export default EmpadronamientoRoutes;