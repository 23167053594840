import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonLabel,
  IonListHeader,
  IonModal,
  IonRow,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import {
  domicilioType,
  NormalizarTelefonoDataType,
  Phone,
  TelefonoDataType,
} from '../../../../../../../store/datos-contacto/types/DatosContactoData';
import styles from './PhoneInputModal.module.scss';
import Loading from '../../../../../../../shared/components/popups/loading/Loading';
import InputForm from '../../../../../../../shared/components/form/inputForm/InputForm';
import analyticsService from '../../../../../../../services/analytics/analytics';
import datosDeContactoService from '../../../../services/DatosContactoService';


interface PhoneInputModalModel {
  type: domicilioType;
  isOpen: boolean;
  setPhone: (data: Phone) => void;
  isFijo: boolean;
  editPhone?: Phone;
  onDismiss?: any;
}

const PhoneInputModal: React.FC<PhoneInputModalModel> = ({
  type,
  isOpen,
  setPhone,
  isFijo,
  editPhone,
  onDismiss,
}) => {
  const initPhone: Phone = {
    changed: false,
    codArea: '',
    editDisabled: false,
    fijo: isFijo,
    interno: '',
    normalizado: false,
    numero: '',
    numeroCompleto: '',
    standard: false,
  };

  const [myPhone, setMyPhone] = useState<Phone>(initPhone);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { residencia, correspondencia } = useSelector(
    (state: RootState) => state.datosContacto.datosContacto
  );

  useEffect(() => {
    analyticsService.trackPageView('tramites/datos-de-contacto/agregar-telefonos');
    if (editPhone) {
      setMyPhone(editPhone);
      return () => {
        setMyPhone(initPhone);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPhone]);

  const onKeyPress = (e: any) => {
    if (
      Number(e.key) ||
      e.key === 'Tab' ||
      e.key === 'Backspace' ||
      e.key === 'Delete' ||
      e.key === 'Shift' ||
      e.key === 'Home' ||
      e.key === 'End' ||
      e.key === 'Home' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight' ||
      e.key === 'ContextMenu' ||
      e.key === '0'
    ) {
      setError(null);
      return;
    }
    e.preventDefault();
  };

  const onClickGuardar = async () => {
    setIsLoading(true);
    const telANormalizar: NormalizarTelefonoDataType = {
      numeroCompleto: myPhone.numeroCompleto,
      changed: myPhone.changed,
      interno: myPhone.interno,
      codigoPostal: myPhone.codArea,
      fijo: isFijo,
    };
    try {
      
      const resp = await datosDeContactoService.normalizarTelefono(
        telANormalizar
      );
      setIsLoading(false);
      const numeroNormalizado: Phone = {
        ...resp.data,
        standard: myPhone.standard,
      };
      let telefonos: TelefonoDataType[] = [];
      if (type === 'residencia') {
        telefonos = [...residencia.telefonos];
      } else {
        telefonos = [...correspondencia.telefonos];
      }
      const encontrado = telefonos.filter(
        (item: TelefonoDataType) =>
          item.numeroCompleto === numeroNormalizado.numeroCompleto
      );
      if (encontrado.length > 0) {
        setError('Numero Repetido');
        analyticsService.trackEventFormNot('trackEventForm', 'datos de contacto > agregar teléfonos', 'error - telefono: numero repetido', 0);
        return;
      }
      setPhone({ ...numeroNormalizado, fijo: isFijo });
      setMyPhone(initPhone);
      setError(null);
      analyticsService.trackPageViewForm('trackPageviewForm','tramites/datos-de-contacto/agregar-telefonos/formulario-enviado',
                'datos de contacto > agregar teléfonos',1);
    } catch (e) {
      setIsLoading(false);
      return;
    }
  };
  return (
    <IonModal
      id="id-phone-modal"
      isOpen={isOpen}
      showBackdrop={true}
      cssClass={styles.inputModal}
      onDidDismiss={() => {
        setMyPhone(initPhone);
        setError(null);
        if (onDismiss) {
          onDismiss();
        }
      }}
    >
      <IonHeader>
        <IonListHeader>
          <IonLabel color="primary">
            Teléfono {myPhone?.fijo ? 'fijo' : 'móvil'}
          </IonLabel>
        </IonListHeader>
      </IonHeader>
      <IonContent>
        <Loading isOpen={isLoading} message="Cargando..." />

        <IonGrid>
          <IonRow>
            <IonCol size="3" class="ion-align-items-center">
              <InputForm
                labelMargin={false}
                required
                label="Cod Area"
                name="codArea"
                minLength={1}
                maxLength={4}
                inputmode="tel"
                type="tel"
                max="9999"
                value={myPhone?.codArea}
                onKeyDown={onKeyPress}
                onChange={(e) => {
                  setMyPhone({
                    ...myPhone,
                    codArea: e.detail.value,
                    changed: true,
                    numeroCompleto: `${e.detail.value}-${myPhone?.numero}`,
                  });
                }}
              />
            </IonCol>
            <IonCol size={myPhone?.fijo ? '6' : '9'}>
              <InputForm
                labelMargin={false}
                label="Número"
                required
                name="numeroTelefono"
                inputmode="tel"
                type="tel"
                max="9999999999"
                minLength={6}
                maxLength={10}
                value={myPhone?.numero.replace(/-/g, '')}
                onKeyDown={onKeyPress}
                onChange={(e) => {
                  setMyPhone({
                    ...myPhone,
                    numero: e.detail.value,
                    changed: true,
                    numeroCompleto: `${myPhone?.codArea}-${e.detail.value}`,
                  });
                }}
              />
            </IonCol>
            {isFijo && (
              <IonCol size="3" class="ion-align-items-center">
                <InputForm
                  labelMargin={false}
                  label="Int."
                  name="numeroInterno"
                  inputmode="tel"
                  type="tel"
                  max="9999"
                  maxLength={4}
                  value={myPhone?.interno}
                  onKeyDown={onKeyPress}
                  onChange={(e) => {
                    if (e.detail.value?.length)
                      setMyPhone({
                        ...myPhone,
                        interno: e.detail.value,
                        changed: true,
                        numeroCompleto: `${myPhone?.codArea}-${myPhone?.numero}`,
                      });
                  }}
                />
              </IonCol>
            )}
          </IonRow>
          {error && (
            <IonRow>
              <IonCol>
                <IonText color="danger">{error}</IonText>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
      <IonFooter className='ion-no-border'>
        <IonButton expand="block" onClick={onClickGuardar}>
          Guardar
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};


export default PhoneInputModal;

