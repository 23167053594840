import authService from "../../../../services/auth/AuthService";
import AxiosInstance from "../../../../services/AxiosService";
import { isLocalDev } from "../../../../shared/utils/utils";
import { ReintegroDataType, ReintegrosDataType, SearchProfesionalDataType, TramiteReintegroDataType } from "../../../../store/reintegros/types/ReintegrosData";
import { consultarFiltradosMock, finalizarMock } from "./mocks/ReintegrosMock";

const baseUrlService = "/reintegrosService";

export interface ReintegrosService {
  consultarFiltrados: () => Promise<{ data: ReintegrosDataType }>;
  profesional: (data: string) => Promise<{ data: SearchProfesionalDataType }>;
  finalizar: (data: ReintegroDataType) => Promise<{ data: TramiteReintegroDataType }>;
}

const reintegrosService: ReintegrosService = {
  consultarFiltrados: () => AxiosInstance.post(`${baseUrlService}/consultarFiltradosNew?refreshToken=${authService.getRefreshToken()}`, {}, { withCredentials: true }),
  profesional: (data) => AxiosInstance.post(`${baseUrlService}/profesional`, { body: data }),
  finalizar: (data) => AxiosInstance.post(`${baseUrlService}/finalizarNew`, { body: data }),
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const reintegrosServiceMock: ReintegrosService = {
  consultarFiltrados: () => Promise.resolve({data:consultarFiltradosMock}),
  profesional: (data) => AxiosInstance.post(`${baseUrlService}/profesional`, { body: data }),
  finalizar: (data) => Promise.resolve({data:finalizarMock}),
};

export default isLocalDev() ? reintegrosServiceMock : reintegrosService;