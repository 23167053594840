import AxiosInstance from "../../../../services/AxiosService";
import { isLocalDev } from "../../../../shared/utils/utils";
import { DatosSociaType, EmpadronamientoResponse } from "../types/EmpadronamientoTypes";
import { getFormMock, postformMock } from "./mocks/EmpadronamientoMock";

const baseUrlService = "/empadronamientosService";

export interface EmpadronamientoService {
  getForm: (contrato?: String, orden?: String, esBeneficiario?: Boolean, nombreCompleto?: String) => Promise<EmpadronamientoResponse>;
  postForm: (originante: DatosSociaType, empadronada: DatosSociaType) => Promise<any>;
}

const empadronamientoService: EmpadronamientoService = {

  getForm: async(contrato, orden, esBeneficiario, nombreCompleto) => {
    let response = await AxiosInstance.post(`${baseUrlService}/getFormNew`, {
        body:{ datosSocio: { contrato, orden, esBeneficiario, nombreCompleto }}
    });
    return response.data;
  },

  postForm: async(originante: DatosSociaType, empadronada: DatosSociaType) => {
    let response = await AxiosInstance.post(`${baseUrlService}/postForm`, {
        body:{ originante, empadronada}
    });
    return response.data;
  },

};

const empadronamientoServiceMock: EmpadronamientoService = {
  getForm: async(contrato, orden, esBeneficiario, nombreCompleto) => {
    let response = await Promise.resolve({data:getFormMock});
    return response.data;
  },
  postForm: async(originante: DatosSociaType, empadronada: DatosSociaType) => {
    let response = await Promise.resolve({data:postformMock});
    return response.data;
  },
};

export default isLocalDev() ? empadronamientoServiceMock : empadronamientoService ;