export enum ContactoType {
    Telefono,
    TelefonoWhatsApp,
    Link,
    Path,
    None
}

export interface IContacto {
    label: string;
    section?: string; 
    icon?: string;
    link?: string;
    type: ContactoType;
    phoneInfo?: string;
    className?: string;
    //target?: '_blank' | '_self' | "";
    options?: options;
}

export interface options {
    requiresAuthentication?: boolean;
    target: Target;
    externalURL?: string;
}

export type Target = '_blank' | '_self' | "";