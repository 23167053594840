import { IItem } from "../menu/types/MenuResponse"


export const PHONE_SET_SHOW = 'PHONE_SET_SHOW'
export const PHONE_SET_UNSHOW = 'PHONE_SET_UNSHOW'

interface SetShow {
    type: typeof PHONE_SET_SHOW;
    payload: Array<IItem>
}

interface SetUnShow {
    type: typeof PHONE_SET_UNSHOW;
}

export type PhoneAction = SetShow | SetUnShow