import { IonCol, IonRow } from '@ionic/react';
import React from 'react';
import styles from './AuthHeader.module.scss';

const AuthHeader: React.FC = ({children}) => {
	return (
		<IonRow className={styles.authHeader}>
			<IonCol>
				{children}
			</IonCol>
		</IonRow>
	);
};

export default AuthHeader;