import { IonContent, IonFooter, IonImg, IonList, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import styles from './StepDatosTicket2Page.module.scss'
import { documentTextOutline } from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../store';
import { IDComprobantes, ReintegroDataType } from '../../../../../store/reintegros/types/ReintegrosData';
import { setNuevoReintegro } from '../../../../../store/reintegros/ReintegrosActions';
import Popover from '../../components/popover/Popover';
import importeImg from '../../assets/img/importe.png'
import DateTime from '../../../../../shared/components/form/date-time/DateTime';
import MaskedInputForm from '../../../../../shared/components/form/Masked-input-form/MaskedInputForm';
import { useHistory, useLocation } from 'react-router';
import Header from '../../../../../shared/components/layout/header/Header';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { routes } from '../../router/routes';
import { routes as homeRoutes } from '../../../../home/router/routes';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import { isIos, isWeb, validateMinDate } from '../../../../../shared/utils/utils';
import analyticsService from '../../../../../services/analytics/analytics';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';


const StepDatosTicket2Page: React.FC = () => {

    const nuevoReintegro = useSelector((state: RootState) => state.reintegros.nuevoReintegro);
    const {isKeyboardOn} = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();
    const [showImportePopover, setShowImportePopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });
    const history = useHistory();
    const { state } = useLocation<{ from: string }>();

    useEffect(() => {
        if (!nuevoReintegro) {
            history.push(homeRoutes.gestionesOnline);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    interface FacturacionFormProperties {
        fechaEmision: string,
        importe: string
    }

    const { control, handleSubmit, errors, clearErrors } = useForm<FacturacionFormProperties>({
        mode: 'onSubmit',
        defaultValues: {
            fechaEmision: nuevoReintegro?.fechaEmision ?? '',
            importe: nuevoReintegro?.importe ?? ''
        }
    });

    useEffect(() => {
        if(errors.fechaEmision)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.fechaEmision}` , routes.datosTicket2)
        if(errors.importe)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.importe}` , routes.datosTicket2)
    }, [errors])

    const maxValue = (value: string): string => {
        if (+value <= 0) {
            return '';
        }
        const enteros = value.substr(0, value.length - 3);
        const decimales = value.substr(value.length - 3);
        if (enteros.length > 7) {
            const aux = enteros.substr(0, 7);
            return aux + decimales;
        }
        return value;
    }
    
    const onSubmit = (dataForm: any) => {
        const nuevoReintegroUpdated: ReintegroDataType = {
            ...nuevoReintegro!,
            importe: dataForm.importe.replace('＄ ', ''),
            fechaEmision: dataForm.fechaEmision.substr(0, 10)
        };
        dispatch(SetStepperNavigation(Animations.next))
        dispatch(setNuevoReintegro(nuevoReintegroUpdated));
        (state?.from === routes.verificaDatos) ? history.goBack() : history.push(routes.archivosAdjuntos);
    }

    const footer = () => (
        <IonFooter className='ion-no-border'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <NavigationButton text='Datos obligatorios(*)' type='submit'>SIGUIENTE</NavigationButton>
            </form>
            {isWeb() && <Footer /> }
        </IonFooter>
    )

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles.ticket2}>

                <PageTitle>
                    Reintegros online
                </PageTitle>

                <Stepper stepsLenght={nuevoReintegro?.tipoComprobante?.id === IDComprobantes.Ticket ? 6 : 7} currentNumber={3} />

                <StepTitle>
                    Datos de facturación
                </StepTitle>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonList className='ion-margin'>

                        <Controller
                            control={control}
                            name='fechaEmision'
                            rules={{
                                required: { value: true, message: 'Por favor, completá la fecha de emisión' },
                                validate: {
                                    maxDate: value => new Date(value) < new Date() || 'La fecha de emisión no puede ser superior a la del dia de hoy',
                                    minDate: value => validateMinDate(value, 1) || 'La fecha de emisión debe ser hasta un año anterior a la del dia de hoy'
                                }
                            }}
                            render={(props) => (
                                <DateTime
                                    label='Fecha de emisión del comprobante (*)'
                                    onChange={e => { clearErrors(["fechaEmision"]); props.onChange(e.detail.value) }}
                                    error={errors.fechaEmision?.message}
                                    value={props.value} />
                            )}
                        />


                        <Controller
                            control={control}
                            name='importe'
                            rules={{
                                required: { value: true, message: 'Por favor, completá el importe total' },
                            }}
                            render={(props) => (
                                <MaskedInputForm
                                    label='Importe total (*)'
                                    labelIcon={documentTextOutline}
                                    labelIconOnClick={e => setShowImportePopover({ open: true, event: e.nativeEvent })}
                                    errors={errors.importe?.message}
                                    numberFormat={{
                                        onValueChange: (values) => { clearErrors(["importe"]); props.onChange(maxValue(values.value)) },
                                        placeholder: '＄ 0.00',
                                        value: props.value,
                                        thousandSeparator: '',
                                        decimalSeparator: '.',
                                        decimalScale: 2,
                                        fixedDecimalScale: true,
                                        allowNegative: false,
                                        prefix: '＄ ',
                                        type: isIos() ? 'tel' : 'text',
                                        isNumericString: true
                                    }}
                                />
                            )}
                        />

                    </IonList>
                </form>

                {isKeyboardOn && footer()}
            </IonContent>

            {!isKeyboardOn && footer()}

            <Popover
                event={showImportePopover.event}
                isOpen={showImportePopover.open}
                onDidDismiss={e => setShowImportePopover({ open: false, event: undefined })}>
                <IonImg src={importeImg} />
            </Popover>

        </IonPage>
    );
}

export default StepDatosTicket2Page;