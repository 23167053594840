import { InAppBrowserObject } from '@ionic-native/in-app-browser';
export const SET_BROWSER_REF = "SET_BROWSER_REF";
export const UNSET_BROWSER_REF = "UNSET_BROWSER_REF";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const UNSET_ERROR_MESSAGE = "UNSET_ERROR_MESSAGE";


interface SetBrowserRefAction {
    type: typeof SET_BROWSER_REF;
    payload: InAppBrowserObject | null;
}

interface UnSetBrowserRefAction {
    type: typeof UNSET_BROWSER_REF;
    payload: InAppBrowserObject | null;
}

interface SetMessageErrorAction {
    type: typeof SET_ERROR_MESSAGE;
    payload: string | null;
}

interface UnSetMessageErrorAction {
    type: typeof UNSET_ERROR_MESSAGE;
    payload: string | null;
}

export type BrowserAction = SetBrowserRefAction | UnSetBrowserRefAction | SetMessageErrorAction | UnSetMessageErrorAction;
