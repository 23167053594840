const Animations: IAnimations = {
    forwardTransition: {
        initial: { x: '100vw' },
        animate: { x: 0 },
        exit: { x: '-100vw'}
    },
    fadeIn: {
        initial: {opacity : 0},
        animate: {opacity : 1},
        exit: {opacity: 0}
    },
    next: {
        initial: { x: '100vw', opacity: 0  },
        animate:{ x: 0, opacity: 1 },
        exit:{ 
            x: '-100vw',
            opacity: 0,
            transition: {
                duration: 0.5
            }
         },
    },
    back: {
        initial: { x: '-100vw', opacity: 0  },
        animate:{ x: 0, opacity: 1 },
        exit:{ 
            x: '100vw',
            opacity: 0,
            transition: {
                duration: 0.5
            }
         },
    }
};

export default Animations;


export interface IAnimation {
    initial: {};
    animate: {};
    exit?: {};
    transition?: {};
}

interface IAnimations {
    forwardTransition: IAnimation;
    fadeIn: IAnimation;
    next: IAnimation;
    back: IAnimation;
}