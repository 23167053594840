import { IonContent, IonPage, IonList } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { GestionesOnlineButton } from './components/gestionesOnlineButton/GestionesOnlineButton';
import { RootState } from '../../../store'
import { getOptionsWeb } from './helpers/getOptionsWeb';
import { GestionesLink } from './types/GestionesLink';
import React, { useEffect, useState } from 'react';
import Header from '../../../shared/components/layout/header/Header';
import AlertPopup from '../../../shared/components/popups/alerts/alert/AlertPopup';
import { useHistory } from 'react-router';
import { User } from '../../../store/users/types/User';
import {ONLY_CONTACT_DETAIL_MESSAGE, SECTION_NOT_ENABLED_MESSAGE} from '../../../shared/globalConstants';
import styles from './GestionesOnlinePage.module.scss';
import Loading from '../../../shared/components/popups/loading/Loading';
import { routes } from '../router/routes';
import { unSetTramites } from '../../../store/users/actions/user';
import whiteList from './whiteList.json'
import environmentService from '../../../services/environment/environmentService';

export const GestionesOnlinePage: React.FC = () => {

  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.user);
  const [items, setItems] = useState(new Array<GestionesLink>());
  const dispatch = useDispatch();

  let getGestionesItems =  getOptionsWeb;

  useEffect(() => {
    // Activar para la salida escalonada a socios
    // if (["2 510"].includes(user.plan)){
    if (whiteList.includes(user.interlocutorComercial)){
      window.location.href = environmentService.getCurrent().urlWebResponsive 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  useEffect(() => {
    user && setItems(getGestionesItems(user));
    dispatch(unSetTramites());
  }, [dispatch, getGestionesItems, user])

  let counter = 1;

  const notEnabledMessage = (usuarioCartilla: User) => {
    return usuarioCartilla?.edad < 16 ? ONLY_CONTACT_DETAIL_MESSAGE : SECTION_NOT_ENABLED_MESSAGE;
  }

  return (
    <IonPage>
      <Header overrideBackToURL={routes.root} />
      <IonContent>
        <IonList className={[styles['gestiones-online-menu'], 'ion-no-padding'].join(' ')}>
          {
            items.map((navItem, index) => {

              // se agrega logica para que cambie el color del botón dinamicamente
              let classCounter = counter;
              counter = counter === 5 ? 1 : counter + 1;

              return <GestionesOnlineButton
                key={index}
                class={`color${classCounter}`}
                icon={navItem.icon}
                title={navItem.title}
                url={navItem.url}
                name={navItem.name}
                event={navItem.event}
                externalLink={navItem.externalLink}
              />
            })
          }
        </IonList>
      </IonContent>

      {
        !user?.gestionesOnlineHabilitado &&
          <AlertPopup
            isOpen={true}
            title='Aviso'
            message={notEnabledMessage(user)}
            buttons={[
              {
                text: 'Aceptar',
                handler: () => history.goBack()
              }
            ]}
          />
      }

      <Loading
        isOpen={!user}
        message={'Cargando...'}
      />
    </IonPage>
  );
};

export default GestionesOnlinePage;