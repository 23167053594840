import reintegrosService from "../../modules/tramites/reintegros/services/ReintegrosService";
import analyticsService from "../../services/analytics/analytics";
import { ReintegrosActions, SET_NUEVOREINTEGRO, SET_REINTEGROS, UNSET_REINTEGROS, TOGGLE_LOADING, FINALIZAR_TRAMITE_SUCCESS, FINALIZAR_TRAMITE_FAILURE } from "./types/actions";
import { ReintegroDataType, ReintegrosDataType, TramiteReintegroDataType } from "./types/ReintegrosData";



export const fetchReintegros = () => async (dispatch: Function, getState: Function) => {
    dispatch(toggleLoading());
    try {
        const response = await reintegrosService.consultarFiltrados();
        dispatch(setReintegros(response.data));
        analyticsService.trackPageView('/gestiones-online/reintegros/practica');
    } catch (error) {
        console.log(error);
    } finally {
        dispatch(toggleLoading());
    }
}

export const finalizarTramite = (nuevoReintegro: ReintegroDataType) => async (dispatch: Function, getState: Function) => {
    const response = await reintegrosService.finalizar(nuevoReintegro);
    if (response) dispatch(finalizarTramiteSuccess(response.data));
}



export const setReintegros = (reintegros: ReintegrosDataType): ReintegrosActions => ({
    type: SET_REINTEGROS,
    payload: reintegros,
});

export const setNuevoReintegro = (reintegro: ReintegroDataType): ReintegrosActions => ({
    type: SET_NUEVOREINTEGRO,
    payload: reintegro,
});

export const unsetReintegros = (): ReintegrosActions => ({
    type: UNSET_REINTEGROS,
});

export const toggleLoading = (): ReintegrosActions => ({
    type: TOGGLE_LOADING,
});

export const finalizarTramiteSuccess = (responseData: TramiteReintegroDataType): ReintegrosActions => ({
    type: FINALIZAR_TRAMITE_SUCCESS,
    payload: responseData
});

export const finalizarTramiteFailure = (responseData: any): ReintegrosActions => ({
    type: FINALIZAR_TRAMITE_FAILURE,
    payload: responseData
});