import React from 'react';
import PrivateRoute from '../../../../shared/components/privateRoute/PrivateRoute';
import FacturacionPage from '../FacturacionPage';

export const rootRoute = '/gestiones-online/facturacion';

export const routes = {
  root: rootRoute
};

const FacturacionRoutes = [
  <PrivateRoute key='facturacion' path={routes.root} exact component={FacturacionPage} />
]

export default FacturacionRoutes;