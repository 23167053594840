export interface DatosContactoDataType {
  domicilioEqual: boolean;
  residencia: DomicilioDataType;
  correspondencia: DomicilioDataType;
  emails: EmailDataType[];
  esBeneficiario: boolean;
}

export interface DomicilioDataType {
  provinciaChanged: boolean;
  provincia: string;
  localidadChanged: boolean;
  localidad: string;
  codigoPostalChanged: boolean;
  codigoPostal: string;
  calleChanged: boolean;
  calle: string;
  numeroChanged: boolean;
  numero: string;
  pisoChanged: boolean;
  piso: string;
  departamentoChanged: boolean;
  departamento: string;
  barrioChanged: boolean;
  barrio: string;
  emailChanged: boolean;
  email: string;
  telefonoMovilDefault: TelefonoDataType;
  telefonoMovilDefaultChanged: boolean | null;
  telefonos: TelefonoDataType[];
  telefonoDefault: TelefonoDataType;
  telefonoDefaultChanged: boolean | null;
  normalizado: boolean;
  motivoNormalizado: null;
  listaEmails: string[];
  interlocutorComercial: string;
}

export interface TelefonoDataType {
  fijo: boolean;
  codArea: string;
  numero: string;
  interno: string;
  standard: boolean;
  numeroCompleto: string;
  changed: boolean;
  normalizado: boolean;
  editDisabled: boolean;
}

export interface EmailDataType {
  isDefault: boolean;
  email: string;
}

export interface BuscarTramiteDataType {
  idTramite: string;
  pendingProcess: boolean;
  incompleteProcess: boolean;
  datosContacto: DatosContactoDataType;
}

export interface GuardarDatosDataType {
  idTramite: string;
  datosContacto: DatosContactoDataType;
  nativo: boolean;
}

export interface NormalizarTelefonoDataType {
  numeroCompleto: string;
  changed: boolean;
  interno: string;
  codigoPostal: string;
  fijo: boolean;
}

export interface PredictivoDataType {
  provincia: string;
  localidad: string;
  calle: string;
}

export interface Phone {
  changed: boolean,
  codArea: string,
  editDisabled: boolean,
  fijo: boolean,
  interno: string,
  normalizado: boolean,
  numero: string
  numeroCompleto: string,
  standard: boolean
}

export interface Address {
  numero: string, 
  calle: string,
  piso: string,
  codigoPostal: string,
  departamento: string,
  provincia: string,
  localidad: string,
  telefonoDefault: TelefonoDataType,
  telefonoMovilDefault: TelefonoDataType
}

export type domicilioType = 'residencia' | 'correspondencia'

export enum typeFromEnum { 
  editar = "editar",
  toggle = "toggle"
};
