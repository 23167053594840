import React from 'react'
import { IonItem, IonLabel } from '@ionic/react';
import styles from "./SuccessTitle.module.scss";

const SuccessTitle: React.FC = ({ children }) => {
  return (
    <IonItem className={`${styles.successTitle} ion-text-center`} lines='full'>
      <IonLabel color='success'>{children}</IonLabel>
    </IonItem>
  )
}

export default SuccessTitle