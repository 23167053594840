import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { authRoutes } from '../../../modules/auth/router/routes';
import { RootState } from '../../../store';
import { isRefreshTokenDateLimitValid } from '../../../store/users/actions/user';
import RouteTransitionWrapper from '../animations/routeTransitionWrapper/RouteTransitionWrapper';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { user: { user } } = useSelector((state: RootState) => state);

  return (
    <Route
      {...rest}
      render={(props) =>
        user ?
          isRefreshTokenDateLimitValid() ?
            <RouteTransitionWrapper variants={rest?.animation}>
              <Component {...props} />
            </RouteTransitionWrapper>
            :
            <Redirect to={authRoutes.timeout} />
          :
          <Redirect to={`${authRoutes.login}${path}`} />
      }
    />
  );
};
export default PrivateRoute;