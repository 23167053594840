import { IonItem, IonTextarea } from '@ionic/react';
import * as React from 'react';
import styles from './TextareaForm.module.scss';

export interface TextareaFormProps {
    label: string,
    name: string,
    register?: any,
    placeholder?: string
    onChange?: any
    value?: string
}
 
const TextareaForm: React.FC<TextareaFormProps> = ({label, name, register, placeholder, onChange, value}) => {
    return ( 
        <>
      <IonItem
        className={[
          styles['textarea-form'],
          'ion-align-items-center',
          'ion-text-center',
        ].join(' ')}
      >
        <label>{label}</label>
                <IonTextarea 
                    maxlength={200} 
                    value={value} 
                    name={name}
                    ref={register}
                    placeholder={placeholder}
                    onIonChange={onChange}
                />
            </IonItem>
        </>
    );
};
 
export default TextareaForm;