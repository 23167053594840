import { IonBackdrop, IonContent, IonFooter, IonList, IonPage } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import styles from './StepVerificarDatos.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { FormularioAutorizacionesDataType } from '../../../../../store/autorizaciones/types/AutorizacionesData';
import CollapsibleListSection from '../../../../../shared/components/collapsible/collapsible-list-section/CollapsibleListSection';
import CollapsibleListItem from '../../../../../shared/components/collapsible/collapsible-list-item/CollapsibleListItem';
import { setAutorizacion } from '../../../../../store/autorizaciones/AutorizacionesActions';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import { isWeb } from '../../../../../shared/utils/utils';
import { useHistory, useLocation } from 'react-router';
import Header from '../../../../../shared/components/layout/header/Header';
import { TrasladoData } from '../../types/TrasladoData';
import { routes as AutorizacionesRoutes } from '../../router/routes';
import dayjs from 'dayjs';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import { routes as homeRoutes } from '../../../../home/router/routes';
import {AutorizacionesTramites as tramite} from './../../helpers/TramitesEnum';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import useHttpErrorHandler from '../../../../../hooks/useHttpErrorHandler';
import StandardModal from '../../../../../shared/components/popups/modals/standard-modal/StandardModal';

const StepVerificarDatos: React.FC<any> = (props) => {
    const { stepDetails, success, tipoTramite, detalle, integrante, archivosAdjuntos, datosPractica, datosSocio, origenDestino, traslado, numeroTramite } = useSelector((state: RootState) => state.autorizaciones);
    const { user: { interlocutorComercial, contrato, fechaNacimiento, esBeneficiario, plan } } = useSelector((state: RootState) => state.user ?? {user: { interlocutorComercial, contrato, fechaNacimiento, esBeneficiario, plan }});
    const [isSectionOpen, setIsSectionOpen] = useState<boolean>(false);
    const [isFinished, setIsFinished] = useState<boolean>(undefined);
    const [wasSuccessful, setWasSuccessful] = useState<boolean>(undefined);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [numeroTramiteEmpty, setNumeroTramiteEmpty] = useState<boolean>(false);
    const onPressSubmit = useRef(false)
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const {httpError: error} = useHttpErrorHandler({overrideAlertAction: () => history.replace(AutorizacionesRoutes.root)});

    useEffect(() => {
        if (!integrante) {
            history.push(homeRoutes.root);
        }
    }, [history, integrante])


    useEffect(() => {
        const timer = setTimeout(() => setIsSectionOpen(true), 500);
        return () => { clearTimeout(timer) };
    }, []);


    useEffect(() => {
        if (onPressSubmit.current) {
            setIsFinished(true);
            if (success) {
                if (!numeroTramite?.numeroTramite?.trim()) {
                    tramiteNotSuccesful();
                    setNumeroTramiteEmpty(true);
                    return;
                }
                setWasSuccessful(true);
                setTimeout(() => {
                    dispatch(SetStepperNavigation(Animations.fadeIn))
                    history.push(AutorizacionesRoutes.finalizarTramite);
                }, 1500)
            }
        }
    }, [success, history, dispatch, numeroTramite])


    useEffect(() => {
        if(error) {
            tramiteNotSuccesful();
        }
    }, [error])


    const tramiteNotSuccesful = () => {
        setIsFinished(true);
        setWasSuccessful(false);
        setShowBackdrop(false);
    }


    const trasladoInformation = (key: string): string => {
        switch (key) {
            case (TrasladoData.observacion):
                return detalle;
            case (TrasladoData.hora):
                return new Date(traslado[key]).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }).toString();
            case (TrasladoData.fecha):
                return dayjs(traslado.fechaTraslado).format('DD/MM/YYYY');
            case (TrasladoData.conAmbulancia):
                return traslado?.conAmbulancia ? 'Con Médico' : 'Sin Médico';
            case (TrasladoData.conEspera):
                return traslado?.conEsperaRegreso ? 'Si' : 'No';
        }
    }

    const handleSubmit = async () => {
        setShowBackdrop(true);
        const {id, aptoServicio, checked, ...integranteAutorizacion} = integrante;
        const [prefijo, codigoPlan] = plan.split(" ");

        let newAutorizacionObj: FormularioAutorizacionesDataType = {
            tipoTramite,
            integrante: {
                ...integranteAutorizacion
            },
            ...traslado,
            ...archivosAdjuntos,
            documentosExtra: archivosAdjuntos.documentosExtra.map(({id, ...doc}) => doc),
            ...origenDestino,
            ...datosPractica,
            ...datosSocio,
            detalle,
            ingresoTelefono: '',
            canal: 2,
            //nuevas props para migracion a microservicio
            plan: {
                codigoPlan,
                prefijo
            },
            interlocutorComercialOriginante: interlocutorComercial,
            numeroSocioOriginante: contrato.nroActividad,
            fechaNacimientoOriginante: dayjs(fechaNacimiento).format('YYYY-MM-DD'),
            esBeneficiario            
        }

        onPressSubmit.current = true;
        dispatch(setAutorizacion(newAutorizacionObj));
    }

    const separateAttachFilesAndObserver = (key: string) => {
        if (key === 'detalle') {
            return detalle;
        } else if (Array.isArray(archivosAdjuntos && archivosAdjuntos[key])) {
            return archivosAdjuntos[key]?.map((content: any) => `doc adicional ${content.id}`)
        } else {
            return archivosAdjuntos[key].nombre && 'Pedido Médico'
        }
    }

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles.verificaAutorizaciones}>
                <PageTitle>{stepDetails?.titleAndLenght?.title}</PageTitle>

                <Stepper currentNumber={stepDetails?.titleAndLenght?.lenghtOfPages - 1} stepsLenght={stepDetails?.titleAndLenght?.lenghtOfPages}></Stepper>

                <StepTitle color="white">
                    Verificá los datos
                </StepTitle>

                <IonList className="ion-margin ion-no-padding">
                    <CollapsibleListSection title='Datos del socio' isOpen={isSectionOpen}>
                        {stepDetails?.editOrder?.map((collapse, index) => collapse.page === 1 &&
                            (
                                <CollapsibleListItem key={index}
                                    content={integrante?.nombre}
                                    label={collapse.label}
                                    editHandler={() => history.push(collapse.path, { from: location.pathname })}
                                />
                            )
                        )}
                    </CollapsibleListSection>

                    {(tipoTramite && (tipoTramite === tramite.TrasladosProgramados)) &&
                        <>
                            <CollapsibleListSection title='Datos del traslado' isOpen={isSectionOpen}>
                                {stepDetails.editOrder?.map((collapse, index) => collapse.page === 2 &&
                                    (
                                        <CollapsibleListItem key={index}
                                            content={trasladoInformation(collapse.content)}
                                            label={collapse.label}
                                            editHandler={() => history.push(collapse.path, { from: location.pathname })}
                                        />
                                    )
                                )}
                            </CollapsibleListSection>

                            <CollapsibleListSection title='Domicilio origen - destino' isOpen={isSectionOpen}>
                                {stepDetails?.editOrder?.map((collapse, index) => collapse.page === 3 &&
                                    (
                                        <CollapsibleListItem key={index}
                                            content={origenDestino[collapse.content]}
                                            label={collapse.label}
                                            editHandler={() => history.push(collapse.path, { from: location.pathname })}
                                        />
                                    )
                                )}
                            </CollapsibleListSection>
                        </>
                    }

                    {(stepDetails?.editOrder?.find(item => item.label === 'Fecha de realización') && (tipoTramite !== tramite.Medicamentos && tipoTramite !== tramite.TrasladosProgramados)) &&
                        <CollapsibleListSection title='Datos de la práctica' isOpen={isSectionOpen}>
                            {stepDetails.editOrder?.map((collapse, index) => (collapse.page === (stepDetails.editOrder?.find(item => item.label === 'Fecha de realización').page)) &&
                                (
                                    <CollapsibleListItem key={index}
                                        content={(collapse.content === 'fechaRealizacion') ? dayjs(datosPractica[collapse.content]).format('DD/MM/YYYY') : datosPractica[collapse.content] ?? detalle}
                                        label={collapse.label}
                                        editHandler={() => history.push(collapse.path, { from: location.pathname })}
                                    />
                                )
                            )}
                        </CollapsibleListSection>
                    }

                    <CollapsibleListSection title='Archivos Adjuntos' isOpen={isSectionOpen}>
                        {stepDetails?.editOrder?.map((collapse, index) => collapse.page === (stepDetails.editOrder?.find(item => item.label === 'Pedido Médico').page) &&
                            (
                                <CollapsibleListItem key={index}
                                    content={separateAttachFilesAndObserver(collapse.content)}
                                    label={collapse.label}
                                    editHandler={() => history.push(collapse.path, { from: location.pathname })}
                                />
                            )
                        )}
                    </CollapsibleListSection>

                    <CollapsibleListSection title='Datos de contacto' isOpen={isSectionOpen}>
                        {stepDetails?.editOrder?.map((collapse, index) => collapse.page === (stepDetails.editOrder?.find(item => item.label === 'Email').page) &&
                            (
                                <CollapsibleListItem key={index}
                                    content={datosSocio[collapse.content]}
                                    label={collapse.label}
                                    editHandler={() => history.push(collapse.path, { from: location.pathname })}
                                />
                            )
                        )}
                    </CollapsibleListSection>
                </IonList>
            </IonContent>

            <IonFooter className={`${styles.verificaAutorizacionesFooter} ion-no-border`}>
                <NavigationButton onClick={handleSubmit} disabled={false} isFinished={isFinished} success={wasSuccessful}>
                    Generar Trámite
                </NavigationButton>

                {isWeb() && <Footer />}
            </IonFooter>

            {showBackdrop && <IonBackdrop style={{zIndex: 99}}/>}

            <StandardModal
                isOpen={numeroTramiteEmpty}
                title='Importante'
                content={'Lo sentimos, tuvimos un problema. Por favor, intentá nuevamente más tarde.'}
                handler={() => history.replace(AutorizacionesRoutes.root)}
            />
            
        </IonPage>
    );
};

export default StepVerificarDatos;