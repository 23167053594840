import {
  IonPage,
  IonContent,
  IonGrid,
  IonSpinner,
  IonRow,
  IonCol,
} from '@ionic/react';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { startLogout } from '../../../store/users/actions/user';
import AuthHeader from '../components/auth-header/AuthHeader';
import AuthText from '../components/auth-text/AuthText';
import { menuController } from '@ionic/core';
import AuthTitle from '../components/auth-title/AuthTitle';
import { useHistory } from 'react-router';
import { routes as homeRoutes } from "../../home/router/routes";

const LogoutPage: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        menuController.close();
        setTimeout(() => {
            dispatch(startLogout())
            history.replace(homeRoutes.root)
        }, 3000);
    }, [dispatch, history])


    return (
        <IonPage>
            <IonContent className="ion-text-center">
                <IonGrid>
                    <AuthHeader>
                        <IonSpinner color="primary" />
                    </AuthHeader>
                    <IonRow>
                        <IonCol>
                            <AuthTitle>AGUARDÁ UN MOMENTO</AuthTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <AuthText>Estamos cerrando tu sesión</AuthText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default LogoutPage;
