import React from 'react';
import { IonModal, IonContent, IonHeader, IonToolbar, IonTitle, IonButton, IonButtons, IonIcon } from '@ionic/react';
import styles from './StandardModal.module.scss';
import { close } from 'ionicons/icons'

interface ModalProps {
    id?: string;
    isOpen: boolean;
    title: string;
    subtitle?: string;
    content: any;
    handler: any;
}

const StandardModal: React.FC<ModalProps> = ({ id, isOpen, title, subtitle, content, handler }) => {
    return (
        
        <IonModal 
            isOpen={isOpen}
            id={ id ? id : "modal"}
            cssClass={styles.standardModal}
            onDidDismiss={handler}
            mode='md'
        >
            <IonHeader>
                <IonToolbar color='primary'>
                    <IonTitle size="large" id='modal-title'>
                        <div className="ion-text-wrap">
                            {title}
                        </div>
                    </IonTitle>
                    {subtitle && 
                        <IonTitle id="title-subtitle" size="small" className={styles.subtitle}>
                            {subtitle}
                        </IonTitle>
                    }
                    <IonButtons slot="end">
                        <IonButton onClick={handler} className="button-modal-close" id='close-modal-btn'>
                            <IonIcon className="icon-modal-close" icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent id="content-content">
                {content}
            </IonContent>
        </IonModal>
        
    );
};

export default StandardModal;

