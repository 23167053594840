import { IonPage, IonContent, IonFooter, IonToolbar, IonList, IonBackdrop } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import styles from './VerificarPage.module.scss';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import Header from '../../../../../shared/components/layout/header/Header';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import { isWeb } from '../../../../../shared/utils/utils';
import { useHistory, useLocation } from 'react-router';
import empadronamientoService from '../../services/EmpadronamientosService';
import { setNumeroTramite } from '../../../../../store/empadronamiento/actions';
import AlertPopup from '../../../../../shared/components/popups/alerts/alert/AlertPopup';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { routes as empadronamientoRoutes } from '../../../empadronamiento/router/routes';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import { routes as homeRoutes } from '../../../../home/router/routes';
import CollapsibleListSection from '../../../../../shared/components/collapsible/collapsible-list-section/CollapsibleListSection';
import CollapsibleListItem from '../../../../../shared/components/collapsible/collapsible-list-item/CollapsibleListItem';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';
import { DatosSociaType } from '../../types/EmpadronamientoTypes';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import useHttpErrorHandler from '../../../../../hooks/useHttpErrorHandler';

const VerificarPage: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const [alertMessage, setAlertMessage] = useState('');
    const [disabledButton, setDisabledButton] = useState(false);
    const [success, setSuccess] = useState<boolean>(undefined)
    const [isFinished, setIsFinished] = useState<boolean>(false)
    const [isSectionOpen, setIsSectionOpen] = useState<boolean>(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const dispatch = useDispatch();
    useHttpErrorHandler();

    const { empadronamiento: { sociaElegida }, user: { user } } = useSelector((state: RootState) => state);
    const { contrato: { numeroBeneficiario, numeroAfiliado, numeroFilial }, esBeneficiario, apellidos, nombres } = user;
    const { contrato: contratoSocia, esBeneficiario: esBeneficiarioSocia, nombreCompleto: nombreCompletoSocia, orden: ordenSocia } = sociaElegida || {};

    useEffect(() => {
        if (!sociaElegida) {
            history.push(homeRoutes.root);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => setIsSectionOpen(true), 500);
        return () => { clearTimeout(timer) }
    }, []);

    const closeAlertHandler = () => {
        setAlertMessage('');
    };

    const httpErrorHandler = () => {
        setShowBackdrop(false);
        setIsFinished(true);
        setSuccess(false);
        setDisabledButton(true);
    }

    const callService = async () => {
        setShowBackdrop(true);
        const originante: DatosSociaType = {
            contrato: numeroFilial + numeroAfiliado,
            orden: numeroBeneficiario,
            esBeneficiario: esBeneficiario,
            nombreCompleto: apellidos + ' ' + nombres
        };

        const empadronada: DatosSociaType = {
            contrato: contratoSocia,
            esBeneficiario: esBeneficiarioSocia,
            nombreCompleto: nombreCompletoSocia,
            orden: ordenSocia
        };

        try {
            const response = await empadronamientoService.postForm(originante, empadronada);
            if (response.mensaje.codigo === 200) {
                setIsFinished(true);
                setSuccess(true);
                dispatch(setNumeroTramite(response.numeroTramite));
                setTimeout(() => {
                    dispatch(SetStepperNavigation(Animations.next))
                    history.push(empadronamientoRoutes.resultado);
                }, 1500)
            } else {
                httpErrorHandler();
                setAlertMessage('Lo sentimos, en este momento no podemos atender tu solicitud. Por favor, intentá nuevamente más tarde.');
            }
        } catch (error) {
            httpErrorHandler();
        }
    };

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles.verificaEmpadronamiento}>

                <PageTitle>
                    Empadronamiento para Anticonceptivos
                </PageTitle>

                <Stepper currentNumber={3} stepsLenght={4} />

                <StepTitle color='white'>
                    Verificá los datos
                </StepTitle>

                <IonList className='ion-margin'>
                    <CollapsibleListSection title='Datos de la práctica' isOpen={isSectionOpen}>
                        <CollapsibleListItem
                            label='Integrante del grupo familiar'
                            content={nombreCompletoSocia}
                            editHandler={() => history.push(empadronamientoRoutes.root, { from: location.pathname })}
                        />
                        <CollapsibleListItem
                            label='Motivo de la prescripción'
                            content='Programa de salud sexual y procreación responsable'
                        />
                    </CollapsibleListSection>
                </IonList>
            </IonContent>

            <IonFooter className={`${styles.verificaEmpadronamientoFooter} ion-no-border`}>
                <IonToolbar color='light'>
                    <NavigationButton
                        data-testid="buttonSiguiente"
                        disabled={disabledButton}
                        onClick={callService}
                        isFinished={isFinished}
                        success={success}
                    >
                        Generar trámite
                    </NavigationButton>
                </IonToolbar>
                {isWeb() && <Footer />}
            </IonFooter>

            <AlertPopup
                isOpen={!!alertMessage}
                title={'ALERTA'}
                message={alertMessage}
                buttons={[{
                    text: 'Aceptar',
                    handler: () => {
                        closeAlertHandler();
                        history.push(homeRoutes.root);
                    }
                }]}
            />

            {showBackdrop && <IonBackdrop style={{zIndex: 99}}/>}

        </IonPage>
    );
};

export default VerificarPage;