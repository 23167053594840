import { IonButton, IonCard, IonIcon, IonLabel, IonListHeader } from '@ionic/react';
import { chevronDown, chevronUp } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react'
import styles from './CollapsibleListSection.module.scss'

interface Props {
    title: string
    isOpen?: boolean
}

 
const CollapsibleListSection: React.FC<Props> = props => {

    const [active, setActive] = useState(false);
    const contentRef = useRef<any>(null);

    useEffect(() => {
        if(contentRef.current) {
            contentRef.current.style.maxHeight = active ? `${contentRef.current.scrollHeight}px` : '0px';
        }
    }, [contentRef, active])
  
    const toogleActive = () => {
        setActive(prevState => !prevState);
    }

    useEffect(() => {
        (props.isOpen !== undefined) && setActive(props.isOpen)
    }, [props.isOpen]);

    return ( 
        <IonCard className={styles.collapsibleSection}>
            <IonListHeader className={styles.collapsibleHeader} mode='md' onClick={toogleActive}>
                <IonLabel className='ion-text-left'>{props.title}</IonLabel>
                <IonButton>
                    {active ? <IonIcon color='medium' icon={chevronUp}/> : <IonIcon color='medium' icon={chevronDown}/>}
                </IonButton>
            </IonListHeader>
            <div ref={contentRef} className={styles.collapsibleContent}>
                {props.children}
            </div>
        </IonCard>
    );
}

 
export default CollapsibleListSection;