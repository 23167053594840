import { InAppBrowserObject } from '@ionic-native/in-app-browser';
import { BrowserAction, SET_BROWSER_REF, SET_ERROR_MESSAGE, UNSET_BROWSER_REF, UNSET_ERROR_MESSAGE } from './actionsType';

export interface browserState {
    browserRef?: InAppBrowserObject | null;
    errorMessage?: string
}

const initialState: browserState = {
    browserRef: null,
    errorMessage: ''
}
export const inAppBrowserReducer = (
    state: browserState = initialState,
    action: BrowserAction
) => {
    switch (action.type) {
        case SET_BROWSER_REF:
            return {
                ...state,
                browserRef: action.payload
            }
        case UNSET_BROWSER_REF:
            return {
                ...state,
                browserRef: action.payload
            }
        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload
            }
        case UNSET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: ''
            }
        default:
            return { ...state };
    }
}