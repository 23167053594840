import { ReintegrosDataType, TramiteReintegroDataType } from "../../../../../store/reintegros/types/ReintegrosData";


export const consultarFiltradosMock: ReintegrosDataType = {
    id: null,
    aptoServicio: true,
    listaTipoPracticas: [
        {
            id: 1,
            nombre: "Consulta Médica",
            sesionesMaximas: 0,
            pedidoMedico: false,
            pedidoMedicoObligatorio: false,
            tieneFactura: true,
            tieneTicket: false
        },
        {
            id: 2,
            nombre: "Nutrición",
            sesionesMaximas: 0,
            pedidoMedico: false,
            pedidoMedicoObligatorio: false,
            tieneFactura: true,
            tieneTicket: false
        },
        {
            id: 3,
            nombre: "Fonoaudiología",
            sesionesMaximas: 20,
            pedidoMedico: true,
            pedidoMedicoObligatorio: true,
            tieneFactura: true,
            tieneTicket: false
        },
        {
            id: 4,
            nombre: "Kinesiología",
            sesionesMaximas: 20,
            pedidoMedico: true,
            pedidoMedicoObligatorio: true,
            tieneFactura: true,
            tieneTicket: false
        },
        {
            id: 5,
            nombre: "Psicología",
            sesionesMaximas: 20,
            pedidoMedico: false,
            pedidoMedicoObligatorio: false,
            tieneFactura: true,
            tieneTicket: false
        },
        {
            id: 6,
            nombre: "Odontología",
            sesionesMaximas: 0,
            pedidoMedico: true,
            pedidoMedicoObligatorio: false,
            tieneFactura: true,
            tieneTicket: false
        },
        {
            id: 7,
            nombre: "Productos de Ortopedia",
            sesionesMaximas: 0,
            pedidoMedico: true,
            pedidoMedicoObligatorio: true,
            tieneFactura: true,
            tieneTicket: true
        },
        {
            id: 8,
            nombre: "Medicamentos",
            sesionesMaximas: 0,
            pedidoMedico: true,
            pedidoMedicoObligatorio: false,
            tieneFactura: true,
            tieneTicket: true
        },
        {
            id: 9,
            nombre: "Otras",
            sesionesMaximas: 0,
            pedidoMedico: true,
            pedidoMedicoObligatorio: false,
            tieneFactura: true,
            tieneTicket: true
        }
    ],
    cuentaCredito: {
        tieneCuentaCredito: true,
        numero: "",
        banco: "Banco Francés-BBVA",
        numeroFormateado: "**********8206"
    },
    listaIntegrantes: [
        {
            filial: "06",
            afiliado: "2031779",
            beneficiario: "01",
            nombre: "SILVIA ISABEL D'AGOSTINO"
        },
        // {
        //     filial: "06",
        //     afiliado: "2031779",
        //     beneficiario: "02",
        //     nombre: "SILVIO ISABELO D'AGOSTINO"
        // },
    ],
    listaTipoComprobante: [
        {
            id: 6,
            descripcion: "Factura B"
        },
        {
            id: 7,
            descripcion: "Nota de Débito B"
        },
        {
            id: 8,
            descripcion: "Nota de Crédito B"
        },
        {
            id: 9,
            descripcion: "Recibo B"
        },
        {
            id: 10,
            descripcion: "Nota de Venta al Contado B"
        },
        {
            id: 11,
            descripcion: "Factura C"
        },
        {
            id: 12,
            descripcion: "Nota de Débito C"
        },
        {
            id: 13,
            descripcion: "Nota de Crédito C"
        },
        {
            id: 15,
            descripcion: "Recibo C"
        },
        {
            id: 19,
            descripcion: "Factura de Exportación"
        },
        {
            id: 20,
            descripcion: "Nota Déb. P/Operac. con el Exterior"
        },
        {
            id: 21,
            descripcion: "Nota Créd. P/Operac. con el Exterior"
        },
        {
            id: 22,
            descripcion: "Fac. Perm. Export. Simp. - Dto.855/97"
        },
        {
            id: 28,
            descripcion: "Liquidación Única Comercial Impositiva Clase B"
        },
        {
            id: 30,
            descripcion: "Cbtes. Compra de Bienes Usados"
        },
        {
            id: 31,
            descripcion: "Mandato/Consignación"
        },
        {
            id: 32,
            descripcion: "Comprobantes de Compra de Materiales a Reciclar Provenientes"
        },
        {
            id: 34,
            descripcion: "Cbtes. A del Anexo I, Apartado A,Inc.F),R.G.Nro. 1415"
        },
        {
            id: 35,
            descripcion: "Cbtes. B del Anexo I, Apartado A, Inc. F), R.G. Nro. 1415"
        },
        {
            id: 37,
            descripcion: "Nota Déb/Doc. Equiv. que Cumplan con la R.G. Nro. 1415"
        },
        {
            id: 38,
            descripcion: "Nota Créd/Doc. Equiv. que Cumplan con la R.G. Nro. 1415"
        },
        {
            id: 40,
            descripcion: "Otros Comprobantes B que Cumplan con la R.G. Nro. 1415"
        },
        {
            id: 43,
            descripcion: "Nota de Crédito Liquidación Única Comercial Impositiva Clase B"
        },
        {
            id: 46,
            descripcion: "Nota de Débito Liquidación Única Comercial Impositiva Clase B"
        },
        {
            id: 49,
            descripcion: "Comprobante de Compra de Bienes Usados"
        },
        {
            id: 51,
            descripcion: "Factura M"
        },
        {
            id: 52,
            descripcion: "Nota de Débito M"
        },
        {
            id: 53,
            descripcion: "Nota de Crédito M"
        },
        {
            id: 54,
            descripcion: "Recibo M"
        },
        {
            id: 55,
            descripcion: "Nota de Venta al Contado M"
        },
        {
            id: 56,
            descripcion: "Comprobantes M del Anexo I, Apartado A, Inc. F) R.G. Nro.1415"
        },
        {
            id: 57,
            descripcion: "Otros Comprobantes M que Cumplan con la R.G. Nro. 1415"
        },
        {
            id: 58,
            descripcion: "Cuenta de Venta Y Líquido Producto M"
        },
        {
            id: 59,
            descripcion: "Liquidación M"
        },
        {
            id: 61,
            descripcion: "Cta. de Vta. Y Líquido Prod. B"
        },
        {
            id: 64,
            descripcion: "Liquidación B"
        },
        {
            id: 70,
            descripcion: "Recibo de Factura de Crédito R"
        },
        {
            id: 91,
            descripcion: "Remito R"
        }
    ],
    correoElectronico: "SILVIADAGOSTINO@YAHOO.COM.AR",
    correoEstado: {
        id: null,
        descripcion: "OK",
        editable: true
    },
    usuarioHabilitado: true,
    listaTipoDocumento: [
        {
            id: 96,
            descripcion: "DNI"
        },
        {
            id: 80,
            descripcion: "CUIT"
        },
        {
            id: 86,
            descripcion: "CUIL"
        },
        {
            id: 90,
            descripcion: "LC"
        },
        {
            id: 89,
            descripcion: "LE"
        },
        {
            id: 94,
            descripcion: "Pasaporte"
        }
    ]
}


export const finalizarMock: TramiteReintegroDataType = {
  error:false,
  errorField:"",
  idTramite:124086
}
