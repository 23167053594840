import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { pencilSharp } from 'ionicons/icons';
import React from 'react'
import styles from './CollapsibleListItem.module.scss'

interface Props {
    label: string;
    content?: string | number;
    editHandler?: () => void;
}


const CollapsibleListItem: React.FC<Props> = ({ label, content, editHandler }) => {

    return (
        <IonItem lines='full' className={styles['collapsible-item']}>
            {Array.isArray(content) ?
                <>
                    <IonLabel>
                        <h2>{label}</h2>
                        {content.map((content, index) =>
                            <p key={index}>
                                {content}
                            </p>
                        )}
                    </IonLabel>
                </>
                :
                <IonLabel>
                    <h2>{label}</h2>
                    <p className='ion-text-wrap'>{content}</p>
                </IonLabel>
            }
            {editHandler &&
                <IonButton color='secondary' slot='end' mode='ios' fill='outline' onClick={editHandler}>
                    <IonIcon icon={pencilSharp} />
                </IonButton>}
        </IonItem>
    );
}

export default CollapsibleListItem;