import React from "react";
import { IonPage, IonGrid, IonContent, IonRow, IonCol, IonLabel } from "@ionic/react";
import styles from "./FacturacionPage.module.scss";
import Header from "../../../shared/components/layout/header/Header";
import { useSelector } from "react-redux";
import Documentacion from "./components/documentacion/Documentacion";
import { RootState } from "../../../store";
import PageTitle from "../../../shared/components/page-title/PageTitle";

const FacturacionPage: React.FC = () => {

    const fetchedUser = (state: RootState) => state.user.user;
    const user = useSelector(fetchedUser);
    
    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles.facturacionContent}>
                <IonGrid className={`${styles.facturacionGrid} ion-no-padding`} >
                    <PageTitle lines>
                        FACTURACIÓN
                    </PageTitle>
                    <IonRow className={styles.subtitle}>
                        <IonCol>
                            <IonLabel id='visualiza-text'>
                                Visualizá la documentación de pago correspondiente al último período facturado.
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonLabel className={styles.socioName}>
                                <h2>Socio Titular</h2>
                                <p id='socio-name'>{user?.apellidos} {user?.nombres}</p>
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <Documentacion />          
            </IonContent>
        </IonPage>
    );
}

export default FacturacionPage;
