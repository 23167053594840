import { Comprobante, GetFormResponse, PaymentMethod, PostFormResponse, Talon } from "./PagoEnLineaTypes";

export const SET_UNAVAILABLE = 'SET_UNAVAILABLE';
export const SET_PAYNUMBER = 'SET_PAYNUMBER';
export const ADD_TALON = 'ADD_TALON';
export const REMOVE_TALON = 'REMOVE_TALON';
export const SET_GET_FORM = 'SET_GET_FORM';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_TOKEN = "SET_TOKEN";
export const SET_FIRST_SIX_NUMBER = "SET_FIRST_SIX_NUMBER";
export const SET_EMAIL = "SET_EMAIL";
export const SET_POST_FORM_RESPONSE = 'SET_POST_FORM_RESPONSE';
export const SET_VOUCHER = 'SET_VOUCHER';
export const SET_SHOW_INITIAL_MESSAGE = 'SET_SHOW_INITIAL_MESSAGE';

export const UNSET_PAGO = 'UNSET_PAGO';

interface SetAvailableAction {
  type: typeof SET_UNAVAILABLE;
  payload: boolean | null;
}

interface SetPayNumberAction {
  type: typeof SET_PAYNUMBER;
  payload: string;
}

interface AddTalonAction {
  type: typeof ADD_TALON;
  payload: Talon;
}

interface RemoveTalonAction {
  type: typeof REMOVE_TALON;
  payload: string;
}

interface SetGetFormAction {
  type: typeof SET_GET_FORM;
  payload: GetFormResponse;
}

interface SetPaymentCardAction {
  type: typeof SET_PAYMENT_METHOD;
  payload: PaymentMethod;
}

interface SetTokenAction{
  type: typeof SET_TOKEN
  payload: string
}

interface SetfirstSixNumbersAction{
  type: typeof SET_FIRST_SIX_NUMBER
  payload: string
}

interface SetEmailAction{
  type: typeof  SET_EMAIL
  payload: string
}

interface SetPostFormAction {
  type: typeof SET_POST_FORM_RESPONSE;
  payload: PostFormResponse;
}

interface SetVoucherAction {
  type: typeof SET_VOUCHER;
  payload: Comprobante;
}

interface SetShowInitialMessage {
  type: typeof SET_SHOW_INITIAL_MESSAGE;
  payload: boolean;
}

interface UnsetPago {
  type: typeof UNSET_PAGO;
}

export type PagoEnLineaAction =
  | SetAvailableAction
  | SetPayNumberAction
  | AddTalonAction
  | RemoveTalonAction
  | SetGetFormAction
  | SetPaymentCardAction
  | SetTokenAction
  | SetfirstSixNumbersAction
  | SetEmailAction
  | SetPostFormAction
  | SetVoucherAction
  | SetShowInitialMessage
  | UnsetPago;
