import { IonList, IonPage, IonContent, IonFooter, IonGrid } from '@ionic/react';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import InputForm from '../../../../../shared/components/form/inputForm/InputForm';
import Header from '../../../../../shared/components/layout/header/Header';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { isWeb } from '../../../../../shared/utils/utils';
import { RootState } from '../../../../../store';
import { setOrigenDestino } from '../../../../../store/autorizaciones/AutorizacionesActions';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import {routes as AutorizacionesRoutes} from '../../router/routes';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import { routes as homeRoutes } from '../../../../home/router/routes';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';


const StepDomicilioOrigenDestino: React.FC = () => {
    const {origenDestino, stepDetails} = useSelector((state: RootState) => state.autorizaciones);
    const {isKeyboardOn} = useSelector((state: RootState) => state.ui);
    const history = useHistory();
    const dispatch = useDispatch();
    const {state} = useLocation<{from: string}>();

    useEffect(() => {
        if(!stepDetails){
            history.push(homeRoutes.root);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { handleSubmit, formState, control } = useForm({
        mode: 'onChange',
        defaultValues: {
            origen: origenDestino?.origen ?? '',
            destino: origenDestino?.destino ?? '',
        }
    });


    const { isValid } = formState;

    const onSubmit = (dataForm: any) => {
        dispatch(SetStepperNavigation(Animations.next))
        let datosDomicilioUpdated = {...origenDestino!}
        datosDomicilioUpdated = {...datosDomicilioUpdated, ...dataForm}
        dispatch(setOrigenDestino(datosDomicilioUpdated));
        if(state?.from){
            history.goBack();
        } else {
            return history.push(AutorizacionesRoutes.adjuntarArchivo)
        }
    }

    const footer = () => (
        <IonFooter className='ion-no-border'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <NavigationButton text='Datos obligatorios(*)' type='submit' disabled={!isValid} >SIGUIENTE</NavigationButton>
            </form>               
            {isWeb() && <Footer />}
        </IonFooter>
    )

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent>
                <IonGrid className='ion-no-margin ion-no-padding'>
                    <PageTitle>{stepDetails?.titleAndLenght?.title}</PageTitle>

                    <Stepper currentNumber={3} stepsLenght={stepDetails?.titleAndLenght?.lenghtOfPages}></Stepper>

                    <StepTitle>
                        Domicilio de origen y destino
                    </StepTitle>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <IonList className='ion-margin-horizontal'>
                            <Controller
                                render={({onChange, value}) => (
                                    <InputForm 
                                        onChange={e => onChange(e.detail.value)}
                                        name='origen'
                                        label='Ingresá el domicilio de origen (*)'
                                        placeHolder='Dirección y localidad'
                                        value={value}
                                    />
                                )} 
                                name='origen'
                                control={control}
                                rules={{required:true}}    
                            />

                            <br />
                            <br />

                            <Controller 
                                render={({onChange, value}) => (
                                    <InputForm 
                                        onChange={e => onChange(e.detail.value)}
                                        name='destino'
                                        label='Ingresá el domicilio de destino (*)'
                                        placeHolder='Dirección y localidad'
                                        value={value}
                                    />      
                                )}
                                control={control}
                                rules={{required:true}}   
                                name='destino'
                            />
                                        
                        </IonList>
                    </form>
                </IonGrid>
                {isKeyboardOn && footer()}                    
            </IonContent>
            {!isKeyboardOn && footer()}
        </IonPage>
    );
};

export default StepDomicilioOrigenDestino;