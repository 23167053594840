import { History } from 'history';
import { IException, IExceptions } from '../../../../services/Exceptions';


export const getReintegrosExceptions = (history: History, ...args: any[]): IExceptions => {
  return (
    {
      ComprobanteInvalido: {
      code: 202,
      name: "Comprobante inválido",
      field: "exception",
      title: "Comprobante inválido",
      message: "No es posible procesar el comprobante. Por favor, verificá que los datos ingresados coincidan exactamente con los que figuran en el ticket o factura para poder validarlos con la AFIP..",
      internalMessage: "",
      showAlert: true
    },
    SinCorreoElectronico: {
      code: 203,
      name: "Sin correo electronico",
      field: "exception",
      title: "IMPORTANTE",
      message: "Para efectuar este trámite, es necesario que el titular del grupo familiar informe un correo electrónico de contacto. Podrá hacerlo a través de nuestra web o la app OSDE Móvil, llamando al 0810.555.6733, o personalmente en el centro de atención más cercano.",
      internalMessage: "",
      showAlert: true
    }})
};


export const compareReintegrosException = (errorResponse, exception: IException): boolean => {
  if (errorResponse["data"]["ns2.objetoMensaje"]) {
    errorResponse = {
      ...errorResponse,
      data: errorResponse["data"]["ns2.objetoMensaje"],
    }
  }
  return (errorResponse?.status === 500 && errorResponse?.data?.codigo === exception.code);
}