import {
  EmpadronamientoAction,
  SET_EMPADRONAMIENTO_GETFORM,
  SET_SOCIA_EMPADRONAMIENTO,
  SET_ACEPTA_TERMINOS,
  SET_NINGUN_ANTECEDENTE,
  SET_NUMERO_TRAMITE,
  UNSET_EMPADRONAMIENTO,
} from './actionsType';
import { AntecedenteDataType, DatosSociaType, SociaDataType } from '../../modules/tramites/empadronamiento/types/EmpadronamientoTypes';

export const setEmpadronamientoGetForm = 
(responseData: {listaSocias: SociaDataType[], antecedentes: AntecedenteDataType[]}): EmpadronamientoAction => {
  return {
    type: SET_EMPADRONAMIENTO_GETFORM,
    payload: responseData,
  };
};

export const setSociaElegida = (sociaElegida: DatosSociaType) => {
  return {
    type: SET_SOCIA_EMPADRONAMIENTO,
    payload: sociaElegida,
  };
};

export const setAceptaTerminos = (aceptaTerminos: boolean) => {
  return {
    type: SET_ACEPTA_TERMINOS,
    payload: aceptaTerminos,
  };
};

export const setNingunAntecedente = (ningunAntecedente: boolean) => {
  return {
    type: SET_NINGUN_ANTECEDENTE,
    payload: ningunAntecedente,
  };
};

export const setNumeroTramite = (numeroTramite: number) => {
  return {
    type: SET_NUMERO_TRAMITE,
    payload: numeroTramite,
  };
};

export const unsetEmpadronamiento = () => {
  return {
    type: UNSET_EMPADRONAMIENTO
  };
};