import { useState, useEffect, useRef, useCallback } from 'react';

type returnedType = {
  beginCountdown: () => void;
  stopCountdown: () => void;
  resetTimeLeft: (newTime?: number) => void;
  timeLeft: number};

/**
 * Hook para realizar countdowns en la app.
 * @description Permite pasar una funcion opcional a ejecutar cuando termina el conteo o usar el tiempo restante retornado en el componente que lo invoca.
 * @param startingTime: Valor en segundos que queremos dure el conteo.
 * @param timeoutAction: Funcion opcional a ejecutar al terminar el conteo.
 * @return beginCountdown: Funcion que inicia el conteo.
 * @return stopCountdown: Funcion para terminar el conteo manualmente.
 * @return resetTimeLeft: Funcion para resetear los segundos a un nuevo valor o el inicial.
 * @return timeLeft: Segundos restantes actualizado en tiempo real.
 */
export const useCountdown = (startingTime: number, timeoutAction?: Function) => {
  const [timeLeft, setTimeLeft] = useState(startingTime);
  const intervalRef = useRef<any>(null);

  const beginCountdown = useCallback(() => {intervalRef.current = setInterval(countDown, 1000)}, []);
  
  const stopCountdown = () => clearInterval(intervalRef.current);
  
  const countDown = () => setTimeLeft(prevState => prevState - 1);
  
  const resetTimeLeft = useCallback((newTime?: number) => setTimeLeft(() => newTime || startingTime), [startingTime]);

  useEffect(() => {
    return () => stopCountdown();
  }, []);

  useEffect(() => {
    if(timeLeft < 1) {
      stopCountdown();
      timeoutAction?.();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return {beginCountdown, stopCountdown, resetTimeLeft, timeLeft} as returnedType;
};