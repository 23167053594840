
import {
  ADD_TALON,
  PagoEnLineaAction,
  SET_PAYNUMBER,
  SET_UNAVAILABLE,
  REMOVE_TALON,
  SET_GET_FORM,
  SET_PAYMENT_METHOD,
  SET_TOKEN,
  SET_FIRST_SIX_NUMBER,
  SET_EMAIL,
  SET_POST_FORM_RESPONSE,
  SET_VOUCHER,
  UNSET_PAGO,
  SET_SHOW_INITIAL_MESSAGE
} from './types/actionTypes';
import { Comprobante, GetFormResponse, PaymentMethod, PostFormResponse } from './types/PagoEnLineaTypes';

export interface PagoEnLineaState {
  token: any | null
  available: boolean | null;
  getForm: GetFormResponse;
  payNumber: string | null;
  paymentCard: PaymentMethod | null;
  firstSixNumbers: string | null;
  postFormResponse: PostFormResponse;
  voucher: Comprobante | null;
  showInitialMessage: boolean;
}

const initialState: PagoEnLineaState = {
  available: true,
  token: null,
  payNumber: null,
  paymentCard: null,
  firstSixNumbers: null,
  getForm: {
    direccionMail: '',
    observacion: { codigo: 0, descripcion: '' },
    talones: [],
  },
  postFormResponse: {
    idTransaccion: null,
    observacion: { codigo: null, descripcion: '' }
  },
  voucher: null,
  showInitialMessage: true
};

export const pagoEnLineaReducer = (
  state: PagoEnLineaState = initialState,
  action: PagoEnLineaAction
) => {
  switch (action.type) {
    case SET_UNAVAILABLE:
      return {
        ...state,
        available: action.payload,
      };
    case SET_PAYNUMBER:
      return {
        ...state,
        payNumber: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case SET_GET_FORM: {
      action.payload.talones = [];
      return {
        ...state,
        getForm: action.payload,
      };
    }
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentCard: action.payload,
      };
    case ADD_TALON:
      return {
        ...state,
        getForm: {
          ...state.getForm,
          talones: [...state.getForm.talones, action.payload],
        },
      };
    case REMOVE_TALON:
      return {
        ...state,
        getForm: {
          ...state.getForm,
          talones: [
            ...state.getForm.talones.filter(
              (item) => action.payload !== item.id
            ),
          ],
        },
      };
    case SET_FIRST_SIX_NUMBER:
      return {
        ...state,
        firstSixNumbers: action.payload
      };
    case SET_EMAIL:
      return {
        ...state,
        getForm: {
          ...state.getForm,
          direccionMail: action.payload
        },
      };
    case SET_POST_FORM_RESPONSE: {
      return {
        ...state,
        postFormResponse: action.payload,
      };
    }
    case SET_VOUCHER: {
      return {
        ...state,
        voucher: action.payload
      }
    }
    case SET_SHOW_INITIAL_MESSAGE:
      return {
        ...state,
        showInitialMessage: action.payload
      };

    case UNSET_PAGO: {
      return initialState
    }

    default:
      return state;
  }
};
