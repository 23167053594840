import {
  IonPage,
  IonContent,
  IonGrid,
  IonSpinner,
  IonRow,
  IonCol,
} from '@ionic/react';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLogginIos, startLogout } from '../../../store/users/actions/user';
import AuthHeader from '../components/auth-header/AuthHeader';
import AuthText from '../components/auth-text/AuthText';
import { menuController } from '@ionic/core';
import AuthTitle from '../components/auth-title/AuthTitle';
import { useHistory } from 'react-router';
import { routes as homeRoutes } from "../../home/router/routes";
import { RootState } from '../../../store';
import { isIos } from '../../../shared/utils/utils';

const TimeoutPage: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user: { isLoginIos }} = useSelector((state: RootState) => state);

    useEffect(() => {
        // TODO: Deuda Tecnica
        // esto se hizo por que el PrivateRoute se esta ejecutando 2 veces, y hace que se pise el componente de cierre de sesion y no vaya a la pantalla de logout de IDM.
        // Para android la variable isLoginIos es siempre falsa, para iOS varia
        if (!isLoginIos) { 
            isIos() && dispatch(setIsLogginIos());
            menuController.close();
            setTimeout(() => {
                dispatch(startLogout())
                history.replace(homeRoutes.root)
            }, 3000);
        }
    }, [dispatch, history, isLoginIos])


    return (
        <IonPage>
            <IonContent className="ion-text-center">
                <IonGrid>
                    <AuthHeader>
                        <IonSpinner color="primary" />
                    </AuthHeader>
                    <IonRow>
                        <IonCol>
                            <AuthTitle>AGUARDÁ UN MOMENTO</AuthTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <AuthText>Se venció tu sesión</AuthText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default TimeoutPage;
