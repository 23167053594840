import React from 'react'
import { IonActionSheet } from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import { setUnShowPhones } from '../../../../store/popUpTelefonos/phoneActions'
import { RootState } from '../../../../store'
import { CallNumber } from '@ionic-native/call-number';
import { callOutline, close } from 'ionicons/icons'
import { isWeb } from '../../../utils/utils';
import styles from './PhonespopUp.module.scss';

const PhonesPopUp = () => {
    const dispatch = useDispatch();
    const { phones } = useSelector((state: RootState) => state.phonePopUp);

    const handleClick = async (number: string) => {
        if (isWeb()) {
            window.location.href = `tel:${number}`;
        } else {
            await CallNumber.callNumber(number, true);
        }
        dispatch(setUnShowPhones());
    }

    return (
        <IonActionSheet
            isOpen={phones.length > 0}
            onDidDismiss={() => dispatch(setUnShowPhones())}
            buttons={
                [...phones.map(item => {
                return {
                    cssClass: styles.actionSheetIcon,
                    text: item.name,
                    icon: callOutline,
                    handler: () => handleClick(item.link)
                }
            }), {
                cssClass: styles.actionSheetIconCancel,
                text: 'Cancelar',
                role: 'cancel',
                icon: close
            }]}
        />

    )
}

export default PhonesPopUp