import { IonContent, IonFooter, IonPage } from "@ionic/react";
import React from "react";
import Header from "../../../shared/components/layout/header/Header";
import {  isWeb } from "../../../shared/utils/utils";
import ContactosWebNative from "./components/ContactosWebNative";
import PageTitle from "../../../shared/components/page-title/PageTitle";
import getContactos from "./helpers/getContactos";
import Footer from "../../../shared/components/layout/footer/Footer";

const Contactanos: React.FC = () => {
  const contactos = getContactos();

  return (
    <IonPage>
      <Header showBackButton={true} />
      <IonContent>
        <PageTitle lines>
          CONTACTANOS
        </PageTitle>

        <ContactosWebNative contactos={contactos} />
        
      </IonContent>
      {isWeb() && 
        <IonFooter className='ion-no-border'>
          <Footer  />
        </IonFooter>
      }
    </IonPage>
  );
};

export default Contactanos;