import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { IonActionSheet } from '@ionic/react';
import OsdeHeader from '../assets/img/Header-Logo-50.svg';
import OsdeHeaderDesa from '../assets/img/Header-Logo-desa.svg';
import OsdeHeaderTest from '../assets/img/Header-Logo-test.svg';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import environmentService, { environmentUrls, environment } from '../../../../../services/environment/environmentService';
import { startLogout } from '../../../../../store/users/actions/user';
import { useLocation } from 'react-router';
import { routes as homeRoutes } from "../../../../../modules/home/router/routes";
import { RootState } from '../../../../../store';
import { isWebLocal } from '../../../../utils/utils';

const EnvironmentSelector: React.FC<{children: ReactNode | HTMLElement}> = ({children}) => {
    const location = useLocation();
    const user = useSelector((state: RootState) => state.user.user);
    const [header, setHeader] = useState<any>(OsdeHeader);
    const [showAlert, setShowAlert] = useState(false);
    const [headerClicks, setHeaderClicks] = useState(0);
    const [currentEnv, setCurrentEnv] = useState<environmentUrls>(environmentService.getCurrent());
    const dispatch = useDispatch();

    const verifyChangeEnv = useCallback(async () => {
        if(isWebLocal()){
            if (!(headerClicks % 5) && headerClicks > 0) {
                const canConnect = await environmentService.verifyVPN();
                if (canConnect) {
                    setShowAlert(true);
                }
            }
        }
    }, [headerClicks]);

    const changeEnvironment = (id) => {
        const newEnv = environmentService.getEnvironment(id);
        setCurrentEnv(newEnv);
        localStorage.removeItem('environment');
        localStorage.removeItem('environmentDate');
        localStorage.setItem('environment', JSON.stringify(newEnv));
        localStorage.setItem('environmentDate', JSON.stringify(dayjs().format('DD/MM/YYYY')));
        window.location.href = 'home';
        user && dispatch(startLogout(false)) 
    }

    useEffect(() => {
        if (currentEnv) {
            switch (currentEnv.id) {
                case environment.prod:
                    setHeader(OsdeHeader);
                    break;
                case environment.prem:
                    setHeader(OsdeHeader);
                    break;
                case environment.test:
                    setHeader(OsdeHeaderTest);
                    break;
                case environment.desa:
                    setHeader(OsdeHeaderDesa);
                    break;
                case environment.local:
                    setHeader(OsdeHeader);
                    break;
                default:
                    console.log('ERROR AL SETEAR AMBIENTE');
                    setHeader(OsdeHeader);
                    break;
            }
        }
    }, [currentEnv]);

    useEffect(() => {
        verifyChangeEnv();
    }, [verifyChangeEnv]);

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                src: header,
                onClick: () => {
                    if (location.pathname === homeRoutes.root) {
                        setHeaderClicks(headerClicks + 1);
                    }
                }
            } as React.Attributes & { src: any });
        }
    });

    return (
        <>
            {childrenWithProps}
            <IonActionSheet
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='my-custom-class'
                buttons={[
                    {
                        text: 'Produccion',
                        handler: () => changeEnvironment(environment.prod)
                    }, 
                    {
                        text: 'Pre-produccion',
                        handler: () => changeEnvironment(environment.prem)
                    }, 
                    {
                        text: 'Testing',
                        handler: () => changeEnvironment(environment.test)
                    }, 
                    {
                        text: 'Desarrollo',
                        handler: () => changeEnvironment(environment.desa)
                    },
                    {
                        text: 'Local',
                        handler: () => changeEnvironment(environment.local)
                    },
                    {
                        text: 'Cancelar',
                        role: 'cancel',
                        handler: () => console.log('Cancel clicked')
                    }
                ]}
            >
            </IonActionSheet>
        </>
    );
};

export default EnvironmentSelector;