import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  IonLabel,
  IonNote
} from '@ionic/react';
import React, { useEffect, useRef } from 'react';
import Header from '../../../../../shared/components/layout/header/Header';
import NumberFormat from 'react-number-format';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Swiper as SwiperInterface } from 'swiper';
import dayjs from 'dayjs';
import styles from './PaymentFormPage.module.scss';
import pagoEnLineaService from '../../services/PagoEnLineaService';
import { PaymentForm } from '../../../../../store/pagoEnLinea/types/PagoEnLineaData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useHistory } from 'react-router';
import { setFirstSixNumbers, setToken} from '../../../../../store/pagoEnLinea/actions';
import Loading from '../../../../../shared/components/popups/loading/Loading';
import { useForm } from '../../../../../hooks/useForm';
import { routes } from '../../router/routes';
import { routes as homeRoutes} from '../../../../home/router/routes';
import analyticsService from '../../../../../services/analytics/analytics';
import TramiteFooter from '../../../../../shared/components/tramite-footer/TramiteFooter';
import { formatCuit, isNaNOnKeyPress, isNaNOnPaste } from '../../../../../shared/utils/utils';
import { PaymentCardIds } from '../../../../../store/pagoEnLinea/types/PagoEnLineaTypes';

interface CardForm {
  cardNumber: string,
  cardName: string,
  cardMonth: string,
  cardYear: string,
  cardCode: string,
  cardIdType: string,
  cardIdNumber: string
}

const PaymentFormPage: React.FC = () => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
  const cardNumberAnimationRef = useRef(null)
  const paymentCard = useSelector((state: RootState) => state.pagoEnLinea.paymentCard);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showInformation, setShowInformation] = useState(false);


  useEffect(() => {
    if (!paymentCard) {
      history.replace(homeRoutes.root);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const validateCard = (value: string): boolean => {
    return paymentCard.regex.test(value);
  };


  const validateMonth = (value: string): boolean => {
    if (parseInt(value) > 12 || parseInt(value) < 1) {
      return false;
    }
    return true;
  };


  const minYear = (value: string): boolean => {
    const currentYear = new Date().getFullYear().toString().slice(2);
    if (parseInt(currentYear) > parseInt(value)) {
      return false;
    }
    return true;
  };


  const formInitialState: CardForm = {
    cardNumber: '',
    cardName: '',
    cardMonth: '',
    cardYear: '',
    cardCode: '',
    cardIdType: 'DNI',
    cardIdNumber: ''
  }


  const validationsForm = {
    cardNumber: validateCard,
    cardMonth: validateMonth,
    cardYear: minYear,
  }


  const formProps = {
    initialState: formInitialState,
    initialValidations: validationsForm
  }


  const [{
    cardNumber,
    cardName,
    cardMonth,
    cardYear,
    cardCode,
    cardIdType,
    cardIdNumber
  }, errors, handleInputChange, setErrors] = useForm(formProps);


  const validateDate = (month: string, year: string) => {
    let valid = false;
    if (month && year) {
      const fullYear = dayjs(year, 'YY').format('YYYY');
      const cardExpireDate = dayjs(`${fullYear}-${month}-01`);
      valid = (
        dayjs().isAfter(cardExpireDate, 'month') && 
        (dayjs().isSame(cardExpireDate, 'year') || dayjs().isBefore(cardExpireDate, 'year'))
      );
      setErrors({
        ...errors,
        cardMonthError: valid
      });
    }
    return valid;
  };

  useEffect(() => {
    validateDate(cardMonth, cardYear)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardMonth, cardYear]);

  const onSubmit = async (dataForm: PaymentForm) => {
    if (cardIdType === 'CUIT') {
      dataForm.card_holder_identification.number = formatCuit(
        dataForm.card_holder_identification.number
      );
    }
    dataForm.card_number = dataForm.card_number.replace(/ /g, '');
    setIsLoading(true);
    try {
      const apiKey = await pagoEnLineaService.getPublicKey();
      const response = await pagoEnLineaService.callDecidir(
        dataForm,
        apiKey.publicKey
      );
      dispatch(setToken(response.id));
      dispatch(setFirstSixNumbers(dataForm.card_number.substring(0, 6)));
      sendErrorToAnalytics(response);
      return history.push('confirmar-pago');
    } catch (error) {
      analyticsService.formNotSubmittedAndSubmitted("pagos en línea","error generico decidir: " + JSON.stringify(error), 1, 0);
    } finally {
      setIsLoading(false);
    }
  };

  const sendErrorToAnalytics = (response) => {
    if (response.error !== undefined || response.error_type !== undefined || response.validation_errors !== undefined) {
      analyticsService.formNotSubmittedAndSubmitted("pagos en línea", "error decidir: " + JSON.stringify(response), 1, 0);
    } 
  };

  const executeValidations = (slide) => {
    let error = false;
    if (slide === 0) {
      setErrors({
        ...errors,
        cardNumberError: (!cardNumber || 0 === String(cardNumber).length)
          || !validateCard(cardNumber)
          || String(cardNumber.replace(/[^0-9]+/g, '')).length < paymentCard.minCharsCardNumber
      })
      error = (!cardNumber || 0 === String(cardNumber).length)
        || !validateCard(cardNumber)
        || String(cardNumber.replace(/[^0-9]+/g, '')).length < paymentCard.minCharsCardNumber;
    } else if (slide === 1) {
      errors.cardNameError = (!cardName || 0 === cardName.length || !/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(cardName))
      setErrors({
        ...errors,
        cardNameError: (!cardName || 0 === cardName.length || !/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(cardName))
      })
      error = (!cardName || 0 === cardName.length || !/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(cardName))
    } else if (slide === 2) {
      setErrors({
        ...errors,
        cardCodeError: (!cardCode || 0 === cardCode.length),
        cardYearError: !minYear(cardYear),
        cardMonthError: !validateMonth(cardMonth)
      })
      error = (!cardCode || 0 === cardCode.length) || !minYear(cardYear) || !validateMonth(cardMonth) || validateDate(cardMonth, cardYear)
    } else if (slide === 3) {
      setErrors({
        ...errors,
        cardIdTypeError: (!cardIdType || 0 === cardIdType.length),
        cardIdNumberError: (!cardIdNumber || 0 === cardIdNumber.length),
      })
      error = (!cardIdType || 0 === cardIdType.length) || (!cardIdNumber || 0 === cardIdNumber.length)
    }
    return error;
  };

  const next = async () => {
    if (!executeValidations(swiperInstance?.activeIndex)) {
      if (swiperInstance?.isEnd) {
        onSubmit({
          card_expiration_month: cardMonth,
          card_expiration_year: cardYear,
          card_holder_identification: {
            type: cardIdType,
            number: cardIdNumber
          },
          card_number: cardNumber,
          card_holder_name: cardName,
          security_code: cardCode
        })
      } else {
        swiperInstance?.slideNext();
      }
    }
  };

  const prev = async () => {
    const actualSlide = swiperInstance?.activeIndex;
    if (actualSlide === 0) {
      history.push(routes.selectCard);
      return;
    }
    swiperInstance?.slidePrev();
  };

  return (
    <IonPage>
      <Header showBackButton={true} />
      {paymentCard && (
        <IonContent>
          <div className={styles.flipBox}>
            <div
              className={[
                styles.flipBoxInner,
                showInformation && styles.rotator,
              ].join(' ')}
            >
              <div className={styles.flipBoxFront}>
                <img src={paymentCard.pathImageCard} alt="Paris" />
                <p ref={cardNumberAnimationRef} className={styles.cardNumber}>
                  {cardNumber ? cardNumber : paymentCard.placeHolder}
                </p>
                {paymentCard.id === PaymentCardIds.americanExpress && (
                  <p className={styles.americanCode}>
                    {' '}
                    {cardCode ? cardCode : 'XXXX'}{' '}
                  </p>
                )}
                <p className={styles.cardName}>
                  {cardName ? cardName.toUpperCase() : 'NOMBRE APELLIDO'}
                </p>
                <p className={styles.cardExpireDate}>
                  {cardMonth ? cardMonth : 'MM'}/{cardYear ? cardYear : 'AA'}
                </p>
              </div>
              <div className={styles.flipBoxBack}>
                <img src={paymentCard.pathImageCardBack} alt="Paris" />
                <p className={styles.cardSecurityCode}>
                  {cardCode ? cardCode : 'XXX'}
                </p>
              </div>
            </div>
          </div>
          <IonGrid>
            <Swiper
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              allowTouchMove={false}
              className={styles.paymentSlides}
            >
              <SwiperSlide className={styles.swiperSlide}>
                <IonRow>
                  <IonCol size="12">
                    <IonItem className={styles.formatedInput}>
                      <IonLabel
                        position="stacked"
                        color={`${errors.cardNumberError ? 'danger' : 'primary'}`}
                        className="ion-padding-start"
                      >
                        Número de tarjeta
                      </IonLabel>
                      <NumberFormat
                        placeholder={paymentCard.placeHolder}
                        format={paymentCard.maskCardNumber}
                        mask="X"
                        value={cardNumber}
                        onKeyDown={(e) => { e.key === 'Tab' && e.preventDefault() }}
                        onChange={handleInputChange}
                        autoComplete="off"
                        name="card_number"
                        id="cardNumber"
                        className="ion-text-left ion-padding-start"
                      />
                    </IonItem>
                    {errors.cardNumberError === true &&
                      <IonNote color="danger" >
                        La tarjeta ingresada no coincide con la seleccionada previamente
                      </IonNote>
                    }
                  </IonCol>
                </IonRow>
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <IonRow>
                  <IonCol size="12">
                    <IonItem>
                      <IonLabel position="stacked" color={errors.cardNameError ? 'danger' : 'primary'}>Titular de la tarjeta</IonLabel>
                      <IonInput
                        value={cardName}
                        onIonChange={handleInputChange}
                        id="cardName"
                        name="card_name"
                        onKeyDown={(e) => { e.key === 'Tab' && e.preventDefault() }}
                        //TODO: revisar en iOS, onKeyPress={(e) => {!/^[a-zA-Z]+$/.test(e.key) && e.preventDefault()}}
                        onPaste={(e) => {!/^[a-zA-Z]+$/.test(e.clipboardData.getData('Text')) && e.preventDefault()}}
                        placeholder="Como se indica en tarjeta"
                        autoCapitalize="on"
                        className={styles.cardOwnerNameInput}
                        maxlength={22}
                      />
                    </IonItem>
                    {errors.cardNameError &&
                      <IonNote color="danger" >
                        {(!cardName || cardName.length === 0) ? 'Por favor, ingresá el nombre que indica en la tarjeta' : 
                        'Por favor, ingrese solo letras'
                        }
                      </IonNote>
                    }
                  </IonCol>
                </IonRow>
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <IonRow>
                  <IonCol size="4">
                    <IonItem>
                      <IonLabel position="floating" color={errors.cardMonthError ? 'danger' : 'primary'}>Mes</IonLabel>
                      <IonInput
                        value={cardMonth}
                        onIonChange={handleInputChange}
                        id="cardMonth"
                        name="card_month"
                        placeholder="MM"
                        type="tel"
                        maxlength={paymentCard.maxCharsCardExpirationMonth}
                        onKeyPress={(e) => isNaNOnKeyPress(e) && e.preventDefault()}
                        onPaste={(e) => isNaNOnPaste(e) && e.preventDefault()}
                        onKeyDown={(e) => { e.key === 'Tab' && e.preventDefault() }}
                        onBlur={() => {
                          if (cardMonth && validateMonth(cardMonth) && cardMonth.length < 2) {
                            handleInputChange({ target: { id: 'cardMonth', value: `0${cardMonth}` } })
                          }
                        }}
                      />
                    </IonItem>
                    {errors.cardMonthError &&
                      <IonNote color="danger" >
                        Mes inválido
                      </IonNote>
                    }
                  </IonCol>
                  <IonCol size="4">
                    <IonItem>
                      <IonLabel position="floating" color={errors.cardYearError ? 'danger' : 'primary'}>Año</IonLabel>
                      <IonInput
                        value={cardYear}
                        onIonChange={handleInputChange}
                        id="cardYear"
                        name="card_name"
                        placeholder="AA"
                        type="tel"
                        maxlength={paymentCard.maxCharsCardExpirationYear}
                        onKeyPress={(e) => isNaNOnKeyPress(e) && e.preventDefault()}
                        onPaste={(e) => isNaNOnPaste(e) && e.preventDefault()}
                        onKeyDown={(e) => { e.key === 'Tab' && e.preventDefault() }}
                      />
                    </IonItem>
                    {errors.cardYearError &&
                      <IonNote color="danger" >
                        Año inválido
                      </IonNote>
                    }
                  </IonCol>
                  <IonCol size="4">
                    <IonItem>
                      <IonLabel position="floating" color={errors.cardCodeError ? 'danger' : 'primary'}>CVE</IonLabel>
                      <IonInput
                        value={cardCode}
                        onFocus={() => { paymentCard?.id !== PaymentCardIds.americanExpress && setShowInformation(true) }}
                        onBlur={() => { paymentCard?.id !== PaymentCardIds.americanExpress && setShowInformation(false) }}
                        onIonChange={handleInputChange}
                        id="cardCode"
                        name="card_code"
                        placeholder={paymentCard.maskCodSecurity}
                        type="tel"
                        inputmode="numeric"
                        onKeyPress={(e) => isNaNOnKeyPress(e) && e.preventDefault()}
                        onPaste={(e) => isNaNOnPaste(e) && e.preventDefault()}
                        onKeyDown={(e) => { e.key === 'Tab' && e.preventDefault() }}
                        maxlength={paymentCard.maxCharsCodSecurity}
                      />
                    </IonItem>
                    {errors.cardCodeError &&
                      <IonNote color="danger" >
                        Código inválido
                      </IonNote>
                    }
                  </IonCol>
                </IonRow>
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <IonRow className={styles.dniRow}>
                  <IonCol size="5">
                    <IonItem lines='none'>
                      <IonLabel position="stacked" color={errors.cardIdTypeError ? 'danger' : 'primary'} />
                      <IonSelect
                        mode='ios'
                        placeholder="DNI/CUIT"
                        value={cardIdType}
                        id="cardIdType"
                        onIonChange={(e) => { handleInputChange(e); }}
                        name="card_holder_identification.type"
                        defaultValue='DNI'
                        interface='action-sheet'
                        interfaceOptions={{cssClass: 'selectActionSheet', header: 'Seleccioná el tipo de documento'}}
                        cancelText="X"
                      >
                        <IonSelectOption value="DNI">DNI</IonSelectOption>
                        <IonSelectOption value="CUIT">CUIT</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    {errors.cardIdTypeError &&
                      <IonNote color="danger" >
                        Tipo inválido
                      </IonNote>
                    }
                  </IonCol>
                  <IonCol size="6" offset='1'>
                    <IonItem className={styles.formatedInput} lines='none'>
                      <IonLabel
                        position="stacked"
                        color={errors.cardNumberError ? 'danger' : 'primary'}
                        className="ion-padding-start"
                      >
                        Número
                      </IonLabel>
                      <NumberFormat
                        format={cardIdType === 'DNI' ? '########' : '##-########-#'}
                        mask="X"
                        placeholder="Número"
                        onChange={handleInputChange}
                        name="card_holder_identification.number"
                        id="cardIdNumber"
                        className="ion-text-left ion-padding-start"
                        value={cardIdNumber}
                        disabled={!cardIdType}
                        onKeyDown={(e) => { e.key === 'Tab' && e.preventDefault() }}
                        minLength={cardIdType === 'DNI' ? 7 : 11}
                      />
                    </IonItem>
                    {errors.cardIdTypeError &&
                      <IonNote color="danger" >
                        Número incompleto
                      </IonNote>
                    }
                  </IonCol>
                </IonRow>
              </SwiperSlide>
            </Swiper>
          </IonGrid>
        </IonContent>
      )}
      <TramiteFooter>
        <IonToolbar>
          <IonRow>
            <IonCol size="6">
              <IonButton expand="block" onClick={prev}>
                Anterior
              </IonButton>
            </IonCol>
            <IonCol size="6">
              <IonButton expand="block" onClick={next}>
                siguiente
              </IonButton>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </TramiteFooter>
      <Loading isOpen={isLoading} message='cargando...' />
    </IonPage>
  );
};

export default PaymentFormPage;