import { IonContent, IonFooter, IonImg, IonList, IonPage, IonSelectOption } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import InputForm from '../../../../../shared/components/form/inputForm/InputForm';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import styles from './StepDatosFacturacion1Page.module.scss'
import { documentTextOutline, informationCircle } from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../store';
import { IDComprobantes, ReintegroDataType, TipoSimpleDataType } from '../../../../../store/reintegros/types/ReintegrosData';
import { setNuevoReintegro } from '../../../../../store/reintegros/ReintegrosActions';
import Popover from '../../components/popover/Popover';
import cuits from '../../assets/img/nro-cuit.png'
import SelectForm from '../../../../../shared/components/form/select-form/SelectForm';
import { cuilValidator, formatCuit, isNaNOnKeyPress, isNaNOnPaste, isWeb } from '../../../../../shared/utils/utils';
import Header from '../../../../../shared/components/layout/header/Header';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import { useHistory, useLocation } from 'react-router';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { routes } from '../../router/routes';
import { routes as homeRoutes } from '../../../../home/router/routes';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import analyticsService from '../../../../../services/analytics/analytics';
import { documentos, DocumentosIds } from '../../helpers/tipoDocumentos';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';


const StepDatosFacturacion1Page: React.FC = () => {

    const { nuevoReintegro, reintegros: { listaTipoDocumento } }: { nuevoReintegro?: ReintegroDataType, reintegros?: { listaTipoDocumento: TipoSimpleDataType[] } }
        = useSelector((state: RootState) => state.reintegros.reintegros ? state.reintegros : { nuevoReintegro: undefined, reintegros: { listaTipoDocumento: undefined } });
    const {isKeyboardOn} = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation<{ from: string }>();
    const [showTipoDocPopover, setShowTipoDocPopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });
    const [showNroDocPopover, setShowNroDocPopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });
    const [showImagePopover, setShowImagePopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });

    interface FacturacionFormProperties {
        cuitEmisor: string,
        tipoDocumento: TipoSimpleDataType | '',
        nroDocumento: string
    }


    const { control, handleSubmit, errors, clearErrors, setValue, getValues } = useForm<FacturacionFormProperties>({
        mode: 'onSubmit',
        shouldUnregister: false,
        defaultValues: {
            cuitEmisor: formatCuit(nuevoReintegro?.profesional?.cuit) ?? '',
            tipoDocumento: nuevoReintegro?.tipoDocumento ?? '',
            nroDocumento: nuevoReintegro?.numeroDocumento ?? ''
        }
    });


    useEffect(() => {
        if (!listaTipoDocumento || listaTipoDocumento.length === 0) {
            history.push(homeRoutes.gestionesOnline);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (errors.cuitEmisor)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.cuitEmisor}`, routes.datosFacturacion1);
        if (errors.nroDocumento)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.nroDocumento}`, routes.datosFacturacion1);
        if (errors.tipoDocumento)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.tipoDocumento}`, routes.datosFacturacion1);
    }, [errors])


    const getTipoDocumento = getValues('tipoDocumento') || undefined;


    const getDocumentoLength = (tipoDoc: string) => {
        const documentoKey = DocumentosIds[getTipoDocumento?.id];
        if (tipoDoc === 'max') {
            return documentos[documentoKey]?.maxLength;
        } else {
            return documentos[documentoKey]?.minLength;
        }
    }


    const onSubmit = (dataForm: any) => {
        const nuevoReintegroUpdated: ReintegroDataType = {
            ...nuevoReintegro!,
            tipoDocumento: dataForm.tipoDocumento,
            numeroDocumento: dataForm.nroDocumento,
            profesional: { cuit: dataForm.cuitEmisor.replace(/[^\d]/g, ''), razonSocial: null }
        };
        dispatch(SetStepperNavigation(Animations.next))
        dispatch(setNuevoReintegro(nuevoReintegroUpdated));
        (state?.from === routes.verificaDatos) ? history.goBack() : history.push(routes.datosFacturacion2);
    }

    const footer = () => (
        <IonFooter className='ion-no-border'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <NavigationButton text='Datos obligatorios(*)' type='submit'>SIGUIENTE</NavigationButton>
            </form>
            {isWeb() && <Footer />}
        </IonFooter>
    )

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles.facturacion1}>

                <PageTitle>
                    Reintegros online
                </PageTitle>

                <Stepper stepsLenght={nuevoReintegro?.tipoComprobante?.id === IDComprobantes.Ticket ? 6 : 7} currentNumber={2} />

                <StepTitle>
                    Datos de facturación
                </StepTitle>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonList className='ion-margin'>
                        <Controller
                            control={control}
                            name='cuitEmisor'
                            rules={{
                                required: { value: true, message: "Por favor, completá el número de CUIT" },
                                validate: { minLength: value => value.replace(/[^\d]/g, '').length > 10 || "CUIT incompleto" ,
                                    value:(value) => cuilValidator(value.replace(/[^\d]/g, '')) || 'CUIT-CUIL Invalido' }
                            }}
                            render={({ onChange, value }) => (
                                <InputForm
                                    type='text'
                                    label="Nro. de C.U.I.T. Emisor (*)"
                                    labelIcon={documentTextOutline}
                                    labelIconOnClick={e => setShowImagePopover({ open: true, event: e.nativeEvent })}
                                    placeHolder='00-00000000-0'
                                    inputmode='numeric'
                                    onChange={(e) => { clearErrors(["cuitEmisor"]); onChange(formatCuit(e.detail.value)) }}
                                    onKeyPress={(e) => isNaNOnKeyPress(e) && e.preventDefault()}
                                    onPaste={e => isNaNOnPaste(e) && e.preventDefault()}
                                    maxLength={13}
                                    minLength={13}
                                    value={value}
                                    errors={errors.cuitEmisor?.message}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name='tipoDocumento'
                            rules={{ required: { value: true, message: "Por favor, completá el tipo de documento" } }}
                            render={({ onChange, value }) => (
                                <SelectForm
                                    label='Tipo de documento (*)'
                                    labelIcon={informationCircle}
                                    labelIconOnClick={e => setShowTipoDocPopover({ open: true, event: e.nativeEvent })}
                                    placeHolder='Que coincida con el de la factura'
                                    onChange={(e) => { clearErrors(['tipoDocumento']); onChange(e.detail.value); setValue('nroDocumento', '') }}
                                    value={value}
                                    header='Tipo de documento del receptor'
                                    subHeader='Elegí el tipo de documento del receptor'
                                    errors={errors.tipoDocumento?.message}
                                    compareWith={(o1: TipoSimpleDataType, o2: TipoSimpleDataType) => (o1 && o2 ? o1.id === o2.id : o1 === o2)}
                                >
                                    {listaTipoDocumento?.map((tipoDoc: TipoSimpleDataType) => (
                                        <IonSelectOption key={tipoDoc.id} value={tipoDoc}>{tipoDoc.descripcion}</IonSelectOption>
                                    ))}
                                </SelectForm>
                            )}
                        />

                        <Controller
                            control={control}
                            name='nroDocumento'
                            rules={{
                                required: { value: true, message: "Por favor, completá el número de documento" },
                                minLength: { value: 7, message: 'Número de documento incompleto.' }
                            }}
                            render={({ onChange, value }) => (
                                <InputForm
                                    type='text'
                                    label="Número de documento (*)"
                                    labelIcon={informationCircle}
                                    labelIconOnClick={e => setShowImagePopover({ open: true, event: e.nativeEvent })}
                                    placeHolder='Que coincida con el de la factura'
                                    inputmode='numeric'
                                    onChange={(e) => { clearErrors(["nroDocumento"]); onChange(e.detail.value) }}
                                    onKeyPress={(e) => {
                                        getTipoDocumento?.id !== documentos["pasaporte"].id ?
                                        isNaNOnKeyPress(e) && e.preventDefault() : !(/[A-Za-z0-9]/.test(e.key)) && e.preventDefault()
                                    }}
                                    onPaste={(e) => getTipoDocumento?.id !== documentos["pasaporte"].id ?
                                        isNaNOnPaste(e) && e.preventDefault() : !(/[A-Za-z0-9]/.test(e.clipboardData.getData('Text'))) && e.preventDefault()}
                                    maxLength={getTipoDocumento ? getDocumentoLength('max') : 12}
                                    minLength={getTipoDocumento ? getDocumentoLength('min') : 7}
                                    value={value}
                                    errors={errors.nroDocumento?.message}
                                />
                            )}
                        />
                    </IonList>
                </form>
                {isKeyboardOn && footer()}
            </IonContent>

            {!isKeyboardOn && footer()}

            <Popover
                event={showImagePopover.event}
                isOpen={showImagePopover.open}
                onDidDismiss={() => setShowImagePopover({ open: false, event: undefined })}>
                <IonImg src={cuits} />
            </Popover>

            <Popover
                event={showTipoDocPopover.event}
                isOpen={showTipoDocPopover.open}
                onDidDismiss={() => setShowTipoDocPopover({ open: false, event: undefined })}>
                <p>Tipo de documento de la persona o razón social a la que se emitió la factura.</p>
            </Popover>

            <Popover
                event={showNroDocPopover.event}
                isOpen={showNroDocPopover.open}
                onDidDismiss={() => setShowNroDocPopover({ open: false, event: undefined })}>
                <p>Nº de documento de la persona o razón social a la que se emitió la factura.</p>
            </Popover>

        </IonPage>
    );
}


export default StepDatosFacturacion1Page;