import * as React from 'react';
import { Children, cloneElement, Fragment, isValidElement, useState } from 'react';
import { useTramitesState } from '../../../../../../hooks/useTramitesState';
import AlertPopup from '../../../../popups/alerts/alert/AlertPopup';

const NavigationCleanUpWrapper: React.FC<{
    children: React.ReactNode | HTMLElement;
    onClickFunction: Function;
}> = (props) => {
    const [showPopUp, setShowPopUp] = useState(false);
    const {onClickFunction} = props;
    const {clearAllTramites, hasAnyTramiteGoing} = useTramitesState();


    const checkIncompleteTramite = (e): boolean => {
        e.preventDefault();
        return hasAnyTramiteGoing;
    };


    const showPopUpOrRedirect = (e) => checkIncompleteTramite(e) ? setShowPopUp(true) : onClickFunction();

    
    const cleanUpTramitesAndRedirect = () => {
        clearAllTramites();
        onClickFunction();
    }


    const childrenWithTheFunction = Children.map(props.children, child => {
            if (isValidElement(child)) {
                return cloneElement(child, { onClick: showPopUpOrRedirect});
            }
        }
    );


    return (
        <Fragment>
            {childrenWithTheFunction}
            <AlertPopup 
                isOpen={showPopUp}
                onDidDismiss={() => setShowPopUp(false)}
                title={'Importante'}
                message={
                    'Detectamos que aún no finalizaste la solicitud. Si abandonas la página, se perderá la información que cargaste. <br><br> ¿Querés abandonar la página?'
                }
                backdropDismiss={true}
                buttons={[
                    { text: 'Aceptar', handler: cleanUpTramitesAndRedirect },
                    { text: 'Cancelar', handler: () => setShowPopUp(false) }
                ]}
            />
        </Fragment>
    );
};

export default NavigationCleanUpWrapper;