import { ReintegrosActions, SET_REINTEGROS, SET_NUEVOREINTEGRO, UNSET_REINTEGROS, TOGGLE_LOADING, FINALIZAR_TRAMITE_SUCCESS, FINALIZAR_TRAMITE_FAILURE } from "./types/actions";
import { ReintegroDataType, ReintegrosDataType, TramiteReintegroDataType } from "./types/ReintegrosData";

export interface ReintegrosState {
	reintegros?: ReintegrosDataType | null;
	nuevoReintegro?: ReintegroDataType | null;
	obtainedData: TramiteReintegroDataType | null;
	isLoading: boolean;
	error: any
}

const initialState = {
	reintegros: null,
	nuevoReintegro: null,
	obtainedData: null,
	isLoading: false,
	error: null
};

export const ReintegrosReducer = (
	state: ReintegrosState = initialState,
	action: ReintegrosActions
): ReintegrosState => {
	switch (action.type) {
		case TOGGLE_LOADING:
			return { ...state, isLoading: !state.isLoading };
		case SET_REINTEGROS:
			return { ...state, reintegros: action.payload };
		case SET_NUEVOREINTEGRO:
			return { ...state, nuevoReintegro: action.payload };
		case FINALIZAR_TRAMITE_SUCCESS:
			return { ...state, obtainedData: action.payload };
		case FINALIZAR_TRAMITE_FAILURE:
			return { ...state, error: action.payload };
		case UNSET_REINTEGROS:
			return initialState;
		default:
			return state;
	}
};
