import { routes as AutorizacionesRoutes } from '../router/routes';

const AutorizacionesTramites = (index: number) => {
  const caminos = [
    {
      titleAndLenght: {
        title: 'Autorizaciones de internación o cirugía',
        lenghtOfPages: 6,
        namePageAnalytics: 'internacion-cirugia',
      },
      editOrder: [
        {
          label: 'Integrante del grupo familiar',
          page: 1,
          content: 'nombre',
          path: AutorizacionesRoutes.seleccionarSocio,
        },
        {
          label: 'Fecha de realización',
          page: 2,
          content: 'fechaRealizacion',
          path: AutorizacionesRoutes.stepInternacion,
        },
        {
          label: 'Institución / Sanatorio',
          page: 2,
          content: 'prestador',
          path: AutorizacionesRoutes.stepInternacion,
        },
        {
          label: 'Profesional que lo realiza',
          page: 2,
          content: 'profesional',
          path: AutorizacionesRoutes.stepInternacion,
        },
        {
          label: 'Observaciones',
          page: 2,
          content: 'detalle',
          path: AutorizacionesRoutes.stepInternacion,
        },
        {
          label: 'Pedido Médico',
          page: 3,
          content: 'pedidoMedico',
          path: AutorizacionesRoutes.adjuntarArchivo,
        },
        {
          label: 'Documentación Adicional',
          page: 3,
          content: 'documentosExtra',
          path: AutorizacionesRoutes.adjuntarArchivo,
        },
        {
          label: 'Email',
          page: 4,
          content: 'emailContacto',
          path: AutorizacionesRoutes.datosContacto,
        },
        {
          label: 'Teléfono',
          page: 4,
          content: 'telefonoContacto',
          path: AutorizacionesRoutes.datosContacto,
        },
      ],
    },
    {
      titleAndLenght: {
        title: 'Autorizaciones de medicamentos',
        lenghtOfPages: 5,
        namePageAnalytics: 'medicamentos',
      },
      editOrder: [
        {
          label: 'Integrante del grupo familiar',
          page: 1,
          content: 'nombre',
          path: AutorizacionesRoutes.seleccionarSocio,
        },
        {
          label: 'Pedido Médico',
          page: 2,
          content: 'pedidoMedico',
          path: AutorizacionesRoutes.adjuntarArchivo,
        },
        {
          label: 'Documentación Adicional',
          page: 2,
          content: 'documentosExtra',
          path: AutorizacionesRoutes.adjuntarArchivo,
        },
        {
          label: 'Observaciones',
          page: 2,
          content: 'detalle',
          path: AutorizacionesRoutes.adjuntarArchivo,
        },
        {
          label: 'Email',
          page: 3,
          content: 'emailContacto',
          path: AutorizacionesRoutes.datosContacto,
        },
        {
          label: 'Teléfono',
          page: 3,
          content: 'telefonoContacto',
          path: AutorizacionesRoutes.datosContacto,
        },
      ],
    },
    {
      titleAndLenght: {
        title: 'Autorizaciones de practicas o estudios',
        lenghtOfPages: 6,
        namePageAnalytics: 'practicas-estudios',
      },
      editOrder: [
        {
          label: 'Integrante del grupo familiar',
          page: 1,
          content: 'nombre',
          path: AutorizacionesRoutes.seleccionarSocio,
        },
        {
          label: 'Fecha de realización',
          page: 2,
          content: 'fechaRealizacion',
          path: AutorizacionesRoutes.stepPracticas,
        },
        {
          label: 'Institucion / Profesional',
          page: 2,
          content: 'prestador',
          path: AutorizacionesRoutes.stepPracticas,
        },
        {
          label: 'Observaciones',
          page: 2,
          content: 'detalle',
          path: AutorizacionesRoutes.stepPracticas,
        },
        {
          label: 'Pedido Médico',
          page: 3,
          content: 'pedidoMedico',
          path: AutorizacionesRoutes.adjuntarArchivo,
        },
        {
          label: 'Documentación Adicional',
          page: 3,
          content: 'documentosExtra',
          path: AutorizacionesRoutes.adjuntarArchivo,
        },
        {
          label: 'Email',
          page: 4,
          content: 'emailContacto',
          path: AutorizacionesRoutes.datosContacto,
        },
        {
          label: 'Teléfono',
          page: 4,
          content: 'telefonoContacto',
          path: AutorizacionesRoutes.datosContacto,
        },
      ],
    },
    {
      titleAndLenght: {
        title: 'Autorizaciones de traslados programados',
        lenghtOfPages: 7,
        namePageAnalytics: 'traslados-programados',
      },
      editOrder: [
        {
          label: 'Integrante del grupo familiar',
          page: 1,
          content: 'nombre',
          path: AutorizacionesRoutes.seleccionarSocio,
        },
        {
          label: 'Fecha de traslado',
          page: 2,
          content: 'fechaTraslado',
          path: AutorizacionesRoutes.stepTraslado,
        },
        {
          label: 'Horario destino',
          page: 2,
          content: 'horaTraslado',
          path: AutorizacionesRoutes.stepTraslado,
        },
        {
          label: 'Tipo de ambulancia',
          page: 2,
          content: 'conAmbulancia',
          path: AutorizacionesRoutes.stepTraslado,
        },
        {
          label: 'Con espera y regreso',
          page: 2,
          content: 'conEsperaRegreso',
          path: AutorizacionesRoutes.stepTraslado,
        },
        {
          label: 'Observaciones',
          page: 2,
          content: 'detalle',
          path: AutorizacionesRoutes.stepTraslado,
        },
        {
          label: 'Domicilio Origen',
          page: 3,
          content: 'origen',
          path: AutorizacionesRoutes.domicilioOrigenDestino,
        },
        {
          label: 'Domicilio destino',
          page: 3,
          content: 'destino',
          path: AutorizacionesRoutes.domicilioOrigenDestino,
        },
        {
          label: 'Pedido Médico',
          page: 4,
          content: 'pedidoMedico',
          path: AutorizacionesRoutes.adjuntarArchivo,
        },
        {
          label: 'Documentación Adicional',
          page: 4,
          content: 'documentosExtra',
          path: AutorizacionesRoutes.adjuntarArchivo,
        },
        {
          label: 'Email',
          page: 5,
          content: 'emailContacto',
          path: AutorizacionesRoutes.datosContacto,
        },
        {
          label: 'Teléfono',
          page: 5,
          content: 'telefonoContacto',
          path: AutorizacionesRoutes.datosContacto,
        },
      ],
    },
  ];

  return caminos[index];
};

export default AutorizacionesTramites;
