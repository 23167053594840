import React from 'react';
import styles from './Header.module.scss';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonIcon,
  IonBackButton,
  IonButton
} from '@ionic/react';

import { arrowBack } from 'ionicons/icons';
import EnvironmentSelector from './environment-selector/EnvironmentSelector';
import { routes } from '../../../../modules/home/router/routes';
import { useHistory } from 'react-router';

interface HeaderProps {
  showBackButton?: boolean;
  overrideBackToURL?: string;
}

const Header: React.FunctionComponent<HeaderProps> = ({ showBackButton = true, overrideBackToURL }) => {
  const history = useHistory();
  const overrideBackAction = () => history.push(overrideBackToURL);

  return (
    <IonHeader className={[styles['app-header'], 'ion-no-border'].join(' ')}>
      <IonToolbar mode='ios' className='ion-no-border'>
        <IonButtons id='menu-button'>
          <IonMenuButton />
        </IonButtons>
        <IonTitle>
          <EnvironmentSelector>
            <img className={styles.logo} alt="Logo" />
          </EnvironmentSelector>
        </IonTitle>
        <IonButtons slot='end' id='back-button'>
          {showBackButton && !overrideBackToURL &&
            <IonBackButton mode='md' color='primary' type='reset' className={styles['back-button']} defaultHref={routes.root} />
          }
          {overrideBackToURL &&
            <IonButton fill='clear' className={styles['back-button']} onClick={overrideBackAction}><IonIcon icon={arrowBack} /></IonButton>
          } 
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;