import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import authService from '../../../../../services/auth/AuthService';
import AlertPopup from '../alert/AlertPopup';
import { setUser} from '../../../../../store/users/actions/user';

export const LocalhostLoginAlert: React.FC<{
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  const saveToken = async (data: any) => {
    const userFullData = await authService.getUserFullData(data.token);
    if (userFullData) {
      dispatch(setUser(userFullData));
    }
  };

  return (
    <AlertPopup
      isOpen={isOpen}
      title={'Token'}
      message={'Agrega el refresh token de la otra pestaña'}
      buttons={[
        {
          text: 'Guardar',
          handler: (alertData) => {
            saveToken(alertData);
            setIsOpen(false);
          },
        },
      ]}
      inputs={[
        {
          name: 'token',
          type: 'text',
          placeholder: 'Token',
        },
      ]}
    />
  );
};
