import { CallNumber } from '@ionic-native/call-number';
import { IonIcon} from '@ionic/react';
import { openOutline } from 'ionicons/icons';
import * as React from 'react';
import { isWeb } from '../../utils/utils';
import styles from './PhoneNumber.module.scss';

interface Props {
    isWhatsapp?:boolean,
    phoneLink:string
}

const PhoneNumber: React.FC<Props> = ({ isWhatsapp, phoneLink, children }) => {

    const call = async () => {
        if (isWeb()) {
            window.location.href = `tel:${phoneLink}`
        } else {
            await CallNumber.callNumber(phoneLink, true)
        }
    }

    let whatsapp = (
            <a href={`https://wa.me/${phoneLink}`} className={styles.link}>{children || phoneLink} <IonIcon icon={openOutline} />
            </a>
    );

    let phone = (
            <span onClick={() => call()} className={styles.link}>{children || phoneLink} <IonIcon icon={openOutline} />
            </span>
    );
    
    return (
        <>
            {isWhatsapp ? whatsapp : phone}
        </>
    );
  };
  
  export default PhoneNumber;