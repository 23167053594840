import React from "react";

export enum LegalType {
  ACUERDO_USUARIO = "ACUERDO_USUARIO",
  POLITICA_PRIVACIDAD = "POLITICA_PRIVACIDAD",
  DATOS_PERSONALES = "DATOS_PERSONALES"
}

const Legal: React.FC<{ type: LegalType }> = ({type}) => {
  const legalTextStyle = {
    textAlign: "justify" as const,
    fontSize: "12px",
    lineHeight: "14px"
  };

  return (
    <>
      {type === LegalType.ACUERDO_USUARIO &&
        <div style={legalTextStyle}>
          Acuerdos con el Usuario <br />
          <br />
          1. Declaración General
          <br />
          <br />
          ORGANIZACIÓN DE SERVICIOS DIRECTOS EMPRESARIOS (en adelante
          "OSDE") se encuentra comprometido con el resguardo de la
          intimidad de los usuarios en su Sitio Web, www.osde.com.ar,
          (en adelante el "SITIO"), en virtud de ello es que realizará
          los esfuerzos que crea necesarios para que los usuarios de su
          Sitio se encuentren protegidos.
          <br />
          <br />
          La presente política de privacidad se aplica exclusivamente a
          la información ofrecida a OSDE mediante su SITIO, y no a la
          ofrecida a otras compañías u organizaciones o Sitios Web con
          los que el SITIO contenga enlaces. OSDE no se responsabiliza
          por el actuar de los Sitios Web a los cuales se puede acceder
          por medio del SITIO, por lo que recomendamos la lectura de la
          Política de Privacidad de cada uno ellos.
          <br />
          <br />
          Toda vez que OSDE mediante el SITIO recopila cierta
          información, creemos que usted debe entender plenamente
          nuestra política con respecto al uso de la información que
          recogemos. Esta declaración sobre política de privacidad
          revela qué información recogemos, cómo la usamos y cómo puede
          corregirse o cambiarse.
          <br />
          <br />
          2. Información general
          <br />
          <br />
          Generalmente nuestro sistema obtiene automáticamente
          información acerca de su conducta cuando navega por el SITIO,
          a través de un "cookie". Una cookie es un pequeño archivo de
          datos que algunos Sitios Web almacenan en el disco duro de su
          computadora cuando usted los visita. Un archivo cookie puede
          contener información tal como la identificación del usuario
          que el sitio utiliza para rastrear las páginas que usted ha
          visitado. Pero la única información personal que una cookie
          puede contener es información que usted mismo suministra. Una
          cookie no puede leer datos de su disco duro ni leer los
          archivos cookie creados por otros sitios. OSDE utiliza cookies
          para rastrear los patrones de tráfico del usuario cuando
          navega por ciertas páginas del SITIO. Si usted ha ajustado su
          navegador para que le avise antes de aceptar cookies, recibirá
          el mensaje de advertencia con cada cookie. Usted puede
          rehusarse a aceptar cookies, desactivándolos en su navegador.
          Usted no necesita tener las cookies activados para usar el
          SITIO de OSDE. Si usted se rehúsa a aceptar cookies, existe la
          posibilidad de que usted no tenga acceso a ciertos servicios
          brindados por el SITIO.
          <br />
          <br />
          Si bien OSDE cuenta con un sistema de protección tecnológico
          que va desde sus servidores hasta la salida a internet,
          ninguna transmisión por internet puede garantizar su seguridad
          en un 100%. Por lo tanto, OSDE no puede garantizar que la
          información ingresada en su sitio web o transmitida utilizando
          un servicio sea completamente segura, con lo cual el usuario
          comprende que utiliza el sitio a su propio riesgo.
          <br />
          <br />
          Esta política de privacidad se aplica solo al sitio web de
          OSDE, no aplicándose a otros sitios web con los que OSDE tenga
          conexiones, vínculos o links.
          <br />
          <br />
          Si el usuario utiliza el sitio web de OSDE y lo servicios
          ofrecidos a través del mismo, significa que ha leído,
          comprendido y acordado con los términos antes expuestos. Si no
          está de acuerdo con ellos, tiene la opción de no utilizar el
          sitio.
          <br />
          <br />
          3. Información específica sobre usted
          <br />
          <br />
          En ciertos casos es posible que OSDE requiera acceder a
          algunos datos personales suyos, como por ejemplo su nombre
          completo, su domicilio, su dirección de e-mail o su número
          telefónico. OSDE necesita dicha información para poder brindar
          los servicios que ofrece a través del SITIO.
          <br />
          <br />
          OSDE garantiza la confidencialidad de dicha información.
          <br />
          <br />
          4. Fines de la recopilación
          <br />
          <br />
          Los fines de OSDE al recoger información personal pueden
          incluir servicio al cliente, manejo de la red, encuestas entre
          clientes, subscripciones, ofertas de servicios relacionados y
          otros intercambios de información en su SITIO. El propósito es
          conocer mejor al cliente para poder brindarle un servicio más
          personalizado.
          <br />
          <br />
          5. ¿Qué puede hacer OSDE con esa información?
          <br />
          <br />
          OSDE no revelará ni compartirá esta información que puede
          identificar a las personas con ninguna otra empresa u
          organización sin el consentimiento informado del consumidor
          que proporciona la información (salvo que fuera requerida por
          vía administrativa, judicial o que alguna norma indicare lo
          contrario). OSDE puede utilizarla para enviar información al
          usuario acerca de sus productos y servicios. Los usuarios
          pueden optar por no recibir envíos en el futuro; véase el
          punto 6 abajo para elegir si desean recibir o no dicha
          información. OSDE puede usar la información personal para
          determinar los datos demográficos de nuestros usuarios y
          distribuir información estadística y general del mercado sobre
          OSDE. Estos datos demográficos siempre representan información
          disociada y nunca identifican a un usuario específico. La
          información personal que individualmente usted le suministró a
          OSDE no será revelada a nadie fuera de OSDE. OSDE utiliza su
          dirección IP para ayudar a diagnosticar problemas con nuestro
          servidor y administrar nuestro SITIO.
          <br />
          <br />
          De esta manera, no compartimos información sobre usuarios
          individuales con ningún tercero u otras empresas. Pero según
          mencionamos anteriormente, podemos compartir información
          estadística agregada sobre el uso del SITIO con nuestros
          socios del SITIO para adaptar mejor nuestro contenido a las
          necesidades de los lectores y para ayudar a nuestros
          anunciantes a entender los datos demográficos de nuestro
          público.
          <br />
          <br />
          Sin perjuicio de lo señalado, de acuerdo a los términos de la
          ley 25.326 le hacemos saber que los datos aportados forman
          parte de un banco de datos de OSDE, con domicilio en L.N.Alem
          1067 piso 9 CABA y serán tratados para brindar servicios a los
          afiliados. El titular de los datos personales no se encuentra
          obligado a proporcionar datos sensibles. Asimismo, el titular
          se responsabiliza por la exactitud de los datos
          proporcionados. El titular de los datos personales tiene la
          facultad de ejercer el derecho de acceso a los mismos en forma
          gratuita a intervalos no inferiores a seis meses, salvo que se
          acredite un interés legítimo al efecto conforme lo establecido
          en el artículo 14, inciso 3 de la Ley N° 25.326. La DIRECCIÓN
          NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, Órgano de control
          de la Ley N° 25.326 , tiene la atribución de atender las
          denuncias y reclamos que se interpongan con relación al
          incumplimiento de las normas sobre protección de datos
          personales.
          <br />
          <br />
          6. Excepciones
          <br />
          <br />
          No obstante, cualquier otra previsión en contrario en esta
          declaración, OSDE podrá divulgar cierta información privada
          cuando creamos de buena fe, que esa divulgación es
          razonablemente necesaria para:
          <br />
          <br />
          a) cumplir una exigencia legal, tal como una orden de
          allanamiento, una citación judicial, una orden judicial;
          <br />
          <br />
          b) cumplir un requerimiento de una autoridad gubernamental o
          reguladora;
          <br />
          <br />
          c) proteger los derechos, propiedad o seguridad de OSDE, de
          los usuarios, o de un tercero.
          <br />
          <br />
          7. Privacidad de los niños
          <br />
          <br />
          Debido a que los menores de edad pueden no alcanzar a
          comprender debidamente la Política de Privacidad y sus
          implicancias, ni decidir válidamente sobre las opciones
          disponibles para nuestros usuarios, instamos a todos los
          padres o representantes o adultos bajo cuya supervisión los
          menores accedan al SITIO a participar activa y cuidadosamente
          en las actividades que el menor realice en Internet, a los
          servicios on-line que utilicen dichos menores, a la
          información a la que estos accedan, ya sea cuando dichos
          menores visiten el SITIO cualquier otro sitio de terceros, y a
          enseñarles cómo proteger su propia Información Personal
          mientras estén on-line.
          <br />
          <br />
          8. Cambios en la política de OSDE referente a privacidad
          <br />
          <br />
          Como demostración del compromiso que asume OSDE con la
          protección de sus datos, en el supuesto que decidamos cambiar
          la política sobre privacidad de nuestro SITIO, anunciaremos
          aquí dichos cambios, de manera que usted siempre pueda saber
          qué información recogemos, cómo podríamos usar dicha
          información y si la revelaremos a alguien. Es por ello que le
          aconsejamos revisar esta política de privacidad en forma
          periódica.
          <br />
          <br />
          Defensa al consumidor
          <br />
          <br />
          Obligaciones de proveedores de bienes o servicios hacia los
          Consumidores:
          <br />
          <br />
          Artículo 10 ter. de las Leyes 24.240 (texto conforme a la Ley
          26.361) y 2.697 (B.O.: 1.432/08 -C.B.A.-): Modos de Rescisión.
          Cuando la contratación de un servicio, incluídos los servicios
          públicos domiciliarios, haya sido realizada en forma
          telefónica, electrónica o similar, podrá ser rescindida a
          elección del consumidor o usuario mediante el mismo medio
          utilizado en la contratación. La empresa receptora del pedido
          de rescisión del servicio deberá enviar sin cargo al domicilio
          del consumidor o usuario una constancia fehaciente dentro de
          las SETENTA Y DOS (72) horas posteriores a la recepción del
          pedido de rescisión.
          <br />
          <br />
          Esta disposición debe ser publicada en la factura o documento
          equivalente que la empresa enviare regularmente al domicilio
          del consumidor o usuario.
          <br />
          <br />
          Ley 2792 C.A.B.A: Existen prestaciones obligatorias de
          cobertura médico asistencial, infórmese de los términos y
          alcances del Programa Médico Obligatorio en la siguiente
          dirección de Internet www.buenosaires.gob.ar.
          <br />
          <br />
          Dirección Nacional de Datos Personales
          <br />
          <br />
          Los datos aportados forman parte de un banco de datos de OSDE,
          con domicilio en L.N.Alem 1067 piso 9 CABA y serán tratados
          para brindar servicios a los asociados. El titular de los
          datos personales no se encuentra obligado a proporcionar datos
          sensibles. Asimismo, el titular se responsabiliza por la
          exactitud de los datos proporcionados. El titular de los datos
          personales tiene la facultad de ejercer el derecho de acceso a
          los mismos en forma gratuita, a intervalos no inferiores a
          seis meses, salvo que se acredite un interés legítimo al
          efecto, conforme lo establecido en el artículo 14, inciso 3 de
          la Ley N° 25.326
          <br />
          <br />
          LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter
          de Órgano de Control de la Ley N° 25.326, tiene la atribución
          de atender las denuncias y reclamos que interpongan quienes
          resulten afectados en sus derechos por incumplimiento de las
          normas vigentes en materia de protección de datos personales.
          <br />
          <br />
          Información para Socios
          <br />
          <br />
          Señor Asociado
          <br />
          <br />
          De conformidad con lo requerido por el art. 11 de la
          Resolución SSSalud N° 362/2012, que ordena comunicar a la
          población beneficiaria/usuaria los extremos indicados en los
          artículos 10 y 11 de la Disposición N° 1831/12 ANMAT,
          transcribimos para su conocimiento los textos de ambos
          artículos.
          <br />
          <br />
          Art. 10. — Los involucrados en la cadena de distribución,
          comercialización y dispensación, usuarios y pacientes que
          adquieran los medicamentos alcanzados por la presente
          disposición deberán tener la posibilidad de solicitar que se
          les informe si las unidades adquiridas son originales y fueron
          distribuidas a través de la cadena de distribución legalmente
          autorizada, sea a través de sistemas de consulta utilizando
          medios informáticos o por cualquier otro sistema de
          comunicación.
          <br />
          <br />
          Art. 11. — Todas aquellas especialidades medicinales cuya
          condición de venta sea bajo receta deberán contar con un
          sistema de seguridad del envase, el cual deberá ser inviolable
          e impedir la apertura de los mismos hasta el momento de su
          uso, de manera de asegurar que el envase contenga
          efectivamente el producto elaborado por el titular. La
          alteración del sistema de seguridad del envase hará que el
          producto sea considerado como adulterado y dará lugar a la
          adopción de todas aquellas medidas preventivas y/o de índole
          administrativa a que hubiere lugar, de conformidad con la
          normativa aplicable, sin perjuicio de las demás acciones que
          pudieran corresponder.
          <br />
          <br />
        </div>
      }
      {type === LegalType.DATOS_PERSONALES && (
        <div style={legalTextStyle}>
          Responsable registrado <br />
          <br />
          Los datos aportados forman parte de un banco de datos de OSDE,
          con domicilio en L.N.Alem 1067 piso 9 CABA y serán tratados
          para brindar servicios a los asociados.
          <br />
          El titular de los datos personales no se encuentra obligado a
          proporcionar datos sensibles. Asi mismo, el titular se
          responsabiliza por la exactitud de los datos proporcionados.
          <br />
          El titular de los datos personales tiene la facultad de
          ejercer el derecho de acceso a los mismos en forma gratuita a
          intervalos no inferiores a seis meses, salvo que se acredite
          un interés legítimo al efecto conforme lo establecido en el
          artículo 14, inciso 3 de la Ley Nº 25.326.
          <br />
          "LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter
          de Órgano de Control de la Ley N° 25.326, tiene la atribución
          de atender las denuncias y reclamos que interpongan quienes
          resulten afectados en sus derechos por incumplimiento de las
          normas vigentes en materia de protección de datos personales".
        </div>
      )}
      {type === LegalType.POLITICA_PRIVACIDAD &&
        <div style={legalTextStyle}>
          Política de Privacidad<br />
          <br />
          1-OBJETIVO
          <br />
          <br />
          En OSDE consideramos que la protección de los datos y la privacidad
          de las personas es uno de los pilares que forman parte del desarrollo
          de sus actividades y de la entrega de sus servicios. Es nuestra prioridad
          cuidar la información de socios, proveedores, prestadores, colaboradores y
          otras terceras partes con las cuales nos relacionamos y, para ello,
          trabajamos día a día buscando la mejora continua.
          <br />
          <br />
          Dado que desarrollamos nuestra actividad en la República Argentina,
          nos atenemos al marco normativo de este país: Ley 25.326, sus
          modificatorias y disposiciones complementarias. Además, como
          prestamos servicios a terceros alcanzados por otras reglamentaciones,
          establecemos acuerdos que permiten definir nuestro compromiso con el
          cumplimiento de sus requerimientos en materia de protección de datos personales.
          <br />
          <br />
          En esta Política de Privacidad describimos la información que gestionamos,
          las finalidades de su tratamiento, los casos en los que la compartimos con
          terceros, los derechos que poseen los titulares de datos personales y cómo
          OSDE los protege en todo su ciclo de vida.
          <br />
          <br />
          <br />
          <br />
          2-ALCANCE
          <br />
          <br />
          Esta Política de Privacidad se aplica a todos los sitios web, aplicaciones
          móviles disponibilizadas y a los servicios en línea que OSDE brinda.
          <br />
          <br />
          A continuación, listamos algunos de los sitios y aplicaciones incluidas en el alcance:
          <br />
          <br />
          Páginas web: de OSDE, de Extranet de prestadores y de Autogestión de Empresas.
          <br />
          <br />
          Aplicaciones móviles: OSDE Móvil, Credencial Digital OSDE, Consulta Médica
          Online,  Monitoreo Domiciliario, etc.
          <br />
          <br />
          <br />
          <br />
          3-RECOLECCIÓN DE DATOS PERSONALES
          <br />
          <br />
          En OSDE recolectamos datos personales de socios con el objeto de prestar los servicios
          con los que nos hemos comprometido. A modo ejemplificativo acopiamos información:
          <br />
          <br />
          identificatoria (nombre completo, DNI, datos biométricos, credenciales de acceso, etc.)
          <br />
          <br />
          de contacto (dirección postal, teléfonos, correo electrónico, etc.),
          <br />
          <br />
          de salud (consumos, enfermedades crónicas, condiciones particulares de salud, etc.),
          <br />
          <br />
          de pago (cuenta bancaria, tarjetas de crédito, cuenta corriente, CUIT, etc.).
          <br />
          <br />
          Asimismo, recolectamos datos personales de nuestros proveedores y prestadores con el objeto
          de cumplir con los contratos suscriptos. A modo ejemplificativo acopiamos información: <br /><br />
          de contacto (dirección postal, teléfonos, correo electrónico, DNI, CUIT),
          <br />
          <br />
          bancaria e impositiva (cuenta bancaria, cuenta corriente, condiciones impositivas)
          <br />
          <br />
          de contratación (condiciones del servicio, valores)
          <br />
          <br />
          Además, podemos recolectar datos de contacto brindados por terceros para realizar un
          acercamiento en respuesta a intereses que nos hayan manifestado. <br /><br />
          También podemos recopilar información brindada a través de los canales de comunicación.
          <br />
          <br />
          Estos datos son obtenidos de los titulares en cada caso a través de nuestros sitios web,
          aplicaciones móviles o redes sociales. Asimismo durante la navegación de usuarios en la
          web -mediante la utilización de cookies-,los datos pueden ser:
          <br />
          <br />
          Información de los dispositivos o computadoras desde los que el usuario accede al sitio,
          tipo o versión del navegador o del sistema operativo y otras configuraciones.
          <br />
          <br />
          Dirección IP de internet utilizada para conectarse.
          <br />
          <br />
          Comportamiento del usuario. Por ejemplo: accesos a información publicada, transacciones realizadas.
          <br />
          <br />
          <br />
          <br />
          4-USO DE LOS DATOS PERSONALES
          <br />
          <br />
          El tratamiento que en OSDE realizamos de los datos personales tiene por objetivo en
          todos los casos brindar servicio a nuestros socios y mejorarlo en el tiempo.Para ello,
          también gestionamos información de prestadores y proveedores -quienes forman parte
          de la entrega de dicho servicio- con las siguientes finalidades: <br /><br />
          brindar servicio a los socios,
          <br />
          <br />
          mejorar el servicio al socio,
          <br />
          <br />
          prevenir, detectar y tratar usos inapropiados del servicio o abusos del mismo,
          <br />
          <br />
          cumplir con obligaciones legales y contractuales,
          <br />
          <br />
          prevenir, detectar y tratar incidentes que pongan en riesgo la seguridad los datos y privacidad de los socios o terceros
          <br />
          <br />
          otorgar beneficios adicionales a los socios,
          <br />
          <br />
          <br />
          <br />
          5-TRANSFERENCIA INTERNACIONAL DE LOS DATOS PERSONALES
          <br />
          <br />
          Algunas de las soluciones tecnológicas que implementamos para brindar nuestro
          servicio se encuentran alojadas en proveedores de nube. En estos casos, es
          posible que los datos residan físicamente en otros países. Cuando esto sucede
          nos ocupamos de garantizar su seguridad mediante acuerdos contractuales y
          normas corporativas vinculantes que requieren el cumplimiento de la presente política.
          <br />
          <br />
          <br />
          <br />
          6-CESIÓN DE DATOS PERSONALES
          <br />
          En OSDE no comercializamos datos personales de ningún tipo bajo ninguna circunstancia.
          A continuación se detallan los terceros con quienes podemos compartir, transferir
          o enviar datos a terceros y sus motivos:
          <br />
          <br />
          Prestadores y proveedores: para brindar servicio a nuestros socios.
          <br />
          <br />
          Entidades gubernamentales y Organismos de control: para el cumplimiento de las
          reglamentaciones establecidas.
          <br />
          <br />
          Grupo OSDE: para la mejora del servicio brindado a nuestros socios.
          <br />
          <br />
          <br />
          <br />
          7-CONSERVACIÓN DE DATOS PERSONALES
          <br />
          <br />
          Conservamos los datos personales durante el período de prestación del servicio y por el
          tiempo que lo requieran las normas y reglamentaciones locales.
          <br />
          <br />
          <br />
          <br />
          8-DERECHOS DEL TITULAR DE LOS DATOS PERSONALES
          <br />
          <br />
          En cumplimiento de lo establecido por la Ley 25.326 disponibilizamos a los titulares
          de datos personales la posibilidad de ejercer sus derechos mediante procedimientos
          preestablecidos. De esta forma, el titular puede solicitar:
          <br />
          <br />
          Información  al organismo de control relativa a la existencia de archivos, registros,
          bases o bancos de datos personales, sus finalidades y la identidad de sus responsables.
          <br />
          <br />
          El acceso a la información: lo cual permite al titular conocer qué información
          posee OSDE de él.
          <br />
          <br />
          La rectificación de la información: lo cual permite al titular corregir ciertos
          datos que OSDE posee de él en caso que los mismos no fuesen íntegros o adecuados.
          <br />
          <br />
          La actualización de la información: lo cual permite al titular modificar ciertos
          datos que OSDE posee de él en caso que se encontrasen desactualizados
          <br />
          <br />
          La supresión de datos: lo cual posibilita la eliminación por parte de OSDE de
          aquellos datos que el titular indique.
          <br />
          <br />
          Cabe aclarar que no procederemos a la supresión de los datos cuando:
          <br />
          <br />
          a) Pudiese causar un perjuicio al interés legítimo del titular o terceros.
          <br />
          <br />
          b) Cuando hubiese una relación contractual vigente para cuyo desarrollo y gestión se
          precisen.
          <br />
          <br />
          c) Cuando sea preciso su mantenimiento para gestionar intereses legítimos de cobro
          u operativos de OSDE.
          <br />
          <br />
          d) Cuando por imposición de la legislación vigente exista obligación de conservarlos.
          <br />
          <br />
          <br />
          <br />
          9-PROTECCIÓN DE LOS DATOS PERSONALES
          <br />
          <br />
          En OSDE implementamos diferentes medidas de seguridad, tecnológicas y de procesos,
          para proteger los datos personales que gestionamos. Tienen por objeto proteger la
          confidencialidad, integridad y disponibilidad de los datos, de manera que solo sean
          accedidos o modificados por las personas autorizadas y se encuentren disponibles en
          tiempo y forma cuando sean requeridos.
          <br />
          <br />
          Estos son algunos de los controles implementados:
          <br />
          <br />
          Practicamos la privacidad y seguridad por diseño: consideramos los requisitos
          desde el primer momento en que se comienza un proyecto o mejora de procesos y/o
          sistemas.
          <br />
          <br />
          Evaluamos los riesgos de los cambios o innovaciones en el tratamiento de datos
          con el objeto de mitigarlos hasta niveles aceptables.
          <br />
          <br />
          Implementamos el criterio de “necesidad de saber” a la hora de asignar permisos
          de acceso/modificación/eliminación de datos:solo se otorgan permisos a quienes
          lo requieren para el cumplimiento de sus funciones.
          <br />
          <br />
          Realizamos concientización y capacitación interna en materia de protección de
          datos y privacidad.
          <br />
          <br />
          Implementamos controles de acceso a los sistemas y los datos en los que estos se
          encuentren de acuerdo a su criticidad, incluyendo políticas de contraseñas,
          múltiples factores de autenticación, esquemas de autorización, entre otros.
          <br />
          <br />
          Ciframos los datos en reposo y en tránsito con el objeto de que no sean inteligibles
          por terceros no autorizados.
          <br />
          <br />
          No utilizamos datos reales en ambientes no productivos como, por ejemplo, ambientes
          de prueba.
          <br />
          <br />
          Implementamos controles de detección y prevención de fuga de datos de acuerdo a
          políticas establecidas.
          <br />
          <br />
          Resguardamos los datos con copias de seguridad adecuadamente protegidas.
          <br />
          <br />
          Implementamos controles de acceso físico a los datos en los centros de
          procesamiento y oficinas.
          <br />
          <br />
          Contamos con un proceso de gestión de incidentes de seguridad, darle respuesta
          rápida y remediarlos con el fin de minimizar efectos adversos. Dicha gestión
          contempla la información a terceros afectados en caso de compromiso de sus datos.
          <br />
          <br />
          <br />
          <br />
          10-DATOS DEL RESPONSABLE DE TRATAMIENTO DE DATOS
          <br />
          <br />
          OSDE, con domicilio en Av. Leandro N. Alem 1067, Piso 9 (CABA), es responsable
          de las bases de datos que gestiona. Cualquier consulta o contacto relacionado
          con esta materia podrá efectuarse a protecciondedatos@osde.com.ar
          <br />
          <br />
          <br />
        </div>
      }
    </>
  );
};

export default Legal;