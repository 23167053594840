export interface DocumentType {  
    id: number, 
    descripcion: string,
    maxLength: number | '',
    minLength: number | ''
}

export interface DocumentsType{
    dni: DocumentType,
    lc: DocumentType,
    le: DocumentType,
    pasaporte: DocumentType,
    cuil: DocumentType,
    cuit: DocumentType
}

export enum DocumentosIds {
    dni = 96,
    lc = 90,
    le = 89,
    pasaporte = 94,
    cuil = 86,
    cuit = 80
}

export const documentos: DocumentsType = {
    dni: {
        id: 96,
        descripcion: 'DNI',
        maxLength: 8,
        minLength: 7
    },
    lc: {
        id: 90,
        descripcion: 'LC',
        maxLength: 7,
        minLength: 7
    },
    le: {
        id: 89,
        descripcion: 'LE',
        maxLength: 7,
        minLength: 7
    },
    pasaporte: {
        id: 94,
        descripcion: 'Pasaporte',
        maxLength: 9,
        minLength: 7
    },
    cuil: {
        id: 86,
        descripcion: 'CUIL',
        maxLength: 11,
        minLength: 10
    },
    cuit: {
        id: 80 ,
        descripcion: 'CUIT',
        maxLength: 11,
        minLength: 10
    }
}
    
