import {
  IonGrid,
  IonRow,
  IonLabel,
  IonFooter,
} from '@ionic/react';
import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import Header from '../../../../../shared/components/layout/header/Header';
import { isWeb } from '../../../../../shared/utils/utils';
import { RootState } from '../../../../../store';
import styles from './SelectCardPage.module.scss';
import { formatImport } from '../../../../../shared/utils/utils';
import analyticsService from '../../../../../services/analytics/analytics';
import PaymentMethods from './components/PaymentMethods/PaymentMethods';
import { useHistory } from 'react-router';
import { routes } from '../../../../home/router/routes';

const SelectCardPage: React.FC = () => {
  const { getForm } = useSelector((state: RootState) => state.pagoEnLinea);
  const history = useHistory();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if(getForm.talones.length > 0) {
      const total = getForm.talones.reduce((acc, item) => acc + parseFloat(item.monto), 0);
      setTotal(total);
      analyticsService.trackPageViewForm("trackPageviewForm", `/gestiones-online/pago-en-linea/seleccion-de-tarjeta?qty=${total}`, "pagos en línea - 1. selección de documento", 1);
    } else {
      history.push(routes.root);
    }
  }, [getForm, history]);

  return (
    <IonPage>
      <Header showBackButton={true} />
      <IonContent>
        <IonGrid className={`${styles.pagoEnLineaGrid} ion-no-padding`}>
          <IonRow className={styles.selectCardGridContainer}>
            <IonLabel className={styles.selectCardTitle}>
              ¿QUE TARJETA VAS A USAR?
            </IonLabel>
            <IonRow className={styles.selectCardSeparator}></IonRow>
            <IonLabel className={styles.selectCardSubtitle}>
              Total a pagar
            </IonLabel>
            <IonLabel className={styles.selectCardAmount}>${formatImport(total.toFixed(2))}</IonLabel>
          </IonRow>

          <PaymentMethods />
        </IonGrid>
      </IonContent>
      {isWeb() && (
        <IonFooter>
          <Footer />
        </IonFooter>
      )}
    </IonPage>
  );
};

export default SelectCardPage;