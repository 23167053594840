import { GestionesLink } from '../types/GestionesLink';
import gestionesOnlineData from './GestionesOnlineData';

const { reintegros, empadronamiento, autorizaciones, misTurnos, datosContacto, facturacion, pagoEnLinea, credencialProvisoria, activaTuCredencial } = gestionesOnlineData;

export const gestionesOnlineWebNav: GestionesLink[] = [
  reintegros,
  empadronamiento,
  autorizaciones,
  pagoEnLinea,
  facturacion,
  misTurnos,
  datosContacto,
  credencialProvisoria,
  activaTuCredencial
];

export default gestionesOnlineWebNav;