import { AutorizacionDataType, FormularioAutorizacionesObtenidoDataType } from '../../../../../store/autorizaciones/types/AutorizacionesData';

export const getFormMock: FormularioAutorizacionesObtenidoDataType = {
  aptoServicio: true,
  email: 'juan@gmail.com',
  listaIntegrantes: [
    {
      afiliado: '5573796',
      aptoServicio: true,
      beneficiario: '02',
      checked: false,
      esBeneficiario: true,
      filial: '62',
      id: 0,
      interlocutorComercial: '2005584800',
      nombre: 'Irma Perez',
    },
    {
      afiliado: '5573796',
      aptoServicio: true,
      beneficiario: '01',
      checked: false,
      esBeneficiario: false,
      filial: '62',
      id: 0,
      interlocutorComercial: '2005668864',
      nombre: 'Juan Perez',
    },
    {
      afiliado: '5764819302175468',
      aptoServicio: true,
      beneficiario: '07',
      checked: false,
      esBeneficiario: false,
      filial: '62',
      id: 0,
      interlocutorComercial: '20056688645',
      nombre: 'Juana de Arco',
    },
    {
      afiliado: '7564598763',
      aptoServicio: true,
      beneficiario: '08',
      checked: false,
      esBeneficiario: false,
      filial: '62',
      id: 0,
      interlocutorComercial: '20056688647',
      nombre: 'JUANA AZURDUY',
    },
  ],
  observacion: null,
  telefono: '0223-15-547-44444',
};

export const postformMock: AutorizacionDataType = {
  numeroTramite: '0303456',
  observacion: {
    codigo: 200,
    descripcion: 'OK',
  },
};
