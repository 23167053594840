import {
    IonRow,
    IonLabel,
    IonCol,
    IonItem,
    IonButton,
    IonIcon,
    IonList
} from "@ionic/react";
import { addOutline, trashOutline } from "ionicons/icons";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import AlertPopup from "../../../../../shared/components/popups/alerts/alert/AlertPopup";
import styles from "./AttachForm.module.scss";
import * as CamaraManager from '../../../../../shared/utils/camera/CameraManager'
import AttachModal from "../../../../../shared/components/popups/modals/attach-modal/AttachModal";
import { toBase64, tamanoMaximoArchivos, getFileExtension, sizeBase64FileString } from "../../../../../shared/utils/utils";
import StandardModalPopup from "../../../../../shared/components/popups/modals/standard-modal-popup/StandardModalPopup";
import CameraTASModal from "../../../../../shared/components/popups/modals/camera-tas-modal/CameraTASModal";
import { AnimatePresence, motion } from "framer-motion";
import { PedidoMedicoDataType } from "../../../../../store/autorizaciones/types/AutorizacionesData";

interface AttachFormProps {
    pedidoMedico: any,
    documentacionAdicional: PedidoMedicoDataType[],
    setDocumentacionAdicional: Dispatch<SetStateAction<PedidoMedicoDataType[]>>,
    setShowPedidoMedico: any,
    showPedidoMedico: any,
    pedidoMedicoImage: any
}

const animation = {
    initial: { opacity: 0, y: 50, scale: 0.3 },
    animate: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 0, scale: 0.5, transition: { duration: 0.2 } }
}

const AttachForm: React.FC<AttachFormProps> = ({ pedidoMedico, documentacionAdicional, setDocumentacionAdicional, setShowPedidoMedico, showPedidoMedico, pedidoMedicoImage }) => {
    const [openPopUp, setOpenPopUp] = useState<boolean>(false);
    //this hook allows to upload the same file twice by reloading the hidden input.
    const [showInputDocAdicional, SetShowInputDocAdicional] = useState<boolean>(true);
    const [isOpenDocumentacionAdicional, setIsOpenDocumentacionAdicional] = useState<boolean>(false);
    const [showFotoModal, setShowFotoModal] = useState<boolean>(false);
    const [fotoModal] = useState<string>("");
    const [disabledButton, setDisabledButton] = useState<boolean>(true);
    const fileInputDocumentacionAdicional = useRef(null);
    const [showCameraTASModal, setShowCameraTASModal] = useState<boolean>(false);

    useEffect(() => {
        SetShowInputDocAdicional(true);
    }, [showInputDocAdicional])

    useEffect(() => {
        setDisabledButton(pedidoMedico);
    }, [pedidoMedico])


    const removeOfDocAdicional = (index: number) => {
        if (documentacionAdicional?.length === 1) {
            setDocumentacionAdicional([]);
            if (pedidoMedicoImage) {
                setDisabledButton(false);
            }
        } else {
            const copyArray = [...documentacionAdicional];
            copyArray?.splice(index, 1);
            setDocumentacionAdicional(copyArray.map((doc, index) => ({ ...doc, nombre: `doc_adicional_${index + 1}.` + getFileExtension(doc.nombre)})));
            if (pedidoMedicoImage) {
                setDisabledButton(false);
            }
        }
    }
    
    // the Id prop is added because framerMotion needs to know which element to animate in the list when an element is removed.

    const addDocumentacionAdicionalByCapacitor = async (camera: boolean) => {
        setIsOpenDocumentacionAdicional(false);
        try {
            const imgBase64 = await CamaraManager.getPhotoFromMobile(camera);
            const size = sizeBase64FileString(imgBase64.base64);
            if (size > tamanoMaximoArchivos * 1024) {
                setOpenPopUp(true);
                return;
            }
            const [lastFile] = [...documentacionAdicional].reverse();
            const newDocument = {
                nombre: `doc_adicional_${documentacionAdicional.length + 1}.${imgBase64.format}`,
                valor: imgBase64.base64,
                id: lastFile ? lastFile.id + 1 : 1
            }
            if (imgBase64) {
                if (documentacionAdicional.length === 5) {
                    setDisabledButton(true);
                }
                setDocumentacionAdicional([...documentacionAdicional, newDocument]);
                SetShowInputDocAdicional(false);
            }
        } catch (error) {
            console.log(error);
        }

    }

    const addDocumentacionAdicional = async (e: any) => {
        if (e.target.files[0].size / 1000000 > tamanoMaximoArchivos) {
            setOpenPopUp(true);
            return;
        }
        setIsOpenDocumentacionAdicional(false);
        try {
            const extension = e.target.files[0].type.split('/')[1] || 'pdf';
            const base64: string = await toBase64(e.target.files[0]);
            if(extension !== 'pdf'){
                setOpenPopUp(true);
                return;
            }
            const [lastFile] = [...documentacionAdicional].reverse();
            const newDocument = {
                nombre: `doc_adicional_${documentacionAdicional.length + 1}.${extension}`,
                valor: base64,
                id: lastFile ? lastFile.id + 1 : 1
            }
            if (base64) {
                if (documentacionAdicional.length === 5) {
                    setDisabledButton(true);
                }
                setDocumentacionAdicional([...documentacionAdicional, newDocument]);
                SetShowInputDocAdicional(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const addDocumentacionAdicionalFromTAS = async () => {
        setShowCameraTASModal(false);
        try {
            const imgBase64 = await CamaraManager.getPhotoFromTas();
            const [lastFile] = [...documentacionAdicional].reverse();
            const newDocument = {
                nombre: `doc_adicional_${documentacionAdicional.length + 1}.PNG`,
                valor: imgBase64,
                id: lastFile ? lastFile.id + 1 : 1
            }
            if (imgBase64) {
                if (documentacionAdicional.length === 2) {
                    setDisabledButton(true);
                }
                setDocumentacionAdicional([...documentacionAdicional, newDocument]);
                SetShowInputDocAdicional(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <IonRow className="ion-margin-bottom">
                <IonCol className={styles.documentation}>
                    <IonItem lines="none">
                        <IonLabel>Documentación adicional</IonLabel>
                    </IonItem>
                </IonCol>
            </IonRow>

            <IonList className={styles.docAdicionalList}>
                <AnimatePresence>
                    {documentacionAdicional.map((doc: any, index: any) => (
                        <motion.div {...animation} key={doc.id} layout>
                            <IonItem lines="none">
                                <div className={`${styles.fileFormat} ion-text-uppercase`} data-format={getFileExtension(doc.nombre)}>
                                    {getFileExtension(doc.nombre)}
                                </div>
                                <IonLabel color='dark' className='ion-text-center'>
                                    {`Doc adicional ${doc.id}`}
                                </IonLabel>
                                <IonIcon icon={trashOutline} onClick={() => { removeOfDocAdicional(index) }} />
                            </IonItem>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </IonList>

            {documentacionAdicional.length < 5 && 
                <IonButton className={[styles.addDocAdicionalButton, documentacionAdicional.length > 0 && 'ion-margin-top'].join(' ')}
                    color='tertiary'
                    fill='outline'
                    onClick={() => setIsOpenDocumentacionAdicional(true)}
                    disabled={disabledButton}
                >
                    <IonIcon icon={addOutline} />
                </IonButton>
            }

            { showInputDocAdicional &&
                <input
                    id="fileinput"
                    ref={fileInputDocumentacionAdicional}
                    hidden
                    type="file"
                    accept='application/pdf'
                    onChange={(e) => addDocumentacionAdicional(e)}
                />
            }

            <AlertPopup
                isOpen={openPopUp}
                message={`El archivo tiene que tener formato .jpg, .png o .pdf, y su tamaño no debe superar los ${tamanoMaximoArchivos} MB. `}
                title="Aviso"
                buttons={[
                    { text: 'Aceptar', handler: () => setOpenPopUp(false) }
                ]}
            />

            <AttachModal
                onDidDismiss={() => setIsOpenDocumentacionAdicional(false)}
                isOpen={isOpenDocumentacionAdicional}
                handlerCamera={async () => { addDocumentacionAdicionalByCapacitor(true) }}
                handlerImage={() => { addDocumentacionAdicionalByCapacitor(false) }}
                handlerDocument={() => {
                    fileInputDocumentacionAdicional.current?.click();
                    setIsOpenDocumentacionAdicional(false);
                }}
            />

            <StandardModalPopup
                isOpen={showFotoModal}
                title='Previsualización'
                content={
                    <img id="image-preview" src={fotoModal} alt="foto" />
                }
                handler={() => setShowFotoModal(false)}>

            </StandardModalPopup>

            <StandardModalPopup
                isOpen={showPedidoMedico}
                title='Previsualización'
                content={
                    <div className={styles.preview}>
                        <img id="image-preview" src={pedidoMedicoImage} alt="foto" />
                    </div>
                }
                handler={() => setShowPedidoMedico(false)}>

            </StandardModalPopup>

            <CameraTASModal
                isOpen={showCameraTASModal}
                closeModal={() => setShowCameraTASModal(false)}
                openCamera={addDocumentacionAdicionalFromTAS}
            />

        </>
    );
};

export default AttachForm;
