import React, { useEffect, useState } from "react";
import { DocumentacionDataType } from "../../../../../store/facturacion/types/FacturacionData";
import {
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonIcon
} from "@ionic/react";
import styles from "./Documentacion.module.scss";
import loginService from "../../../../../services/login/LoginService";
import Loading from "../../../../../shared/components/popups/loading/Loading";
import { chevronForward, documentTextOutline, newspaperOutline, ticketOutline, documentsOutline, alertCircle } from "ionicons/icons";
import useHttpErrorHandler from "../../../../../hooks/useHttpErrorHandler";

const loadingMessage = "Cargando...";

const Documentacion: React.FC = () => {
  const [documentacion, setDocumentacion] = useState<DocumentacionDataType[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useHttpErrorHandler({tramite: 'FACTURACION'});
  const icons = {
    EstadodeCuenta: newspaperOutline,
    TalóndePago: ticketOutline,
    NotadeCrédito: documentsOutline
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await loginService.getDocumentosFacturacion();
        const resp = response?.data?.map(item => { 
        return {
          ...item, 
          link : item.link.replace('http','https').replace(':80','')
        }
      });
      setDocumentacion(resp);
      setIsLoading(false);
      } catch (error) {
        console.log("Error al obtener documentos de facturacion", error);
        setIsLoading(false);
      }
    })()
  }, []);

  const downloadFile = async (documento: DocumentacionDataType) => {
      window.open(documento.link,'_blank');
  }

  return (
    <>
      <Loading
        isOpen={isLoading}
        message={loadingMessage}
      />
      {!isLoading && (
        documentacion?.length === 0 || !Array.isArray(documentacion) ?
          <IonItem lines="none" className={styles["docs-not-found"]}>
            <IonIcon color='secondary' icon={ alertCircle } />
            <IonLabel>
              No se encontraron comprobantes.
            </IonLabel>
          </IonItem>
          :
          <IonList id='facturacion-list' className={styles["facturacion-list"]}>
            <IonListHeader>
              DOCUMENTACIÓN DISPONIBLE
            </IonListHeader>
            {documentacion.map((documento, index) => (
                <IonItem
                  lines="none"
                  key={index}
                  onClick={() => downloadFile(documento)}>                 
                  <IonIcon color='secondary' icon={ icons[documento.descripcion.split(' ').join('')] || documentTextOutline } />
                  <IonLabel>
                    {documento.descripcion}
                  </IonLabel>
                  <IonIcon color='medium' icon={chevronForward} />
                </IonItem>
              )
            )}
          </IonList>
      )}
    </>
  );
};

export default Documentacion;