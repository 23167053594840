import React from 'react';
import { IonItem, IonLabel } from '@ionic/react';
import styles from './PageTitle.module.scss'

const PageTitle: React.FC<{lines?: boolean, color?: string}> = ({lines, color ,children}) => {

    const style = { "--background": color ?? '' } as React.CSSProperties

    return (
        <IonItem lines={lines ? 'full' : 'none'} style={style} className={`${styles.pageTitle} ion-text-center`}>
            <IonLabel color='primary' className='ion-text-wrap ion-text-uppercase'>
                {children}
            </IonLabel>
        </IonItem>
    );
};

export default PageTitle;