import React from 'react';
import { IonSpinner } from '@ionic/react';
import styles from './Spinner.module.scss'

interface SpinnerProps extends React.ComponentPropsWithoutRef<typeof IonSpinner> {}

const Spinner: React.FC<SpinnerProps> = ({...rest}) => {
    return (
      <div className={styles.spinner}>
        <IonSpinner
          {...rest}
        />
      </div>
    );
};

export default Spinner;