import { IonItem, IonLabel, IonText } from '@ionic/react';
import React from 'react'
import styles from './FormErrorMessage.module.scss'


const FormErrorMessage: React.FC = props => {

    return (
        <IonItem lines='none' className={styles['form-error-msg']}>
            <IonLabel className='ion-text-wrap'>
                <IonText color='danger'>{props.children}</IonText>
            </IonLabel>
        </IonItem>
    );
}

export default FormErrorMessage;