import { IonItem, IonInput, IonIcon } from '@ionic/react';
import React from 'react';
import FormErrorMessage from '../form-error-message/FormErrorMessage';
import styles from './InputForm.module.scss';

export interface InputFormProps {
  label: string;
  reference?: any;
  register?: any;
  name?: string;
  info?: string;
  labelIcon?: string;
  labelIconOnClick?: (e: any) => void;
  error?: boolean;
  inputIcon?: string;
  inputIconOnClick?: (e: any) => void;
  disabled?: boolean;
  readonly?: boolean;
  type?:
    | 'number'
    | 'time'
    | 'text'
    | 'date'
    | 'email'
    | 'password'
    | 'search'
    | 'tel'
    | 'url'
    | 'week'
    | 'month'
    | 'datetime-local'
    | undefined;
  inputmode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  placeHolder?: string;
  onChange?: (e: any) => void | undefined;
  onBlur?: (e: any) => void | undefined;
  onFocus?: (e: any) => void | undefined;
  onKeyPress?: (e: any) => void | undefined;
  onKeyDown?: (e: any) => void | undefined;
  onPaste?: (e: any) => void | undefined;
  maxLength?: number;
  minLength?: number;
  value?: any;
  errors?: any;
  required?: boolean;
  max?: string;
  labelMargin?: boolean;
}

const InputForm: React.FC<InputFormProps> = (props) => {
  return (
    <>
      <div
        className={[
          styles['input-tramite'],
          'ion-align-items-center',
          'ion-text-center',
        ].join(' ')}
      >
        <label
          className={
            styles[
              props.labelMargin === true || props.labelMargin === undefined
                ? 'labelMargin'
                : 'labelNoMargin'
            ]
          }
        >
          {props.label}
          {props.labelIcon && (
            <IonIcon
              color="secondary"
              icon={props.labelIcon}
              slot="end"
              onClick={props.labelIconOnClick}
            />
          )}
        </label>
        {props.info && <p className={styles.info}>{props.info}</p>}
        <IonItem
          ref={props.reference}
          className={[
            'ion-align-items-center',
            'ion-text-center',
            props.errors && styles['error-validation'],
            props.readonly && styles.readonly,
          ].join(' ')}
        >
          <IonInput
            max={props.max}
            required={props.required}
            maxlength={props.maxLength}
            minlength={props.minLength}
            readonly={props.readonly}
            disabled={props.disabled}
            type={props.type}
            inputmode={props.inputmode}
            onPaste={props.onPaste}
            name={props.name}
            placeholder={props.placeHolder}
            onIonChange={props.onChange}
            onIonBlur={props.onBlur}
            onFocus={props.onFocus}
            onKeyPress={props.onKeyPress}
            onKeyDown={props.onKeyDown}
            value={props.value}
          />
        </IonItem>
        {props.inputIcon && (
          <IonIcon
            icon={props.inputIcon}
            slot="end"
            className={styles['input-icon']}
            onClick={props.inputIconOnClick}
          />
        )}
      </div>
      {props.errors && <FormErrorMessage>{props.errors}</FormErrorMessage>}
    </>
  );
};

export default InputForm;
