import { IonIcon, IonSpinner } from "@ionic/react";
import { checkmark, close } from "ionicons/icons";
import React, { CSSProperties, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from 'react-redux';

import { RootState } from "../../../../store";


interface INavigationButtonProps {
  setDisabledButton: Dispatch<SetStateAction<boolean>>;
  startProgress: boolean;
  setStartProgress: Dispatch<SetStateAction<boolean>>;
}

interface IProgressBarProps extends INavigationButtonProps {
  isFinished: boolean,
  success: boolean,
  reset?: boolean,
}

const ProgressBar: React.FC<IProgressBarProps> = (props) => {
  const { isFinished, success, reset, setDisabledButton, startProgress, setStartProgress } = props;
  const { autorizaciones: { archivosAdjuntos } } = useSelector((state: RootState) => state);
  const [completed, setCompleted] = useState<number>(0);
  const [fillSec, setFillSec] = useState<number>(8);
  const [colorProgressBar, setColorProgressBar] = useState<string>('var(--ion-color-success)');
  const [colorIcon, setColorIcon] = useState<string>('var(--ion-color-success-contrast)');
  const [spinner, setSpinner] = useState<boolean>(false);

  const changePercent = () => {
    setCompleted(90);
  }

  useEffect(() => {
    if (startProgress) {
      changePercent();
      setSpinner(true);
      setDisabledButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startProgress])

  useEffect(() => {
    setDisabledButton(false);
    if (reset) {
      setStartProgress(false);
      setFillSec(0);
      setCompleted(0);
      setSpinner(false);
      setColorProgressBar('var(--ion-color-success)');
      setColorIcon('var(--ion-color-success-contrast)');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (isFinished) {
      setCompleted(100);
      setFillSec(1)
      if (success === false) {
        setColorProgressBar('var(--ion-color-danger)')
        setColorIcon('var(--ion-color-danger-contrast)')
      }
    }
  }, [isFinished, success])

  useEffect(() => {
    if (completed === 0) {
      const timeOutTime = (archivosAdjuntos && archivosAdjuntos?.documentosExtra) ? archivosAdjuntos?.documentosExtra.length + 1 : 1; // +1 por el pedido medico mas los archivos adjuntos
      setTimeout(() => setFillSec(11 * timeOutTime),  300 );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  const fillerStyles: CSSProperties = {
    position: 'absolute',
    transition: `all ${fillSec}s ease-in-out`,
    height: '100%',
    left: 0,
    width: `${completed}%`,
    backgroundColor: `${colorProgressBar}`,
    borderRadius: 'inherit',
    textAlign: 'right',
    marginRight: 'auto'
  }

  const iconStyle: CSSProperties = {
    position: "absolute",
    padding: 5,
    color: `${colorIcon}`,
    width: '100%',
    height: '60%',
  }

  return (
    <>
      <div style={fillerStyles} />
      {!isFinished ?
        spinner && <IonSpinner style={iconStyle} name="crescent" />
        :
        <IonIcon style={iconStyle} icon={success ? checkmark : close} />
      }
    </>
  );
};

export default ProgressBar;