import {
  IonButton,
  IonCardSubtitle,
  IonChip,
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';
import { Address, domicilioType, typeFromEnum } from '../../../../../store/datos-contacto/types/DatosContactoData';
import { routes } from '../../router/routes';
import styles from './AddressBlock.module.scss';

interface Props {
  data: Address;
  same?: boolean;
  type: domicilioType;
}

const AddressBlock: React.FC<Props> = ({ data, same = false, type }) => {
  const history = useHistory();

  return (
    <>
      <IonRow className={[styles['address-block'], 'ion-no-padding'].join(' ')}>
        <IonCol size="12" color="light" className="ion-no-padding">
          <IonItem lines="none">
            <IonLabel>
              {data.calle} {data.numero}
            </IonLabel>
            <IonButton
              slot="end"
              fill="clear"
              onClick={() => {
                history.push(routes.editAddress.replace(':type', type).replace(':typeFrom', typeFromEnum.editar));
              }}
            >
              EDITAR
            </IonButton>
          </IonItem>
        </IonCol>
        <IonCol size="12" className="ion-padding-start">
          <IonCardSubtitle mode='md'>
            Piso {data.piso}, Depto. {data.departamento}, {data.codigoPostal}, {data.provincia}, {data.localidad}
          </IonCardSubtitle>
        </IonCol>
        {data?.telefonoDefault && 
          <IonCol size="12" className="ion-padding-start">
            <IonCardSubtitle mode='md'>
               Telefono Fijo {data?.telefonoDefault?.numeroCompleto}
            </IonCardSubtitle>
          </IonCol>
        }
        {data?.telefonoMovilDefault && 
          <IonCol size="12" className="ion-padding-start">
            <IonCardSubtitle mode='md'>
              Telefono Móvil {data?.telefonoMovilDefault?.numeroCompleto}
            </IonCardSubtitle>
          </IonCol>
        }
        <IonCol size="12">
          <IonChip color="primary" outline>
            {type}
          </IonChip>
          {same && (
            <IonChip color="secondary" outline>
              Correspondencia
            </IonChip>
          )}
        </IonCol>
      </IonRow>
    </>
  );
};

export default AddressBlock;