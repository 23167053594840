import authService from "../../../../services/auth/AuthService";
import { Contrato } from "../../../../store/users/types/User";
import { PaymentForm } from "../../../../store/pagoEnLinea/types/PagoEnLineaData";
import AxiosInstance, { headerDefault } from "../../../../services/AxiosService";
import environmentService from "../../../../services/environment/environmentService";
import { GetComprobantesResponse, GetComprobantePDFResponse, PostFormResponse, ApiKeyResponse, GetFormResponse, PostForm } from "../../../../store/pagoEnLinea/types/PagoEnLineaTypes";
import { callDecidirMock, getComprobantesMock, getPaymentsBillMock, getPublicKeyMock, postFormMock } from "./mock/pagoEnLineaMock";
import { isLocalDev } from "../../../../shared/utils/utils";

const baseUrlService = '/pagoEnLineaService';

interface PagoEnLineaService {
  getPaymentsBill: (numero?: String, orden?: String) => Promise<GetFormResponse>;
  getComprobantes: (contrato:Contrato) => Promise<GetComprobantesResponse>;
  getComprobantePDF: (transactionId:string, contrato: Contrato) => Promise<GetComprobantePDFResponse>;
  postForm: (postFormRequest: PostForm) => Promise<PostFormResponse>;
  callDecidir: (data: PaymentForm, apiKey: string) => any;
  getPublicKey: () => Promise<ApiKeyResponse>;
};

const pagoEnLineaService: PagoEnLineaService = {
  getPaymentsBill: async (numero, orden) => {
    let resp = await AxiosInstance.post(`${baseUrlService}/paymentsBillNew`,{
      body: { contrato: { numero, orden } },
      header: makeRequestHeader()
    })
    const data: GetFormResponse = await resp?.data;
    return data;
  },

  getComprobantes: async (contrato) : Promise<GetComprobantesResponse> => {
    let resp = await AxiosInstance.post(`${baseUrlService}/getComprobantes`,makeVouchersRequest(contrato))
    const data: GetComprobantesResponse = await resp?.data;
    return data;
  },

  getComprobantePDF: async (transactionId, contrato):Promise<GetComprobantePDFResponse> => {
    const { body, header} = makePDFRequest(transactionId, contrato);
    let resp = await AxiosInstance.post(`${baseUrlService}/getComprobantePdf`,{body, header})
    const data = await resp?.data;
    return data;
  },

  postForm: async (postFormRequest) => {
    let resp = await AxiosInstance.post(`${baseUrlService}/payBillNew`,makePayBillRequest(postFormRequest))
    const data: PostFormResponse = await resp?.data;
    return data;
  },

  callDecidir: async (data: PaymentForm, apiKey: string) => {
    let response = await fetch(`${environmentService.getCurrent().urlDecidir}/tokens`, {
      method: 'post',
      headers: {
        apikey: apiKey,
        'X-Consumer-Username': apiKey,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  },

  getPublicKey: async () => {
    let resp = await AxiosInstance.post(`${baseUrlService}/getPublicKeyNew`, {
      body: {},
      header: makeRequestHeader(),
    }, 
    {
      headers: {
        apikey: '41cbc74acc604a109157bb8394561d27',
        'Content-Type': 'application/json',
      }
    })
    const data: ApiKeyResponse = await resp?.data;
    return data;
  }
};

const makeVouchersRequest = (contrato: Contrato) => {
  return {
    body: { datosSocio: { 
      nroContrato: contrato.numeroFilial + contrato.numeroAfiliado,
      filial: contrato.numeroFilial,
      nroOrden: contrato.numeroBeneficiario
    } },
    header: makeRequestHeader(),
  }
};

const makeRequestHeader = () => {
  return {
    ...headerDefault, 
    refreshToken: authService.getRefreshToken()
  };
};

const makePDFRequest = (transactionId, contrato) => {
  return {
      body: { 
        datosSocio: { 
          nroContrato: contrato.numeroFilial + contrato.numeroAfiliado,
          filial: contrato.numeroFilial,
          nroOrden: contrato.numeroBeneficiario
        },
        transactionId 
      },
      header: makeRequestHeader(),
  }
};

const makePayBillRequest = (postFormRequest) => {
  return {
    body: {
      pago: {
        datosSocio: {
          nroContrato: postFormRequest.pago.datosSocio.nroContrato,
          nroOrden: postFormRequest.pago.datosSocio.nroOrden,
        },
        token: postFormRequest.pago.token,
        medioDePago: {
          paymentMethodId:
            postFormRequest.pago.medioDePago.paymentMethodId,
          primerosDigitos:
            postFormRequest.pago.medioDePago.primerosDigitos,
        },
        idTalonDePago: postFormRequest.pago.idTalonDePago,
      },
      canal: postFormRequest.canal,
      enviarMail: postFormRequest.enviarMail,
    },
    header: makeRequestHeader()
  }
};


//#region MOCKS
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const pagoEnLineaServiceMock: PagoEnLineaService = {
  getPaymentsBill: async (numero, orden) => getPaymentsBillMock,
  getComprobantes: async (contrato) => getComprobantesMock,
  getComprobantePDF: async (transactionId, contrato) => {
    let resp = await AxiosInstance.post(`${baseUrlService}/getComprobantePdf`,makePDFRequest(transactionId, contrato))
    return await resp?.data;
  },
  postForm: async (postFormRequest) => postFormMock,
  callDecidir: async (data: PaymentForm, apiKey: string) => callDecidirMock,
  getPublicKey: async () => getPublicKeyMock
};
//#endregion



export default isLocalDev() ? pagoEnLineaServiceMock : pagoEnLineaService;