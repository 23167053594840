import { IonItem, IonDatetime, IonIcon } from '@ionic/react';
import { calendarOutline } from 'ionicons/icons';
import * as React from 'react';
import FormErrorMessage from '../form-error-message/FormErrorMessage';
// import { Dispatch } from 'react';
import styles from './DateTime.module.scss'

export interface DateTimeProps {
    label: string;
    name?: string;
    hasError?: boolean
    onChange?: (e: any) => void | undefined;
    format?: string;
    icon?: any;
    placeholder?: string;
    value?: any;
    error?: string;
    register?: any;
    max?: string;
    min?: string;
}


const DateTime: React.FC<DateTimeProps> = ({ label, name, register, onChange, format, icon, placeholder, value, error, max, min }) => {
    return (
        <>
            <IonItem className={[styles["date-time"], error && styles['error-validation']].join(' ')}>
                <label>
                    {label}
                </label>
                <IonDatetime
                    name={name}
                    displayFormat={format ? format : "DD/MM/YYYY"}
                    onIonChange={onChange}
                    placeholder={placeholder ? placeholder : "dd/mm/aaaa"}
                    doneText="Hecho"
                    cancelText="Cancelar"
                    ref={register}
                    max={max}
                    min={min}
                    value={value}
                    pickerOptions={{cssClass: styles.extended}}
                />
                <IonIcon icon={icon ? icon : calendarOutline} slot="end" />
            </IonItem>
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
        </>
    );
}

export default DateTime;