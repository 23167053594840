import { IonHeader, IonButton, IonFooter } from '@ionic/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { Dispatch, FunctionComponent, MouseEventHandler, SetStateAction, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import styles from './GenericAlert.module.scss';

interface GenericAlertProps {
  id?: string;
  isOpen?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>> | any;
  backdropOnClick?: MouseEventHandler<HTMLDivElement>;
  closeOnBackNavigation?: boolean;
  title: string;
  buttons?: IonButtonProps[];
  scrolleable?: boolean;
}

interface IonButtonProps extends React.ComponentPropsWithoutRef<typeof IonButton> { }


const backdrop = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
};

const modal = {
  hidden: { opacity: 0, x: '-50%', y: '70%' },
  visible: {
    opacity: 1,
    y: '-50%',
    x: '-50%',
    transition: { delay: 0.1 }
  },
  exit: {opacity: 0, x: '-50%', y: '0'}
};

const GenericAlert: FunctionComponent<GenericAlertProps> = ({ isOpen, title, children, buttons, setIsOpen, backdropOnClick, closeOnBackNavigation = true}) => {
  const location = useLocation();
  const alertLocation = useRef<string>();

  useEffect(() => {
    if(!closeOnBackNavigation) return;
    if (!alertLocation.current) {
      alertLocation.current = location.pathname;
    } else if (alertLocation.current !== location.pathname) {
      alertLocation.current = '';
      setIsOpen?.();
    }
  }, [closeOnBackNavigation, location, setIsOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className={styles.backdrop}
            variants={backdrop}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={backdropOnClick}
          />

          <motion.div
            className={[styles.modal, 'ion-padding'].join(' ')}
            variants={modal}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <IonHeader className="ion-no-border ion-margin-top ion-margin-bottom">
              <h2 className='ion-text-wrap'>
                {title}
              </h2>
            </IonHeader>
            <div className={styles.body}>
              {children}
            </div>
            <IonFooter className="ion-no-border">
              <div className={[styles.buttons, 'ion-margin-top'].join(' ')}>
                {buttons.map((button, index) => (
                  <IonButton
                    key={index}
                    expand="block"
                    color={index === 0 ? 'primary' : 'tertiary'}
                    fill={index === 0 ? 'solid' : 'outline'}
                    {...button}
                  >
                    {button.children}
                  </IonButton>
                ))}
              </div>
            </IonFooter>
          </motion.div>

        </>
      )}
    </AnimatePresence>
  );
};

export default GenericAlert;