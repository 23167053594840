import { IonToolbar, IonList } from '@ionic/react';
import { IonContent, IonPage, IonCheckbox } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './PaymentsPage.module.scss'
import {
  PAGO_EN_LINEA_MODAL_TITLE_PDDP,
  PAGO_EN_LINEA_MODAL_SUBTITLE_PDDP,
  PAGO_EN_LINEA_MODAL_TITLE_TYC,
  PAGO_EN_LINEA_LINK_MODAL_TYC,
  PAGO_EN_LINEA_LINK_MODAL_PDDP,
  NEXT_STEP
} from '../../../../../shared/globalConstants';
import { routes as PagoEnLineaRoutes } from '../../router/routes';
import pagoEnLineaService from '../../services/PagoEnLineaService';
import { ErrorModal } from '../../components/ErrorModal/ErrorModal';
import Header from '../../../../../shared/components/layout/header/Header';
import StandardModal from '../../../../../shared/components/popups/modals/standard-modal/StandardModal';
import ProteccionDeDatos from '../../components/ProteccionDeDatos/ProteccionDeDatos';
import TerminosYCondiciones from '../../components/TerminosYCondiciones/TerminosYCondiciones';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import { isWeb } from '../../../../../shared/utils/utils';
import { RootState } from '../../../../../store';
import analyticsService from '../../../../../services/analytics/analytics';
import TramiteFooter from '../../../../../shared/components/tramite-footer/TramiteFooter';
import { Talon } from '../../../../../store/pagoEnLinea/types/PagoEnLineaTypes';
import { setEmail } from '../../../../../store/pagoEnLinea/actions';
import PaymentItem from './components/PaymentItem/PaymentItem';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import { motion, useAnimation, Variants } from 'framer-motion';
import { VoucherSkeleton } from '../../components/Voucher/Voucher';
import useHttpErrorHandler from '../../../../../hooks/useHttpErrorHandler';


const PaymentsPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const controls = useAnimation();
  const { pagoEnLinea: { getForm }, user: { user } } = useSelector((state: RootState) => state);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [importeTotal, setImporteTotal] = useState<any>();
  const [checked, setChecked] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showModalTYC, setShowModalTYC] = useState(false);
  const [showModalProteccionDeDatos, setShowModalProteccionDeDatos] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [talonesTs1yTs2, setTalonesTs1yTs2] = useState<Talon[]>([]);
  const [talonesTs3yTs4, setTalonesTs3yTs4] = useState<Talon[]>([]);
  useHttpErrorHandler();

  const variants: Variants = {
    start: (i) => ({
      scale: [1, 1.2, 1],
      transition: {
        duration: 0.3,
      }
    }),
    reset: {
      rotate: 0
    }
  };


  useEffect(() => {
    setIsLoading(true);
    try {
      let isMounted = true;
      callPagoEnLineaService(isMounted);
      setImporteTotal(parseFloat('0,00'));
      analyticsService.trackEvent('gestiones online native', 'menu principal', 'pago en línea - realizar pago');
      return () => { isMounted = false };
    } catch (err) {
      console.log('ERROR - PagoEnLinea', err);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const total = getForm.talones.reduce((acc, item) => acc + parseFloat(item.monto), 0);
    setImporteTotal(total);
    setButtonDisabled(!(getForm.talones.length > 0 && checked));
  }, [getForm, checked, controls]);


  useEffect(() => {
    const total = getForm.talones.reduce((acc, item) => acc + parseFloat(item.monto), 0);
    if (importeTotal !== total) {
      controls.start('start');
    }
  }, [controls, getForm.talones, importeTotal]);


  const callPagoEnLineaService = async (isMounted) => {
    let numero: any =
      user && user?.contrato.numeroFilial + user?.contrato.numeroAfiliado;
    let orden: any = user && user?.contrato.numeroBeneficiario;
    const response = await pagoEnLineaService.getPaymentsBill(numero, orden);
    if (isMounted) {
      if (!response || !response.talones) {
        setIsLoading(false);
        analyticsService.trackPageView("/gestiones-online/pago-en-linea/documentos-pendientes-de-pago?qty=0");
        setShowErrorModal(true);
        return;
      }
      dispatch(setEmail(response.direccionMail));
      const temp = response.talones.filter(
        (item) => item.tipoTalon === 'TS1' || item.tipoTalon === 'TS2'
      );

      let cantidadDeudaVencida = temp.filter(item => item.tipoTalon === 'TS1').length;
      if (cantidadDeudaVencida > 0) {
        analyticsService.trackPageView(`/gestiones-online/pago-en-linea/documentos-pendientes-de-pago?m=documento-con-deuda-inhabilitante&qty=${cantidadDeudaVencida}`);
      } else {
        analyticsService.trackPageView("/gestiones-online/pago-en-linea/documentos-pendientes-de-pago?qty=" + temp.length);
      }

      temp.sort((item1, item2) =>
        item1.tipoTalon > item2.tipoTalon
          ? 1
          : item2.tipoTalon > item1.tipoTalon
            ? -1
            : 0
      );
      setTalonesTs1yTs2(temp);
      setTalonesTs3yTs4(response.talones.filter((item) => item.tipoTalon === 'TS3' || item.tipoTalon === 'TS4'));
      setIsLoading(false);
    }
  };


  const formatImport = (nStr: string) => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? ',' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      // eslint-disable-next-line no-useless-concat
      x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
  };


  const onChangeTerminosYCondiciones = () => {
    setChecked(!checked);
    if (getForm.talones.length > 0) {
      setButtonDisabled(!buttonDisabled);
    }
  };


  const callbackGoToMainPage = () => {
    history.replace(PagoEnLineaRoutes.root);
  }


  return (
    <IonPage>
      <Header showBackButton={true} />
      <IonContent className={`${styles.paymentsContent} flexContent`}>
        <PageTitle lines >Pagar en línea</PageTitle>
        {!isLoading ?
          <>
            <IonList>
              {talonesTs1yTs2?.map(
                (talon: Talon, index: number, arr: Talon[]) => {
                  return (
                    <PaymentItem
                      key={talon.id}
                      talon={talon}
                    />
                  );
                }
              )}
              {talonesTs3yTs4?.map(
                (talon: Talon, index: number, arr: Talon[]) => {
                  return (
                    <PaymentItem
                      key={talon.id}
                      talon={talon}
                    />
                  );
                }
              )}
            </IonList>
            <IonToolbar color='light' className={styles.termsToolbar}>
              <ul>
                <li>
                  <IonCheckbox
                    checked={checked}
                    className={styles.checkboxTerminos}
                    onIonChange={onChangeTerminosYCondiciones}
                  />
                  <p onClick={() => setShowModalTYC(true)}>
                    {PAGO_EN_LINEA_LINK_MODAL_TYC}
                  </p>
                </li>
                <li>
                  <p onClick={() => setShowModalProteccionDeDatos(true)}>
                    {PAGO_EN_LINEA_LINK_MODAL_PDDP}
                  </p>
                </li>
              </ul>
            </IonToolbar>
          </>
          :
          <IonList>
            {Array.from({ length: 3 })?.map((_, index) => <VoucherSkeleton key={index} />)}
          </IonList>
        }
      </IonContent>

      <TramiteFooter showBorder>
        <IonToolbar color='light'>
          <div className={styles.paymentsToolbar}>
            <p>total a pagar</p>
            <motion.h3 animate={controls} variants={variants}>
              ${importeTotal && formatImport(importeTotal.toFixed(2))}
            </motion.h3>
          </div>
        </IonToolbar>
        <NavigationButton
          color="light"
          disabled={buttonDisabled}
          onClick={() => history.push(PagoEnLineaRoutes.selectCard)}>
          {NEXT_STEP}
        </NavigationButton>
        {isWeb() && <Footer />}
      </TramiteFooter>

      <ErrorModal
        isOpen={showErrorModal}
        onDidDismiss={callbackGoToMainPage}
        errorCode={'404'}
      />

      <StandardModal
        isOpen={showModalTYC}
        title={PAGO_EN_LINEA_MODAL_TITLE_TYC}
        subtitle=""
        content={<ProteccionDeDatos />}
        handler={() => setShowModalTYC(false)}
      />

      <StandardModal
        isOpen={showModalProteccionDeDatos}
        title={PAGO_EN_LINEA_MODAL_TITLE_PDDP}
        subtitle={PAGO_EN_LINEA_MODAL_SUBTITLE_PDDP}
        content={<TerminosYCondiciones />}
        handler={() => setShowModalProteccionDeDatos(false)}
      />

    </IonPage>
  );
};

export default PaymentsPage;