import {
  AntecedenteDataType,
  DatosSociaType,
  SociaDataType,
} from '../../modules/tramites/empadronamiento/types/EmpadronamientoTypes';

export const SET_EMPADRONAMIENTO_GETFORM = 'SET_EMPADRONAMIENTO_GETFORM';
export const SET_SOCIA_EMPADRONAMIENTO = 'SET_SOCIA_EMPADRONAMIENTO';
export const SET_ACEPTA_TERMINOS = "SET_ACEPTA_TERMINOS";
export const SET_NINGUN_ANTECEDENTE = "SET_NINGUN_ANTECEDENTE";
export const SET_NUMERO_TRAMITE = 'SET_NUMERO_TRAMITE';
export const UNSET_EMPADRONAMIENTO = 'UNSET_EMPADRONAMIENTO';

interface setEmpadronamientoGetForm {
  type: typeof SET_EMPADRONAMIENTO_GETFORM;
  payload: {listaSocias: SociaDataType[], antecedentes: AntecedenteDataType[]};
}

interface setSociaEmpadronamiento {
  type: typeof SET_SOCIA_EMPADRONAMIENTO;
  payload: DatosSociaType | null;
}

interface setAceptaTerminos {
  type: typeof SET_ACEPTA_TERMINOS;
  payload: boolean;
}

interface setNingunAntecedente {
  type: typeof SET_NINGUN_ANTECEDENTE;
  payload: boolean;
}

interface setNumeroTramite {
  type: typeof SET_NUMERO_TRAMITE;
  payload: number;
}

interface unsetEmpadronamiento {
  type: typeof UNSET_EMPADRONAMIENTO;  
}

export type EmpadronamientoAction =
  | setEmpadronamientoGetForm
  | setSociaEmpadronamiento
  | setAceptaTerminos
  | setNingunAntecedente
  | setNumeroTramite
  | unsetEmpadronamiento;