import {
  AutorizacionesAction,
    SET_ARCHIVOSADJUNTOS,
  SET_AUTORIZACIONOBTAINEDDATA,
  SET_DATOSPRACTICA,
  SET_DATOSSOCIO,
  SET_DETALLE,
  SET_NUMEROTRAMITE,
  SET_ORIGENDESTINO,
  SET_SOCIOSELECCIONADO,
  SET_STEPS,
  SET_SUCCESS,
  SET_TIPOTRAMITE,
  SET_TRASLADO,
  UNSET_TRAMITE,
  TOGGLE_LOADING,
} from "./AutorizacionesActions";
import { ArchivosAdjuntos, AutorizacionDataType, DatosPractica, DatosSocio, FormularioAutorizacionesObtenidoDataType, IntegranteAutorizacionesDataType, OrigenDestino, Traslado } from "./types/AutorizacionesData";

export interface AutorizacionesState {
    tipoTramite: string | null,
    integrante:  IntegranteAutorizacionesDataType |null,
    datosPractica: DatosPractica |null,
    datosSocio: DatosSocio | null,
    archivosAdjuntos: ArchivosAdjuntos | null,
    traslado:Traslado | null,
    origenDestino : OrigenDestino | null
    obtainedData: FormularioAutorizacionesObtenidoDataType | null
    detalle: string | null
    numeroTramite: AutorizacionDataType | null
    stepDetails: {editOrder: any, titleAndLenght: any} | null
    success : boolean | undefined
    isLoading: boolean
}

const initialState = {
    integrante: null,
    traslado: null,
    detalle: null,
    origenDestino: null,
    datosPractica: null,
    archivosAdjuntos: null,
    datosSocio: null,
    tipoTramite: '',
    obtainedData : null,
    numeroTramite: null,
    stepDetails: null,
    success: undefined,
    isLoading: false
};

export const AutorizacionesReducer = (
  state: AutorizacionesState = initialState,
  action: AutorizacionesAction
): AutorizacionesState => {
    switch (action.type) {
        case SET_DATOSPRACTICA:
            return { ...state, datosPractica: action.payload };
        case SET_DATOSSOCIO:
            return { ...state, datosSocio: action.payload };
        case SET_ARCHIVOSADJUNTOS:
            return { ...state, archivosAdjuntos: action.payload };  
        case SET_TRASLADO:
            return { ...state, traslado: action.payload };   
        case SET_SOCIOSELECCIONADO: 
            return { ...state, integrante: action.payload};
        case SET_AUTORIZACIONOBTAINEDDATA:
            return { ...state, obtainedData: action.payload };   
        case SET_ORIGENDESTINO:
            return {...state, origenDestino: action.payload};
        case SET_TIPOTRAMITE:
            return {...state, tipoTramite: action.payload}
        case SET_DETALLE:
            return {...state, detalle: action.payload}
        case SET_NUMEROTRAMITE:
            return {...state, numeroTramite: action.payload}
        case SET_STEPS:
            return {...state, stepDetails: action.payload}
        case SET_SUCCESS:
            return {...state, success: action.payload}    
        case UNSET_TRAMITE:
            return initialState
        case TOGGLE_LOADING:
            return {...state, isLoading: !state.isLoading}
        default:
            return state;
    }
};
