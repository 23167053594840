import { ContratoFullData, User } from "./User";


export const SET_USER = "SET_USER";
export const UNSET_USER = "UNSER_USER";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const UNSET_LOGGED_IN = "UNSET_LOGGED_IN";
export const SET_CHECKSESSIONTAS = "SET_CHECKSESSIONTAS";
export const SET_EMAIL = 'SET_EMAIL';
export const SET_REDIRECT_LOGINWITHCI_ERROR = 'SET_REDIRECT_LOGINWITHCI_ERROR';
export const UNSET_TRAMITES = 'UNSET_TRAMITES';
export const SET_URL_AUTH_IOS = 'SET_URL_AUTH_IOS';
export const SET_REDIRECT_AFTER_LOGGED_IN_URL = 'SET_REDIRECT_AFTER_LOGGED_IN_URL'
export const USER_NOT_LOGIN_IOS = 'USER_NOT_LOGIN_IOS';
export const USER_LOGIN_IOS = 'USER_LOGIN_IOS'; 

interface SetUserNotLoginIosAction {
  type: typeof USER_NOT_LOGIN_IOS;
}

interface SetUserLoginIosAction {
  type: typeof USER_LOGIN_IOS;
}

interface SetRedirectLoginWithCIErrorAction {
  type: typeof SET_REDIRECT_LOGINWITHCI_ERROR;
  payload: string;
}

interface SetUserAction {
  type: typeof SET_USER;
  payload: {user: User, contratos?: ContratoFullData[]} | null;
}
interface UnSetUserAction {
  type: typeof UNSET_USER;
}

interface SetLoggedInAction {
  type: typeof SET_LOGGED_IN;
}
interface UnSetLoggedInAction {
  type: typeof UNSET_LOGGED_IN;
}

interface SetCheckSessionTASAction {
  type: typeof SET_CHECKSESSIONTAS;
}

interface SetEmailAction {
  type: typeof SET_EMAIL;
  payload: string;
}

interface UnSetTramites {
  type: typeof UNSET_TRAMITES;
}

interface SetUrlAuthIos {
  type: typeof SET_URL_AUTH_IOS;
  payload: string;
}

interface SetRedirectAfterLoggedInUrl {
  type: typeof SET_REDIRECT_AFTER_LOGGED_IN_URL;
  payload: string;
}

export type UserAction = SetUserLoginIosAction | SetUserNotLoginIosAction | SetUserAction | UnSetUserAction | SetLoggedInAction | UnSetLoggedInAction | SetCheckSessionTASAction | SetEmailAction | UnSetTramites | SetUrlAuthIos | SetRedirectAfterLoggedInUrl | SetRedirectLoginWithCIErrorAction;
