import { IonChip, IonIcon, IonItem, IonLabel, IonList, IonSpinner } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import GenericAlert from '../../../../popups/alerts/generic-alert/GenericAlert';
import styles from './PlanSelector.module.scss';
import { checkmarkCircle, warningOutline } from 'ionicons/icons';
import { RootState } from '../../../../../../store';
import { AnimatePresence, motion } from 'framer-motion';
import { CodigoContrato } from '../../../../../../store/users/types/User';
import authService from '../../../../../../services/auth/AuthService';
import { setUser } from '../../../../../../store/users/actions/user';
import { useHistory } from 'react-router';
import { routes } from '../../../../../../modules/home/router/routes';


const PlanSelector: React.FC<{ openPlanSelectorAlert: any }> = ({ openPlanSelectorAlert }) => {
    const { user: { user } } = useSelector((state: RootState) => state);
    return (
        <IonItem color="primary" className={styles.menu__plan} lines='none'>
            <IonChip color="light" onClick={openPlanSelectorAlert}>
                <div className={styles.displayBlock}>
                    <div className={[styles.menu__toolbarEmail, 'ion-text-wrap'].join(' ')}>
                        Nro. Socio {user?.contrato?.numeroFilial}{user?.contrato?.numeroAfiliado}{user?.contrato?.numeroBeneficiario}
                    </div>
                    <div className={[styles.menu__toolbarEmail, 'ion-text-wrap'].join(' ')}>
                        <b>Plan {user?.plan}</b>
                    </div>
                </div>
                <span className={styles.ionButtonContratos}>
                    CAMBIAR
                </span>
            </IonChip>
        </IonItem>
    )
}

export default PlanSelector



interface IPlanSelectorAlertProps extends React.ComponentPropsWithoutRef<typeof GenericAlert> { }

export const PlanSelectorAlert: React.FC<IPlanSelectorAlertProps> = ({ ...props }) => {
    const dispatch = useDispatch();
    const { user, contratos } = useSelector((state: RootState) => state.user);
    const [checkedPlan, setCheckedPlan] = useState<CodigoContrato>();
    const [isLoading, setIsLoading] = useState(false);
    const [httpError, setHttpError] = useState(false);
    const history = useHistory();


    useEffect(() => {
        if (!user) return;
        const { nroActividad, valid, ...rest } = user.contrato;
        setCheckedPlan(rest);
    }, [user])


    const isSameAsChecked = (tappedPlanCodigo: CodigoContrato): boolean => {
        if (!checkedPlan) return;
        return JSON.stringify(tappedPlanCodigo) === JSON.stringify(checkedPlan);
    }


    const selectPlan = async () => {
        const { nroActividad, valid, ...rest } = user.contrato;
        if (isSameAsChecked(rest)) {
            props.setIsOpen();
            return;
        }
        setIsLoading(true);
        const resp = await authService.contratos(checkedPlan);
        if (resp) {
            const user = (await authService.getUserFullData())?.user;
            if(!user){
                window.location.href = 'home';
                return;
            }
            dispatch(setUser({user}));
            props.setIsOpen();
            history.replace(routes.root);
        } else {
            setHttpError(true);
        }
        setIsLoading(false);
    }


    const closeAlert = () => {
        if(!user) return;
        setHttpError(false);
        const { nroActividad, valid, ...rest } = user.contrato;
        setCheckedPlan(rest);
        props.setIsOpen();
    }


    return (
        <GenericAlert
            {...props}
            title={!httpError ? props.title : 'Importante'}
            backdropOnClick={!isLoading && closeAlert}
            closeOnBackNavigation={!isLoading}
            setIsOpen={closeAlert}
            buttons={
                !httpError ? [
                    {
                        children: !isLoading ? 'Seleccionar' : <IonSpinner name="crescent" />,
                        disabled: isLoading,
                        onClick: selectPlan
                    }
                ] : [
                    {
                        children: 'Volver',
                        onClick: closeAlert
                    }
                ]}
        >
            {!httpError ? <IonList className={styles.planesList}>
                {contratos?.map((plan, i) => (
                    <IonItem
                        button
                        disabled={isLoading}
                        onClick={() => setCheckedPlan(plan.codigoContrato)}
                        className={isSameAsChecked(plan.codigoContrato) && styles.checkedPlanStyle}
                        key={i}
                        lines={i === contratos?.length - 1 ? 'none' : 'full'}
                    >
                        <div className={styles.displayBlock}>
                            <h3>Nro. Socio:  {plan.codigoContrato.numeroFilial}{plan.codigoContrato.numeroAfiliado}{plan.codigoContrato.numeroBeneficiario}</h3>
                            <p>Plan: {plan.plan}</p>
                        </div>
                        <AnimatePresence>
                            {isSameAsChecked(plan.codigoContrato) && (
                                <motion.div
                                    slot='end'
                                    initial={{ opacity: 0, scale: 0.3 }}
                                    animate={{ opacity: 1, scale: [1.5, 1], transition: { duration: 0.2 } }}
                                    exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                                >
                                    <IonIcon color='success' icon={checkmarkCircle} />
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </IonItem>
                ))}
            </IonList>
                :
                <IonItem lines='none' className='ion-padding-start'>
                    <IonIcon color='warning' slot='start' icon={warningOutline} />
                    <IonLabel className='ion-text-wrap'>Lo sentimos, tuvimos un problema. Por favor, intentá nuevamente más tarde.</IonLabel>
                </IonItem>
            }
        </GenericAlert>
    )
};