import { IonContent, IonFooter, IonPage } from '@ionic/react';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import Header from '../../../../../shared/components/layout/header/Header';
import {  isWeb } from '../../../../../shared/utils/utils';
import { RootState } from '../../../../../store';
import { unSetTramite } from '../../../../../store/autorizaciones/AutorizacionesActions';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import analyticsService from '../../../../../services/analytics/analytics';
import { routes } from '../../../../home/router/routes';
import Check from '../../../../../shared/components/stepper/final-step/check/Check';
import SuccessText from '../../../../../shared/components/stepper/final-step/success-text/SuccessText';
import SuccessTitle from '../../../../../shared/components/stepper/final-step/success-title/SuccessTitle';
import SuccessDivider from '../../../../../shared/components/stepper/final-step/success-divider/SuccessDivider';

const StepFinalizarTramitePage: React.FC = () => {
    const { numeroTramite, stepDetails, traslado, datosPractica, tipoTramite } = useSelector((state: RootState) => state.autorizaciones);
    const { fechaTraslado } = traslado || {};
    const { fechaRealizacion } = datosPractica || {};
    const { titleAndLenght } = stepDetails || {};
    const dispatch = useDispatch();
    const history = useHistory();
    const nroTramite = useRef(numeroTramite?.numeroTramite);

  
    const sendTrackToAnalytics = () => {
        const fecha = fechaTraslado ?? fechaRealizacion ?? '';
        const pageName = "gestiones-online/autorizaciones/" + titleAndLenght?.namePageAnalytics + "/formulario-enviado/f=" + fecha + "&num_sol=" + numeroTramite;
        analyticsService.trackPageViewForm('trackPageviewForm', pageName, tipoTramite, 1)
    }


    useEffect(() => {
        if (!numeroTramite) {
            history.push(routes.root);
        } else {
            sendTrackToAnalytics();
            dispatch(unSetTramite());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <IonPage>
            <Header showBackButton={false} />
            <IonContent>

                <div className="ion-text-center ion-padding-top">
                    <Check />
                </div>

                <SuccessTitle>
                    Tu trámite esta en proceso
                </SuccessTitle>

                <SuccessDivider />

                <SuccessText>
                    Tu número de gestión es: <b>{nroTramite.current}</b>
                </SuccessText>

                <SuccessDivider />

                <SuccessText>
                    A medida que avancemos en la gestión, te vamos a contactar para informarte el estado de tu trámite.
                </SuccessText>

                <SuccessDivider />

                <SuccessText noLines>
                    Si necesitás realizar otra solicitud, volvé al menú inicial de la sección Gestiones Online.
                </SuccessText>

            </IonContent>

            <IonFooter className='ion-no-border'>
                <NavigationButton onClick={() => history.push(routes.root, false)} disabled={false}>Finalizar</NavigationButton>
                {isWeb() && <Footer />}
            </IonFooter>
        </IonPage>
    );
}

export default StepFinalizarTramitePage;