export interface AnalyticsService {
    trackEvent: (eventCategory, eventAction, eventLabel, pageName?) => void;
    trackPageView: (pageName, customAttributes?) => void;
    trackPageViewTag: (pageName, tag, tagView) => void;
    trackEventForm: (event, form, eventLabel, formSubmitted) => void;
    trackPageViewForm: (event, pageName, form, formSubmitted) => void;
    trackPageViewFormUserPlan: (event, pageName, form, formSubmitted, userId, plan) => void;
    trackPageViewFormNot: (event, pageName, form, formNotSubmitted) => void;
    trackEventFormNot: (event, form, eventLabel, formNotSubmitted) => void;
    trackEventFormError: (code, description, pageName) => void;
    formNotSubmittedAndSubmitted: (form, eventLabel, formNotSubmitted, formSubmitted) => void;
}

window.dataLayer = window.dataLayer || [];

declare global {
    interface Window {
        // TODO: replace this with a more specific type based on usage
        dataLayer: any[];
    }
}

const dataLayer = window.dataLayer;

const trackEvent = async (eventCategory, eventAction, eventLabel, pageName?) => {
    const data = await buildData({
        'event': 'trackEvent',
        'eventCategory': eventCategory,
        'eventAction': eventAction,
        'eventLabel': eventLabel,
        ...(pageName && {'pageName': pageName})
    });
    push(data);
}

const trackPageView = async (pageName: string, customAttributes?) => {
    const data = await buildData({
        'event': 'trackPageview',
        'pageName': pageName,
        ...(customAttributes && {'customAttributes': customAttributes})
    });
    push(data);
}

const trackPageViewTag = async (pageName, tag, tagView) => {
    const data = await buildData({
        'event': 'trackPageview',
        'pageName': pageName,
        'tag': tag,
        'tagView': tagView
    });
    push(data);
}

const trackEventForm = async (event, form, eventLabel, formSubmitted) => {
    const data = await buildData({
        'event': event,
        'form': form,
        'eventLabel': eventLabel,
        'formSubmitted': formSubmitted
    });
    push(data);
}

const trackPageViewForm = async (event, pageName, form, formSubmitted) => {
    const data = await buildData({
        'event': event,
        'pageName': pageName,
        'form': form,
        'formSubmitted': formSubmitted
    });
    push(data);
}

const trackPageViewFormUserPlan = async (event, pageName, form, formSubmitted, userId, plan) => {
    const data = await buildData({
        'event': event,
        'pageName': pageName,
        'form': form,
        'formSubmitted': formSubmitted,
        'userId': userId,
        'plan': plan
    });
    push(data);
}

const trackEventFormNot = async (event, form, eventLabel, formNotSubmitted) => {
    const data = await buildData({
        'event': event,
        'form': form,
        'eventLabel': eventLabel,
        'formNotSubmitted': formNotSubmitted
    });
    push(data);
}

const trackPageViewFormNot = async (event, pageName, form, formNotSubmitted) => {
    const data = await buildData({
        'event': event,
        'pageName': pageName,
        'form': form,
        'formNotSubmitted': formNotSubmitted
    });
    push(data);
}

const trackEventFormError = async (code, description, pageName) => {
    const data = await buildData({
        'event': 'trackEventForm',
        'eventCategory': 'errores',
        'eventAction': 'error ' + code + ': ' + description,
        'eventLabel': pageName
    });
    push(data);
}

const formNotSubmittedAndSubmitted = async (form, eventLabel, formNotSubmitted, formSubmitted) => {
    const data = await buildData({
        'event': 'trackEventForm',
        'form': form,
        'eventLabel': eventLabel,
        'formNotSubmitted': formNotSubmitted,
        'formSubmitted': formSubmitted
    });
    push(data);
}

const push = (obj: any) => {
    dataLayer?.push(obj);
}

const buildData = async (data): Promise<any> => {
    return data;
}


//#endregion


const analyticsService: AnalyticsService = {
    trackEvent,
    trackPageView,
    trackPageViewTag,
    trackEventForm,
    trackPageViewForm,
    trackPageViewFormUserPlan,
    trackPageViewFormNot,
    trackEventFormNot,
    trackEventFormError,
    formNotSubmittedAndSubmitted,
}

export default analyticsService