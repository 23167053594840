import React from 'react'
import { IGroup } from '../../../../../../store/menu/types/MenuResponse';
import MenuItem from '../menu-item/MenuItem';
import styles from './MenuGroup.module.scss';



const MenuGroup:React.FC<IGroup> = ({ title, items }) => {

    return (
        <>
            {
                title === ''
                ? <br></br>
                : <div className={styles["menu__group-title"]}>
                    { title }
                </div>
            }
            {
              items.map( (item, i) => (
                <MenuItem 
                    key={i}
                    name={ item.name }
                    link={ item.link }
                    type={ item.type }
                    items={ item.items}
                />
              ))
            }
            
        </>
    )
}

export default MenuGroup
