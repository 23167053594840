import {
  IonActionSheet,
  IonChip,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
} from '@ionic/react';
import {
  add,
  alertCircle,
  callOutline,
  checkmarkCircleSharp,
  close,
  ellipsisVertical,
  pencil,
  phonePortraitOutline,
  trash
} from 'ionicons/icons';
import React, { Dispatch, SetStateAction, useState } from 'react';
import ModuleModal from '../../../../../../../shared/components/popups/modals/module-modal/ModuleModal';
import { DomicilioDataType, domicilioType, Phone } from '../../../../../../../store/datos-contacto/types/DatosContactoData';
import PhoneInputModal from '../phone-input-modal/PhoneInputModal';
import styles from '../phones-modal/PhonesModal.module.scss';

interface PhonesModalModel {
  type: domicilioType;
  domicilio: DomicilioDataType;
  isOpen: boolean;
  setIsOpen: (newVal: boolean) => void;
  setPhone: (newTel:Phone, oldTel:Phone) => void;
  setDomicilio: Dispatch<SetStateAction<DomicilioDataType>>;
};

const PhonesModal: React.FC<PhonesModalModel> = ({type, domicilio, isOpen, setIsOpen, setPhone, setDomicilio}) => {
  const [showNewPhoneFijo, setShowNewPhoneFijo] = useState(false);
  const [showNewPhoneMovil, setShowNewPhoneMovil] = useState(false);
  const [showActionPhone, setShowActionPhone] = useState(false);
  const [phoneToAction, setPhoneToAction] = useState<Phone>(null);

  const getButtonsAction = () => {
    let buttons = [
      {
        text: 'Editar',
        icon: pencil,
        cssClass: styles.actionSheetIcon,
        handler: () => {
          phoneToAction.fijo && setShowNewPhoneFijo(true);
          !phoneToAction.fijo && setShowNewPhoneMovil(true);
        },
      },
      {
        text: 'Cancelar',
        icon: close,
        role: 'cancel',
        cssClass: styles.actionSheetIcon,
        handler: () => { },
      },
    ];
    if (phoneToAction && !phoneToAction.standard) {
      buttons = [
        ...buttons,
        {
          text: 'Seleccionar',
          icon: checkmarkCircleSharp,
          cssClass: styles.actionSheetIcon,
          handler: () => {
            updatePhones(phoneToAction);
            setPhoneToAction(null);
          },
        },
      ];
    }
    if (phoneToAction && domicilio.telefonos.length > 1) {
      buttons = [
        ...buttons,
        {
          text: 'Eliminar',
          role: 'destructive',
          icon: trash,
          cssClass: styles.actionSheetIcon,
          handler: () => {
            deleteTelefono(phoneToAction);
          },
        },
      ];
    }

    return buttons;
  };


  const telefonosLimitNotReached = (isFijo: boolean): boolean => {
    const total = domicilio.telefonos
      .filter((item) => item.fijo === isFijo)
      .reduce((acc, item) => {
        return acc + 1;
      }, 0);

    return total < 5;
  };

  const deleteTelefono = (phone : Phone) => { 
    const telefonosList = [...domicilio.telefonos].filter((telefono) => telefono.numeroCompleto !== phone.numeroCompleto);
    let telefonoDefault: Phone;
    let telefonoMovilDefault: Phone;

    if(domicilio.telefonoDefault?.numeroCompleto === phone.numeroCompleto) {
      telefonoDefault = telefonosList.find(tel => tel.fijo) ?? null;
      if (telefonoDefault) telefonoDefault.standard = true;
    }
  
    if(domicilio.telefonoMovilDefault?.numeroCompleto === phone.numeroCompleto) {
      telefonoMovilDefault = telefonosList.find(tel => !tel.fijo) ?? null;
      if (telefonoMovilDefault) (telefonoMovilDefault.standard = true)
    }
  
    setDomicilio({
      ...domicilio,
      telefonoDefault : (phone.fijo && phone.standard) ? telefonoDefault : domicilio.telefonoDefault,
      telefonoDefaultChanged: telefonoDefault ? true : null,
      telefonoMovilDefault: (!phone.fijo && phone.standard) ? telefonoMovilDefault : domicilio.telefonoMovilDefault,
      telefonoMovilDefaultChanged: telefonoMovilDefault ? true : null,
      telefonos: 
        telefonosList.map(
          (tel) => {
            if(telefonoDefault && tel.fijo){
              return {...tel, standard: tel.numeroCompleto === telefonoDefault.numeroCompleto}
            }
            else if(telefonoMovilDefault && !tel.fijo){
              return {...tel, standard: tel.numeroCompleto === telefonoMovilDefault.numeroCompleto }
            }
            else {
              return tel;
            }
          }
        ) 
    })
  }

  const updatePhones = (phone: Phone) => {
    setDomicilio({
      ...domicilio,
      telefonoDefault: phone.fijo ? {...phone, standard: true} : domicilio?.telefonoDefault,
      telefonoDefaultChanged: phone.fijo ? true : domicilio?.telefonoDefaultChanged,
      telefonoMovilDefault: !phone.fijo ? {...phone, standard: true} : domicilio?.telefonoMovilDefault,
      telefonoMovilDefaultChanged: !phone.fijo ? true : domicilio?.telefonoDefaultChanged,
      telefonos: [
        ...domicilio.telefonos.map((tel) => {
          if(tel.fijo === phone.fijo){
            return {...tel, standard: tel.numeroCompleto === phone.numeroCompleto}
          } else {
            return {...tel}
          }
        }),
      ]
    })
  };

  const editOrAddTelefono = (phone: Phone) => {
    if (!phoneToAction) {
      setDomicilio({
        ...domicilio,
        telefonoDefault: phone.fijo ? {...phone, standard: true} : domicilio?.telefonoDefault,
        telefonoDefaultChanged: phone.fijo ? true : domicilio?.telefonoDefaultChanged,
        telefonoMovilDefault: !phone.fijo ? {...phone, standard: true} : domicilio?.telefonoMovilDefault,
        telefonoMovilDefaultChanged: !phone.fijo ? true : domicilio?.telefonoDefaultChanged,
        telefonos: [
          ...domicilio.telefonos.map((tel) => {
            if(tel.fijo === phone.fijo){
              return {...tel, standard: tel.numeroCompleto === phone.numeroCompleto}
            } else {
              return {...tel}
            }
          }),
          {...phone, standard: true}
        ]
      })
    } else {
      setPhone(phone, phoneToAction);
    };
  };
  

  return (
    <ModuleModal
      isOpen={isOpen}
      closeModal={() => {
        setIsOpen(false);
      }}
      title="Teléfonos"
    >
      <IonContent>
        <IonList className="ion-no-padding">
          <IonListHeader color="light">
            <IonLabel color="tertiary">Teléfonos fijos</IonLabel>
          </IonListHeader>
          {domicilio.telefonos.map((tel, i) => {
            return (
              tel.fijo && (
                <IonItem
                  detail={false}
                  key={i}
                  lines="none"
                  button
                  onClick={() => {
                    setPhoneToAction(tel);
                    setShowActionPhone(true);
                  }}
                >
                  <IonIcon icon={callOutline} slot="start" color="primary" className={styles.telIcon} />
                  <IonLabel>
                    {tel.codArea} - {tel.numero}{' '}
                    {tel.interno && tel.interno !== ''
                      ? `- ${tel.interno}`
                      : ''}
                  </IonLabel>
                  <IonIcon slot="end" icon={ellipsisVertical}/>
                  <IonIcon
                    icon={checkmarkCircleSharp}
                    slot="end"
                    color={tel.standard ? 'primary' : 'medium'}
                    className={styles.checkIcon}
                  />
                </IonItem>
              )
            );
          })}
        </IonList>

        <IonListHeader color="light" className="ion-margin-top">
          <IonLabel color="tertiary">Teléfonos móviles</IonLabel>
        </IonListHeader>
        <IonRow>
          <IonCol size="12" className="ion-no-padding">
            {domicilio.telefonos.map((tel, i) => {
              return (
                !tel.fijo && (
                  <IonItem
                    detail={false}
                    key={i}
                    lines="none"
                    button
                    onClick={() => {
                      setPhoneToAction(tel);
                      setShowActionPhone(true);
                    }}
                  >
                    <IonIcon
                      icon={phonePortraitOutline}
                      slot="start"
                      color="primary"
                    />
                    <IonLabel>
                      {tel.codArea} - {tel.numero}{' '}
                      {tel.interno && tel.interno !== ''
                        ? `- ${tel.interno}`
                        : ''}
                    </IonLabel>
                    <IonIcon slot="end" icon={ellipsisVertical}/>
                    <IonIcon
                      icon={checkmarkCircleSharp}
                      slot="end"
                      color={tel.standard ? 'primary' : 'medium'}
                    />
                  </IonItem>
                )
              );
            })}
          </IonCol>
        </IonRow>
        <IonChip color="primary" className={styles.mensajeMaxTels}>
          <IonIcon icon={alertCircle} color="tertiary"/>
          <IonLabel>
            Podés adicionar hasta un máximo de cinco números fijos y cinco móviles
          </IonLabel>
        </IonChip>

        {(telefonosLimitNotReached(true) || telefonosLimitNotReached(false)) && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton>
              <IonIcon icon={add} />
            </IonFabButton>
            <IonFabList side="top">
              {telefonosLimitNotReached(true) && (
                <IonFabButton
                  button-label="Teléfono Fijo"
                  color="primary"
                  size='small'
                  onClick={() => {
                    setPhoneToAction(undefined);
                    setShowNewPhoneFijo(true);
                  }}
                >
                  <IonIcon icon={callOutline} color='light' />
                </IonFabButton>
              )}
              {telefonosLimitNotReached(false) && (
                  <IonFabButton
                    button-label="Teléfono Móvil"
                    color="primary"
                    onClick={() => {
                      setPhoneToAction(undefined);
                      setShowNewPhoneMovil(true);
                    } }
                  >
                    <IonIcon icon={phonePortraitOutline} color='light' />
                  </IonFabButton>
              )}
            </IonFabList>
          </IonFab>
        )}
      </IonContent>
      {showNewPhoneFijo && (
        <PhoneInputModal          
          type={type}
          editPhone={phoneToAction}
          isOpen={showNewPhoneFijo}
          setPhone={(data) => {
            setShowNewPhoneFijo(false);
            editOrAddTelefono(data);
          }}
          isFijo={true}
          onDismiss={() => setShowNewPhoneFijo(false)}
        />
      )}
      {showNewPhoneMovil && (
        <PhoneInputModal
          type={type}
          editPhone={phoneToAction}
          isOpen={showNewPhoneMovil}
          setPhone={(data) => {
            setShowNewPhoneMovil(false);
            editOrAddTelefono(data);
          }}
          onDismiss={() => setShowNewPhoneMovil(false)}
          isFijo={false}
        />
      )}
      <IonActionSheet
        mode='md'
        isOpen={showActionPhone}
        onDidDismiss={() => setShowActionPhone(false)}
        cssClass="my-custom-class"
        buttons={getButtonsAction()}
      />
    </ModuleModal>
  );
};

export default PhonesModal;