import { IonFooter } from '@ionic/react';
import React from 'react';
import styles from './TramiteFooter.module.scss';

export interface Props extends React.ComponentPropsWithoutRef<typeof IonFooter> {
  children: React.ReactNode;
  showBorder?: boolean;
  gradientColor?: 'light' | 'white' | '';
}

const TramiteFooter: React.FC<Props> = ({children, showBorder, gradientColor = '', ...rest}) => {
  return (
    <IonFooter 
      mode="md" 
      className={`${styles.tramiteFooter} ${!showBorder && 'ion-no-border'} ${styles[`gradient-${gradientColor}`]}`} {...rest}>
      {children}
    </IonFooter>
  );
};

export default TramiteFooter;