import { IonContent, IonFooter, IonPage } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import AlertPopup from "../../../shared/components/popups/alerts/alert/AlertPopup";
import Footer from "../../../shared/components/layout/footer/Footer";
import Header from "../../../shared/components/layout/header/Header";
import Loading from "../../../shared/components/popups/loading/Loading";
import StandardModal from "../../../shared/components/popups/modals/standard-modal/StandardModal";
import TramiteHomeMenu from "../../../shared/components/tramite-home-menu/TramiteHomeMenu";
import { isAndroid, isWeb } from "../../../shared/utils/utils";
import { RootState } from "../../../store";
import { fetchReintegros, setNuevoReintegro } from "../../../store/reintegros/ReintegrosActions";
import { CuentaCreditoDataType, ReintegroDataType, TipoPracticaDataType } from "../../../store/reintegros/types/ReintegrosData";
import { routes } from "./router/routes";
import { NO_COMMERCIAL_MESSAGE, ERROR_MESSAGE_CUENTA_CREDITO, ERROR_MESSAGE_SALDO_PENDIENTE } from '../../../shared/globalConstants';
import analyticsService from "../../../services/analytics/analytics";
import { routes as homeRoutes } from '../../home/router/routes';
import { SetStepperNavigation } from "../../../store/ui/uiActions";
import Animations from "../../../shared/components/animations/Animations";
import useHttpErrorHandler from "../../../hooks/useHttpErrorHandler";


const ReintegrosPage: React.FC = () => {

  const { listaTipoPracticas, cuentaCredito, correoElectronico, aptoServicio }: { listaTipoPracticas?: TipoPracticaDataType[], cuentaCredito?: CuentaCreditoDataType, correoElectronico: string, aptoServicio: boolean }
    = useSelector((state: RootState) => state.reintegros.reintegros || ({ listaTipoPracticas: undefined, cuentaCredito: undefined, correoElectronico: '', aptoServicio: undefined }));
  const isLoading = useSelector((state: RootState) => state.reintegros.isLoading);
  const isFirstRender = useRef<boolean>(true);
  const { user } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [showCuentaCreditoModal, setShowCuentaCreditoModal] = useState<boolean>(false);
  const [showSaldoPendienteModal, setShowSaldoPendienteModal] = useState<boolean>(false);
  const [showGenericErrorModal, setShowGenericErrorModal] = useState<boolean>(false);
  const [showNoCommercialAlert, setNoCommercialAlert] = useState<boolean>(false);
  const history = useHistory();
  useHttpErrorHandler({tramite: 'REINTEGROS'});


  useEffect(() => {
    if (isFirstRender.current){
      dispatch(SetStepperNavigation(Animations.fadeIn));
    };

    if (!user.reintegrosHabilitado) {
      setNoCommercialAlert(!user?.reintegrosHabilitado);
      analyticsService.trackEvent('formularios', 'reintegros', `error - ${NO_COMMERCIAL_MESSAGE}`, routes.root);
      return;
    }

    if (isFirstRender.current) {
      dispatch(fetchReintegros());
      isFirstRender.current = false;
      return;
    }

    if (isLoading) return;

    if (cuentaCredito?.tieneCuentaCredito === false) {
      setShowCuentaCreditoModal(true);
      analyticsService.trackEvent('formularios', 'reintegros', `error - ${ERROR_MESSAGE_CUENTA_CREDITO}`, routes.root);
    } else if (aptoServicio === false) {
      setShowSaldoPendienteModal(true);
      analyticsService.trackEvent('formularios', 'reintegros', `error - ${ERROR_MESSAGE_SALDO_PENDIENTE}`, routes.root);
    } else if (!listaTipoPracticas) {
      console.log('66')
      setShowGenericErrorModal(true);
    }
  }, [user, isLoading, aptoServicio, cuentaCredito, listaTipoPracticas, dispatch]);


  const selectTramite = (practica: TipoPracticaDataType) => {
    const nuevoReintegro: ReintegroDataType = {
      tipoPractica: practica,
      comprobantePrestacion: null,
      comprobanteFacturacion: null,
      tipoDocumento: null,
      numeroDocumento: '',
      tipoComprobante: null,
      integrante: null,
      profesional: null,
      facturado: null,
      nroComprobantePrefijo: '',
      nroComprobante: '',
      fechaEmision: '',
      cae: '',
      fechaVencimientoCae: '',
      importe: '',
      correoElectronico: correoElectronico,
      emailChanged: false,
      isNative: false
    }
    dispatch(setNuevoReintegro(nuevoReintegro));
    analyticsService.trackPageView(routes.root, JSON.stringify(practica));
    history.push((practica.tieneFactura && practica.tieneTicket) ? routes.seleccionComprobante : routes.seleccionSocio);
  }


const configButtons = {
  titulo: 'Tenemos un problema',
  message: `En este momento el trámite no está disponible.
  Podés intentarlo más tarde o realizarlo desde la app OSDE.`,
  buttons: [
    {
      text: 'Entendido',
      handler: () => {
        history.push(homeRoutes.root)
      },
    },

  ],
}

if (isAndroid()){
    const buttonDeepLink = { 
        text: 'App OSDE',
        handler: () => {
          window.location.href = "ads://login";
        },
      }
      configButtons.message = `En este momento el trámite no está disponible.
      Podés intentarlo más tarde o realizarlo desde nuestra aplicación`
      configButtons.buttons.unshift(buttonDeepLink)
}

  return (
    <IonPage>
      <Header showBackButton={true} />
      <IonContent>
        {user.reintegrosHabilitado && cuentaCredito?.tieneCuentaCredito && listaTipoPracticas &&
          <TramiteHomeMenu
            title="Reintegros online"
            subtitle="Seleccioná el tipo de práctica"
            list={listaTipoPracticas.map((practica) => ({
              nombre: practica.nombre,
              onClick: () => selectTramite(practica),
            }))}
          />
        }
      </IonContent>
      <IonFooter className="ion-no-border">
        {isWeb() && <Footer />}
      </IonFooter>

      <Loading isOpen={isLoading} message="Cargando..." />

      <AlertPopup
        isOpen={showNoCommercialAlert}
        title="Aviso"
        message={NO_COMMERCIAL_MESSAGE}
        cssClass=""
        buttons={[
          {
            text: 'CERRAR',
            handler: () => history.push(homeRoutes.root)
          }
        ]}
      />

      <AlertPopup
        isOpen={showGenericErrorModal}
        title={configButtons.titulo}
        message={configButtons.message}
        buttons={configButtons.buttons}
      />

      <StandardModal
        isOpen={showCuentaCreditoModal}
        title="Trámites"
        content={
          ERROR_MESSAGE_CUENTA_CREDITO
        }
        handler={() => {
          setShowCuentaCreditoModal(false);
          history.push(homeRoutes.root);
        }}
      />

      <StandardModal
        isOpen={showSaldoPendienteModal}
        title="Trámites"
        content={
          ERROR_MESSAGE_SALDO_PENDIENTE
        }
        handler={() => {
          setShowSaldoPendienteModal(false);
          history.push(homeRoutes.root);
        }}
      />
    </IonPage>
  );
};

export default ReintegrosPage;