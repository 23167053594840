import { AnimatePresence } from "framer-motion";
import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import GestionesOnlinePage from "./modules/home/gestiones-online/GestionesOnlinePage";
import HomePage from "./modules/home/HomePage";
import { routes as homeRoutes } from "./modules/home/router/routes";
import { authRoutes } from "./modules/auth/router/routes";
import Contactanos from "./modules/home/contactanos/ContactanosPage";
import AutorizacionesRoutes from "./modules/tramites/autorizaciones/router/routes";
import DatosContactoRoutes from "./modules/tramites/datos-contacto/router/routes";
import EmpadronamientoRoutes from "./modules/tramites/empadronamiento/router/routes";
import FacturacionRoutes from "./modules/tramites/facturacion/router/routes";
import PagoEnLineaRoutes from "./modules/tramites/pago-en-linea/router/routes";
import ReintegrosRoutes from "./modules/tramites/reintegros/router/routes";
import RouteTransitionWrapper from "./shared/components/animations/routeTransitionWrapper/RouteTransitionWrapper";
import Spinner from "./shared/components/popups/spinner/Spinner";
import PrivateRoute from "./shared/components/privateRoute/PrivateRoute";
import LoginPage from "./modules/auth/login-page/LoginPage";
import AuthPage from "./modules/auth/auth-page/AuthPage";
import AuthError from "./modules/auth/auth-error/AuthError";
import LogoutPage from "./modules/auth/logout-page/LogoutPage";
import Animations from "./shared/components/animations/Animations";
import Ping from "./modules/home/ping/Ping";
import { useGoogleAnalytics } from "./hooks/useGoogleAnalytics";
import { AppMinimize } from '@ionic-native/app-minimize';
import AuthPageLegacy from "./modules/auth/auth-page-legacy/AuthPageLegacy";
import TimeoutPage from "./modules/auth/timeout-page/TimeoutPage";
import StartLogin from "./modules/auth/StartLogin";

const Ayuda = lazy(() => import('./modules/home/ayuda/AyudaPage'));

const Routes = () => {
    const location = useLocation();
    const history = useHistory();
    useGoogleAnalytics();
    
   useEffect(() => {
         document.addEventListener('ionBackButton', (event: any) => {   
           event.detail.register(-1, () => {
               if (location.pathname === homeRoutes.root || location.pathname === "/") {
                   AppMinimize.minimize(); 
               }         
           }); 
       });    
   }, [history, location.pathname]);
   
    useEffect(() => {
        if(location.hash.indexOf('token') !== -1 || location.hash.indexOf('error') !== -1){
            history.replace(location.hash.replace('#/', ''))
        };
    }, [location, history]);

    return (
        <Suspense fallback={<Spinner />}>
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.key}>

                    {/* Private Routes */}
                    <Route path={homeRoutes.gestionesOnline} component={GestionesOnline} />

                    {/* Public Routes */}
                    <Route exact path={authRoutes.startLogin} component={StartLogin} />
                    <Route path={`${authRoutes.login}/:path?/:subpath?`} component={LoginPage} />
                    <Route path={authRoutes.logout} exact component={LogoutPage} />
                    <Route path={authRoutes.timeout} exact component={TimeoutPage} />
                    <Route path={homeRoutes.root} exact component={HomePage} />
                    <Route path={homeRoutes.ping} exact component={Ping} />
                    <Route path="/" exact render={() => <Redirect to={homeRoutes.root} />} />
                    <RouteTransitionWrapper variants={Animations.fadeIn} >
                        <Route path={`${authRoutes.token}/:token?`} exact component={AuthPage} />
                        <Route path={`${authRoutes.tokenLegacy}/:token?`} exact component={AuthPageLegacy} />
                        <Route path={authRoutes.error} exact component={AuthError} />
                        <Route path={homeRoutes.contactanos} exact component={Contactanos} />
                        <Route path={homeRoutes.ayuda} exact component={Ayuda} />
                    </RouteTransitionWrapper>

                </Switch>
            </AnimatePresence>
        </Suspense>
    )
};

const GestionesOnline = ({ match }) => (
    <Switch>
        <PrivateRoute key="gestiones" path={match.url} animation={Animations.fadeIn} exact component={GestionesOnlinePage} />
        {AutorizacionesRoutes}
        {DatosContactoRoutes}
        {EmpadronamientoRoutes}
        {PagoEnLineaRoutes}
        {ReintegrosRoutes}
        {FacturacionRoutes}
    </Switch>
);

export default Routes;