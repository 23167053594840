import SliderMocks from "./mocks/SliderMock";
import environmentService from "../../../services/environment/environmentService";
import { isProduction } from "../../../shared/utils/utils";
import AxiosInstanceTAS from "../../../services/AxiosServiceStaticContentTAS";
import AxiosInstance from "../../../services/AxiosServiceStaticContent";

const baseUrlService = "/data";
const baseUrlServiceTAS = "?select=fields.imagen,fields.orden,fields.titulo&content_type=slice&access_token=yLoP4qvUx8Q1D7UJVP_HvIToHDd0GPE_iygvklCyuPs";

enum slideTypes {
  BANNER = "banner",
  DESTACADO = "destacado",
  SLIDE = "slider"
};

export interface bannerSlide {
  btnTxt: string;
  copete: string;
  despublicacion: string;
  imgUrl: string;
  link: string;
  publicacion: string;
  publico: boolean;
  tipo: string;
  titulo: string;
  urlMobile: string;
  visibleMobile: boolean;
};

export interface slideTAS {
  id: string,
  titulo: string,
  orden: string,
  url: '',
};

export interface strBannerSlide {
  banners: bannerSlide[];
  slides: bannerSlide[];
};

export interface HomeService {
  getSlidesTAS: () => Promise<slideTAS[]>;
  getSlidesNative: () => Promise<bannerSlide[]>;
  getBannersSlidesWebMobile: () => Promise<strBannerSlide>;
};


/**
Función que se utiliza para filtrar los sliders según la fecha de despublicación
Formato de fecha esperado (devuelvo por el server): dd-mm-aaaa
*/
const _unpublish = (item: { despublicacion: string }) => {
  const parts = item.despublicacion.split("-");
  const formatDate = new Date(+parts[2], +parts[1] - 1, +parts[0]);
  return formatDate > new Date();
};

const _filterByType = (type: slideTypes) => ((item: { tipo: string }) => ( item.tipo === type));

const homeService: HomeService = {
  getBannersSlidesWebMobile: async () => {
    let sliderResponse: {slider: bannerSlide[]};
    try {
      const resp = await AxiosInstance.get(`${baseUrlService}/sliderhomeMobile.js`);
      sliderResponse = await resp.data;
      sliderResponse.slider.forEach(slide => {
        slide.imgUrl = `${environmentService.getCurrent().webBaseUrl}/${slide.imgUrl}` 
      });
    } catch (error) {
      sliderResponse = !isProduction() ? SliderMocks.sliderWebMobile : undefined;
    };

    let content: bannerSlide[] = [];
    let banners: bannerSlide[] = [];
    let slides: bannerSlide[] = [];

    if (sliderResponse?.slider) {
      content = sliderResponse.slider.filter(_unpublish);
      banners = sliderResponse.slider.filter(_filterByType(slideTypes.BANNER));
      banners = banners.concat(content.filter(_filterByType(slideTypes.DESTACADO)));
      slides = content.filter(_filterByType(slideTypes.SLIDE));
    }

    const out: strBannerSlide = {
      banners: banners,
      slides: slides
    };
    return out;
  },

  getSlidesTAS: async () => {
    try {
      const response = await AxiosInstanceTAS.get(baseUrlServiceTAS, {});
      const slides = response.data.items.map((item: any, index: number) => {
        const slide = {
          id: index,
          titulo: item.fields.titulo,
          orden: item.fields.orden,
          url: '',
        };

        slide.url = 'https:' + response.data.includes.Asset.find((asset: any) =>
          (asset.sys.id === item.fields.imagen.sys.id))?.fields.file.url;

        return slide;
      });
      return slides;

    } catch (error) {
      console.log(error);
    }
  },
  
  getSlidesNative: async () => {
    let slidersResponse = null;
    try {
      const resp = await AxiosInstance.get(`${baseUrlService}/sliderhomeapp.js`);
      slidersResponse = resp.data;
    } catch (error) {
      slidersResponse = SliderMocks.sliderNative;
    };

    let slidersFilter = [];
    if (slidersResponse && slidersResponse.slider) {
      slidersFilter = slidersResponse.slider.filter(_unpublish).filter(_filterByType(slideTypes.SLIDE));
    };

    return slidersFilter;
  },
};

export default homeService;