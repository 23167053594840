/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const TerminosReintegros: React.FC = () => {

  return (

    <div style={{
      textAlign: "justify",
      lineHeight: 'normal',
    }}>

      Para acceder al sistema de reintegros online deberás estar registrado en la web de OSDE o en la app OSDE Móvil. 
      Además, es imprescindible tener vinculada una cuenta bancaria para la acreditación de reintegros. Si aún no la 
      adheriste, podés hacerlo a través de la sección Gestiones Online <b> &gt; Gestionar reintegros</b> &gt; 
      Cuenta de acreditación de nuestra web. <br /><br /> 
      
      Este sistema puede ser utilizado exclusivamente para reintegros por prestaciones ambulatorias y en concepto de: <br /><br />
      
      <ul style={{listStyleType: 'none', padding: '0', margin:'0'}} >
        <li>■ Consultas médicas de cualquier especialidad,</li>
        <li>■ Psicopatología,</li>
        <li>■ Kinesiología, (*)</li>
        <li>■ Fonoaudiología, (*)</li>
        <li>■ Nutrición, (**).</li>
        <li>■ Odontología, (***).</li>
        <li>■ Elementos de ortopedia, (*)</li>
        <li>■ Medicamentos </li>
      </ul> <br />
      
      (*) Se deberá adjuntar pedido médico correspondiente a los comprobantes presentados. <br />
      (**) Se podrán presentar facturas de médicos especialistas o de licenciados en nutrición que descontarán de diferentes 
      topes de acuerdo a la especialidad. Solo se aceptará una consulta por factura. <br />
      (***) Se otorgará en concordancia con las recomendaciones del Ministerio de Salud en el contexto del aislamiento social 
      preventivo obligatorio y aplicará para tratamientos previos a su inicio o a prácticas de urgencia e impostergables para 
      mantener la buena salud dental del paciente. <br /><br /> <br />
      
      
      No se deberán adjuntar comprobantes de pago que requieran documentación médica adicional (historia clínica, protocolo, etc.). <br /><br />
      
      Todos los reintegros solicitados serán reconocidos a los valores que se encuentran dispuestos en las <b>
      Normas generales para reintegros</b> de tu cartilla médica. <br /><br />
      
      <b>Para la solicitud de reintegros online. </b><br /><br />
      
      <ul style={{listStyleType: 'none', padding: '0', margin:'0'}}>
        <li> <b>1.</b> Es necesario que el comprobante de pago sea una factura electrónica y que 
          cuente con el detalle de la matrícula y especialidad del efector. </li>
        <li> <b>2.</b> Si en un mismo comprobante de pago el profesional incluye más de una práctica, 
          se deberá solicitar el desglose del importe correspondiente a cada una de ellas. </li>
        <li> <b>3.</b> No se efectuarán reintegros por prestaciones abonadas en forma parcial, es 
          exigible la condición de “Pagado” en el comprobante. </li>
        <li> <b>4.</b> No se aceptarán reintegros cuando la fecha del comprobante de pago sea 
          posterior a la fecha de presentación en OSDE. </li>
        <li> <b>5.</b> No se efectuarán reintegros por prestaciones abonadas con anterioridad a la 
          fecha de su realización. </li>
        <li> <b>6.</b> OSDE podrá solicitar información adicional a fin de evaluar las prestaciones 
          cuyo reintegro solicita. El depósito del reintegro se efectivizará en tu cuenta bancaria dentro de los 7 días hábiles 
          de realizada la presentación, salvo que existiera alguna anomalía en la documentación enviada. En ese caso nos comunicaremos con vos. </li>
      </ul> <br />
      
      Recordá que a través tu cartilla médica es posible acceder a toda la información referente a los reintegros previstos en tu plan 
      de cobertura. Asimismo, podés comunicarte con nuestro Centro de Atención Telefónica al 0810-555-6733, escribirnos por WhatsApp al 
      +54911-4872-9000 o ingresar a la sección Servicios y Cobertura de nuestra web para obtener más información.

    </div>
  );
};

export default TerminosReintegros;
