import { EmpadronamientoResponse } from '../../types/EmpadronamientoTypes';

export const getFormMock: EmpadronamientoResponse = {
  antecedentes: [
    {
      codigo: 1,
      descripcion: 'Coronariopatías',
    },
    {
      codigo: 2,
      descripcion: 'Accidente Cerebrovascular (ACV)',
    },
    {
      codigo: 3,
      descripcion: 'Tromboembolismo Pulmonar (TeP)',
    },
    {
      codigo: 4,
      descripcion: 'Tumor de mama',
    },
    {
      codigo: 5,
      descripcion: 'Utilizo un dispositivo intrauterino (DIU/SIU)',
    },
    {
      codigo: 6,
      descripcion: 'Utilizo un implante subdérmico',
    },
  ],
  aptoServicio: true,
  esDeudor: false,
  listaSocias: [
    {
      datosSocia: {
        contrato: '232472643',
        orden: '05',
        nombreCompleto: 'ANGELINA VIANI',
        esBeneficiario: true,
      },
      statusSocia: [
        {
          codigo: 203,
          descripcion: 'Empadronable',
          detalle: '',
        },
      ],
    },
    {
      datosSocia: {
        contrato: '232472644',
        orden: '06',
        nombreCompleto: 'PEPA VIANI',
        esBeneficiario: true,
      },
      statusSocia: [
        {
          codigo: 203,
          descripcion: 'Empadronable',
          detalle: '',
        },
      ],
    },
    {
      datosSocia: {
        contrato: '5764819302175468',
        orden: '07',
        nombreCompleto: 'JUANA DE ARCO',
        esBeneficiario: true,
      },
      statusSocia: [
        {
          codigo: 214,
          descripcion: 'No Empadronable',
          detalle: '',
        },
      ],
    },
    {
      datosSocia: {
        contrato: '7564598763',
        orden: '08',
        nombreCompleto: 'JUANA AZURDUY',
        esBeneficiario: true,
      },
      statusSocia: [
        {
          codigo: 255,
          descripcion: 'No Empadronable',
          detalle: '',
        },
      ],
    },
  ],
  status: {
    codigo: 200,
    descripcion: 'ok',
  },
};

export const postformMock: any = {
  mensaje: {
    codigo: 200,
    descripcion: 'ok',
  },
  numeroTramite: 124086,
  pdfFarmacia: null,
};
