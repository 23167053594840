import { IonButton, IonCol, IonInput, IonItem, IonLabel, IonListHeader, IonRow } from '@ionic/react';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useEffect } from 'react';
import { DomicilioDataType, domicilioType, Phone } from '../../../../../../../store/datos-contacto/types/DatosContactoData';
import PhoneInputModal from '../phone-input-modal/PhoneInputModal';
import PhonesModal from '../phones-modal/PhonesModal';
import styles from './EditPhonesInfo.module.scss';

interface EditPhonesInfoModel {
  type: domicilioType;
  domicilio: DomicilioDataType;
  setIsPhoneInputModalOpen: Dispatch<SetStateAction<boolean>>;
  setDomicilio: Dispatch<SetStateAction<DomicilioDataType>>;
}

const EditPhonesInfo: React.FC<EditPhonesInfoModel> = ({ type, domicilio, setIsPhoneInputModalOpen, setDomicilio }) => {

  const [showTelefonosModal, setShowTelefonosModal] = useState(false);
  const [editPhone, setEditPhone] = useState<Phone>(null);

  const setPhone = (newTel: Phone, oldtel: Phone = editPhone) => {
    if (oldtel.standard) {
      setDomicilio({
        ...domicilio,
        telefonoDefault: oldtel.fijo ? { ...newTel, standard: true } : domicilio?.telefonoDefault,
        telefonoDefaultChanged: oldtel.fijo ? true : domicilio?.telefonoDefaultChanged,
        telefonoMovilDefault: !oldtel.fijo ? { ...newTel, standard: true } : domicilio?.telefonoMovilDefault,
        telefonoMovilDefaultChanged: !oldtel.fijo ? true : domicilio?.telefonoDefaultChanged,
        telefonos: [
          ...domicilio.telefonos.filter(
            (tel) =>
              tel.numeroCompleto !==
              oldtel.numeroCompleto
          ),
          newTel
        ]
      })
    } else {
      setDomicilio({
        ...domicilio,
        telefonos: [
          ...domicilio.telefonos.filter((tel) => tel.numeroCompleto !== oldtel.numeroCompleto),
          newTel
        ]
      })
    }
    setEditPhone(null);
  };

  useEffect(() => {
    setIsPhoneInputModalOpen(showTelefonosModal || !!editPhone);
  }, [showTelefonosModal, editPhone, setIsPhoneInputModalOpen]);

  return (
    <>
      {editPhone && (
        <PhoneInputModal
          onDismiss={() => setEditPhone(null)}
          type={type}
          isOpen={!!editPhone} // Deberia ser un boolean, esta simplificacion puede traer problemas
          setPhone={setPhone}
          isFijo={editPhone?.fijo}
          editPhone={editPhone}
        />
      )}
      {domicilio.telefonoDefault && (
        <>
          <IonListHeader color="light">
            <IonLabel color="tertiary">Teléfono Fijo</IonLabel>
            <IonButton
              color='primary'
              fill='outline'
              className={styles.verTodosButton}
              onClick={() => setShowTelefonosModal(true)}
            >
              Ver todos
            </IonButton>
          </IonListHeader>
          <IonRow onClick={() => setEditPhone(domicilio.telefonoDefault)}>
            <IonCol size="3">
              <IonItem lines="full">
                <IonLabel position="floating" color="primary">
                  Car.
                </IonLabel>
                <IonInput
                  readonly
                  value={domicilio.telefonoDefault.codArea}
                />
              </IonItem>
            </IonCol>
            <IonCol size="6">
              <IonItem lines="full">
                <IonLabel position="floating" color="primary">
                  Número
                </IonLabel>
                <IonInput
                  readonly
                  value={domicilio.telefonoDefault.numero}
                />
              </IonItem>
            </IonCol>
            <IonCol size="3">
              <IonItem lines="full">
                <IonLabel position="floating" color="primary">
                  Int.
                </IonLabel>
                <IonInput
                  readonly
                  value={domicilio.telefonoDefault.interno}
                />
              </IonItem>
            </IonCol>
          </IonRow>

        </>
      )}
      {domicilio.telefonoMovilDefault && (
        <>
          <IonListHeader color="light">
            <IonLabel color="tertiary">Teléfono Móvil</IonLabel>
            <IonButton
              color='primary'
              fill='outline'
              className={styles.verTodosButton}
              onClick={() => setShowTelefonosModal(true)}
            >
              Ver todos
            </IonButton>
          </IonListHeader>
          <IonRow onClick={() => setEditPhone(domicilio.telefonoMovilDefault)}>
            <IonCol size="3">
              <IonItem lines="full">
                <IonLabel position="floating" color="primary">
                  Car.
                </IonLabel>
                <IonInput
                  readonly
                  value={domicilio.telefonoMovilDefault?.codArea}
                />
              </IonItem>
            </IonCol>
            <IonCol size="9">
              <IonItem lines="full">
                <IonLabel position="floating" color="primary">
                  Número
                </IonLabel>
                <IonInput
                  readonly
                  value={domicilio.telefonoMovilDefault?.numero}
                />
              </IonItem>
            </IonCol>
          </IonRow>
        </>
      )}
      <PhonesModal
        type={type}
        isOpen={showTelefonosModal}
        setIsOpen={setShowTelefonosModal}
        setDomicilio={setDomicilio}
        setPhone={setPhone}
        domicilio={domicilio}
      />
    </>
  );
};

export default EditPhonesInfo;