import React, { useEffect, useRef } from 'react';
import { IonPage, IonContent, IonFooter } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../shared/components/layout/header/Header';
import {
  fetchAutorizaciones,
  setSteps,
  setTipoTramite,
  unSetTramite,
} from '../../../store/autorizaciones/AutorizacionesActions';
import TramiteHomeMenu from '../../../shared/components/tramite-home-menu/TramiteHomeMenu';
import { useHistory } from 'react-router';
import Loading from '../../../shared/components/popups/loading/Loading';
import { RootState } from '../../../store';
import AutorizacionesTramites from './helpers/getStepsTramite';
import {AutorizacionesTramites as tramite} from './helpers/TramitesEnum';
import { isWeb } from '../../../shared/utils/utils';
import Footer from '../../../shared/components/layout/footer/Footer';
import { routes as AutorizacionesRoutes } from './router/routes';
import { SetStepperNavigation } from '../../../store/ui/uiActions';
import Animations from '../../../shared/components/animations/Animations';
import useHttpErrorHandler from '../../../hooks/useHttpErrorHandler';

const AutorizacionesPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useHttpErrorHandler();
  const isLoading = useSelector((state: RootState) => state.reintegros.isLoading);
  const isFirstRender = useRef(true);
  const {
    autorizaciones: { obtainedData },
    user: {
      user: { contrato },
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      dispatch(SetStepperNavigation(Animations.fadeIn))
    }
  }, [dispatch, obtainedData]);

  var listaAutorizaciones = [
    {
      nombre: 'Internación - Cirugía',
      onClick: () => {
        dispatch(setTipoTramite(tramite.InternacionCirugia));
        const res = AutorizacionesTramites(0);
        dispatch(setSteps(res));
        history.push(res.editOrder[0].path);
      },
    },
    {
      nombre: 'Medicamentos',
      onClick: () => {
        history.push(AutorizacionesRoutes.stepInformacionMedicamentos)
      },
    },
    {
      nombre: 'Prácticas o estudios',
      onClick: () => {
        dispatch(setTipoTramite(tramite.PracticasEstudios));
        const res = AutorizacionesTramites(2);
        dispatch(setSteps(res));
        history.push(res.editOrder[0].path);
      },
    },
    {
      nombre: 'Traslados programados',
      onClick: () => {
        dispatch(setTipoTramite(tramite.TrasladosProgramados));
        const res = AutorizacionesTramites(3);
        dispatch(setSteps(res));
        history.push(res.editOrder[0].path);
      },
    },
  ];

  useEffect(() => {
    dispatch(unSetTramite());
    dispatch(fetchAutorizaciones(contrato.nroActividad));
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPage>
      <Header showBackButton={true}></Header>
      <IonContent>
        <TramiteHomeMenu
          title="Autorizaciones"
          subtitle="Seleccioná la autorización a realizar"
          list={listaAutorizaciones}
        />
      </IonContent>
      {isWeb() && (
        <IonFooter>
          <Footer />
        </IonFooter>
      )}
      <Loading isOpen={isLoading} message="Cargando..." />
    </IonPage>
  );
};

export default AutorizacionesPage;