import { IonCol, IonContent, IonFooter, IonImg, IonLabel, IonModal, IonRow, IonTitle } from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import { RootState } from '../../../../../store';
import { setDatosContactoStore } from '../../../../../store/datos-contacto/datosContactoActions';
import { routes } from '../../../../home/router/routes';
import iconoTramite from '../../assets/img/mis-datos-azul.svg';
import relojArena from '../../assets/img/reloj-de-arena.svg';
import styles from './PendingOrIncompleteProcessModal.module.scss';


const PendingOrIncompleteProcessModal: React.FC = () => {
  const datosContactoState = useSelector((state: RootState) => state.datosContacto);
  const { incompleteProcess, pendingProcess, idTramite, showIncompleteProcessModal } = datosContactoState;
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <IonModal isOpen={incompleteProcess && showIncompleteProcessModal} cssClass={styles.modal}>
        <IonContent>
          <IonRow>
            <IonCol size="12">
              <IonImg src={iconoTramite} />
            </IonCol>
            <IonCol size="12">
              <IonTitle className="ion-text-center" color="primary">
                Tenés una solicitud <br />
                pendiente de envío
              </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" className={[styles.colMargin, "ion-text-center"].join(' ')}>
              <IonLabel className="ion-text-center">
                Aquí podras modificarla o confirmarla si los datos ingresados son
                correctos.
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonContent>
        <IonFooter className="ion-no-border">
          <NavigationButton onClick={() => dispatch(setDatosContactoStore({ ...datosContactoState, showIncompleteProcessModal: false }))}>
            Ingresar
          </NavigationButton>
        </IonFooter>
      </IonModal>

      <IonModal isOpen={pendingProcess} cssClass={styles.modal}>
        <IonContent>
          <IonRow>
            <IonCol size="12">
              <IonImg src={relojArena} />
            </IonCol>
            <IonCol size="12" className="ion-text-center">
              <IonLabel className="ion-text-center" color="primary">
                Tenés el tramite {idTramite} en proceso <br /> que debe
                terminar de gestionarse <br /> para poder avanzar con la nueva
                solicitud. <br /> Podés consultar su estado en la sección Mis
                Trámites en la web de OSDE.
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonContent>
        <IonFooter className='ion-no-border'>
          <NavigationButton onClick={() => history.push(routes.gestionesOnline)}>
            Aceptar
          </NavigationButton>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default PendingOrIncompleteProcessModal;