import { IonPage } from '@ionic/react';
import React from 'react';
import Header from '../../shared/components/layout/header/Header';
import { useTramitesState } from '../../hooks/useTramitesState';
import HomeWeb from './components/home-web/HomeWeb'


const HomePage: React.FC = () => {
  useTramitesState(true);

  return (
    <IonPage>
      <Header showBackButton={false} />
       <HomeWeb />
    </IonPage>
  );
};

export default HomePage;