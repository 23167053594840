import { IonModal, IonIcon, IonList, IonItem, IonLabel } from '@ionic/react'
import './AttachModal.scss'
import React from 'react'
import { camera, imagesOutline, documentOutline } from 'ionicons/icons';

interface AttachModalProps {
    isOpen: boolean
    handlerCamera: any,
    handlerDocument: any,
    handlerImage: any
    onDidDismiss: any
}

const AttachModal: React.FC<AttachModalProps> = ({ isOpen, handlerCamera, handlerDocument, handlerImage, onDidDismiss }) => {

    return (
        <IonModal isOpen={isOpen} cssClass='attachModal' onDidDismiss={onDidDismiss}>
            <IonList className='ion-no-padding'>
                <IonItem button lines='full' onClick={handlerImage}>
                    <IonLabel>Galería</IonLabel>
                    <IonIcon icon={imagesOutline} />
                </IonItem >
                <IonItem button lines='full' onClick={handlerCamera}>
                    <IonLabel>Tomar foto</IonLabel>
                    <IonIcon icon={camera} />
                </IonItem>
                <IonItem button lines='full' onClick={handlerDocument}>
                    <IonLabel>Documento</IonLabel>
                    <IonIcon icon={documentOutline} />
                </IonItem>
                <IonItem button lines='none' onClick={onDidDismiss}>
                    <IonLabel color="danger">Cancelar</IonLabel>
                </IonItem>
            </IonList>
        </IonModal>
    );
}

export default AttachModal;