/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Terminos: React.FC = () => {

  return (

    <div style={{
      textAlign: "justify",
      lineHeight: 'normal',
    }}>

      A través de este trámite podés solicitar la cobertura en determinados
      anticonceptivos al 100% establecida de acuerdo con el Programa Nacional de
    Salud Sexual y Procreación Responsable, Ley N° 25.673.<br /><br />

    Para acceder al trámite, deberás estar registrado en la web de OSDE o en la
    app OSDE Móvil. Se podrán empadronar socias de 12 a 55 años de edad, titulares
    o beneficiarias. Es requisito no poseer deuda en la asociación al momento de
    empadronarse.<br /><br />

    Una vez finalizado el trámite, recibirás un mensaje dentro de las 48 horas, si
    el empadronamiento pudo realizarse en forma exitosa y te informaremos el
    listado de las farmacias disponibles para la adquisición de los
    anticonceptivos.<br /><br />

    Una vez empadronada, deberás presentar el pedido médico, la credencial y tu
    DNI para adquirir los anticonceptivos, de forma gratuita, en las farmacias
    contratadas por OSDE.<br /><br />

    La vigencia del empadronamiento es de 1 (un) año aniversario desde la fecha de
    solicitud. Deberás volver a empadronarte una vez cumplido este período.<br /><br />

    No podrán empadronarse aquellas mujeres que tengan los siguientes antecedentes
    de salud:

      <ul>
        <li> Cardiopatías.</li>
        <li> Accidente cerebrovascular.</li>
        <li> Tromboembolismo.</li>
        <li> Tumor de mamá.</li>
      </ul>
      <br />
    De poseer alguno de los antecedentes de salud detallados anteriormente,
    deberás presentar un justificativo médico en cualquiera de los centros de
    atención para ser evaluado por nuestra Asesoría Médica.
      <br /><br />

    En caso de prescripción de anticonceptivos para menores de 12 años, será
    necesario adjuntar un consentimiento por escrito de uno de sus padres o tutor
    y un justificativo médico para ser evaluado por nuestra Asesoría Médica.
    Asimismo, si la socia es mayor de 55 años, será requisito presentar un
    justificativo médico para su evaluación. Podés acercarnos esta documentación a
    través de nuestra
      <a
      style={{
        color: "black",
        fontWeight: 'bold'
      }}
      > web </a> o en cualquiera de nuestros
      <a
         style={{
          color: "black",
          fontWeight: 'bold'
        }}> Centros de Atención Personalizada </a>.<br /><br />

    Para obtener más información acerca de otros métodos anticonceptivos
    contemplados dentro del Programa Nacional de Salud Sexual y Procreación
    Responsable, podés contactarnos a través de nuestra
      <a  style={{
        color: "black",
        fontWeight: 'bold'
      }}> web </a>,
    comunicarte con nuestro Centro de Atención Telefónica llamando al
    0810-555-6733 o acercarte al
      <a
         style={{
          color: "black",
          fontWeight: 'bold'
        }}
      > Centro de Atención Personalizada </a>
    más próximo a tu domicilio.<br /><br />

    En el marco del Programa de Salud Sexual y Procreación Responsable, OSDE
    informa que las enfermedades de transmisión sexual, entre ellas el virus de
    SIDA, no se previenen con este método. Actuá en prevención para vos y tu
    familia. Ante cualquier duda, sugerimos consultar con tu médico.<br /><br />
    </div>

  );
};

export default Terminos;
