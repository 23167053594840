import React, { useEffect, useState } from 'react'
import { motion, useMotionValue, useTransform } from "framer-motion";

const tickVariants = {
  checked: { pathLength: 1 },
  unchecked: { pathLength: 0},
};

const Check = () => {
  const [isChecked, setIsChecked] = useState(false);
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);

  useEffect(() => {
    setTimeout(() => {
      setIsChecked(true);
    }, 500);
  }, [])

  return (
    <motion.svg
      initial={false}
      animate={isChecked ? "checked" : "unchecked"}
      width="40vw"
      height="40vw"   
      viewBox="0 0 150 150"   
    >
      <motion.circle r="50" cx="50%" cy="50%" fill="none" stroke="var(--ion-color-success)" strokeWidth="5" />

      <motion.path
        d="M -2, 39 L 7.597, 53.211 L 44.542, 30"
        transform="translate(60 28) rotate(-4 150.904 108.687)"
        fill="transparent"
        strokeWidth="8"
        stroke="var(--ion-color-success)"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={tickVariants}
        style={{ pathLength, opacity }}
        custom={isChecked}
      />
    </motion.svg>
  );
};

export default Check;