import reintegroIcon from '../assets/img/reintegro.svg';
import empadronamientoIcon from '../assets/img/empadronamiento.svg';
import autorizacionesIcon from '../assets/img/autorizaciones.svg';
import turnosIcon from '../assets/img/turnos.svg';
import datosIcon from '../assets/img/mis-datos.svg';
import facturacionIcon from '../assets/img/facturacion.svg';
import credencialIcon from '../assets/img/provisoria.svg';
import pagoEnLineaIcon from '../assets/img/pago-en-linea.svg';
import { routes as empadronamientoRoutes } from '../../../tramites/empadronamiento/router/routes';
import { routes as pagoEnLineaRoutes } from '../../../tramites/pago-en-linea/router/routes';
import { routes as autorizacionesRoutes } from '../../../tramites/autorizaciones/router/routes';
import { routes as reintegrosRoutes } from '../../../tramites/reintegros/router/routes';
import { routes as facturacionRoutes} from '../../../tramites/facturacion/router/routes';
import { rootRoute } from '../../../tramites/datos-contacto/router/routes';
import { routes as homeRoutes } from '../../../home/router/routes';
import { GestionesLinks } from '../types/GestionesLink';
import { isWeb } from '../../../../shared/utils/utils';
import { cartillaRoutes } from '../../cartilla/router/routes';


const gestionesOnlineData: GestionesLinks = {
    reintegros: {
        title: 'Solicitar un reintegro',
        url: reintegrosRoutes.root,
        class: '',
        icon: reintegroIcon,
        name: 'reintegros',
        event: 'solicitar un reintegro'
    },
    empadronamiento: {
        title: 'Empadronarme para anticonceptivos',
        url: empadronamientoRoutes.root,
        class: '',
        icon: empadronamientoIcon,
        name: 'empadronamientos',
        event: 'empadronarme para anticonceptivos'
    },
    autorizaciones: {
        title: 'Autorizar una prestación',
        url: autorizacionesRoutes.root,
        class: '',
        icon: autorizacionesIcon,
        name: 'autorizaciones',
        event: 'autorizaciones'
    },
    pagoEnLinea: {
        title: 'Pago en linea',
        url: pagoEnLineaRoutes.root,
        class: '',
        icon: pagoEnLineaIcon,
        name: 'pagoEnLinea',
        event: ''
    },
    facturacion: {
        title: 'Facturación',
        url: facturacionRoutes.root,
        class: '',
        icon: facturacionIcon,
        name: 'facturacion',
        event: 'facturación'
    },
    misTurnos: {
        title: 'Mis turnos',
        url: cartillaRoutes.misTurnos,
        class: '',
        icon: turnosIcon,
        name: 'misTurnos',
        event: 'mis turnos',
        externalLink: isWeb() 
    },
    datosContacto: {
        title: 'Mis datos de contacto',
        url: rootRoute,
        class: '',
        icon: datosIcon,
        name: 'datosContacto',
        event: 'mis datos de contacto'
    },
    credencialProvisoria: {
        title: 'Credencial provisoria',
        url: homeRoutes.credencialProvisoriaRoute,
        class: '',
        icon: credencialIcon,
        name: 'credencialProvisoria',
        event: 'credencial provisoria móvil',
        externalLink: isWeb() 
    },
    activaTuCredencial: {
        title: 'Activa tu credencial',
        url: homeRoutes.activarCredencialRoute,
        class: '',
        icon: credencialIcon,
        name: 'activaTuCredencial',
        event: 'activá tu credencial',
        externalLink: isWeb() 
    },
}

export default gestionesOnlineData