import { ReintegrosDataType, ReintegroDataType, TramiteReintegroDataType } from "./ReintegrosData";

export const SET_REINTEGROS = "SET_REINTEGRO";
export const SET_NUEVOREINTEGRO = "SET_NUEVOREINTEGRO";
export const UNSET_REINTEGROS = "UNSET_REINTEGRO";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const FINALIZAR_TRAMITE_SUCCESS = "FINALIZAR_TRAMITE_SUCCESS";
export const FINALIZAR_TRAMITE_FAILURE = "FINALIZAR_TRAMITE_FAILURE";



interface SetReintegrosAction {
  type: typeof SET_REINTEGROS;
  payload: ReintegrosDataType;
}

interface SetNuevoReintegroAction {
    type: typeof SET_NUEVOREINTEGRO;
    payload: ReintegroDataType;
}

interface UnsetReintegros {
  type: typeof UNSET_REINTEGROS;
}

interface ToggleLoading {
  type: typeof TOGGLE_LOADING;
}

interface FinalizarTramiteSuccess {
  type: typeof FINALIZAR_TRAMITE_SUCCESS;
  payload: TramiteReintegroDataType;
}

interface FinalizarTramiteFailure {
  type: typeof FINALIZAR_TRAMITE_FAILURE;
  payload: any;
}




export type ReintegrosActions = SetReintegrosAction | SetNuevoReintegroAction | UnsetReintegros | ToggleLoading | FinalizarTramiteSuccess | FinalizarTramiteFailure;