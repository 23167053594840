import { routes as PedidoMedicoRoutes } from '../../../tramites/pedido-medico/router/routes';
import { IContacto, ContactoType } from '../types/contactosTypes';
import { callOutline, logoWhatsapp, mailOutline } from 'ionicons/icons';
import { phones } from '../../../../shared/phones';
import { cartillaRoutes } from '../../cartilla/router/routes';

interface IContactos {
  [key: string]: IContacto;
}

const contactos: IContactos = {
  asistenciaWhatsApp: {
    label: phones.asistenciaMedica.whatsApp.label,
    section: 'ASISTENCIA MÉDICA',
    icon: logoWhatsapp,
    link: phones.asistenciaMedica.whatsApp.link,
    phoneInfo: 'urgencias',
    type: ContactoType.TelefonoWhatsApp,
    className: 'asistencia',
  },
  asistenciaLinea1: {
    label: phones.asistenciaMedica.lineaUno.label,
    section: 'ASISTENCIA MÉDICA',
    icon: callOutline,
    link: phones.asistenciaMedica.lineaUno.link,
    phoneInfo: 'urgencias',
    type: ContactoType.Telefono,
  },
  asistenciaLinea2: {
    label: phones.asistenciaMedica.lineaDos.label,
    section: 'ASISTENCIA MÉDICA',
    icon: callOutline,
    link: phones.asistenciaMedica.lineaDos.link,
    phoneInfo: 'urgencias',
    type: ContactoType.Telefono,
  },
  asistenciaPedidoMedico: {
    label: 'SOLICITAR ASISTENCIA MÉDICA DE URGENCIAS',
    section: 'ASISTENCIA MÉDICA',
    type: ContactoType.Link,
    link: PedidoMedicoRoutes.root,
    options: {target: '_blank'}
    //target: '_blank',
  },
  riesgo: {
    label: phones.riesgoDeVida.label,
    section: 'RIESGO DE VIDA',
    icon: callOutline,
    link: phones.riesgoDeVida.link,
    phoneInfo: 'riesgo de vida',
    className: 'riesgo',
    type: ContactoType.Telefono,
  },
  atencionWhatsApp: {
    label: phones.atencionAlSocio.whatsApp.label,
    section: 'ATENCIÓN AL SOCIO',
    icon: logoWhatsapp,
    link: phones.atencionAlSocio.whatsApp.link,
    phoneInfo: 'centro de atencion',
    type: ContactoType.TelefonoWhatsApp,
    className: 'atencion-socio',
  },
  atencionLinea0810: {
    label: phones.atencionAlSocio.linea0810.label,
    section: 'ATENCIÓN AL SOCIO',
    icon: callOutline,
    link: phones.atencionAlSocio.linea0810.link,
    phoneInfo: 'centro de atencion',
    type: ContactoType.Telefono,
  },
  atencionLinea0800: {
    label: phones.atencionAlSocio.linea0800.label,
    section: 'ATENCIÓN AL SOCIO',
    icon: callOutline,
    link: phones.atencionAlSocio.linea0800.link,
    phoneInfo: 'centro de atencion',
    type: ContactoType.Telefono,
  },
  turnosEnSucursal: {
    label: 'TURNOS EN SUCURSAL',
    section: 'ATENCIÓN AL SOCIO',
    type: ContactoType.Link,
    options: { requiresAuthentication: true, target: '_blank' },
    link: cartillaRoutes.caps,
  },
  email: {
    label: 'contacto@osde.com.ar',
    section: 'ATENCIÓN AL SOCIO',
    icon: mailOutline,
    type: ContactoType.None,
    className: 'email'
  },
};

const getContactos = (): IContacto[] => {
  const {
    asistenciaWhatsApp,
    asistenciaLinea1,
    asistenciaLinea2,
    asistenciaPedidoMedico,
    riesgo,
    atencionWhatsApp,
    atencionLinea0810,
    atencionLinea0800,
    turnosEnSucursal,
    email
  } = contactos;

  return [
    asistenciaWhatsApp,
    asistenciaLinea1,
    asistenciaLinea2,
    asistenciaPedidoMedico,
    riesgo,
    atencionWhatsApp,
    atencionLinea0810,
    atencionLinea0800,
    turnosEnSucursal,
    email
  ];
};

export default getContactos;