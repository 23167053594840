import autorizacionesService from "../../modules/tramites/autorizaciones/services/AutorizacionesService";
import { ArchivosAdjuntos, AutorizacionDataType, DatosPractica, DatosSocio, FormularioAutorizacionesDataType, FormularioAutorizacionesObtenidoDataType, IntegranteAutorizacionesDataType, OrigenDestino, Traslado } from "./types/AutorizacionesData";

export const SET_AUTORIZACIONOBTAINEDDATA = "SET_AUTORIZACIONOBTAINEDDATA"
export const SET_TIPOTRAMITE = "SET_TIPOTRAMITE"
export const SET_DATOSPRACTICA = "SET_DATOSPRACTICA"
export const SET_DATOSSOCIO = "SET_DATOSSOCIO"
export const SET_ARCHIVOSADJUNTOS = "SET_ARCHIVOSADJUNTOS"
export const SET_TRASLADO = "SET_TRASLADO"
export const SET_SOCIOSELECCIONADO = "SET_SOCIOSELECCIONADO"
export const SET_ORIGENDESTINO = "SET_ORIGENDESTINO"
export const SET_DETALLE = "SET_DETALLE"
export const SET_NUMEROTRAMITE = "SET_NUMEROTRAMITE"
export const UNSET_TRAMITE = "UNSET_TRAMITE"
export const SET_STEPS = "SET_STEPS"
export const SET_SUCCESS = "SET_SUCCESS"
export const TOGGLE_LOADING = "TOGGLE_LOADING"

interface SetDatosPractica {
    type: typeof SET_DATOSPRACTICA;
    payload: DatosPractica;
}

interface SetDatosSocio {
    type: typeof SET_DATOSSOCIO;
    payload: DatosSocio;
}

interface SetTraslado {
    type: typeof SET_TRASLADO;
    payload: Traslado;
}

interface SetArchivosAdjuntos {
    type: typeof SET_ARCHIVOSADJUNTOS;
    payload: ArchivosAdjuntos;
}

interface SetSocioSeleccionado {
    type: typeof SET_SOCIOSELECCIONADO;
    payload: IntegranteAutorizacionesDataType;
}

interface SetAutorizacionObtainedData {
    type: typeof SET_AUTORIZACIONOBTAINEDDATA;
    payload: FormularioAutorizacionesObtenidoDataType;
}

interface SetOrigenDestino {
    type: typeof SET_ORIGENDESTINO;
    payload: OrigenDestino;
}

interface SetTipoTramite {
    type: typeof SET_TIPOTRAMITE;
    payload: string;
}

interface SetDetalle {
    type: typeof SET_DETALLE;
    payload: string;
}

interface SetNumeroTramite {
    type: typeof SET_NUMEROTRAMITE;
    payload: AutorizacionDataType;
}

interface UnSetTramite {
    type: typeof UNSET_TRAMITE;
}

interface SetSteps {
    type: typeof SET_STEPS;
    payload: {editOrder: any[], titleAndLenght: object};
}

interface SetSuccess {
    type: typeof SET_SUCCESS;
    payload: boolean;
}

interface ToggleLoading {
    type: typeof TOGGLE_LOADING;
}

export type AutorizacionesAction = SetDatosPractica | SetDatosSocio| SetTraslado | SetArchivosAdjuntos| SetSocioSeleccionado |SetAutorizacionObtainedData | SetOrigenDestino | SetTipoTramite | SetDetalle | SetNumeroTramite | UnSetTramite | SetSteps | SetSuccess | ToggleLoading;

export const setDatosPractica = (datosPractica: DatosPractica): AutorizacionesAction => ({
    type: SET_DATOSPRACTICA,
    payload: datosPractica,
});

export const setDatosSocio = (datosSocio: DatosSocio): AutorizacionesAction => ({
    type: SET_DATOSSOCIO,
    payload: datosSocio,
});

export const setArchivosAdjuntos = (archivosAdjuntos: ArchivosAdjuntos): AutorizacionesAction => ({
    type: SET_ARCHIVOSADJUNTOS,
    payload: archivosAdjuntos,
});

export const setTraslado = (traslado: Traslado): AutorizacionesAction => ({
    type: SET_TRASLADO,
    payload: traslado,
});

export const setSocioSeleccionado = (socio: IntegranteAutorizacionesDataType): AutorizacionesAction => ({
    type: SET_SOCIOSELECCIONADO,
    payload: socio,
});

export const setOrigenDestino = (origenDestino: OrigenDestino): AutorizacionesAction => ({
    type: SET_ORIGENDESTINO,
    payload: origenDestino,
});

export const setAutorizacionObtainedData = (obtainedData: FormularioAutorizacionesObtenidoDataType): AutorizacionesAction => ({
    type: SET_AUTORIZACIONOBTAINEDDATA,
    payload: obtainedData,
});

export const setTipoTramite = (tipoTramite: string): AutorizacionesAction => ({
    type: SET_TIPOTRAMITE,
    payload: tipoTramite,
});

export const setDetalle = (detalle: string): AutorizacionesAction => ({
    type: SET_DETALLE,
    payload: detalle,
});

export const setNumeroTramite = (numeroTramite: AutorizacionDataType): AutorizacionesAction => ({
    type: SET_NUMEROTRAMITE,
    payload: numeroTramite,
});

export const unSetTramite = (): AutorizacionesAction => ({
    type: UNSET_TRAMITE
});

export const setSucces = (isSuccess : boolean): AutorizacionesAction => ({
    type: SET_SUCCESS,
    payload: isSuccess
})

export const toogleLoading = (): AutorizacionesAction => ({
    type: "TOGGLE_LOADING",    
})

export const setSteps = (tramite : {editOrder: any[], titleAndLenght: any}): AutorizacionesAction => ({
    type: SET_STEPS,
    payload: tramite
})

export const fetchAutorizaciones = (ContratcNumber : string) => async (dispatch: Function, getState: Function) => {
    const contrato = {
        numero: ContratcNumber,
        orden: "01"
    }
    try{
        dispatch(toogleLoading());
        const response = await autorizacionesService.getForm({contrato});
        if(response){
            dispatch(setAutorizacionObtainedData(response.data))
        }
    }catch (error) {
        console.log(error)
    } finally {
        dispatch(toogleLoading());
    }
}

export const setAutorizacion = (newAutorizacionObj: FormularioAutorizacionesDataType) => async (dispatch: Function, getState: Function) => {
    try {
        const response = await autorizacionesService.postForm(newAutorizacionObj);
        if(response){
            dispatch(setNumeroTramite(response.data));
            dispatch(setSucces(true));
        }
    } catch (error) {
        dispatch(setSucces(false));
        console.log(error)
    }

}