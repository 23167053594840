import { IonGrid, IonRow, IonLabel, IonCol, IonCheckbox, IonButton, IonToolbar, IonCardSubtitle } from '@ionic/react';
import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import styles from './ConfirmPayPage.module.scss';
import { isWeb } from '../../../../../shared/utils/utils';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import { useHistory } from 'react-router';
import { setPayNumber, setPostFormResponse, setVoucher } from '../../../../../store/pagoEnLinea/actions';
import { ErrorModal } from '../../components/ErrorModal/ErrorModal';
import Loading from '../../../../../shared/components/popups/loading/Loading';
import { LOADING } from '../../../../../shared/globalConstants';
import Header from '../../../../../shared/components/layout/header/Header';
import dayjs from 'dayjs';
import { routes as pagoEnLineaRoutes } from '../../router/routes';
import { formatImport } from '../../../../../shared/utils/utils';
import analyticsService from '../../../../../services/analytics/analytics';
import TramiteFooter from '../../../../../shared/components/tramite-footer/TramiteFooter';
import { Comprobante } from '../../../../../store/pagoEnLinea/types/PagoEnLineaTypes';
import pagoEnLineaService from '../../services/PagoEnLineaService';
import { routes } from '../../../../home/router/routes';
import Countdown from './components/Countdown/Countdown';
import AlertPopup from '../../../../../shared/components/popups/alerts/alert/AlertPopup';
import useHttpErrorHandler from '../../../../../hooks/useHttpErrorHandler';

const ConfirmPayPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    pagoEnLinea: { available, getForm, paymentCard, postFormResponse, token, firstSixNumbers },
    user: { user }
  } = useSelector((state: RootState) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [total, setTotal] = useState(0);
  useHttpErrorHandler();


  useEffect(() => {
    if(getForm?.talones?.length > 0) {
      const total = getForm.talones.reduce((acc, item) => acc + parseFloat(item.monto), 0);
      setTotal(total);
    } else {
      history.push(routes.root);
    }
  }, [getForm, history]);


  useEffect(() => {
    if (
      postFormResponse?.observacion?.codigo &&
      postFormResponse.observacion.codigo !== 200
    ) {
      setOpenModalError(true);
    }
    return () => { };
  }, [postFormResponse]);


  useEffect(() => {
    if (!token && getForm?.talones?.length > 0) {
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const addPayments = () => {
    let value = 0;
    for (let index = 0; index < getForm.talones.length; index++) {
      value += parseFloat(getForm.talones[index].monto);
    }
    return formatImport(value);
  };


  const getMessageVoucher = () => {
    let value = '';
    for (let index = 0; index < getForm?.talones.length; index++) {
      value += getForm.talones[index].periodo;
      if (index !== getForm.talones.length) {
        value += '-';
      }
    }
    return value;
  };


  const handleConfirmPay = async () => {
    setIsLoading(true);
    let postFormRequest = {
      pago: {
        datosSocio: {
          nroContrato:
            user && user?.contrato.numeroFilial + user?.contrato.numeroAfiliado,
          nroOrden: user && user?.contrato.numeroBeneficiario,
        },
        token,
        medioDePago: {
          paymentMethodId: paymentCard.id,
          primerosDigitos: firstSixNumbers,
        },
        idTalonDePago: getForm.talones,
      },
      canal: isWeb() ? 2 : 3,
      enviarMail: false,
    };
    try {
      const response = await pagoEnLineaService.postForm(postFormRequest);
      dispatch(setPostFormResponse(response));
      if (response.observacion.codigo === 200) {
        dispatch(setPayNumber(response.idTransaccion));
        const voucher: Comprobante = {
          contratoSocio: `${user.contrato.nroActividad}`,
          fecha: `Fecha: ${dayjs().format(
            'DD/MM/YYYY'
          )} - Hora: ${dayjs().format('hh:mm')}`,
          importe: `${addPayments()}`,
          medioDePago: paymentCard.name,
          mensajeDePago: `${getMessageVoucher()}`,
          siteTransactionId: response.idTransaccion,
        };
        dispatch(setVoucher(voucher));
        history.push(pagoEnLineaRoutes.payConfirmed);
      } else {
        analyticsService.trackPageView(`/gestiones-online/pago-en-linea/formulario-no-enviado?m=no-es-posible-procesar-pago&error=${JSON.stringify(response.observacion.descripcion.replace(/\s/g, '-'))}`);
      }
    } catch (error) {
      analyticsService.trackPageView(`/gestiones-online/pago-en-linea/formulario-no-enviado?m=no-es-posible-procesar-pago&error=${JSON.stringify(error)}`);
    } 
    setIsLoading(false);
  };


  return (
    <IonPage>
      <Header showBackButton={false} />
      <IonContent>
        <IonGrid className={styles.pagoEnLineaGrid}>
          <IonRow className={styles.confirmPayGridContainer}>
            <IonLabel className={styles.selectCardTitle}>
              CONFIRMACIÓN DE PAGO
            </IonLabel>

            <Countdown time={120} />

            <IonLabel className={styles.selectCardSubtitle}>
              Total a pagar
            </IonLabel>
            <IonLabel className={styles.selectCardAmount}>${formatImport(total.toFixed(2))}</IonLabel>
          </IonRow>

          <IonRow className={styles.confirmPayGridTitle}>
            <IonLabel>Estás pagando:</IonLabel>
          </IonRow>

          {getForm?.talones.map((x, i) => {
            return (
              <React.Fragment key={i}>
                <IonRow className={styles.confirmPayDetail}>
                  <IonCol size="9">
                    <IonLabel>{x.periodo}</IonLabel>
                  </IonCol>

                  <IonCol>
                    <IonLabel>${formatImport(parseFloat(x.monto).toFixed(2))}</IonLabel>
                  </IonCol>
                </IonRow>

                <IonRow className={styles.selectCardSeparator} />
              </React.Fragment>
            );
          })}

          <IonRow className={styles.confirmPayDetail}>
            <IonCol size="9">
              <IonLabel>Medio de pago</IonLabel>
            </IonCol>

            <IonCol>
              <div className={styles.cardContainer}>
                <img
                  src={paymentCard && paymentCard.pathImageIcon}
                  alt={paymentCard && paymentCard.name}
                  style={{ width: 50 }}
                />
              </div>
            </IonCol>
          </IonRow>

          <IonRow className={styles.selectCardSeparator} />

          <IonRow>
            <IonCol size="10">
              <IonCardSubtitle className={styles.confirmPayDetail}>
                Enviar comprobante por mail a: <br />
                {getForm?.direccionMail}
              </IonCardSubtitle>
            </IonCol>
            <IonCol>
              <IonCheckbox />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <TramiteFooter>
        <IonToolbar>
          <IonButton
            disabled={!available}
            onClick={handleConfirmPay}
            expand="block"
          >
            CONFIRMAR
          </IonButton>
          <IonButton
            onClick={() => history.replace(routes.gestionesOnline)}
            color='tertiary'
            fill="outline"
            expand="block"
          >
            CANCELAR
          </IonButton>
        </IonToolbar>
        {isWeb() && <Footer />}
      </TramiteFooter>

      <Loading isOpen={isLoading} message={LOADING} />

      <ErrorModal
        isOpen={openModalError}
        onDidDismiss={() => history.replace(pagoEnLineaRoutes.payments)}
        errorCode={postFormResponse?.observacion?.codigo?.toString()}
      />

      <AlertPopup
        title={`Error`}
        message={'Ocurrio un error. Por favor, intentelo nuevamente.'}
        isOpen={showAlert}
        buttons={[
          {
            text: 'Aceptar',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => {
              history.goBack();
            },
          },
        ]}
      />

    </IonPage>
  );
};

export default ConfirmPayPage;