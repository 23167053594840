import React from 'react';
import { AlertButton, IonAlert, AlertInput } from '@ionic/react';
import './AlertPopup.scss'

interface AlertProps {
    id?:string;
    isOpen: boolean;
    title: string;
    message: string | any;
    cssClass?: string;
    backdropDismiss?: boolean;
    buttons?: AlertButton[],
    inputs?: AlertInput[],
    onDidDismiss?: () => void
}

const AlertPopup: React.FC<AlertProps> = ({ id, isOpen, title, message, backdropDismiss, cssClass, buttons, inputs, onDidDismiss }) => {

    return (
        <IonAlert 
            id={id ? id : "alert-popup" }
            isOpen={isOpen}
            header={title}
            message={message}
            mode='md'
            cssClass={['alert-style', cssClass].join(' ')}
            backdropDismiss={backdropDismiss ?? false}
            buttons={ buttons?.map(button => ({ ...button, cssClass: `button button--tas ${button.cssClass}` })) }
            inputs={ inputs }
            onDidDismiss={onDidDismiss}
        />
    );
};

export default AlertPopup;