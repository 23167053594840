
import React, { useMemo } from 'react'
import { Comprobante } from '../../../../../../../store/pagoEnLinea/types/PagoEnLineaTypes';
import { getPaymentIcon } from '../../../../helpers/getPaymentMethods';
import Voucher from '../../../../components/Voucher/Voucher';
import { IonButton } from '@ionic/react';


const VoucherCard: React.FC<{ voucher: Comprobante, onView: Function }> = ({ voucher, onView }) => {
    const [date] = voucher.fecha.split(' ');

    const getPaymentImage = useMemo(() => {
        return getPaymentIcon(voucher.pagoInternoOsde && voucher.medioDePago);
    }, [voucher.medioDePago, voucher.pagoInternoOsde])

    return (
        <Voucher
            type='comprobante'
            paymentIcon={getPaymentImage}
            amount={voucher.importe}
            description={voucher.mensajeDePago}
            entidadDePago={voucher.entidadDePago}
            pagoInternoOsde={voucher.pagoInternoOsde}
            date={date}
        >
            {
                voucher.pagoInternoOsde &&
                <IonButton
                    color='light'
                    mode='md'
                    onClick={() => onView(voucher)}
                >
                    Ver
                </IonButton>
            }
        </Voucher>
    )
}


export default VoucherCard;