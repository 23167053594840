import {
  DatosContactoActions,
  SET_DATOSCONTACTO,
  SET_NUMEROSOLICITUD,
  UNSET_TRAMITE_DATOS_CONTACTO,
  SET_INCOMPLETEPROCESS
} from './types/actions';
import { DatosContactoState } from './datosContactoReducer';

export const setIncompleteProcess = ( incompleteProcess: boolean ): DatosContactoActions => ({
  type: SET_INCOMPLETEPROCESS,
  payload: incompleteProcess
});

export const setDatosContactoStore = ( datosContactoStore: DatosContactoState ): DatosContactoActions => ({
  type: SET_DATOSCONTACTO,
  payload: datosContactoStore
});

export const setNumeroDeSolicitud = ( numeroDeSolicitud: string ): DatosContactoActions => ({
  type: SET_NUMEROSOLICITUD,
  payload: numeroDeSolicitud
});

export const leaveTramite = () => ({
  type: UNSET_TRAMITE_DATOS_CONTACTO
})