import { isLocalDev } from "../../shared/utils/utils";
import { userDataMock } from "./helpers/getUserDataMock";
import { UserAction, SET_REDIRECT_LOGINWITHCI_ERROR, SET_USER, UNSET_USER, SET_LOGGED_IN, UNSET_LOGGED_IN, SET_CHECKSESSIONTAS, SET_EMAIL, SET_URL_AUTH_IOS, SET_REDIRECT_AFTER_LOGGED_IN_URL, USER_LOGIN_IOS, USER_NOT_LOGIN_IOS } from "./types/actions";
import { ContratoFullData, User } from "./types/User";

export interface userState {
  user?: User | null;
  contratos?: ContratoFullData[] | null;
  loggedIn: boolean | null;
  checkSessionTAS: boolean;
  urlAuthIos?: string;
  redirectAfterLoggedInUrl?: string;
  redirectLoginWithCIError?: string;
  isLoginIos: boolean;
}

const initialState = {
  loggedIn: null,
  checkSessionTAS: true,
  isLoginIos: false,
  user: isLocalDev() ? userDataMock : null 
};

export const userReducer = (
  state: userState = initialState,
  action: UserAction
): userState => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload };
    case USER_LOGIN_IOS:
      return { ...state, isLoginIos: true };
    case USER_NOT_LOGIN_IOS:
      return { ...state, isLoginIos: false };
    case UNSET_USER:
      return { ...state, user: null, contratos: null };
    case SET_LOGGED_IN:
      return { ...state, loggedIn: true }
    case UNSET_LOGGED_IN:
      return { ...state, loggedIn: false }
    case SET_CHECKSESSIONTAS:
      return { ...state, checkSessionTAS: false };
    case SET_EMAIL:
      return { ...state, user: { ...state.user, email: action.payload } };
    case SET_URL_AUTH_IOS:
      return { ...state, urlAuthIos: action.payload }
    case SET_REDIRECT_AFTER_LOGGED_IN_URL:
      return { ...state, redirectAfterLoggedInUrl: action.payload }
    case SET_REDIRECT_LOGINWITHCI_ERROR: 
      return { ...state, redirectLoginWithCIError: action.payload }
    default:
      return state;
  }
};
