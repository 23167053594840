import React from 'react';

const TerminosYCondiciones: React.FC = () => {
  return (
    <div style={{ textAlign: 'justify', fontSize: '13px', lineHeight: '14px' }}>
      A través de este trámite podés pagar las cuotas de OSDE vencidas y/o la
      próxima a vencer. <br />
      <br /> Para acceder al trámite, es necesario ser titular activo de tu
      grupo familiar de OSDE y tener tu usuario registrado en la web o en la app{' '}
      <strong>OSDE Móvil</strong>. <br />
      <br /> Toda la información que éste Agente de Salud proporciona a través
      de ésta página web, debe ser considerada como provisoria, especialmente en
      lo referido a los planes de pago, las deudas de cuota mensual y los
      intereses por mora, toda vez que la misma podrá modificarse en virtud de
      nuevas disposiciones y/o por los asientos contables y/o imputaciones que
      puedan ocurrir con posterioridad a la misma y que por diversos motivos no
      hayan sido reflejadas anteriormente. Por lo tanto cualquier operación de
      cancelación de cuotas y/o intereses por mora será considerado como pago a
      cuenta y su imputación final le será informado oficialmente a través del
      próximo talón de pago según detalle correspondiente. <br /> Los medios de
      pago aceptados son: <br />
      <br /> Tarjeta de crédito emitidas en la República Argentina: <br />
      <ul>
        <li>■ Visa</li>
        <li>■ Mastercard</li>
        <li>■ American Express</li>
        <li>■ Diners</li>
      </ul>
      Tarjeta de débito: <br />
      <ul>
        <li>■ Visa</li>
        <li>■ Maestro</li>
      </ul>
      Importante: <br />
      <br /> - Los montos informados corresponden a los registros de{' '}
      <strong>OSDE</strong> a la fecha, S.E.U.O. <br />
      <br /> - Los <strong>intereses</strong> correspondientes al pago posterior
      a la fecha de vencimiento de la cuota serán incluidos en la facturación
      del próximo período. <br />
      <br /> - Para el uso de la plataforma es requerido: <br />
      <br />
      <li>Navegadores:Firefox 68, Chrome 75, IE 11 o </li>
      <li>
        Sistemas operativos: Android 4.4 en adelante / IOs 9.0 en adelante{' '}
      </li>
    </div>
  );
};

export default TerminosYCondiciones;
