import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../store";
import { unSetTramites } from "../store/users/actions/user";

type returnedType = {
    hasAnyTramiteGoing?: boolean,
    clearAllTramites?: () => void;
}

/**
 * Hook para detectar si hay tramites iniciados y para limpiar los mismos.
 * @param {boolean} autoClearAllTramites Si se pasa true el hook borra cualquier tramite iniciado del store de Redux.
 * @returns {returnedType} hasAnyTramiteGoing: true si existe tramite en state, clearAllTramites: funcion para limpiar manualmente los tramites 
 */
export const useTramitesState = (autoClearAllTramites?: boolean) => {
    const dispatch = useDispatch();
    const {
      reintegros: { nuevoReintegro },
      autorizaciones: { stepDetails },
      pagoEnLinea: { paymentCard },
      empadronamiento: { sociaElegida },
      datosContacto: { datosContacto },
    } = useSelector((state: RootState) => state);
  
  
    const hasAnyTramiteGoing = useMemo((): boolean => {
      return !!(nuevoReintegro || stepDetails || paymentCard || sociaElegida || datosContacto)
    }, [datosContacto, nuevoReintegro, paymentCard, sociaElegida, stepDetails]);


    const clearAllTramites = useCallback(() => dispatch(unSetTramites()), [dispatch]);


    useEffect(() => {
        if(autoClearAllTramites && hasAnyTramiteGoing) {
            clearAllTramites();
        }
    }, [autoClearAllTramites, clearAllTramites, hasAnyTramiteGoing]);
  

    return {hasAnyTramiteGoing, clearAllTramites} as returnedType;
};