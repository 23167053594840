import { IonButton, IonIcon } from "@ionic/react";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { closeOutline } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import cookieService from "../../../../services/cookies/CookiesService";
import styles from './InstallBanner.module.scss';
import osdeLogo from './assets/osde-icon.svg';
import { getUrlStore } from "../../../../shared/utils/utils";

const InstallBanner: React.FC = () => {
  const [show, setShow] = useState(true);
  const url = getUrlStore();

  useEffect(() => {
    const cookie = cookieService.getCookie("conocerbanner");
    setShow(cookie === "");
  }, []);

  const hideInstallBanner = () => {
    cookieService.setCookie("conocerbanner");
    setShow(false);
  };

  const bannerVariants: Variants = {
    hidden: { opacity: 0, y: 50, scale: 0.3 },
    show: { opacity: 1, y: 0, scale: 1, transition: { delay: 1 } },
    exit: { opacity: 0, scale: 0.5, transition: { duration: 0.2 } }
  };

  return (
    <AnimatePresence>
      {show &&
        <motion.div
          key={1}
          className={styles.conoceNuestraAppBanner}
          variants={bannerVariants}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <IonButton color='light' id="id-close-banner" className={styles.closeButton} onClick={hideInstallBanner} fill="clear">
            <IonIcon color='medium' icon={closeOutline}></IonIcon>
          </IonButton>
          <img
            alt="ícono OSDE"
            className={styles.osdeIcon}
            src={`${osdeLogo}`}
          />
          <span className={styles.textConoce}>
            ¡Conocé <br />
            nuestra app!
          </span>
          <IonButton color='light' className={styles.usarButton} href={url}>
            USAR
          </IonButton>
        </motion.div>
      }
    </AnimatePresence>
  );
};

export default InstallBanner;