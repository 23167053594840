import * as React from 'react';
import { motion } from 'framer-motion';
import Animations from '../Animations';
import styles from './RouteTransitionWrapper.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from '../../../../store';
import { SetStepperNavigation } from '../../../../store/ui/uiActions';

const RouteTransitionWrapper: React.FC<{variants?: any}> = (props) => {
  const {stepperNavigation} = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();

  useEffect(() => {
    if(stepperNavigation === Animations.next) {
      dispatch(SetStepperNavigation(Animations.back))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <motion.div
      initial='initial'
      animate='animate'
      exit='exit'
      transition={{ duration: 0.3}}
      variants={props.variants ?? stepperNavigation}
      className={styles.transition}
    >
      {props.children}
    </motion.div>
  );
};

export default RouteTransitionWrapper;
