import { IonContent, IonFooter, IonImg, IonList, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import InputForm from '../../../../../shared/components/form/inputForm/InputForm';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import styles from './StepDatosTicket1Page.module.scss'
import { documentTextOutline } from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../store';
import { IDComprobantes, ReintegroDataType } from '../../../../../store/reintegros/types/ReintegrosData';
import { setNuevoReintegro } from '../../../../../store/reintegros/ReintegrosActions';
import Popover from '../../components/popover/Popover';
import ejemploTicket from '../../assets/img/ejemplo-ticket.jpg'
import { cuilValidator, fillMask, formatCuit, isWeb } from '../../../../../shared/utils/utils';
import { useHistory, useLocation } from 'react-router';
import Header from '../../../../../shared/components/layout/header/Header';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { routes } from '../../router/routes';
import { routes as homeRoutes } from '../../../../home/router/routes';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import analyticsService from '../../../../../services/analytics/analytics';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';


const StepDatosTicket1Page: React.FC = () => {

    const nuevoReintegro = useSelector((state: RootState) => state.reintegros.nuevoReintegro);
    const {isKeyboardOn} = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();
    const [showImagePopover, setShowImagePopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });
    const history = useHistory();
    const { state } = useLocation<{from: string}>();


    useEffect(() => {
        if (!nuevoReintegro) {
            history.push(homeRoutes.gestionesOnline);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    interface FacturacionFormProperties {
        cuitEmisor: string,
        puntoVenta: string,
        nroComprobante: string,
    }

    const { control, handleSubmit, errors, clearErrors } = useForm<FacturacionFormProperties>({
        mode: 'onSubmit',
        defaultValues: {
            cuitEmisor: formatCuit(nuevoReintegro?.profesional?.cuit) ?? '',
            puntoVenta: fillMask(nuevoReintegro?.nroComprobantePrefijo, '00000') ?? '',
            nroComprobante: fillMask(nuevoReintegro?.nroComprobante, '00000000') ?? '',
        }
    });

    useEffect(() => {
        if(errors.cuitEmisor)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.cuitEmisor}` , routes.datosTicket1)
        if(errors.puntoVenta)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.puntoVenta}` , routes.datosTicket1)
        if(errors.nroComprobante)  
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.nroComprobante}` , routes.datosTicket1)
    }, [errors])

    const onSubmit = (dataForm: any) => {
        const nuevoReintegroUpdated: ReintegroDataType = {
            ...nuevoReintegro!,
            profesional: { cuit: dataForm.cuitEmisor.replace(/[^\d]/g, ''), razonSocial: null },
            nroComprobantePrefijo: dataForm.puntoVenta,
            nroComprobante: dataForm.nroComprobante
        };
        dispatch(SetStepperNavigation(Animations.next))
        dispatch(setNuevoReintegro(nuevoReintegroUpdated));
        (state?.from === routes.verificaDatos) ? history.goBack() : history.push(routes.datosTicket2);
    }

    const footer = () => (
        <IonFooter className='ion-no-border'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <NavigationButton text='Datos obligatorios(*)' type='submit'>SIGUIENTE</NavigationButton>
            </form>               
            {isWeb() && <Footer /> }
        </IonFooter>
    )

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles.ticket1}>

                <PageTitle>
                    Reintegros online
                </PageTitle>

                <Stepper stepsLenght={nuevoReintegro?.tipoComprobante?.id === IDComprobantes.Ticket ? 6 : 7} currentNumber={2} />

                <StepTitle>
                    Datos de facturación
                </StepTitle>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonList className='ion-margin'>
                        <Controller
                            control={control}
                            name='cuitEmisor'
                            rules={{
                                required: { value: true, message: "Por favor, completá el número de CUIT" },
                                validate: { minLength: value => value.replace(/[^\d]/g, '').length > 10 || "Cuit incompleto",
                                value: (value) => cuilValidator(value.replace(/[^\d]/g, '')) || 'CUIT-CUIL Invalido' },
                            }}                            
                            render={({ onChange, value }) => (
                                <InputForm
                                    type='text'
                                    label="Nro. de C.U.I.T. Emisor (*)"
                                    labelIcon={documentTextOutline}
                                    labelIconOnClick={e => setShowImagePopover({ open: true, event: e.nativeEvent })}
                                    placeHolder='00-00000000-0'
                                    inputmode='numeric'
                                    onChange={(e) => { clearErrors(["cuitEmisor"]); onChange(formatCuit(e.detail.value)) }}
                                    onKeyPress={(e) => { !(/[0-9]/.test(e.key)) && e.preventDefault() }}
                                    onPaste={e => !(/[0-9]/.test(e.clipboardData.getData('Text'))) && e.preventDefault()}
                                    errors={errors.cuitEmisor?.message}
                                    maxLength={13}
                                    minLength={13}
                                    value={value}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name='puntoVenta'
                            rules={{ required: { value: true, message: "Por favor, completá el punto de venta" } }}
                            render={(props) => (
                                <InputForm
                                    label='Punto de venta (*)'
                                    placeHolder='00000'
                                    inputmode='numeric'
                                    onBlur={(e) => { props.onChange(+e.detail.target.value === 0 ? '' : fillMask(e.detail.target.value, '00000')) }}
                                    onKeyPress={(e) => { !(/[0-9]/.test(e.key)) && e.preventDefault() }}
                                    onPaste={e => !(/[0-9]/.test(e.clipboardData.getData('Text'))) && e.preventDefault()}
                                    maxLength={5}
                                    errors={errors.puntoVenta?.message}
                                    value={props.value}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name='nroComprobante'
                            rules={{ required: { value: true, message: "Por favor, completá el número de comprobante" } }}
                            render={(props) => (
                                <InputForm
                                    label='Nro. de comprobante (*)'
                                    placeHolder='00000000'
                                    inputmode='numeric'
                                    maxLength={8}
                                    onBlur={(e) => { props.onChange(+e.detail.target.value === 0 ? '' : fillMask(e.detail.target.value, '00000000')) }}
                                    onKeyPress={(e) => { !(/[0-9]/.test(e.key)) && e.preventDefault() }}
                                    onPaste={e => !(/[0-9]/.test(e.clipboardData.getData('Text'))) && e.preventDefault()}
                                    errors={errors.nroComprobante?.message}
                                    value={props.value}
                                />
                            )}
                        />

                    </IonList>
                </form>

                {isKeyboardOn && footer()}

                <Popover
                    event={showImagePopover.event}
                    isOpen={showImagePopover.open}
                    showInFullScreen={true}
                    onDidDismiss={() => setShowImagePopover({ open: false, event: undefined })}>
                    <IonImg src={ejemploTicket} />
                </Popover>
            
            </IonContent>

            {!isKeyboardOn && footer()}

        </IonPage>
    );
}

export default StepDatosTicket1Page;