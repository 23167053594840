import { InAppBrowser, InAppBrowserObject } from "@ionic-native/in-app-browser";


export const openBrowserApp = (url, target = '_blank', hidden = false, showToolbar = false): InAppBrowserObject => {
    const ref = InAppBrowser.create(url, target, {
        location: showToolbar ? 'yes' : 'no', //toolbar Android
        toolbarposition: 'top',
        closebuttoncolor: '#FFFFFF',
        closebuttoncaption: 'Volver',
        toolbar: showToolbar ? 'yes' : 'no', //toolbar iOS
        navigationbuttoncolor: 'white',
        toolbarcolor: window.getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary')?.trim(),
        hideurlbar: 'yes',
        hidenavigationbuttons: 'yes',
        zoom: 'no',
        clearcache: 'no',
        clearsessioncache: 'no',
        hidden: hidden ? "yes" : "no"
    })

    return ref;
}


/**
 * Metodo que setea las cookies.
 * @author cgarrido
 */
export const updateCookies = (url: string, cookies: string) => {
    const ref = InAppBrowser.create(url, '_blank', {
        hidden: 'yes',
        location: 'yes'
    })

    ref.on('loadstop').subscribe(() => {
        if (ref !== undefined) {
            const stringCookies = "document.cookie = '" + cookies + ";'";
            ref.executeScript({ code: stringCookies });
        }
    }, err => {
        console.error(err);
    });
};

