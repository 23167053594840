import { IItem } from "../menu/types/MenuResponse"
import { PhoneAction, PHONE_SET_SHOW, PHONE_SET_UNSHOW } from "./phoneTypes"


export interface phoneState {
    phones: Array<IItem>
}

const initialState = {
    phones: []
}

export const phoneReducer = (
    state: phoneState = initialState,
    action: PhoneAction
) => {

    switch (action.type) {
        case PHONE_SET_SHOW:
            return { ...state, phones: action.payload }
        case PHONE_SET_UNSHOW:
            return { ...state, phones: [] }
        default:
            return { ...state }
    }

}