import { DatosContactoDataType } from './types/DatosContactoData';
import {
  DatosContactoActions,
  SET_DATOSCONTACTO,
  SET_NUMEROSOLICITUD,
  UNSET_TRAMITE_DATOS_CONTACTO,
  SET_INCOMPLETEPROCESS
} from './types/actions';

export interface DatosContactoState {
  datosContacto: DatosContactoDataType | null;
  idTramite: string;
  numeroDeSolicitud: string;
  incompleteProcess: boolean;
  pendingProcess: boolean;
  showIncompleteProcessModal: boolean;
}

const initialState: DatosContactoState = {
  datosContacto: null,
  idTramite: '',
  numeroDeSolicitud: '',
  incompleteProcess: false,
  pendingProcess: false,
  showIncompleteProcessModal: true
};

export const datosContactoReducer = (
  state: DatosContactoState = initialState,
  action: DatosContactoActions
): DatosContactoState => {
  switch (action.type) {
    case SET_DATOSCONTACTO: {
      return {
        ...state,
        ...action.payload
      };
    }
    case SET_NUMEROSOLICITUD: {
      return {
        ...state,
        numeroDeSolicitud: action.payload,
      };
    }
    case UNSET_TRAMITE_DATOS_CONTACTO:
      return initialState
    case SET_INCOMPLETEPROCESS: {
      return { ...state, incompleteProcess:  action.payload }
    }
      
    default:
      return state;
  }
};