import { useEffect } from "react"
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom"
import { AutorizacionesTramitesGA } from "../modules/tramites/autorizaciones/helpers/TramitesEnum";
import analyticsService from "../services/analytics/analytics";
import { removeSpecialCharacters } from "../shared/utils/utils";
import { RootState } from "../store";


export const useGoogleAnalytics = () => {
  const location = useLocation();
  const { reintegros: { nuevoReintegro }, autorizaciones: { tipoTramite } } = useSelector((state: RootState) => state);

  const excludedPages = [
    'login',
    'datos-de-contacto' //TODO: sacar el trackeo viejo en datos de contacto y empezar a usar el hook
  ]


  useEffect(() => {
    trackPage(location?.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])


  const isPageExcluded = (location: string): boolean => excludedPages.some(page => location.includes(page) || location === '/');


  const addTramiteType = (location: string, tramite: string, type: string): string => {
    const normalizedType = removeSpecialCharacters(type).replace(/\s+/g, '-').toLowerCase();
    return location.replace(`${tramite}/`, `${tramite}/${normalizedType}/`);
  }


  const trackPage = (path: string) => {
    if (isPageExcluded(path)) return;
    if (nuevoReintegro) {
      path = addTramiteType(path, 'reintegros', nuevoReintegro.tipoPractica.nombre);
    } else if (tipoTramite) {
      path = addTramiteType(path, 'autorizaciones', AutorizacionesTramitesGA[tipoTramite]);
    }
    analyticsService.trackPageView(path);
  }
}