import { routes } from '../../../../../modules/home/router/routes';
import { routes as reintegrosRoutes } from '../../../../../modules/tramites/reintegros/router/routes';
import { rootRoute as pagoEnLineaRoute } from '../../../../../modules/tramites/pago-en-linea/router/routes';
import { rootRoute as facturacionRoute } from '../../../../../modules/tramites/facturacion/router/routes';
import { rootRoute as datosDeContactoRoute } from '../../../../../modules/tramites/datos-contacto/router/routes';
import { rootRoute as autorizacionesRoute } from '../../../../../modules/tramites/autorizaciones/router/routes';
import { routes as pedidoMedicoRoutes } from '../../../../../modules/tramites/pedido-medico/router/routes';
import { routes as empadronamientoRoutes } from '../../../../../modules/tramites/empadronamiento/router/routes';
import { ILinks, MenuType } from '../../../../../store/menu/types/MenuResponse';
import { cartillaRoutes } from '../../../../../modules/home/cartilla/router/routes';
import { isWeb } from '../../../../utils/utils';
import { phones } from '../../../../phones';

export const links: ILinks = {
  inicio: {
    name: 'Inicio',
    link: routes.root,
    type: MenuType.path,
  },
  cartilla: {
    name: 'Cartilla Médica',
    link: cartillaRoutes.root,
    type: isWeb() ? MenuType.href : MenuType.path,
  },
  buscarPorCercania:
  {
    name: 'Buscar por Cercanía',
    link: cartillaRoutes.busquedaPorCercania,
    type: MenuType.path,
  },
  buscarPorEspecialidad:
  {
    name: 'Buscar por Especialidad',
    link: cartillaRoutes.busquedaPorEspecialidad,
    type: MenuType.path,
  },
  buscarPorNombre:
  {
    name: 'Buscar por Nombre',
    link: cartillaRoutes.busquedaPorNombre,
    type: MenuType.path,
  },
  osdeViajaConVos:
  {
    name: 'OSDE Viaja con Vos',
    link: cartillaRoutes.osdeViajaConVos,
    type: MenuType.path,
  },
  quieroSerSocio: {
    name: 'Quiero ser Socio',
    link: routes.quieroSerSocio,
    type: MenuType.href,
  },
  miCartilla: {
    name: 'Mi Cartilla',
    link: cartillaRoutes.miCartilla,
    type: isWeb() ? MenuType.href : MenuType.path,
  },
  informacionAlSocio: {
    name: 'Información al Socio',
    link: routes.informacionSocio,
    type: MenuType.href,
  },
  saludYBienestar: {
    name: 'Salud y Bienestar',
    link: routes.saludYBienestar,
    type: MenuType.blank,
  },
  novedades: {
    name: 'Novedades',
    link: routes.novedades,
    type: MenuType.blank,
  },
  misTurnos: {
    name: 'Mis Turnos',
    link: cartillaRoutes.misTurnos,
    type: isWeb() ? MenuType.href : MenuType.path,
  },
  reintegros: {
    name: 'Reintegros Online',
    link: reintegrosRoutes.root,
    type: MenuType.path,
  },
  datosDeContacto: {
    name: 'Datos de Contacto',
    link: datosDeContactoRoute,
    type: MenuType.path,
  },
  empadronamiento: {
    name: 'Empadronamiento de Anticonceptivos',
    link: empadronamientoRoutes.root,
    type: MenuType.path,
  },
  autorizaciones: {
    name: 'Autorizaciones',
    link: autorizacionesRoute,
    type: MenuType.path,
  },
  facturacion: {
    name: 'Facturación',
    link: facturacionRoute,
    type: MenuType.path,
  },
  activaTuCredencial: {
    name: 'Activá tu Credencial',
    link: routes.activarCredencialRoute,
    type: MenuType.href,
  },
  credencialProvisoriaMovil: {
    name: 'Credencial Provisoria Móvil',
    link: routes.credencialProvisoriaRoute,
    type: MenuType.href,
  },
  pagoEnLinea: {
    name: 'Pago en Línea',
    link: pagoEnLineaRoute,
    type: MenuType.path,
  },
  asistenciaMedica: {
    name: 'Llamar a Asistencia Médica',
    id: 'TelefonosUrgencias',
    items: [
      {
        name: phones.asistenciaMedica.lineaUno.label.replace(/\./g, '-'),
        link: phones.asistenciaMedica.lineaUno.link,
        type: MenuType.tel,
      },
      {
        name: phones.asistenciaMedica.lineaDos.label.replace(/\./g, "-"),
        link: phones.asistenciaMedica.lineaDos.link,
        type: MenuType.tel,
      },
    ],
    type: MenuType.picker,
  },
  asistenciaMedicaDeUrgencias: {
    name: 'Solicitar Asistencia Médica de Urgencias',
    link: pedidoMedicoRoutes.root,
    type: isWeb() ? MenuType.blank : MenuType.path
  },
  riesgoDeVida: {
    name: 'Llamar a Riesgo de Vida',
    link: phones.riesgoDeVida.link,
    type: MenuType.tel,
  },
  atencionAlSocio: {
    name: 'Llamar a Atención al Socio',
    items: [
      {
        name: phones.atencionAlSocio.linea0810.label.replace(/\./g, "-"),
        link: phones.atencionAlSocio.linea0810.link,
        type: MenuType.tel,
      },
      {
        name: phones.atencionAlSocio.linea0800.label.replace(/\./g, "-"),
        link: phones.atencionAlSocio.linea0800.link,
        type: MenuType.tel,
      },
    ],
    type: MenuType.picker,
  },
  whatsApp: {
    name: 'Contactarse por Whatsapp',
    link: phones.atencionAlSocio.whatsApp.link,
    type: MenuType.whatsapp,
  }
};