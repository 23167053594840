export interface PaymentMethod {
 id: number;
 name: string;
 pathImageIcon: string;
 pathImageCard: string;
 pathImageCardBack: string;
 maxCharsCardNumber: number;
 minCharsCardNumber: number;
 maskCardNumber: string;
 maxCharsCodSecurity: number;
 maskCodSecurity: string;
 maxCharsCardExpirationMonth: number;
 maxCharsCardExpirationYear: number;
 firstCardNumber: number;
 regex: RegExp;
 placeHolder: string
}

export interface PostForm {
 pago: {
   datosSocio: {
     nroContrato: string,
     nroOrden: string,
   },
   token: string,
   medioDePago: {
     paymentMethodId: number,
     primerosDigitos: string
   },
   idTalonDePago: Talon[]
 },
 canal: number,
 enviarMail: boolean
}

export enum tipoTalon {
  TS1 = 'TS1',
  TS2 = 'TS2',
  TS3 = 'TS3',
  TS4 = 'TS4',
}

export enum PaymentCardIds { americanExpress = 65 };

export interface Talon {
 id: string | number | undefined;
 periodo: string;
 monto: string;
 vencimiento: string;
 tipoTalon: tipoTalon;
}

export interface ApiKeyResponse {
 publicKey: string,
 observacion:{
   codigo: number,
   descripcion: string
 }
}

export interface GetFormResponse {
 direccionMail: string;
 observacion: { codigo: number; descripcion: string };
 talones: Talon[];
}
export interface GetComprobantesResponse {
 documentoArray: string | null,
 listaComprobantes: Array<Comprobante>,
 objetoMensaje: ObjetoMensaje | null
}

export interface GetComprobantePDFResponse {
 documentoArray: string | null,
 objetoMensaje: ObjetoMensaje | null
}

export interface Comprobante {
 contratoSocio: string,
 entidadDePago?: string //solo viene si el pago es de afuera
 fecha: string,
 importe: string,
 medioDePago?: string, //solo llega si el pago es de osde
 mensajeDePago: string
 pagoInternoOsde?: boolean // para indicar si es de osde o no el pago.
 siteTransactionId?: string //solo llega si el pago es de osde
}

export interface ObjetoMensaje {
 codigo: number,
 descripcion: string
}

export interface PostFormResponse {
 idTransaccion: string;
 observacion: { codigo: number; descripcion: string };
}

export interface PaymentMethod {
  id: number;
  name: string;
  pathImageIcon: string;
  pathImageCard: string;
  pathImageCardBack: string;
  maxCharsCardNumber: number;
  minCharsCardNumber: number;
  maskCardNumber: string;
  maxCharsCodSecurity: number;
  maskCodSecurity: string;
  maxCharsCardExpirationMonth: number;
  maxCharsCardExpirationYear: number;
  firstCardNumber: number;
  regex: RegExp;
  placeHolder: string
}