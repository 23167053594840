import {
  BuscarTramiteDataType,
  DatosContactoDataType,
  DomicilioDataType,
  EmailDataType,
  GuardarDatosDataType,
  TelefonoDataType,
} from '../../../../../store/datos-contacto/types/DatosContactoData';

export const mockTelefonoDataType: TelefonoDataType = {
  fijo: false,
  codArea: 'string',
  numero: 'string',
  interno: 'string',
  standard: false,
  numeroCompleto: 'string',
  changed: false,
  normalizado: false,
  editDisabled: false,
};

export const mockDomicilioDataType: DomicilioDataType = {
  provinciaChanged: false,
  provincia: 'string',
  localidadChanged: false,
  localidad: 'string',
  codigoPostalChanged: false,
  codigoPostal: 'string',
  calleChanged: false,
  calle: 'string',
  numeroChanged: false,
  numero: 'string',
  pisoChanged: false,
  piso: 'string',
  departamentoChanged: false,
  departamento: 'string',
  barrioChanged: false,
  barrio: 'string',
  emailChanged: false,
  email: 'string',
  telefonoMovilDefault: mockTelefonoDataType,
  telefonoMovilDefaultChanged: false,
  telefonos: [mockTelefonoDataType],
  telefonoDefault: mockTelefonoDataType,
  telefonoDefaultChanged: false,
  normalizado: false,
  motivoNormalizado: null,
  listaEmails: ['string@string.com'],
  interlocutorComercial: 'string',
};

export const mockEmailDataType: EmailDataType = {
  isDefault: true,
  email: 'asd@asd.com',
};

export const mockDatosContactoDataType: DatosContactoDataType = {
  domicilioEqual: false,
  residencia: mockDomicilioDataType,
  correspondencia: mockDomicilioDataType,
  emails: [mockEmailDataType],
  esBeneficiario: false,
};

export const mockBuscarTramiteDataType: BuscarTramiteDataType = {
  idTramite: 'asd',
  pendingProcess: true,
  incompleteProcess: false,
  datosContacto: mockDatosContactoDataType,
};

export const mockBuscarTramite2: BuscarTramiteDataType = {
  idTramite: '374694',
  pendingProcess: false,
  incompleteProcess: true,
  datosContacto: {
    domicilioEqual: true,
    residencia: {
      provinciaChanged: false,
      provincia: 'CAPITAL FEDERAL',
      localidadChanged: false,
      localidad: 'CIUDAD AUTONOMA BUENOS AIRES',
      codigoPostalChanged: false,
      codigoPostal: '1407',
      calleChanged: false,
      calle: 'CNEL RAMON LORENZO FALCON',
      numeroChanged: false,
      numero: '3402',
      pisoChanged: false,
      piso: '1',
      departamentoChanged: false,
      departamento: '3',
      barrioChanged: false,
      barrio: '',
      emailChanged: false,
      email: null,
      telefonoMovilDefault: {
        fijo: true,
        codArea: '0223',
        numero: '487-1813',
        interno: null,
        standard: true,
        numeroCompleto: '0223-487-1813',
        changed: false,
        normalizado: false,
        editDisabled: false,
      },
      telefonoMovilDefaultChanged: null,
      telefonos: [
        {
          fijo: true,
          codArea: '0223',
          numero: '487-1813',
          interno: null,
          standard: true,
          numeroCompleto: '0223-487-1813',
          changed: false,
          normalizado: false,
          editDisabled: false,
        },
      ],
      telefonoDefault: {
        fijo: true,
        codArea: '0223',
        numero: '487-1813',
        interno: null,
        standard: true,
        numeroCompleto: '0223-487-1813',
        changed: false,
        normalizado: false,
        editDisabled: false,
      },
      telefonoDefaultChanged: false,
      normalizado: null,
      motivoNormalizado: null,
      listaEmails: [],
      interlocutorComercial: '1002827350',
    },
    correspondencia: {
      provinciaChanged: false,
      provincia: 'CAPITAL FEDERAL',
      localidadChanged: false,
      localidad: 'CIUDAD AUTONOMA BUENOS AIRES',
      codigoPostalChanged: false,
      codigoPostal: '1407',
      calleChanged: false,
      calle: 'CNEL RAMON LORENZO FALCON',
      numeroChanged: false,
      numero: '3402',
      pisoChanged: false,
      piso: '1',
      departamentoChanged: false,
      departamento: '3',
      barrioChanged: false,
      barrio: '',
      emailChanged: false,
      email: null,
      telefonoMovilDefault: {
        fijo: true,
        codArea: '0223',
        numero: '487-1813',
        interno: null,
        standard: true,
        numeroCompleto: '0223-487-1813',
        changed: false,
        normalizado: false,
        editDisabled: false,
      },
      telefonoMovilDefaultChanged: null,
      telefonos: [
        {
          fijo: true,
          codArea: '011',
          numero: '4619-1156',
          interno: null,
          standard: true,
          numeroCompleto: '011-4619-1156',
          changed: false,
          normalizado: false,
          editDisabled: false,
        },
      ],
      telefonoDefault: {
        fijo: true,
        codArea: '011',
        numero: '4619-1156',
        interno: null,
        standard: true,
        numeroCompleto: '011-4619-1156',
        changed: false,
        normalizado: false,
        editDisabled: false,
      },
      telefonoDefaultChanged: null,
      normalizado: null,
      motivoNormalizado: null,
      listaEmails: [],
      interlocutorComercial: '1002827350',
    },
    emails: [
      {
        isDefault: true,
        email: 'adriantevez@hayoo.com.ar',
      },
      {
        isDefault: false,
        email: 'pepe@osde.com',
      },
      {
        isDefault: false,
        email: 'idm.cloud@reserv.com.ar',
      },
    ],
    esBeneficiario: false,
  },
};

export const mockProvincias = [
    'BUENOS AIRES',
    'CAPITAL FEDERAL',
    'CATAMARCA',
    'CHACO',
    'CHUBUT',
    'CÓRDOBA',
    'CORRIENTES',
    'ENTRE RIOS',
    'FORMOSA',
    'JUJUY',
    'LA PAMPA',
    'LA RIOJA',
    'MENDOZA',
    'MISIONES',
    'NEUQUEN',
    'RIO NEGRO',
    'SALTA',
    'SAN JUAN',
    'SAN LUIS',
    'SANTA CRUZ',
    'SANTA FE',
    'SANTIAGO DEL ESTERO',
    'TIERRA DEL FUEGO',
    'TUCUMÁN',
];

export const mockCalle = [
  'CALLE MOCKEADA'
];

export const mockNormalizarTelefonoDataType: TelefonoDataType = {
  changed: true,
  codArea: "1111",
  editDisabled: false,
  fijo: false,
  interno: null,
  normalizado: true,
  numero: "123-1234",
  numeroCompleto: "1111-123-1234",
  standard: true
};

export const mockNumeroDeSolicitud: string = '12345';

export const mockGuardarDatosDataType: GuardarDatosDataType = {
  idTramite: '12345',
  datosContacto: mockDatosContactoDataType,
  nativo: false
};