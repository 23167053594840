import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setKeyoard } from "../store/ui/uiActions";
import { useKeyboardState } from '@capacitor-community/react-hooks/keyboard';

export const useKeyboardScreenResize = () => {
    const dispatch = useDispatch();
    const { isOpen, keyboardHeight } = useKeyboardState();

    const changeReduxKeboyardValue = useCallback((isKeyboardOn: boolean) => {
        dispatch(setKeyoard(isKeyboardOn));
    }, [dispatch]);

    useEffect(() => {
        let ionApp: HTMLIonAppElement | null = document.getElementsByTagName("ion-app")[0];
        if (isOpen) {
            ionApp && (ionApp.style["margin-bottom"] = keyboardHeight.toString() + "px");
            changeReduxKeboyardValue(true);
            setTimeout(() => {
                document.activeElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }, 500);
        } else {
            ionApp && (ionApp.style["margin-bottom"] = "0px");         
            changeReduxKeboyardValue(false);
        };
    }, [changeReduxKeboyardValue, isOpen, keyboardHeight]);

    useEffect(() => {
            setTimeout(() =>
                document.querySelector("meta[name=viewport]")?.setAttribute("content", `height=${window.screen.height*0.9}, width=device-width,initial-scale=1.0`)
            , 300);
    }, []);
};