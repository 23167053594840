import { IonItem, IonLabel, IonIcon } from '@ionic/react';
import React, { useRef, useState } from 'react'
import styles from './Filepicker.module.scss'
import { attach } from 'ionicons/icons';
import AttachModal from '../../popups/modals/attach-modal/AttachModal';
import * as CamaraManager from '../../../../shared/utils/camera/CameraManager'
import { toBase64, tamanoMaximoArchivos, getFileExtension, sizeBase64FileString } from '../../../utils/utils';
import AlertPopup from '../../popups/alerts/alert/AlertPopup';
import FormErrorMessage from '../form-error-message/FormErrorMessage';
import CameraTASModal from '../../popups/modals/camera-tas-modal/CameraTASModal';


export interface FilepickerProps {
    label: string;
    name: string;
    register?: any;
    placeHolder: string;
    hasError?: boolean;
    storedFileName?: string;
    setValue: (value: any) => void;
    showImage?: any; 
    error?: string;
    overrideFileName?: string
}
 
const Filepicker: React.FC<FilepickerProps> = ({label, name, register, placeHolder, hasError, error, setValue, showImage, storedFileName, overrideFileName }) => {

    const fileInput = useRef<HTMLInputElement>(null);
    const [showAttachModal, setShowAttachModal] = useState<boolean>(false);
    const [showCameraTASModal, setShowCameraTASModal] = useState<boolean>(false);
    const [showFileSizeAlert, setShowFileSizeAlert] = useState<boolean>(false);
    const [selectedFileName, setSelectedFileName] = useState<string>(storedFileName ?? '');


    const attachFromCameraTas = async () => {
        setShowCameraTASModal(false);
        try {
            const imgBase64 = await CamaraManager.getPhotoFromTas();
            setValue({nombre: overrideFileName ? `${overrideFileName}.jpeg` : 'Factura.jpeg' , base64: imgBase64});
            setSelectedFileName(overrideFileName ? `${overrideFileName}.jpeg` : 'Factura.jpeg');
        } catch (error) {
            console.log(error);
        }
    }


    const attachFromCameraOrGallery = async(camera : boolean) => {
        setShowAttachModal(false);
        try {
            //const imgBase64 = await CamaraManager.getPhotoFromMobile2(camera);
            const imgObj = await CamaraManager.getPhotoFromMobile(camera);
            const sizeInKb = sizeBase64FileString(imgObj.base64);
            if (sizeInKb > tamanoMaximoArchivos * 1024) {
                setShowFileSizeAlert(true);
                return;
            }
            setValue({nombre: overrideFileName ? `${overrideFileName}.${imgObj.format}` : `Factura.${imgObj.format}`, base64: imgObj.base64});
            setSelectedFileName(overrideFileName ? `${overrideFileName}.${imgObj.format}` : `Factura.${imgObj.format}`);
        } catch (error) {
            console.log(error);  
        } 
    }


    const attachFromDocuments = async (fileList: FileList | null) => {
        setShowAttachModal(false);
        if(!fileList || !fileList[0]) {
            return;
        }
        if(fileList[0].size / (1024*1024) > tamanoMaximoArchivos){
            setShowFileSizeAlert(true);
            return;
        }
        const extension = fileList[0].type.split('/')[1] || 'pdf';
        const docBase64: string = await toBase64(fileList[0]);
        if(extension !== 'pdf'){
            setShowFileSizeAlert(true);
            return;
        }
        setValue({nombre: overrideFileName ? `${overrideFileName}.pdf` : 'Factura.pdf', base64: docBase64});
        setSelectedFileName(overrideFileName ? `${overrideFileName}.pdf` : 'Factura.pdf');
    }


    return (
        <div className={styles.filepicker}>
            <IonItem 
                className={[styles.filepickerItem, error && styles.errorValidation].join(' ')}
                onClick={ () => setShowAttachModal(true) }>
                <IonLabel position="stacked">
                    {label}
                </IonLabel>
                <div className={styles.container}>
                    <div className={`${styles.fileFormatIcon} ion-text-uppercase`} data-format={getFileExtension(selectedFileName)}>
                        {getFileExtension(selectedFileName)}
                    </div>
                    <label className={[styles.placeholder, selectedFileName && styles.selectedFile].join(' ')}>
                        {selectedFileName ? selectedFileName.split('.').shift() : placeHolder}
                    </label>
                    <IonIcon icon={attach} />
                </div>
            </IonItem>
            {error && <FormErrorMessage>{error}</FormErrorMessage>}

            <input
                type='file'
                hidden
                ref={fileInput}
                accept='application/pdf'
                onChange={e => {attachFromDocuments(e.target.files)}}
            />

            <AttachModal 
                onDidDismiss={() => setShowAttachModal(false)}
                isOpen={showAttachModal} 
                handlerCamera={async () => attachFromCameraOrGallery(true)} //se podria detectar si es web en vez de mobile, hacer como con DocumentHandler
                handlerImage={() => attachFromCameraOrGallery(false)} //se podria detectar si es web en vez de mobile, hacer como con DocumentHandler
                handlerDocument={() => fileInput.current?.click()} 
            />

            <AlertPopup
                isOpen={showFileSizeAlert}
                message={`El archivo tiene que tener formato .jpg, .png o .pdf, y su tamaño no debe superar los ${tamanoMaximoArchivos} MB.`}
                title="Aviso"
                buttons={[
                    {text: 'Aceptar', handler:() => setShowFileSizeAlert(false)}
                ]}
            />

            <CameraTASModal 
                isOpen={showCameraTASModal} 
                closeModal={() => setShowCameraTASModal(false)}
                openCamera={attachFromCameraTas}
            />
        </div>
    );
}
 
export default Filepicker;