import {
  IonPage,
  IonFooter,
  IonToolbar,
  IonCheckbox,
  IonLabel,
  IonGrid,
  IonContent,
} from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import React, { useState, useEffect } from 'react';
import styles from './EmpadronamientoPage.module.scss';

import Header from '../../../shared/components/layout/header/Header';
import Footer from '../../../shared/components/layout/footer/Footer';
import { isWeb } from '../../../shared/utils/utils';
import StandardModal from '../../../shared/components/popups/modals/standard-modal/StandardModal';
import TerminosYCondiciones from './components/terminos-condiciones/TerminosYCondiciones';
import { EmpadronamientoResponse } from './types/EmpadronamientoTypes';
import SociasRadioGroup from './components/socias-radio-group/SociasRadioGroup';
import ProteccionDatos from '../../../shared/components/texts/proteccion-datos/ProteccionDatos';
import Loading from '../../../shared/components/popups/loading/Loading';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOADING,
  PAGO_EN_LINEA_LINK_MODAL_TYC,
  PAGO_EN_LINEA_LINK_MODAL_PDDP,
} from '../../../shared/globalConstants';
import empadronamientoService from './services/EmpadronamientosService';
import { RootState } from '../../../store';
import {
  setAceptaTerminos,
  setEmpadronamientoGetForm,
} from '../../../store/empadronamiento/actions';
import Stepper from '../../../shared/components/stepper/Stepper';
import StepTitle from '../../../shared/components/stepper/step-title/StepTitle';
import NavigationButton from '../../../shared/components/buttons/navigation/NavigationButton';
import { routes as homeRoute } from '../../home/router/routes';
import { routes as empadronamientoRoutes } from '../empadronamiento/router/routes';
import PageTitle from '../../../shared/components/page-title/PageTitle';
import analyticsService from '../../../services/analytics/analytics';
import { SetStepperNavigation } from '../../../store/ui/uiActions';
import Animations from '../../../shared/components/animations/Animations';
import GenericAlert from '../../../shared/components/popups/alerts/generic-alert/GenericAlert';
import PhoneNumber from '../../../shared/components/phone-number/PhoneNumber';
import { phones } from '../../../shared/phones';
import useHttpErrorHandler from '../../../hooks/useHttpErrorHandler';


const EmpadronamientoPage: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<{ from: string }>();
  const dispatch = useDispatch();
  useHttpErrorHandler();

  const {
    user: {
      user: {
        contrato: { numeroBeneficiario, numeroAfiliado, numeroFilial },
        apellidos,
        esBeneficiario,
        nombres,
      },
    },
    empadronamiento: { sociaElegida, aceptaTerminos },
  } = useSelector((state: RootState) => state);

  const [noSocias, setNoSocias] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showTycModal, setShowTycModal] = useState<boolean>(false);
  const [showModalProteccionDeDatos, setShowModalProteccionDeDatos] =
    useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setButtonDisabled(!(sociaElegida && aceptaTerminos));
  }, [sociaElegida, aceptaTerminos]);

  useEffect(() => {
    if (sociaElegida) return;
    (async () => {
      setIsLoading(true);
      const numeroContrato = numeroFilial + numeroAfiliado;
      const nombreCompleto = apellidos + ' ' + nombres;
      try {
        const resp: EmpadronamientoResponse = await empadronamientoService.getForm(
            numeroContrato,
            numeroBeneficiario,
            esBeneficiario,
            nombreCompleto
          );
        const { listaSocias, antecedentes } = resp;
        if (listaSocias.length === 0) {
          setIsLoading(false);
          setNoSocias(true);
          return;
        }
        dispatch(setEmpadronamientoGetForm({ listaSocias, antecedentes }));
      } catch (error) {
        analyticsService.formNotSubmittedAndSubmitted(
          'empadronamiento anticonceptivos > 1. selección de beneficiaria',
          JSON.stringify(error),
          1,
          0
        );
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModalTyC = (showModal) => {
    setShowTycModal(showModal);
  };

  const handleOpenModalProteccionDeDatos = (showModal) => {
    setShowModalProteccionDeDatos(showModal);
  };

  const onClickNext = () => {
    dispatch(SetStepperNavigation(Animations.next));
    state?.from
      ? history.goBack()
      : history.push(empadronamientoRoutes.antecedentesSalud);
  };

  return (
    <IonPage>
      <Loading isOpen={isLoading} message={LOADING} />
      <Header showBackButton={true} />
      <IonContent>
        <IonGrid className={styles.empadronamientoGrid}>
          <PageTitle>Empadronamiento para Anticonceptivos</PageTitle>

          <Stepper stepsLenght={4} currentNumber={1} />

          <StepTitle>¿Para quién es?</StepTitle>

          <SociasRadioGroup />
        </IonGrid>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <div className={styles.checkbox}>
            <IonCheckbox
              id="id-check-tyc"
              checked={aceptaTerminos}
              onIonChange={(e) => dispatch(setAceptaTerminos(e.detail.checked))}
            />
            <IonLabel id="tyc-label" onClick={() => handleOpenModalTyC(true)}>
              {PAGO_EN_LINEA_LINK_MODAL_TYC}
            </IonLabel>
          </div>
          <IonLabel
            id="pdd-label"
            onClick={() => handleOpenModalProteccionDeDatos(true)}
            className={styles.datosPersonales}
          >
            {PAGO_EN_LINEA_LINK_MODAL_PDDP}
          </IonLabel>
        </IonToolbar>

        <NavigationButton disabled={buttonDisabled} onClick={onClickNext}>
          SIGUIENTE
        </NavigationButton>
        {isWeb() && <Footer />}
      </IonFooter>

      <GenericAlert
        isOpen={noSocias}
        title="Importante"
        buttons={[
          {
            children: 'Aceptar',
            onClick: () => {
              setNoSocias(false);
              history.replace(homeRoute.root);
            },
          },
        ]}
      >
        <p className={styles.noSociasAlert}>
          No hay ninguna socia empadronable en su grupo familiar. Para más
          información, escribinos a través de WhatsApp al 
          <PhoneNumber isWhatsapp phoneLink={phones.atencionAlSocio.whatsApp.link}> {`+${phones.atencionAlSocio.whatsApp.link}`}</PhoneNumber>
          , en días hábiles, de lunes a viernes de 9 a 20.
        </p>
      </GenericAlert>

      <StandardModal
        id="tyc-modal"
        isOpen={showTycModal}
        title="TÉRMINOS Y CONDICIONES"
        subtitle="Empadronamiento para anticonceptivos online"
        content={<TerminosYCondiciones />}
        handler={() => setShowTycModal(false)}
      />

      <StandardModal
        id="pdd-modal"
        isOpen={showModalProteccionDeDatos}
        title="PROTECCIÓN DE LOS DATOS PERSONALES"
        subtitle="Ley 25.326"
        content={<ProteccionDatos />}
        handler={() => setShowModalProteccionDeDatos(false)}
      />
    </IonPage>
  );
};

export default EmpadronamientoPage;