import environmentService from '../../../../services/environment/environmentService';

export const cartillaRoot= environmentService.getCurrent().urlWebMobile + "/cartilla";

//environmentService.getCurrent().urlWebMobile + "/cartilla"

export const cartillaRoutes = {
  root:`${environmentService.getCurrent().urlCartilla}/home`,
  miCartilla: `${environmentService.getCurrent().urlCartilla}/micartilla`,
  misTurnos:`${environmentService.getCurrent().urlCartilla}/misturnos`,
  busquedaPorCercania:  `${cartillaRoot}/busquedacercania`,
  busquedaPorEspecialidad: `${cartillaRoot}/busquedaespecialidad`,
  busquedaPorNombre: `${cartillaRoot}/busquedapornombre`,
  osdeViajaConVos: `${cartillaRoot}/osdeviaja`,
  caps: `${cartillaRoot}/?caps#1`
};