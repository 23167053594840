import instance from "../services/AxiosService"; 
import { useEffect } from 'react';
import { TRAMITE_NAME } from '../shared/globalConstants';
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setHttpError } from "../store/ui/uiActions";
import { compareGenericException, getExceptionFromList, getGenericExceptions, IException } from "../services/Exceptions";
import { compareReintegrosException, getReintegrosExceptions } from "../modules/tramites/reintegros/services/ReintegrosExceptions";
import { RootState } from "../store";
import { routes } from "../modules/home/router/routes";

interface IProps {
  tramite?: TRAMITE_NAME,
  showErrorAlert?: boolean,
  overrideAlertAction?: (...args: any[]) => void
}

interface useHttpErrorHandlerResponse{
  httpError: IException, 
  errorConfirmedHandler: () => void
}

const useHttpErrorHandler = (props?: IProps): useHttpErrorHandlerResponse => {
  const { tramite, showErrorAlert, overrideAlertAction } = (typeof props !== 'undefined' && props) || {};
  const { httpError } = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();


  //#region Interceptors
  const requestInterceptor = instance.interceptors.request.use(
    (request) => request,
    (error) => Promise.reject(error)
  );


  const responseInterceptor = instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const exception = getException(error?.response);
      if (overrideAlertAction) {
        exception.alertConfirmAction = overrideAlertAction;
      }
      if(showErrorAlert !== undefined) {
        exception.showAlert = showErrorAlert;
      }
      dispatch(setHttpError(exception))
    }
  );


  useEffect(() => {
    return () => {
      instance.interceptors.request.eject(requestInterceptor);
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, [requestInterceptor, responseInterceptor]);
  //#endregion


  const getException = (errorResponse): IException => {
    let returnedException: IException;
    switch (tramite) {
      case 'REINTEGROS':
        returnedException = getExceptionFromList(
          getReintegrosExceptions(history),
          errorResponse,
          compareReintegrosException
        );
        break;
      case 'FACTURACION':
        if (errorResponse?.status === 500) return;
        break;
      case 'DATOS_DE_CONTACTO':
        if (errorResponse?.status !== 500 || !errorResponse?.data?.code) break;
        
        returnedException = {
          code: errorResponse.data.code,
          name: 'DatosDeContactoException',
          field: 'exception',
          title: 'Importante',
          message: errorResponse.data.message,
          internalMessage: 'El servidor no está disponible temporalmente',
          showAlert: true,
          alertConfirmAction: () => history.replace(routes.root)
        }
        break;
    }
    return (
      returnedException ??
      getExceptionFromList(
        getGenericExceptions(history),
        errorResponse,
        compareGenericException
      )
    );
  };


  const errorConfirmedHandler = () => dispatch(setHttpError(null));


  return {httpError, errorConfirmedHandler};
};


export default useHttpErrorHandler;