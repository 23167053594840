import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonLabel, IonPage, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import analyticsService from '../../../../../services/analytics/analytics';
import Header from '../../../../../shared/components/layout/header/Header';
import Loading from '../../../../../shared/components/popups/loading/Loading';
import { RootState } from '../../../../../store';
import { setDatosContactoStore } from '../../../../../store/datos-contacto/datosContactoActions';
import { DatosContactoDataType, DomicilioDataType, domicilioType, typeFromEnum } from '../../../../../store/datos-contacto/types/DatosContactoData';
import EditAddressInfo from './components/edit-address-info/EditAddressInfo';
import EditPhonesInfo from './components/edit-phones-info/EditPhonesInfo';
import datosDeContactoService from '../../services/DatosContactoService';
import styles from './EditAddressPage.module.scss';
import { rootRoute } from '../../router/routes'
import useHttpErrorHandler from '../../../../../hooks/useHttpErrorHandler';
import { routes as homeRoutes } from '../../../../home/router/routes'
import AlertPopup from '../../../../../shared/components/popups/alerts/alert/AlertPopup';

interface Params {
  type: domicilioType;
  typeFrom: typeFromEnum;
}

const EditAddressPage = () => {
  const { type, typeFrom } = useParams<Params>();
  const history = useHistory();
  const datosContactoState = useSelector((state: RootState) => state.datosContacto);
  const { datosContacto, idTramite } = datosContactoState;
  const {httpError : httpRequestError, errorConfirmedHandler: clearHttpRequestError} = useHttpErrorHandler({ tramite: 'DATOS_DE_CONTACTO', showErrorAlert: false });
  const [isAddressFormValid, setIsAddressFormValid] = useState(true);
  const [domicilio, setDomicilio] = useState<DomicilioDataType>();
  const [hasChange, setHasChange] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPhoneInputModalOpen, setIsPhoneInputModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (domicilio) {
      setHasChange(JSON.stringify(domicilio) !== JSON.stringify(datosContacto[type]));
    };
  }, [datosContacto, domicilio, type]);


  useEffect(() => {
    if (datosContacto) {
      setDomicilio(
        type === 'residencia'
          ? { ...datosContacto.residencia }
          : { ...datosContacto.correspondencia }
      );
    } else {
      history.replace(rootRoute);
    }
  }, [datosContacto, type, history]);


  useEffect(() => {
    analyticsService.trackPageView(`tramites/datos-de-contacto/domicilio-de-${type}`);
  }, [type]);


  useEffect(() => {
    if (httpRequestError && isPhoneInputModalOpen) {
      analyticsService.trackEventFormNot('trackEventForm', 'datos de contacto > agregar teléfonos', `error - telefono: ${httpRequestError.message}`, 0);
    }
  }, [httpRequestError, isPhoneInputModalOpen]);


  const guardarDatos = async (datosDeContacto: DatosContactoDataType, idTramite: string) => {
    try {
      const { data: guardarDatosresponse } = await datosDeContactoService.guardarDatos({
        datosContacto: datosDeContacto,
        idTramite: idTramite,
        nativo: false,
      });
      delete guardarDatosresponse.nativo;
      dispatch(setDatosContactoStore({ ...datosContactoState, ...guardarDatosresponse, incompleteProcess: true }));
      let tipoDomicilio = type === 'residencia' ? 'domicilio-de-residencia' : 'domicilio-de-correspondencia';
      analyticsService.trackPageViewForm('trackPageviewForm', `tramites/datos-de-contacto/${tipoDomicilio}/formulario-enviado`,
        `datos de contacto > domicilio de ${type}`, 1);
      setIsLoading(false);
      history.replace(rootRoute);
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };


  const saveDomicilioChanges = () => {
    setIsLoading(true)
    const correctToggle = typeFrom === typeFromEnum.editar ? datosContacto.domicilioEqual : !datosContacto.domicilioEqual;
    if (type === 'residencia') {
      if (
        JSON.stringify(domicilio) === JSON.stringify(datosContacto?.correspondencia)
      ) {
        if (!datosContacto.domicilioEqual) {
          guardarDatos({ ...datosContacto, residencia: datosContacto.correspondencia, domicilioEqual: correctToggle }, idTramite)
        }
        setIsLoading(false)
        return history.replace(rootRoute);
      } else {
        datosContacto.residencia = domicilio;
        if (correctToggle) {
          datosContacto.correspondencia = domicilio;
        }
      }
    } else {
      if (
        JSON.stringify(domicilio) === JSON.stringify(datosContacto?.residencia)
      ) {
        if (!datosContacto.domicilioEqual) {
          guardarDatos({ ...datosContacto, correspondencia: datosContacto.residencia, domicilioEqual: correctToggle }, idTramite)
        }
        setIsLoading(false)
        return history.replace(rootRoute);
      } else {
        datosContacto.correspondencia = domicilio;
        if (correctToggle) {
          datosContacto.residencia = domicilio;
        }
      }
    }
    guardarDatos({ ...datosContacto, domicilioEqual: correctToggle }, idTramite);
  };


  return (
    <>
      {domicilio && (
        <IonPage>
          <Header showBackButton={true} />
          <IonContent className={styles['edit-adress']}>
            <IonGrid className="ion-no-padding">
              <EditAddressInfo
                domicilio={domicilio}
                setDomicilio={setDomicilio}
                domicilioEqual={datosContacto.domicilioEqual}
                setIsFormValid={setIsAddressFormValid}
                type={type}
                typeFrom={typeFrom}
              />
              <EditPhonesInfo
                type={type}
                setDomicilio={setDomicilio}
                domicilio={domicilio}
                setIsPhoneInputModalOpen={setIsPhoneInputModalOpen}
              />
            </IonGrid>
          </IonContent>
          <IonFooter className={`${styles.footer} ion-padding ion-no-border`}>
            <IonRow>
              <IonCol>
                <IonLabel color="primary">
                  * Los datos son obligatorios
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonButton
                  mode='md'
                  expand="block"
                  onClick={() => {
                    history.replace(rootRoute);
                  }}
                  color="tertiary"
                  fill="outline"
                >
                  Cancelar
                </IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton
                  mode='md'
                  expand="block"
                  disabled={!hasChange || !isAddressFormValid}
                  onClick={saveDomicilioChanges}
                >
                  Guardar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonFooter>
          <Loading isOpen={isLoading} message={'Actualizando...'}></Loading>
        </IonPage>
      )}

      <AlertPopup
        isOpen={!!httpRequestError}
        title={httpRequestError?.title}
        message={httpRequestError?.message}
        buttons={[{
          text: 'Aceptar', handler: () => {
            if (!isPhoneInputModalOpen) {
              history.replace(homeRoutes.root);
            }
            clearHttpRequestError();
          }
        }]}
      />
    </>
  );
};

export default EditAddressPage;