import { IonList, IonRadioGroup, IonItem, IonLabel, IonRadio } from '@ionic/react';
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { IntegranteAutorizacionesDataType } from '../../../../store/autorizaciones/types/AutorizacionesData';
import { IntegranteReintegrosDataType } from '../../../../store/reintegros/types/ReintegrosData';
import styles from './SociosList.module.scss'

export interface SociosListProps {
    list: IntegranteAutorizacionesDataType[] | IntegranteReintegrosDataType[];
    selectedSocio: string;
    setSelectedSocio:  Dispatch<SetStateAction<any>>
}
 
const SociosList: React.FC<SociosListProps> = ({list, selectedSocio, setSelectedSocio}) => {
    useEffect(() => {
        if (list.length === 1) {
          setSelectedSocio(list[0].beneficiario);
        }
      }, [list, setSelectedSocio]);

    return (  
        <IonList className={styles.sociosList}>
            <IonRadioGroup id="radio" onIonChange={e => setSelectedSocio(e.detail.value)} value={selectedSocio}>
                {(list as any[])?.map((socio, index) => {
                    return (
                        <IonItem key={index} className={[styles.socioRadio, 'ion-text-center ion-no-padding'].join(' ')}>
                            <IonLabel>
                                <h2>{socio.nombre}</h2>
                                <h3>Nº Socio: {socio.filial}{socio.afiliado}{socio.beneficiario}</h3>
                            </IonLabel>
                            <IonRadio slot='end' id={`radio-socia-${index}`} value={socio.beneficiario} mode='ios'/>
                        </IonItem>
                    );
                })}
            </IonRadioGroup>
        </IonList>
    );
}
 
export default SociosList;