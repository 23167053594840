import ticketError from '../assets/img/ticket-error.svg';
import iconoTel from '../assets/img/icono-tel.jpg';
import ticket from '../assets/img/Ticket.svg';
import { PHONE_NUMBER_ATENTION } from '../../../../shared/globalConstants';
import {routes as pagoEnLineaRoutes} from '../router/routes';
import { routes } from '../../../home/router/routes';

export const getError = (errorCode:string) => {
  return errors.find((error) => error.codeError === errorCode);
};

const isNuevoPagoAction = (history) => {
  history.replace(pagoEnLineaRoutes.payments);
};

const errors = [
  {
    codeError: '400',
    textError: 'NO FUE POSIBLE PROCESAR TU PAGO',
    secondText: 'Por favor, intentá con otra tarjeta de crédito o débito.',
    button: 'VOLVÉ A INTENTARLO',
    img: ticketError,
    isNuevoPago: true,
    action: isNuevoPagoAction,
  },
  {
    codeError: '402',
    textError: 'NO FUE POSIBLE PROCESAR TU PAGO',
    secondText:
      'Por favor, intentá nuevamente más tarde o comunicate con nosotros.',
    button: 'LLAMANOS',
    img: ticketError,
    imgButton: iconoTel,
    isLlamar: true,
    phoneNumberAtention: PHONE_NUMBER_ATENTION,
    action: (history) => {
      window.location.href = `tel:${PHONE_NUMBER_ATENTION}`;
    },
  },
  {
    codeError: '403', // CUANDO NO HAY COMPROBANTES DE PAGO
    textError:
      'POR EL MOMENTO NO REGISTRAMOS NINGÚN PAGO',
    button: 'NUEVO PAGO',
    img: ticketError,
    isNuevoPago: true,
    action: isNuevoPagoAction,
  },
  {
    codeError: '404', // QUE CAXO HAY QUE HACER
    textError: 'EN ESTE MOMENTO NO SE REGISTRAN PERÍODOS PENDIENTES DE PAGO',
    secondText: '',
    img: ticket,
  },
  {
    codeError: '406', // CODIGO PARA CUANDO LOS DATOS SON INVALIDOS
    textError: 'NO FUE POSIBLE PROCESAR TU PAGO.',
    secondText:
      'Por favor, verificá que los datos ingresados de la tarjeta sean correctos y volvé a intentarlo.',
    button: 'VOLVÉ A INTENTARLO',
    img: ticketError,
    isNuevoPago: true,
    action: isNuevoPagoAction,
  },
  {
    codeError: '407', // CODIGO PARA CUANDO LA TARJETA RECHAZO EL PAGO
    textError: 'NO FUE POSIBLE PROCESAR TU PAGO.',
    secondText:
      'La entidad emisora de tu tarjeta rechazó el pago. Por favor, intentá con otro medio de pago.',
    button: 'VOLVÉ A INTENTARLO',
    img: ticketError,
    isNuevoPago: true,
    action: isNuevoPagoAction,
  },
  {
    codeError: '408', // CODIGO PARA CUANDO LA TARJETA NO TIENE SALDO
    textError: 'TU TARJETA RECHAZÓ EL PAGO.',
    secondText: 'Por favor, intentá con otra tarjeta de crédito o de débito.',
    button: 'VOLVÉ A INTENTARLO',
    img: ticketError,
    isNuevoPago: true,
    action: isNuevoPagoAction,
  },
  {
    codeError: '409', // CODIGO PARA CUANDO YA SE ESTA EFECTUANDO EL PAGO
    textError: 'NO FUE POSIBLE PROCESAR TU PAGO.',
    secondText: 'Ya se esta efectuando el pago para este talón.',
    button: 'VOLVER',
    img: ticketError,
    isNuevoPago: true,
    action: isNuevoPagoAction,
  },
  {
    codeError: '500', // ERROR INESPERADO
    textError:
      'EN ESTE MOMENTO LA APLICACIÓN DE PAGO EN LÍNEA NO SE ENCUENTRA DISPONIBLE',
    secondText: 'Por favor, intentá nuevamente más tarde.',
    button: 'VOLVER A INICIO',
    img: ticketError,
    isGeneral: true,
    action: (history) => {
      history.replace(routes.root);
    },
  },
  {
    codeError: '333', // CODIGO PARA CUANDO LA TARJETA ESTA VENCIDA
    textError: 'NO FUE POSIBLE PROCESAR TU PAGO.',
    secondText: 'Por favor, intentá con otro medio.',
    button: 'VOLVÉ A INTENTARLO',
    img: ticketError,
    isNuevoPago: true,
    action: isNuevoPagoAction,
  },
];
