import { IonContent, IonFooter, IonImg, IonList, IonPage, IonSelectOption } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import InputForm from '../../../../../shared/components/form/inputForm/InputForm';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import styles from './StepDatosFacturacion2Page.module.scss'
import { documentTextOutline } from 'ionicons/icons';
import DateTime from '../../../../../shared/components/form/date-time/DateTime';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { setNuevoReintegro } from '../../../../../store/reintegros/ReintegrosActions';
import { IDComprobantes, ReintegroDataType, TipoSimpleDataType } from '../../../../../store/reintegros/types/ReintegrosData';
import SelectForm from '../../../../../shared/components/form/select-form/SelectForm';
import Popover from '../../components/popover/Popover';
import comprobanteImg from '../../assets/img/tipo-nro-fecha-comp.png'
import { fillMask, ionDateTimeToISOString, isNaNOnKeyPress, isNaNOnPaste, isWeb, validateMinDate } from '../../../../../shared/utils/utils';
import { useHistory, useLocation } from 'react-router';
import Header from '../../../../../shared/components/layout/header/Header';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { routes } from '../../router/routes';
import { routes as homeRoutes } from '../../../../home/router/routes';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import analyticsService from '../../../../../services/analytics/analytics';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';


const StepDatosFacturacion2Page: React.FC = () => {

    const {nuevoReintegro, reintegros: {listaTipoComprobante}}: {nuevoReintegro?: ReintegroDataType, reintegros?: {listaTipoComprobante: TipoSimpleDataType[] }}
     = useSelector((state: RootState) => state.reintegros.reintegros ? state.reintegros : {nuevoReintegro: undefined, reintegros: {listaTipoComprobante: undefined}});
    const {isKeyboardOn} = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation<{from: string}>();
    const [showComprobantePopover, setShowComprobantePopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });

    useEffect(() => {
        if (!listaTipoComprobante || listaTipoComprobante.length === 0) {
            history.push(homeRoutes.gestionesOnline);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    interface FacturacionFormProperties {
        comprobante: TipoSimpleDataType | '',
        puntoVenta: string,
        nroComprobante: string,
        fechaEmision: string,
    }

    const { control, handleSubmit, errors, clearErrors } = useForm<FacturacionFormProperties>({
        mode: 'onSubmit',
        defaultValues: {
            comprobante: nuevoReintegro?.tipoComprobante ?? '',
            puntoVenta: fillMask(nuevoReintegro?.nroComprobantePrefijo, '00000') ?? '',
            nroComprobante: fillMask(nuevoReintegro?.nroComprobante, '00000000') ?? '',
            fechaEmision: nuevoReintegro?.fechaEmision ?? ''
        }
    });

    useEffect(() => {
        if(errors.comprobante) 
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.comprobante}` , routes.datosFacturacion2);
        if(errors.fechaEmision)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.fechaEmision}` , routes.datosFacturacion2);
        if(errors.nroComprobante)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.nroComprobante}` , routes.datosFacturacion2); 
        if(errors.puntoVenta)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.puntoVenta}` , routes.datosFacturacion2);      
    }, [errors])

    const onSubmit = (dataForm: any) => {
        const nuevoReintegroUpdated: ReintegroDataType = {
            ...nuevoReintegro!,
            tipoComprobante: dataForm.comprobante,
            nroComprobantePrefijo: dataForm.puntoVenta,
            nroComprobante: dataForm.nroComprobante,
            fechaEmision: ionDateTimeToISOString(dataForm.fechaEmision)
        };
        dispatch(SetStepperNavigation(Animations.next))
        dispatch(setNuevoReintegro(nuevoReintegroUpdated));
        (state?.from === routes.verificaDatos) ? history.goBack() : history.push(routes.datosFacturacion3);
    }

    const footer = () => (
        <IonFooter className='ion-no-border'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <NavigationButton text='Datos obligatorios(*)' type='submit'>SIGUIENTE</NavigationButton>
            </form>               
            {isWeb() && <Footer />}
        </IonFooter>
    )

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles.facturacion2}>

                <PageTitle>
                    Reintegros online
                </PageTitle>

                <Stepper stepsLenght={nuevoReintegro?.tipoComprobante?.id === IDComprobantes.Ticket ? 6 : 7} currentNumber={3} />

                <StepTitle>
                    Datos de facturación
                </StepTitle>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonList className='ion-margin'>

                        <Controller
                            control={control}
                            name='comprobante'
                            rules={{ required: { value: true, message: "Por favor, completá el tipo de comprobante" } }}
                            render={({ onChange, value }) => (
                                <SelectForm
                                    label='Comprobante (*)'
                                    labelIcon={documentTextOutline}
                                    labelIconOnClick={e => setShowComprobantePopover({ open: true, event: e.nativeEvent })}
                                    placeHolder='Factura, recibo, remito, etc'
                                    onChange={(e) => { clearErrors(['comprobante']); onChange(e.detail.value) }}
                                    value={value}
                                    header='Lista de comprobantes'
                                    subHeader='Elegí el tipo de comprobante'
                                    errors={errors.comprobante?.message}
                                    compareWith={(o1: TipoSimpleDataType, o2: TipoSimpleDataType) => (o1 && o2 ? o1.id === o2.id : o1 === o2)}
                                >
                                    {listaTipoComprobante?.map((tipoComp: TipoSimpleDataType) => (
                                        <IonSelectOption key={tipoComp.id} value={tipoComp}>{tipoComp.descripcion}</IonSelectOption>
                                    ))}
                                </SelectForm>
                            )}
                        />

                        <Controller
                            control={control}
                            name='puntoVenta'
                            rules={{ required: { value: true, message: "Por favor, completá el punto de venta" } }}
                            render={(props) => (
                                <InputForm
                                    label='Punto de venta (*)'
                                    inputmode='numeric'
                                    placeHolder='00000'
                                    onBlur={(e) => { props.onChange(+e.detail.target.value === 0 ? '' : fillMask(e.detail.target.value, '00000')) }}
                                    onKeyPress={(e) => isNaNOnKeyPress(e) && e.preventDefault()}
                                    onPaste={e => isNaNOnPaste(e) && e.preventDefault()}
                                    maxLength={5}
                                    errors={errors.puntoVenta?.message}
                                    value={props.value}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name='nroComprobante'
                            rules={{ required: { value: true, message: "Por favor, completá el número de comprobante" } }}
                            render={(props) => (
                                <InputForm
                                    label='Nro. de comprobante (*)'
                                    placeHolder='00000000'
                                    maxLength={8}
                                    inputmode='numeric'
                                    onBlur={(e) => { props.onChange(+e.detail.target.value === 0 ? '' : fillMask(e.detail.target.value, '00000000')) }}
                                    onKeyPress={(e) => isNaNOnKeyPress(e) && e.preventDefault()}
                                    onPaste={e => isNaNOnPaste(e) && e.preventDefault()}
                                    errors={errors.nroComprobante?.message}
                                    value={props.value}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name='fechaEmision'
                            rules={{
                                required: { value: true, message: 'Por favor, completá la fecha de emisión' },
                                validate: {
                                    maxDate: value => new Date(value) < new Date() || 'La fecha de emisión no puede ser superior a la del dia de hoy',
                                    minDate: value => validateMinDate(value, 1) || 'La fecha de emisión debe ser hasta un año anterior a la del dia de hoy'
                                }
                            }}
                            render={(props) => (
                                <DateTime
                                    label='Fecha de emisión (*)'
                                    onChange={e => { clearErrors(["fechaEmision"]); props.onChange(e.detail.value) }}
                                    error={errors.fechaEmision?.message}
                                    value={props.value} />
                            )}
                        />

                    </IonList>
                </form>
                {isKeyboardOn && footer()}
            </IonContent>

            {!isKeyboardOn && footer()}

            <Popover
                event={showComprobantePopover.event}
                isOpen={showComprobantePopover.open}
                onDidDismiss={e => setShowComprobantePopover({ open: false, event: undefined })}>
                <IonImg src={comprobanteImg} />
            </Popover>
        </IonPage>
    );
}


export default StepDatosFacturacion2Page;