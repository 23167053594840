import axios from 'axios';
import dayjs from 'dayjs';
import { isWebDev, isWebLocal, isWebPreProd, isWebTesting } from '../../shared/utils/utils';

export enum environment {
  local = 'local',
  desa = 'desa',
  test = 'tm',
  prem = 'prem',
  prod = 'producion'
}

export interface environmentUrls {
  id: environment;
  baseUrlProxy: string;
  staticContent: string;
  protocol: string;
  webBaseUrl: string;
  urlDecidir: string;
  urlPedidoMedico: string;
  urlCartilla: string;
  urlLoginLowEnvironment: string;
  urlWebMobile: string;
  themeId:string;
  urlIBM?: string;
  clientId?: string;
  redirectURI?: string;
  redirectRegistrationCallback?: string;
  urlHomeHubspot?: string;
  urlWebResponsive?: string
}

const values: environmentUrls[] = [
  {
    id: environment.prod,
    baseUrlProxy: 'https://m.osde.com.ar/proxy/rest',
    staticContent:
      'https://cdn.contentful.com/spaces/9fnqsdv11sgp/environments/master/entries',
    webBaseUrl: 'https://www.osde.com.ar',
    urlDecidir: 'https://live.decidir.com/api/v2',
    urlPedidoMedico: 'https://asistenciamedica.osde.com.ar/',
    urlCartilla: 'https://www.osde.com.ar/cartilla-inteligente',
    urlWebMobile: 'https://m.osde.com.ar',
    protocol: 'https',
    urlLoginLowEnvironment:'',
    themeId: 'c329a247-25f0-4029-93fe-1cb4cb42de64',
    urlIBM: 'https://id.osde.com.ar/oidc/endpoint/default/authorize',
    clientId: '6f19a10e-3a88-45b0-9994-f12edf1a6c3b',
    redirectURI: 'https://m.osde.com.ar/proxy/rest/CloudIdentityService/token',
    redirectRegistrationCallback: 'https://m.osde.com.ar/proxy/rest/CloudIdentityService/registrationCallback',
    urlWebResponsive: 'https://www.osde.com.ar/mis-tramites',
    urlHomeHubspot: 'https://www.osde.com.ar/gestiones-online'
  },
  {
    id: environment.prem,
    baseUrlProxy: 'https://prem.osde.ar/proxy/rest',
    staticContent:
      'https://cdn.contentful.com/spaces/9fnqsdv11sgp/environments/master/entries',
    webBaseUrl: 'https://web-sociost.test-apps.com.ar',
    urlDecidir: 'https://developers.decidir.com/api/v2',
    urlPedidoMedico: 'https://app-socios-frontend-zrvedpo66q-uc.a.run.app/',
    urlCartilla: 'https://web-sociost.test-apps.com.ar/cartilla-inteligente',
    urlWebMobile: 'https://prem.osde.ar',
    protocol: 'https',
    urlLoginLowEnvironment:'https://web-sociost.test-apps.com.ar/.well-known/cluster/login?code=u5BH2nuryGrPWD76',
    themeId: 'b02dc991-7d77-42e3-a7b2-c3ef2426eebb',
    urlIBM: 'https://idosde-test.ice.ibmcloud.com/oidc/endpoint/default/authorize',
    clientId: 'eab4a79a-a375-4fec-9279-c8d02d83529b',
    redirectURI: 'https://prem.osde.ar/proxy/rest/CloudIdentityService/token',
    redirectRegistrationCallback: 'https://prem.osde.ar/proxy/rest/CloudIdentityService/registrationCallback',
    urlWebResponsive: 'https://web-sociost.test-apps.com.ar/mis-tramites',
    urlHomeHubspot: 'https://web-sociost.test-apps.com.ar/gestiones-online'
  },
  {
    id: environment.test,
    baseUrlProxy: 'https://tm.osde.ar/proxy/rest',
    staticContent:
      'https://cdn.contentful.com/spaces/9fnqsdv11sgp/environments/master/entries',
    webBaseUrl: 'https://web-sociost.test-apps.com.ar',
    urlDecidir: 'https://developers.decidir.com/api/v2',
    urlPedidoMedico: 'https://app-socios-frontend-zrvedpo66q-uc.a.run.app/',
    urlCartilla: 'https://web-sociost.test-apps.com.ar/cartilla-inteligente',
    urlWebMobile: 'https://tm.osde.ar',
    protocol: 'https',
    urlLoginLowEnvironment:'https://web-sociost.test-apps.com.ar/.well-known/cluster/login?code=u5BH2nuryGrPWD76',
    themeId: 'b02dc991-7d77-42e3-a7b2-c3ef2426eebb',
    urlIBM: 'https://idosde-test.ice.ibmcloud.com/oidc/endpoint/default/authorize',
    clientId: 'eab4a79a-a375-4fec-9279-c8d02d83529b',
    redirectURI: 'https://tm.osde.ar/proxy/rest/CloudIdentityService/token',
    redirectRegistrationCallback: 'https://tm.osde.ar/proxy/rest/CloudIdentityService/registrationCallback',
    urlWebResponsive: 'https://web-sociost.test-apps.com.ar/mis-tramites',
    urlHomeHubspot: 'https://web-sociost.test-apps.com.ar/gestiones-online'
  },
  {
    id: environment.desa,
    baseUrlProxy: 'https://10.214.33.234/proxy/rest',
    staticContent:
      'https://cdn.contentful.com/spaces/9fnqsdv11sgp/environments/master/entries',
    webBaseUrl: 'https://web-sociosd.test-apps.com.ar',
    urlDecidir: 'https://developers.decidir.com/api/v2',
    urlPedidoMedico: 'https://app-socios-frontend-zrvedpo66q-uc.a.run.app/',
    urlCartilla: '10.214.33.234',
    urlWebMobile: 'https://10.214.33.234',
    protocol: 'https',
    urlLoginLowEnvironment:'https://web-sociost.test-apps.com.ar/.well-known/cluster/login?code=u5BH2nuryGrPWD76',
    themeId: '569aad77-a0b6-4cec-9c3e-17f29d923d83',
    urlIBM: 'https://idosde-dev.ice.ibmcloud.com/oidc/endpoint/default/authorize',
    clientId: 'dff18daa-920a-490e-aac2-137b58643ea3',
    redirectURI: 'https://10.214.33.234/proxy/rest/CloudIdentityService/token',
    redirectRegistrationCallback: 'https://10.214.33.234/proxy/rest/CloudIdentityService/registrationCallback',
    urlWebResponsive: 'https://web-sociosd.test-apps.com.ar/mis-tramites',
    urlHomeHubspot: 'https://web-sociosd.test-apps.com.ar/gestiones-online'
  },
  {
    id: environment.local,
    baseUrlProxy: 'http://localhost:8080/proxy/rest', // En caso de querer cambiar de ambiente hacerlo apuntando cn http, ejemplo, http://tm.osde.ar
    staticContent:
      'https://cdn.contentful.com/spaces/9fnqsdv11sgp/environments/master/entries',
    webBaseUrl: 'https://www.osde.com.ar',
    urlDecidir: 'https://developers.decidir.com/api/v2',
    urlPedidoMedico: 'https://app-socios-frontend-zrvedpo66q-uc.a.run.app/',
    urlCartilla: 'http://localhost:8080',
    urlWebMobile: 'http://localhost:8080',
    protocol: 'http',
    urlLoginLowEnvironment:'https://web-sociost.test-apps.com.ar/.well-known/cluster/login?code=u5BH2nuryGrPWD76',
    themeId: '569aad77-a0b6-4cec-9c3e-17f29d923d83',
    urlIBM: 'https://idosde-dev.ice.ibmcloud.com/oidc/endpoint/default/authorize',
    clientId: 'dff18daa-920a-490e-aac2-137b58643ea3',
    redirectURI: 'https://m.osde.com.ar/proxy/rest/CloudIdentityService/token',
    redirectRegistrationCallback: 'http://localhost:8080/proxy/rest/CloudIdentityService/registrationCallback',
    urlWebResponsive: 'https://web-sociosd.test-apps.com.ar/mis-tramites',
    urlHomeHubspot: 'https://web-sociosd.test-apps.com.ar/gestiones-online'
  },
];

interface EnvironmentService {
  getCurrent: () => environmentUrls;
  verifyVPN: () => Promise<boolean>;
  getEnvironment: (id) => environmentUrls;
  clearOutdatedEnvironment: () => void;
  cleanEnvironment: () => void;
  getEnvironmentName:() => string; 
}

const environmentService: EnvironmentService = {
  getEnvironmentName:() => {
    const currentSavedEnv = localStorage.getItem('environment');
    if (!currentSavedEnv){
      return "produccion";
    }
    const env:environmentUrls = JSON.parse(currentSavedEnv);
    switch(env.id){
      case environment.desa:
        return "desarrollo";
      case environment.local:
        return "local";
      case environment.prem:
        return "prem";
      case environment.test:
        return "testing";
      case environment.prod:
        return "produccion";
    }
  },
  cleanEnvironment: () => {
    localStorage.removeItem('environment');
  },
  getCurrent: () => {
    const currentSavedEnv = localStorage.getItem('environment');
    if(!currentSavedEnv){
      let env = values[0];
      if(isWebLocal()){
        env = values[4];
      }else if(isWebDev()){
        env = values[3];
      }else if(isWebTesting()){
        env = values[2];
      }else if(isWebPreProd()){
        env = values[1];
      }
      localStorage.setItem('environment', JSON.stringify(env));
      return env;
    }
    return JSON.parse(currentSavedEnv) as environmentUrls;
  },
  verifyVPN: async () => {
    try {
      const res = await axios.get(`${values[1].baseUrlProxy}/version?v`);
      if (res.status === 200) {
        return true;
      }
    } catch (error) {
      localStorage.removeItem('environment');
      localStorage.removeItem('environmentDate');
      return false;
    }
  },
  getEnvironment: (id) => {
    return values.find((env) => env.id === id);
  },
  clearOutdatedEnvironment: () => {
    const envDate = dayjs(localStorage.getItem('environmentDate'), 'DD/MM/YYYY');
    const nowDate = dayjs(dayjs().format('DD/MM/YYYY'), 'DD/MM/YYYY');
    if (envDate.isBefore(nowDate)) {
      localStorage.removeItem('environment');
      localStorage.removeItem('environmentDate');
    }
  }
};

export default environmentService;