import React from "react";
import { IContacto } from "../types/contactosTypes";
import Section from "./section/Section";

interface ContactosWebNativeProps {
  contactos: IContacto[];
}

const ContactosWebNative: React.FC<ContactosWebNativeProps> = ({contactos}) => {
  return (
    <>
      <Section
        title="ASISTENCIA MÉDICA"
        contactos={contactos}
        styles="asistenciaMedica"
      />

      <Section
        title="RIESGO DE VIDA"
        contactos={contactos}
        styles="riesgoVida"
      />

      <Section
        title="ATENCIÓN AL SOCIO"
        subtitle={<>Lunes a viernes de 9 a 20 h.</>}
        contactos={contactos}
        styles="atencionSocio"
      />
    </>
  );
};

export default ContactosWebNative;