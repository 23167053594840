export enum AutorizacionesTramites {
    Medicamentos = 'MEDICAMENTOS',
    TrasladosProgramados = 'TRASLADO',
    PracticasEstudios = 'AUT_PRACT_EST',
    InternacionCirugia = 'AUT_INT_CIR'
}

export enum AutorizacionesTramitesGA {
    MEDICAMENTOS = 'medicamentos',
    TRASLADO = 'traslados-programados',
    AUT_PRACT_EST = 'practicas-estudios',
    AUT_INT_CIR = 'internacion-cirugia'
}