/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const ProteccionDatos: React.FC = () => {

    const divStyle = {
        textAlign: 'justify',
        lineHeight: 'normal',
    } as React.CSSProperties;

    return (
        <div style={divStyle}>
            Responsable registrado <br /><br />
            
            Los datos aportados forman parte de un banco de datos de OSDE, con domicilio
            en L.N.Alem 1067 piso 9 CABA y serán tratados para brindar servicios a los
            asociados.<br />        
            El titular de los datos personales no se encuentra obligado a proporcionar
            datos sensibles. Asi mismo, el titular se responsabiliza por la exactitud de
            los datos proporcionados.<br />
            El titular de los datos personales tiene la facultad de ejercer el derecho de
            acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses,
            salvo que se acredite un interés legítimo al efecto conforme lo establecido en
            el artículo 14, inciso 3 de la Ley Nº 25.326.<br />        
            "LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de
            Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y
            reclamos que interpongan quienes resulten afectados en sus derechos por
            incumplimiento de las normas vigentes en materia de protección de datos
            personales".
        </div>
    );
};

export default ProteccionDatos;
