import { IonItem, IonIcon } from '@ionic/react';
import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import FormErrorMessage from '../form-error-message/FormErrorMessage';
import styles from './MaskedInputForm.module.scss';


export interface InputFormProps {
    label: string;
    register?: any;
    info?: string;
    labelIcon?: string;
    labelIconOnClick?: (e: any) => void;
    error?: boolean;
    errors?: string;
    inputIcon?: string;
    inputIconOnClick?: (e: any) => void;
    numberFormat?: NumberFormatProps
}


const MaskedInputForm: React.FC<InputFormProps> = (props) => {
    return (
        <>
            <div className={[styles['masked-input-tramite'], 'ion-align-items-center', 'ion-text-center'].join(' ')}>
                <label>
                    {props.label}
                    {props.labelIcon && <IonIcon color='secondary' icon={props.labelIcon} slot='end' onClick={props.labelIconOnClick} />}
                </label>
                {props.info && <p className={styles.info}>{props.info}</p>}

                <IonItem lines="none" className={['ion-align-items-center', 'ion-text-center', props.error && styles['error-validation'], props.numberFormat?.readOnly && styles.readonly].join(' ')}>
                    <NumberFormat
                        autoComplete='off'
                        getInputRef={props.register}
                        {...props.numberFormat}
                    />
                </IonItem>

                {props.inputIcon && <IonIcon icon={props.inputIcon} slot="end" className={styles['input-icon']} onClick={props.inputIconOnClick} />}
            </div>
            {props.errors && <FormErrorMessage>{props.errors}</FormErrorMessage>}
        </>
    );
}

export default MaskedInputForm;