import React, { useEffect, useState } from "react";
import Legal, { LegalType } from "../legal/Legal";
import styles from './FooterHome.module.scss';
import AlertPopup from "../../../../shared/components/popups/alerts/alert/AlertPopup";
import StandardModal from "../../../../shared/components/popups/modals/standard-modal/StandardModal";
import { FOOTER_COPYRIGHT } from "../../../../shared/globalConstants";

const FooterHome: React.FC = () => {
  const [showLegalesModal, setShowLegalesModal] = useState<boolean>(false);
  const [showDefensaAlConsumidorAlert, setShowDefensaAlConsumidorAlert] = useState<boolean>(false);
  const [legalType, setLegalType] = useState<LegalType>();

  useEffect(() => {
    setShowLegalesModal(!!legalType);
  }, [legalType]);  
    
  const closeLegalesModal = () => {
    setShowLegalesModal(false);
    setLegalType(null);
  };

  const onClickLegal = (legal: LegalType) => setLegalType(legal);

  return (
    <>
      <footer className={styles.homeWebFooter}>
        <img
          alt=""
          className={styles.osdeLogoFooter}
          src={`${process.env.PUBLIC_URL}/assets/home/osde_home_footer_50.svg`}
        />
        <div className={styles.footerText}>
          <ul className={styles.footerLinks}>
            <li id="id-acuerdo-usuario" onClick={() => onClickLegal(LegalType.ACUERDO_USUARIO)}>
              Acuerdo con el usuario
            </li>
            <li id="id-politicas-privacidad" onClick={() => onClickLegal(LegalType.POLITICA_PRIVACIDAD)}>
              Politicas de privacidad
            </li>
            <li id="id-defensa-consumidores" onClick={() => setShowDefensaAlConsumidorAlert(true)}>
              Defensa de las y los consumidores
            </li>
            <li>
              <a href="/formulario-baja/">Baja del servicio</a>
            </li>
          </ul>

          <p className={styles.legalText}>
            Para conocer el detalle de la Dirección Nacional de Datos Personales, información al socio, defensa al consumidor
            y libro de quejas online, ingresá desde una PC o tablet a la sección de Información adicional de nuestra web.<br />

            {FOOTER_COPYRIGHT}
          </p>
          <br />
        </div>

        <div className={styles.iconsContainerFooter}>
          <a href="https://ssl.comodo.com/">
            <img
              alt=""
              className={styles.iconComodo}
              src={`${process.env.PUBLIC_URL}/assets/home/comodo-secure-400x252.png`}
            />
          </a>
          <img
            alt=""
            id="id-datos-personales"
            onClick={() => onClickLegal(LegalType.DATOS_PERSONALES)}
            className={styles.iconDatosPersonales}
            src={`${process.env.PUBLIC_URL}/assets/home/RNBD.png`}
          />
        </div>
      </footer>

      <StandardModal
        title={legalType === LegalType.DATOS_PERSONALES ? "PROTECCIÓN DE LOS DATOS PERSONALES" : "LEGALES"}
        subtitle={legalType === LegalType.DATOS_PERSONALES && "Ley 25.326"}
        id="id-modal-legal"
        isOpen={showLegalesModal}
        handler={closeLegalesModal}
        content={<Legal type={legalType} />}
      />

      <AlertPopup
        isOpen={showDefensaAlConsumidorAlert}
        title="Importante"
        message="Con esta acción abandonarás la web de OSDE e ingresarás a la web de Defensa al Consumidor. Toda la información que ingreses en este sitio se encuentra fuera del dominio de OSDE."
        buttons={[
          { text: 'Continuar', handler: () => window.location.href = "https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario" },
          { text: 'Cancelar', handler: () => setShowDefensaAlConsumidorAlert(false) }
        ]}
      />
    </>
  );
};

export default FooterHome;