import { IonContent, IonMenu, IonHeader } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { IGroup } from '../../../../store/menu/types/MenuResponse';
import MenuGroup from './components/menu-group/MenuGroup';
import MenuLogin from './components/menu-login/MenuLogin';
import MenuUser from './components/menu-user/MenuUser';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../popups/spinner/Spinner';
import authService from '../../../../services/auth/AuthService';
import { setUser } from '../../../../store/users/actions/user';
import { RootState } from '../../../../store';
import styles from './Menu.module.scss';
import {  isWeb } from '../../../utils/utils';
import { cartillaRoutes } from '../../../../modules/home/cartilla/router/routes';
import { linksNative } from './helpers/linksNative';
import { linksWeb } from './helpers/linksWeb';
import { menuController } from '@ionic/core';
import { PlanSelectorAlert } from './components/plan-selector/PlanSelector';
import { routes as pagoEnLineaRoutes } from '../../../../modules/tramites/pago-en-linea/router/routes';

const Menu: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.user);
  const [menuOptions, setMenuOptions] = useState(new Array<IGroup>());
  const [openChangePlanModal, setOpenChangePlanModal] = useState<boolean>(false);

  const checkSession = async () => {
    const isActive = await authService.checkActiveSession();
    if (isActive) {
      const userFullData = (await authService.getUserFullData());
      if (userFullData) {
        dispatch(setUser(userFullData));
      }
    }
  };


  const getMenuItems = async () => {
    let links: Array<IGroup> = isWeb() ? linksWeb : linksNative;

    let filtered = [];

    links.forEach((group) => {
      let items = [];
      group.items.forEach((item) => {
        if(user){
          if(item.link === cartillaRoutes.misTurnos && !user.hasTurnosEnabled){
            return;
          }
          if(item.link === pagoEnLineaRoutes.root && !user.pagoEnLineaHabilitado){
            return;
          }
        } else{
          if(item.link === cartillaRoutes.misTurnos || item.link === cartillaRoutes.miCartilla){
            return;
          }
        }
        items.push(item);
      });
      filtered.push({ title: group.title, items: items });
    });
    setMenuOptions(filtered);
  };


  useEffect(() => {
    getMenuItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  useEffect(() => {
    checkSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <IonMenu side="start" menuId="first" contentId="main-content">
        <IonHeader className={styles.menu__header}>
          {user ? (
            <MenuUser onSetOpenPlanSelectorAlert={() => { menuController.close(); setOpenChangePlanModal(true) }} />
          ) : (
            <MenuLogin />
          )}
        </IonHeader>
        <IonContent>
          {menuOptions?.length > 0 ? (
            menuOptions.map((group, i) => (
              <MenuGroup key={i} items={group.items} title={group.title} />
            ))
          ) : (
            <div className={styles['menu__spinner-container']}>
              <Spinner />
            </div>
          )}
         
        </IonContent>
      </IonMenu>
      <PlanSelectorAlert
        title="Seleccioná el grupo familiar que querés gestionar"
        isOpen={openChangePlanModal}
        setIsOpen={() => setOpenChangePlanModal(false)}
        backdropOnClick={() => setOpenChangePlanModal(false)}
      />
    </>
  );
};

export default Menu;