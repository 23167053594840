import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IItem, MenuType } from '../../../../../../store/menu/types/MenuResponse';
import { setShowPhones } from '../../../../../../store/popUpTelefonos/phoneActions';
import { menuController } from '@ionic/core';
import styles from './MenuItem.module.scss';
import NavigationCleanUpWrapper from '../navigation-cleanup-wrapper/NavigationCleanUpWrapper';

const MenuItem: React.FC<IItem> = ({ name, link, type, items }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onPress = (e: any) => {
        switch (type) {
            case MenuType.picker:
                e?.preventDefault();
                menuController.close();
                dispatch(setShowPhones(items));
                break;
            case MenuType.tel:
                    window.location.href = `tel:${link}`;
                break;
            case MenuType.href:
                window.location.href = link;
                break;
            case MenuType.blank:
                window.open(link);
                break;
            case MenuType.whatsapp:
                window.location.href = `https://wa.me/${link}`;
                break;
            case MenuType.path:
                menuController.toggle();
                history.push(link);
                break;
        }
    }

    return (
        <div className={styles["menu__item-title"]}>
            <NavigationCleanUpWrapper onClickFunction={onPress}>
                <span id="id-link" className={history.location.pathname === link ? styles.active : ''}>
                    {name}
                </span>
            </NavigationCleanUpWrapper>
        </div>
    )
}

export default MenuItem
