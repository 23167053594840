import React from 'react'
import { IonItemDivider } from '@ionic/react';
import styles from "./SuccessDivider.module.scss";

const SuccessDivider: React.FC = () => {
  return (
    <IonItemDivider className={styles.successDivider} />
  )
}

export default SuccessDivider