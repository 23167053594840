import React from 'react'
import { IonItem, IonLabel } from '@ionic/react';
import styles from "./SuccessText.module.scss";

const SuccessText: React.FC<{noLines?: boolean}> = ({children, noLines}) => {
  return (
    <IonItem lines={noLines ? 'none' : 'full'} className={styles.successText}>
      <IonLabel className='ion-text-wrap ion-margin-top ion-margin-bottom'>
        {children}
      </IonLabel>
    </IonItem>
  )
}

export default SuccessText