import { IonRow } from '@ionic/react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { routes as pagoEnLineaRoutes } from '../../../../router/routes';
import { setUnavailable } from '../../../../../../../store/pagoEnLinea/actions';
import styles from './Countdown.module.scss';
import { useCountdown } from '../../../../../../../hooks/useCountdown';
import AlertPopup from '../../../../../../../shared/components/popups/alerts/alert/AlertPopup';

const Countdown: React.FC<{ time: number }> = ({ time }) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false)
  const { beginCountdown, timeLeft } = useCountdown(time, () => {
    dispatch(setUnavailable());
    setShowAlert(true);
  });

  const timeLeftToPay = (): string => {
    dayjs.extend(duration);
    const durationSeconds = timeLeft;
    const minutes = Math.floor(durationSeconds / 60);
    const seconds = durationSeconds % 60;
    const time = dayjs().set('minute', minutes).set('second', seconds).format('mm:ss');
    return time;
  };

  useEffect(() => {
    const ac = new AbortController();
    beginCountdown();
    return () => ac.abort();
  }, [beginCountdown]);

  return (
    <>
      <IonRow
        className={`${timeLeft === 0 ? styles.timeToPayDisabled : styles.timeToPayEnabled}`}
        id={`${timeLeft === 0 ? "deshabilitado" : "habilitado"}`}
      >
        Tenés {timeLeftToPay()} min para confirmar el pago
      </IonRow>
      <AlertPopup
        isOpen={showAlert}
        title='Error'
        message={'Lo sentimos, ha expirado el tiempo para realizar la transacción. Deberás ingregar tus datos nuevamente.'}
        buttons={[{
          text: 'Aceptar',
          role: 'cancel',
          cssClass: 'primary',
          handler: () => history.replace(pagoEnLineaRoutes.root)
        }]}
      />

    </>
  );
};

export default Countdown;