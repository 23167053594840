import React from 'react';
import { IonButton, IonIcon, IonItemDivider, IonModal, IonText } from '@ionic/react';
import { camera, chevronForward } from 'ionicons/icons';
import './CameraTASModal.scss'
import { motion } from 'framer-motion';

const cameraVariants = {
    animation: {
        scale: [1, 1.3, 1, 1.1, 1, 1, 1, 1, 1, 1, 1, 1],
        transition: {
            bounce: 0.25,
            duration: 2.3,
            repeat: Infinity,
        }
    }
}
const arrowVariants = {
    animation: {
        x: [0, -40, 0],
        transition: {
            x: {
                repeat: Infinity,
                repeatType: 'loop',
                duration: 0.6,
                ease: 'easeOut'
            }
        }
    }
};

const CameraTASModal: React.FC<{ isOpen: boolean; closeModal: () => void; openCamera: () => void; }> = ({ isOpen, closeModal, openCamera }) => {

    return (
        <IonModal isOpen={isOpen} cssClass='camera-alert ion-text-center' onDidDismiss={closeModal}>
            <motion.div variants={cameraVariants} animate='animation'>
                <IonButton onClick={openCamera}><IonIcon slot='icon-only' color='primary' icon={camera} /></IonButton>
            </motion.div>
            <motion.div
                className="arrow"
                variants={arrowVariants}
                animate="animation"
            >
                <IonIcon color='primary' icon={chevronForward} />
            </motion.div>
            <IonText color='tertiary'>Para escanear los documentos, previamente los deberá colocar en el scanner del costado.</IonText>
            <IonItemDivider />
            <IonText color='tertiary'>Luego oprima el ícono de la cámara.</IonText>
        </IonModal>
    );
};

export default CameraTASModal;