import { IonContent, IonList, IonItem, IonIcon, IonLabel, IonFab, IonFabButton, IonModal, IonRow, IonCol, IonInput, IonButton } from '@ionic/react';
import { mailOutline, checkmarkCircleSharp, add } from 'ionicons/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../../shared/components/popups/loading/Loading';
import ModuleModal from '../../../../../shared/components/popups/modals/module-modal/ModuleModal';
import { RootState } from '../../../../../store';
import styles from './EmailsModal.module.scss';
import * as EmailValidator from 'email-validator';
import { setDatosContactoStore } from '../../../../../store/datos-contacto/datosContactoActions';
import datosDeContactoService from '../../services/DatosContactoService';
import { EmailDataType } from '../../../../../store/datos-contacto/types/DatosContactoData';


interface EmailsModalProps {
  isOpen: boolean;
  setIsOpen: (newVal: boolean) => void;
  isValidEmail: boolean,
  setIsValidEmail: Function
}

const EmailsModal: React.FC<EmailsModalProps> = ({ isOpen, setIsOpen, isValidEmail, setIsValidEmail }) => {

  const datosContactoState = useSelector((state: RootState) => state.datosContacto);
  const { datosContacto, idTramite } = datosContactoState;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAddNewEmailModal, setShowAddNewEmailModal] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const dispatch = useDispatch();


  const resetValues = () => {
    setIsValidEmail(true);
    setNewEmail('');
  };


  const handleSaveNewEmail = () => {
    if (EmailValidator.validate(newEmail)) {
      setIsLoading(true);
      setShowAddNewEmailModal(false);
      datosContacto.emails.forEach((email) => (email.isDefault = false));
      datosContacto.emails.push({
        email: newEmail,
        isDefault: true,
      });
      handleSelectEmail(datosContacto.emails[datosContacto.emails.length - 1]);
    } else {
      setIsValidEmail(false);
    }
  };


  const handleSelectEmail = async (selectedEmail: EmailDataType) => {
    setIsLoading(true);
    datosContacto.emails.forEach((email) => {
      email.isDefault = email.email === selectedEmail.email;
    });
    let idTramiteResponse = idTramite;
    if (!datosContacto.esBeneficiario) {
      const { data: guardarDatosresponse } = await datosDeContactoService.guardarDatos({
        datosContacto,
        idTramite,
        nativo: false
      });
      idTramiteResponse = guardarDatosresponse.idTramite;
    }
    dispatch(setDatosContactoStore( {...datosContactoState, datosContacto, idTramite: idTramiteResponse, incompleteProcess: true }));
    setIsLoading(false);
    setIsOpen(false);
  };


  return (
    <ModuleModal
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      title="Correo electrónico"
    >
      <Loading isOpen={isLoading} message="Cargando..." />
      <IonContent>
        <IonList>
          {datosContacto?.emails?.map((email, i) => {
              return (
                <IonItem key={i} button lines="full" detail={false}>
                  <IonIcon icon={mailOutline} slot="start" color="primary" className={styles.telIcon} />
                  <IonLabel onClick={() => !email.isDefault && handleSelectEmail(email)}>
                    {email.email}
                  </IonLabel>
                  {email.isDefault && (
                    <IonIcon
                      icon={checkmarkCircleSharp}
                      color={email.isDefault ? 'primary' : 'default'}
                    />
                  )}
                </IonItem>
              );
            })}
        </IonList>

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton
            onClick={() => {
              setShowAddNewEmailModal(true);
            }}
          >
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>

        <IonModal
          cssClass={styles.addEmailModal}
          isOpen={showAddNewEmailModal}
          onWillDismiss={() => { resetValues(); setShowAddNewEmailModal(false) }}
        >
          <IonContent>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Nuevo email</IonLabel>
                  <IonInput
                    type="email"
                    onIonChange={(e) => setNewEmail(e.detail.value)}
                    value={newEmail}
                  />
                </IonItem>
                {!isValidEmail && (
                  <IonItem lines='none'>
                    <IonLabel className={styles.modalLabel} color="danger">
                      Formato de email ingresado incorrecto
                      </IonLabel>
                  </IonItem>
                )}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <IonButton
                  mode='md'
                  expand="block"
                  fill="clear"
                  onClick={() => setShowAddNewEmailModal(false)}
                >
                  Cancelar
                  </IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton
                  mode='md'
                  expand="block"
                  onClick={() => handleSaveNewEmail()}
                >
                  Guardar
                  </IonButton>
              </IonCol>
            </IonRow>
          </IonContent>
        </IonModal>
      </IonContent>
    </ModuleModal>
  );
};

export default EmailsModal;