import { User } from "../../../../store/users/types/User";
import { gestionesOnlineWebNav } from "../data/GestionesOnlineDataWeb";

export const getOptionsWeb = (userData: User) => {
    
    let options = gestionesOnlineWebNav;

    if ( userData ) {
        
        if (!userData.pagoEnLineaHabilitado){
            options = options.filter( opcion => opcion.name !== "pagoEnLinea")
        }
    
        if (!userData.hasTurnosEnabled){
            options = options.filter( opcion => opcion.name !== 'misTurnos')
        }
    
        if(userData.plan === "6 030" || userData.plan === "2 110" || userData.plan === "2 025"){
            options = options.filter( opcion => opcion.name !== 'reintegros')
        }
    }

    return options;

}