import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonSpinner } from '@ionic/react'
import React from 'react'
import AuthHeader from '../auth-header/AuthHeader'
import AuthText from '../auth-text/AuthText'
import AuthTitle from '../auth-title/AuthTitle'

const AuthHtml = () => {
    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                <AuthHeader>
                <IonSpinner color="primary" />
                </AuthHeader>
                <IonRow>
                    <IonCol className="ion-text-center">
                    <AuthTitle>AGUARDÁ UN MOMENTO</AuthTitle>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="ion-text-center ion-margin">
                    <AuthText>
                        Estamos obteniendo tus datos. <br />
                        Enseguida te devolveremos a la aplicación.
                    </AuthText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="ion-text-center">
                    <AuthText>¡Muchas gracias!</AuthText>
                    </IonCol>
                </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default AuthHtml
