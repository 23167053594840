import { IonContent, IonFooter, IonList, IonPage } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import InputForm from '../../../../../shared/components/form/inputForm/InputForm';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import styles from './StepArchivosAdjuntosPage.module.scss'
import Filepicker from '../../../../../shared/components/form/filepicker/Filepicker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { ComprobanteDataType, IDComprobantes, ReintegroDataType } from '../../../../../store/reintegros/types/ReintegrosData';
import { setNuevoReintegro } from '../../../../../store/reintegros/ReintegrosActions';
import { useHistory } from 'react-router';
import Header from '../../../../../shared/components/layout/header/Header';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { routes } from '../../router/routes';
import { routes as homeRoutes } from '../../../../home/router/routes';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import { getFileExtension, isWeb, removeAccents, tamanoMaximoArchivos } from '../../../../../shared/utils/utils';
import StandardModalPopup from '../../../../../shared/components/popups/modals/standard-modal-popup/StandardModalPopup';
import analyticsService from '../../../../../services/analytics/analytics';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';


const StepArchivosAdjuntosPage: React.FC = () => {

    const nuevoReintegro = useSelector((state: RootState) => state.reintegros.nuevoReintegro);
    const {isKeyboardOn} = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();
    const emailRef = useRef<HTMLIonItemElement>(null);
    const history = useHistory();
    const [showComprobanteFacturacion, setShowComprobanteFacturacion] = useState<boolean>(false);
    const [showComprobantePrestacion, setShowComprobantePrestacion] = useState<boolean>(false);
    const [prestacion, setPrestacion] = useState(String);
    const [facturacion, setFacturacion] = useState(String);

    interface FacturacionFormProperties {
        comprobanteFacturacion: ComprobanteDataType | null,
        pmedico: ComprobanteDataType | null,
        email: string
    }

    const { control, handleSubmit, setValue, errors, clearErrors } = useForm<FacturacionFormProperties>({
        defaultValues: {
            comprobanteFacturacion: nuevoReintegro?.comprobanteFacturacion ?? null,
            pmedico: nuevoReintegro?.comprobantePrestacion ?? null,
            email: nuevoReintegro?.correoElectronico ?? '',
        }
    });


    useEffect(() => {
        if (!nuevoReintegro) {
            history.push(homeRoutes.gestionesOnline);
        }
        if (nuevoReintegro?.comprobanteFacturacion?.base64) {
            setFacturacion(nuevoReintegro?.comprobanteFacturacion.base64);
        }
        if (nuevoReintegro?.comprobantePrestacion?.base64) {
            setPrestacion(nuevoReintegro?.comprobantePrestacion.base64);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (errors.pmedico)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.pmedico}`, routes.archivosAdjuntos)
        if (errors.comprobanteFacturacion)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.comprobanteFacturacion}`, routes.archivosAdjuntos)
        if (errors.email)
            analyticsService.trackEvent('formularios', 'reintegros - archivos adjuntos', `error - ${errors.email}`, routes.archivosAdjuntos)
    }, [errors])


    const onSubmit = (dataForm: FacturacionFormProperties) => {
        const nuevoReintegroUpdated: ReintegroDataType = {
            ...nuevoReintegro!,
            comprobanteFacturacion: dataForm.comprobanteFacturacion,
            correoElectronico: dataForm.email,
            comprobantePrestacion: dataForm.pmedico ? { ...dataForm.pmedico, nombre: removeAccents(dataForm.pmedico.nombre) } : null
        };
        if (dataForm.email !== nuevoReintegro.correoElectronico) nuevoReintegroUpdated.emailChanged = true;
        dispatch(SetStepperNavigation(Animations.next))    
        dispatch(setNuevoReintegro(nuevoReintegroUpdated));
        history.push(routes.verificaDatos);
    }

    const footer = () => (
        <IonFooter className='ion-no-border'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <NavigationButton text='Datos obligatorios(*)' type='submit'>SIGUIENTE</NavigationButton>
            </form>
            {isWeb() && <Footer />}
        </IonFooter>
    )

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles.archivosAdjuntosReintegros}>

                <PageTitle>
                    Reintegros online
                </PageTitle>

                <Stepper
                    stepsLenght={nuevoReintegro?.tipoComprobante?.id === IDComprobantes.Ticket ? 6 : 7}
                    currentNumber={nuevoReintegro?.tipoComprobante?.id === IDComprobantes.Ticket ? 4 : 5}
                />

                <StepTitle>
                    Archivos adjuntos
                </StepTitle>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonList className='ion-margin'>

                        <Controller
                            name='comprobanteFacturacion'
                            control={control}
                            rules={{ required: { value: true, message: 'Por favor, adjuntá el comprobante de facturación' } }}
                            render={() =>
                                <Filepicker
                                    storedFileName={nuevoReintegro?.comprobanteFacturacion?.nombre}
                                    label='Adjuntar comprobante (*)'
                                    name='comprobanteFacturacion'
                                    placeHolder={`Tamaño máximo ${tamanoMaximoArchivos}MB. PDF o JPG`}
                                    error={(errors.comprobanteFacturacion as any)?.message}
                                    setValue={(selectedFile) => { setValue('comprobanteFacturacion', selectedFile); setFacturacion(selectedFile.base64); clearErrors(["comprobanteFacturacion"]) }}
                                    showImage={facturacion && (() => setShowComprobanteFacturacion(true))}
                                />
                            }
                        />

                        {nuevoReintegro?.tipoPractica?.pedidoMedico &&
                            <Controller
                                name='pmedico'
                                control={control}
                                rules={{ required: { value: nuevoReintegro.tipoPractica.pedidoMedicoObligatorio, message: 'Por favor, adjuntá el pedido médico' } }}
                                render={() =>
                                    <Filepicker
                                        storedFileName={nuevoReintegro?.comprobantePrestacion?.nombre && `Pedido médico.${getFileExtension(nuevoReintegro.comprobantePrestacion.nombre)}`}
                                        label={`Pedido Médico${(nuevoReintegro?.tipoPractica?.pedidoMedicoObligatorio) ? ' (*)' : ''}`}
                                        name='pmedico'
                                        placeHolder={`Tamaño máximo ${tamanoMaximoArchivos}MB. PDF o JPG`}
                                        error={(errors.pmedico as any)?.message}
                                        setValue={(selectedFile) => { setValue('pmedico', selectedFile); setPrestacion(selectedFile.base64); clearErrors(["pmedico"]) }}
                                        showImage={prestacion && (() => setShowComprobantePrestacion(true))}
                                        overrideFileName='Pedido médico'
                                    />
                                }
                            />
                        }

                        <Controller
                            name='email'
                            control={control}
                            rules={{
                                required: { value: true, message: 'Por favor, completá el correo electrónico' },
                                pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Por favor, ingresá un correo electrónico válido" }
                            }}
                            render={(props) =>
                                <InputForm
                                    reference={emailRef}
                                    label='Email de contacto (*)'
                                    info='La documentación será enviada a:'
                                    type='email'
                                    inputmode='email'
                                    placeHolder='Ingrese Email'
                                    errors={errors.email?.message}
                                    value={props.value}
                                    onChange={(e) => { props.onChange(e.detail.value); clearErrors(["email"]) }}
                                />
                            }
                        />

                    </IonList>
                </form>

                {isKeyboardOn && footer()}
            </IonContent>

            {!isKeyboardOn && footer()}

            <StandardModalPopup
                isOpen={showComprobanteFacturacion}
                title='Previsualización'
                content={
                    <img id="image-preview" src={facturacion} alt="foto" />
                }
                handler={() => setShowComprobanteFacturacion(false)}
            />

            <StandardModalPopup
                isOpen={showComprobantePrestacion}
                title='Previsualización'
                content={
                    <img id="image-preview" src={prestacion} alt="foto" />
                }
                handler={() => setShowComprobantePrestacion(false)}
            />
        </IonPage>
    );
}


export default StepArchivosAdjuntosPage;