import { IonRow, IonCol } from "@ionic/react";
import React from "react";
import styles from './Banner.module.scss';

interface BannerProps {
  link: string;
  backgroundImg: string;
  titulo: string;
  descripcion?: string;
}

const Banner: React.FC<BannerProps> = (props) => {
  return (
    <IonRow>
      <IonCol className='ion-no-padding'>
        <a href={props.link}>
          <div
            className={`${styles['container-banner']} ${styles['banner-color-font']}`} style={{backgroundImage: `url(${props.backgroundImg})`}}>
            <div className={styles["banner-text"]}>
              <p className={styles["banner-titulo"]}> {props.titulo}</p>
              {props.descripcion && (
                <p className={styles["banner-descripcion"]}>{props.descripcion}</p>
              )}
            </div>
          </div>
        </a>
      </IonCol>
    </IonRow>
  );
};

export default Banner;
