import React, { useEffect } from 'react';
import { IonCol, IonRow } from '@ionic/react';
import { useHistory } from 'react-router-dom';

import { getPaymentMethods as cards } from '../../../../helpers/getPaymentMethods';
import styles from './PaymentMethods.module.scss';
import { useDispatch } from 'react-redux';
import { setPaymentCard } from '../../../../../../../store/pagoEnLinea/actions';
import {routes as PagoEnLineaRoutes} from '../../../../router/routes';
import analyticsService from '../../../../../../../services/analytics/analytics';
import { PaymentMethod } from '../../../../../../../store/pagoEnLinea/types/PagoEnLineaTypes';
import { motion } from 'framer-motion';

const PaymentMethods: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    analyticsService.trackPageViewForm("trackPageviewForm", "/gestiones-online/pago-en-linea/confirmacion","pagos en línea - 2. selección de tarjeta", 1);
  }, [])

  const handleSelectCard = (payment: PaymentMethod) => {
    dispatch(setPaymentCard(payment));
    return history.push(PagoEnLineaRoutes.paymentInformation);
  };

  return (
    <>
      <IonRow className='ion-margin-vertical'>
        <IonCol
          className={styles.card}
          id={`card-${cards[0].name}`}
          onClick={() => handleSelectCard(cards[0])}
        >
          <motion.div whileTap={{ scale: 0.8 }} className={styles.cardContainer}>
            <img src={cards[0].pathImageIcon} alt={cards[0].name} />
          </motion.div>
        </IonCol>
        <IonCol
          className={styles.card}
          id={`card-${cards[1].name}`}
          onClick={() => handleSelectCard(cards[1])}
        >
          <motion.div whileTap={{ scale: 0.8 }} className={styles.cardContainer}>
            <img src={cards[1].pathImageIcon} alt={cards[1].name} />
          </motion.div>

        </IonCol>
      </IonRow>

      <IonRow className='ion-margin-bottom'>
        <IonCol
          className={styles.card}
          id={`card-${cards[2].name}`}
          onClick={() => handleSelectCard(cards[2])}
        >
          <motion.div whileTap={{ scale: 0.8 }} className={styles.cardContainer}><img src={cards[2].pathImageIcon} alt={cards[2].name} /></motion.div>
        </IonCol>
        <IonCol
          className={styles.card}
          id={`card-${cards[3].name}`}
          onClick={() => handleSelectCard(cards[3])}
        >
          <motion.div whileTap={{ scale: 0.8 }} className={styles.cardContainer}><img src={cards[3].pathImageIcon} alt={cards[3].name} /></motion.div>
        </IonCol>
      </IonRow>

      <IonRow className='ion-margin-bottom'>
        <IonCol
          className={styles.card}
          id={`card-${cards[4].name}`}
          onClick={() => handleSelectCard(cards[4])}
        >
          <motion.div whileTap={{ scale: 0.8 }} className={styles.cardContainer}><img src={cards[4].pathImageIcon} alt={cards[4].name} /></motion.div>
        </IonCol>
        <IonCol />
      </IonRow>
    </>
  );
};

export default PaymentMethods;