import { IonItem, IonLabel, IonRadio, IonRadioGroup } from '@ionic/react';
import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import analyticsService from '../../../../../services/analytics/analytics';
import GenericAlert from '../../../../../shared/components/popups/alerts/generic-alert/GenericAlert';
import { RootState } from '../../../../../store';
import { fetchAutorizaciones, setSocioSeleccionado, unSetTramite } from '../../../../../store/autorizaciones/AutorizacionesActions';
import { IntegranteAutorizacionesDataType } from '../../../../../store/autorizaciones/types/AutorizacionesData';
import { setSociaElegida } from '../../../../../store/empadronamiento/actions';
import { routes as autorizacionesRoutes } from '../../../autorizaciones/router/routes';
import { SociaDataType } from '../../types/EmpadronamientoTypes';
import styles from './SociasRadioGroup.module.scss';

const SociasRadioGroup: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    empadronamiento: { sociaElegida, listaSocias },
    autorizaciones: { obtainedData },
    user: {
      user: { contrato },
    },
  } = useSelector((state: RootState) => state);

  const [messageAlert, setMessageAlert] = useState<HTMLSpanElement | string>();
  const [showAlert, setShowAlert] = useState(false);
  const [sociaSeleccionada, setSociaSeleccionada] = useState<SociaDataType>(null);

  const handleCloseAlert = () => {
    setShowAlert(false);
    setSociaSeleccionada(null);
  };

  const redirectAutorizaciones = async () => {
    dispatch(unSetTramite());
    dispatch(fetchAutorizaciones(contrato.nroActividad));
  };

  useEffect(() => {
    if (obtainedData && sociaSeleccionada) {
      //TODO verificar si es la condicion correcta para setear el usuario elegido
      const selected = obtainedData.listaIntegrantes.find((integrante) => integrante.nombre.toLowerCase().trim() === sociaSeleccionada.datosSocia.nombreCompleto.toLowerCase().trim());
      const socio: IntegranteAutorizacionesDataType = { ...selected };
      dispatch(setSocioSeleccionado(socio));
      history.replace(autorizacionesRoutes.stepInformacionMedicamentos);
      setSociaSeleccionada(null);
    }
  }, [obtainedData, history, dispatch, sociaSeleccionada]);

  const buildMessage = (socia: SociaDataType) => {
    const { codigo, detalle } = socia.statusSocia[0];
    let message: any = '';
    switch (codigo) {
      case 202:
        message = `La socia para la que estás intentando realizar el trámite tiene un empadronamiento vigente hasta el ${detalle}. Recordá que es necesario efectuarlo una vez por año aniversario.`;
        setMessageAlert(message);
        setSociaSeleccionada(null);
        break;
      case 207:
        message = `La socia seleccionada tiene un empadronamiento vigente ${detalle}.`;
        setMessageAlert(message);
        setSociaSeleccionada(null);
        break;
      case 214:
        message = (
          <span>
            La persona para la que estás solicitando el empadronamiento es menor de 12 años. Para evaluar tu solicitud, es necesario que envíes el formulario completo de empadronamiento para
            anticonceptivos, un justificativo médico y el consentimiento firmado por padre, madre o tutor a través de{' '}
            <span onClick={redirectAutorizaciones} className={styles.link}>
              {' '}
              Gestiones Online {'>'} Autorizar una prestación {'>'} Medicamentos.{' '}
            </span>
          </span>
        );
        setSociaSeleccionada(socia);
        setMessageAlert(message);
        break;
      case 255:
        message = (
          <span>
            La persona para la que estás solicitando el empadronamiento es mayor de 55 años. Para evaluar tu solicitud, es necesario que envíes el formulario completo de empadronamiento para
            anticonceptivos y un justificativo médico a través del trámite{' '}
            <span onClick={redirectAutorizaciones} className={styles.link}>
              {' '}
              Gestiones Online {'>'} Autorizar una prestación {'>'} Medicamentos.{' '}
            </span>
          </span>
        );
        setSociaSeleccionada(socia);
        setMessageAlert(message);
        break;
      case 206:
        message = 'Esta solicitud no puede ser gestionada porque la socia posee un empadronamiento de Plan Materno Infantil vigente.';
        setMessageAlert(message);
        setSociaSeleccionada(null);
        break;
      case 208:
        message = 'La socia para la que estás intentando realizar el trámite tiene una solicitud de empadronamiento en proceso.';
        setMessageAlert(message);
        setSociaSeleccionada(null);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (listaSocias.length === 1 && listaSocias[0].statusSocia[0].codigo === 203) {
      dispatch(setSociaElegida(listaSocias[0].datosSocia));
    }
  }, [listaSocias, dispatch]);

  const buttonsAlert = () => {
    const resp = [
      {
        children: 'Aceptar',
        onClick: handleCloseAlert,
      },
    ];
    if (sociaSeleccionada !== null) {
      resp.push({
        children: 'Ir a Autorizaciones',
        onClick: redirectAutorizaciones,
      });
    }
    return resp;
  };

  return (
    <>
      <IonRadioGroup
        className={styles['socias-radio']}
        id="radio"
        value={sociaElegida?.nombreCompleto ?? undefined}
        onIonChange={(e) => {
          let socia = listaSocias.find((i) => i.datosSocia.nombreCompleto.includes(e.detail.value));
          if (socia && socia.statusSocia[0].codigo !== 203) {
            buildMessage(socia);
            setShowAlert(true);
            analyticsService.formNotSubmittedAndSubmitted('empadronamiento anticonceptivos > 1. selección de beneficiaria', 'Error para Socia', 1, 0);
          } else {
            dispatch(setSociaElegida(socia?.datosSocia ?? null));
          }
        }}
      >
        {listaSocias?.map((m, index) => {
          return (
            <IonItem key={index} className="ion-text-center ion-no-padding">
              <IonLabel>{m.datosSocia.nombreCompleto}</IonLabel>
              <IonRadio id={`radio-socia-${index}`} value={m.datosSocia.nombreCompleto} mode="ios" slot="end" />
            </IonItem>
          );
        })}
      </IonRadioGroup>
      <GenericAlert isOpen={showAlert} title="Importante" buttons={buttonsAlert()}>
        {messageAlert}
      </GenericAlert>
    </>
  );
};

export default SociasRadioGroup;
