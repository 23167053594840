export const ONLY_CONTACT_DETAIL_MESSAGE =  'Te contamos que, a través de Gestiones Online, solo podrás editar tus datos de contacto. En caso de necesitar realizar un trámite que no esté habilitado para tu usuario, deberá efectuarlo el titular del grupo familiar.'
export const SECTION_NOT_ENABLED_MESSAGE =  'Esta sección no se encuentra habilitada para tu plan.  Para más información, escribinos a través de WhatsApp al +5491148729000, en días hábiles, de lunes a viernes de 9 a 20.'
export const PAGO_EN_LINEA_MODAL_TITLE_TYC = 'TÉRMINOS Y CONDICIONES';
export const PAGO_EN_LINEA_LINK_MODAL_TYC = 'Términos y condiciones'
export const PAGO_EN_LINEA_MODAL_TITLE_PDDP = 'PROTECCIÓN DE LOS DATOS PERSONALES';
export const PAGO_EN_LINEA_MODAL_SUBTITLE_PDDP = 'Ley 25.326';
export const PAGO_EN_LINEA_LINK_MODAL_PDDP = "Protección de los datos personales. Ley 25.326";
export const LOADING = "Cargando";
export const NEXT_STEP = "Siguiente";
export const PHONE_NUMBER_ATENTION = '08105556733'
export type TRAMITE_NAME = 'FACTURACION' | 'REINTEGROS' | 'AUTORIZACIONES' | 'DATOS_DE_CONTACTO' ;
export const GENERIC_MESSAGE_ERROR = 'Lo sentimos, en este momento no podemos atender tu solicitud. <br> Por favor, intentá nuevamente más tarde.';
export const NO_COMMERCIAL_MESSAGE = 'Esta sección no se encuentra habilitada para tu plan.  Para más información, escribinos a través de WhatsApp al +5491148729000, en días hábiles, de lunes a viernes de 9 a 20.';
export const ERROR_MESSAGE_CUENTA_CREDITO = 'Para efectuar este trámite, es necesario que el titular del grupo familiar informe una cuenta bancaria para el depósito de reintegros. Podrá hacerlo a través de nuestra web ingresando a Gestiones Online > Gestionar reintegros > Cuenta de acreditación, o personalmente en el centro de atención más cercano. '
export const ERROR_MESSAGE_SALDO_PENDIENTE = 'No es posible realizar este trámite debido a que registra un saldo pendiente de pago. Para cancelarlo, comunicate con nuestro Centro de Atención Telefónica llamando al 0810-555-6733 o acercate al Centro de Atención Personalizado más próximo.';
export const FOOTER_COPYRIGHT = `©OSDE ${new Date().getFullYear()} - Todos los derechos reservados - Av. Leandro N. Alem 1067 - Piso 9 -
C1001AAF - Buenos Aires - Superintendencia de Servicios de Salud - Órgano de Control
de Obras Sociales y Entidades de Medicina Prepaga - 0800-222-SALUD (72583) - www.sssalud.gov.ar - R.N.E.M.P.
(Prov.) 1408 - R.N.O.S. 4-0080-0.`