import { IGroup } from '../../../../../store/menu/types/MenuResponse';
import { links } from './links';

const {
  inicio,
  cartilla,
  buscarPorCercania,
  buscarPorEspecialidad,
  buscarPorNombre,
  osdeViajaConVos,
  miCartilla,
  misTurnos,
  reintegros,
  datosDeContacto,
  empadronamiento,
  autorizaciones,
  facturacion,
  pagoEnLinea,
  asistenciaMedica,
  asistenciaMedicaDeUrgencias,
  riesgoDeVida,
  atencionAlSocio,
  whatsApp,
} = links;

export const linksNative: Array<IGroup> = [
  {
    title: '',
    items: [inicio],
  },
  {
    title: 'CARTILLA MÉDICA',
    items: [
      buscarPorCercania,
      buscarPorEspecialidad,
      buscarPorNombre,
      osdeViajaConVos,
      cartilla,
      miCartilla,
      misTurnos
    ],
  },
  {
    title: 'GESTIONES ONLINE',
    items: [
      reintegros,
      datosDeContacto,
      empadronamiento,
      autorizaciones,
      facturacion,
      pagoEnLinea,
    ],
  },
  {
    title: 'CONTACTANOS',
    items: [
      asistenciaMedica,
      asistenciaMedicaDeUrgencias,
      riesgoDeVida,
      atencionAlSocio,
      whatsApp,
    ],
  },
];
