import { IonCol, IonContent, IonFooter, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react';
import React, { useEffect } from 'react';
import { menuController } from '@ionic/core';
import icono from '../assets/img/iconoError.svg';
import AuthHeader from '../components/auth-header/AuthHeader';
import AuthTitle from '../components/auth-title/AuthTitle';
import AuthText from '../components/auth-text/AuthText';
import { useHistory } from 'react-router';
import { routes as homeRoutes } from '../../home/router/routes';
import NavigationButton from '../../../shared/components/buttons/navigation/NavigationButton';

const AuthError: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    menuController.swipeGesture(false);
    document.addEventListener('ionBackButton', (event: any) => {   
      event.detail.register(-1, () => {
        history.replace(homeRoutes.root);    
      }); 
    }); 
    return () => {
      menuController.swipeGesture(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <AuthHeader>
            <IonImg src={icono} />
          </AuthHeader>
          <IonRow>
            <IonCol className="ion-text-center">
              <AuthTitle>
                OCURRIO UN ERROR <br /> AL INTENTAR LOGUEARTE
              </AuthTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center ion-margin">
              <AuthText>
                Por favor intentelo nuevamente
              </AuthText>
            </IonCol>
          </IonRow>
          
        </IonGrid>
      </IonContent>
      <IonFooter>
        <NavigationButton onClick={() => history.replace(homeRoutes.root) }> Volver </NavigationButton>
      </IonFooter>
    </IonPage>
  );
};

export default AuthError;