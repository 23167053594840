import { IonItem, IonCheckbox, IonLabel } from "@ionic/react";
import React, { useState } from "react";
import styles from './AntecedenteItem.module.scss';
import { useEffect } from "react";

interface Props {
  antecedente: any | undefined;
  checkedAction: (checked: boolean) => void;
  checkedValue?: boolean | undefined;
}

const AntecedenteItem: React.FC<Props> = ({ antecedente, checkedAction, checkedValue }) => {
  const [checked, setChecked] = useState(checkedValue ?? false);

  useEffect(() => {
    checkedAction(checked);
  }, [checked, checkedAction])

  return (
    <IonItem lines='none' className={`${styles.antecedenteItem} ion-no-padding`}>
      <IonCheckbox
        id={`check-antecedente-${antecedente.codigo}`}
        checked={checked}
        onIonChange={e => setChecked(e.detail.checked)}
      />
      <IonLabel className="ion-text-wrap">
        {antecedente.descripcion}
      </IonLabel>
    </IonItem>
  );
};

export default AntecedenteItem;