import { IonContent, IonPage, IonFooter } from '@ionic/react';
import React, { useEffect, useRef } from 'react';
import { RootState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../../../shared/components/layout/header/Header';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import {  isWeb } from '../../../../../shared/utils/utils';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import { useHistory } from 'react-router-dom';
import { unsetEmpadronamiento } from '../../../../../store/empadronamiento/actions';
import analyticsService from '../../../../../services/analytics/analytics';
import { routes as HomeRoutes } from '../../../../home/router/routes'
import Check from '../../../../../shared/components/stepper/final-step/check/Check';
import SuccessText from '../../../../../shared/components/stepper/final-step/success-text/SuccessText';
import SuccessDivider from '../../../../../shared/components/stepper/final-step/success-divider/SuccessDivider';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';
import styles from './ResultadoPage.module.scss';

const ResultadoPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { numeroTramite } = useSelector((state: RootState) => state.empadronamiento);
  const nroTramite = useRef(numeroTramite);


  useEffect(() => {
    if(!numeroTramite){
      history.push(HomeRoutes.root);
    } else {
      analyticsService.trackPageViewForm('empadronamiento anticonceptivos > 2. antecedentes', '/tramites/empadronamiento-anticonceptivos/verificacion-de-datos',0,1);
      dispatch(unsetEmpadronamiento());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
   

  const onClickNext = () => {
    dispatch(SetStepperNavigation(Animations.next));
    history.push(HomeRoutes.root, false)
  }


  const goToGestionesOnline = () => {
    history.replace(HomeRoutes.gestionesOnline);
  };


  return (
    <IonPage>
      <Header showBackButton={false} />
      <IonContent>

        <div className="ion-text-center ion-padding-top">
          <Check />
        </div>

        <SuccessDivider />

        <SuccessText>
          <span id="numero-tramite">
            Tu solicitud está en curso.
          </span>
        </SuccessText>

        <SuccessDivider />

        <SuccessText>
          <span id="procesamiento-solicitud">
            El número de gestión es <b>{nroTramite.current}</b>.
          </span>
        </SuccessText>

        <SuccessDivider />

        <SuccessText noLines>
          <span id="pie-solicitud">
            Te enviaremos un mail cuando esté aprobada para que puedas retirar los anticonceptivos en las farmacias adheridas. Recordá que deberás presentar la receta junto con la credencial digital y un documento de identidad.
          </span>
        </SuccessText>

        <SuccessDivider />

        <SuccessText noLines>
          Si necesitás realizar otra solicitud, hacé clic&nbsp;      
          <span
            className={styles.textLightBlue}
            onClick={goToGestionesOnline}
          >
            aquí
          </span>
          .
        </SuccessText>

      </IonContent>

      <IonFooter className="ion-no-border">
        <NavigationButton id="id-finalizar" disabled={false} onClick={onClickNext}>
          FINALIZAR
        </NavigationButton>
        {isWeb() && <Footer />}
      </IonFooter>
    </IonPage>
  );
};

export default ResultadoPage;