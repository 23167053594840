import { IonToolbar } from '@ionic/react'
import React, { useState } from 'react'
import { IonButton } from '@ionic/react';
import { IonItem } from '@ionic/react';
import { IonLabel } from '@ionic/react';
import menuStyles from '../../Menu.module.scss';
import { isWebLocal } from '../../../../../utils/utils';
import { LocalhostLoginAlert } from '../../../../popups/alerts/localhost-login-alert/LocalhostLoginAlert';
import { menuController } from '@ionic/core';
import { useHistory } from 'react-router';
import { authRoutes } from '../../../../../../modules/auth/router/routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

const MenuLogin = () => {
  const [showLocalhostLoginAlert, setShowLocalhostLoginAlert] = useState(false);
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.user);
  
  const handleLogin = () => {
    if (!user && isWebLocal()) {
      setShowLocalhostLoginAlert(true);
    } else {
      history.replace(`${authRoutes.login}${history.location.pathname}`); // login/contactanos
    }
    menuController.close();
  };

  return (
    <>
      <IonToolbar color="primary" className={menuStyles["menu__toolbar"]}>
        <IonItem color="primary" lines='none'>
          <IonLabel className={menuStyles["menu__toolbar-title"]}> OSDE Móvil </IonLabel>
        </IonItem>
      </IonToolbar>
      <IonToolbar color="primary" className={menuStyles["menu__toolbar"]}>
        <IonItem color="primary" lines='none'>
          <IonButton 
            className={`${menuStyles["menu__button"]} ion-text-wrap`} 
            color="secondary" 
            expand="block"
            onClick={handleLogin}
          >Iniciar sesión o registrarse</IonButton>
        </IonItem>
      </IonToolbar>
      <LocalhostLoginAlert isOpen={showLocalhostLoginAlert} setIsOpen={setShowLocalhostLoginAlert}/>
    </>
  )
};

export default MenuLogin;
