import React from 'react';
import { IonItem, IonLabel } from '@ionic/react';
import styles from './StepTitle.module.scss'

const StepTitle: React.FC<any> = props => {

    return (
        <IonItem 
            color={props.color || 'light'} 
            lines='none' 
            className={`${styles.stepTitle} ion-text-center ion-align-items-center`}
        >
            <IonLabel color='secondary' id='subtitle'>
                {props.children}
            </IonLabel>
        </IonItem>
    );
};

export default StepTitle;