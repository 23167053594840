import { IonContent, IonFooter, IonPage } from '@ionic/react';
import React from 'react'
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import Header from '../../../../../shared/components/layout/header/Header';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import { isWeb } from '../../../../../shared/utils/utils';
import styles from './StepInformacionMedicamentosPage.module.scss'
import {AutorizacionesTramites as tramite} from '../../helpers/TramitesEnum';
import AutorizacionesTramites from '../../helpers/getStepsTramite';
import { setSteps, setTipoTramite } from '../../../../../store/autorizaciones/AutorizacionesActions';
import { useDispatch} from 'react-redux';
import { useHistory } from 'react-router';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';

 
const StepInformacionMedicamentosPage: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const onClick = () => {
        dispatch(SetStepperNavigation(Animations.fadeIn))
        dispatch(setTipoTramite(tramite.Medicamentos));
        const res = AutorizacionesTramites(1);
        dispatch(setSteps(res));
        history.push(res.editOrder[0].path);
    }

    return ( 
        <IonPage>
            <Header showBackButton={true} />

            <IonContent className={styles.stepInformacion}>
                <PageTitle>
                    Autorizacion de Medicamentos
                </PageTitle>

                <StepTitle>
                    Importante
                </StepTitle>

                <div className={[styles.informacionTexto, 'ion-margin'].join(' ')}>
                    <p id='top-text'>El tiempo de entrega de la medicación estimado es de 6 días hábiles y, según el tipo de medicamento, podría ser mayor.</p>
                    <p id='middle-text'>Sugerimos enviar el pedido con la suficiente anticipación.</p>
                    <p id='bottom-text'>Además, OSDE podrá requerir información adicional.</p>
                </div>

            </IonContent>

            <IonFooter className='ion-no-border'>
                <NavigationButton onClick={onClick} disabled={false}>Siguiente</NavigationButton>
                {isWeb() && <Footer /> }
            </IonFooter>

        </IonPage>
    );
}
 
export default StepInformacionMedicamentosPage;