import { IException } from "../../services/Exceptions";
import { IAnimation } from "../../shared/components/animations/Animations";
import {  SET_RESET_SCREENSAVER_TIME, SET_STEPPER_NAVIGATION, SET_RESET_INACTIVITY_LOGOUT, UIAction, SET_UPDATE_AVAILABLE, SET_SHOW_SPLASH, SET_KEYBOARD, SET_HTTP_ERROR } from "./uiActionTypes";

export const setResetScreenSaverTime = (resetScreenSaverTime: boolean): UIAction => ({
    type: SET_RESET_SCREENSAVER_TIME,
    payload: resetScreenSaverTime    
})

export const SetResetInactivityLogout = (resetInactivityLogout: boolean): UIAction => ({
    type: SET_RESET_INACTIVITY_LOGOUT,
    payload: resetInactivityLogout    
})

export const SetStepperNavigation = (stepperNavigation: IAnimation): UIAction => ({
    type: SET_STEPPER_NAVIGATION,
    payload: stepperNavigation    
})

export const setUpdateAvailable = (available: boolean): UIAction => ({
    type: SET_UPDATE_AVAILABLE,
    payload: available
})

export const setShowSplash = (showSplash: boolean): UIAction => ({
    type: SET_SHOW_SPLASH,
    payload: showSplash
})

export const setKeyoard = (isKeyboardOn: boolean) : UIAction => ({
    type: SET_KEYBOARD,
    payload: isKeyboardOn
})

export const setHttpError = (httpError: IException) : UIAction => ({
    type: SET_HTTP_ERROR,
    payload: httpError
})