import { DatosContactoState } from '../datosContactoReducer';

export const SET_DATOSCONTACTO = 'SET_DATOSCONTACTO';
export const SET_NUMEROSOLICITUD = 'SET_NUMEROSOLICITUD';
export const UNSET_TRAMITE_DATOS_CONTACTO = 'UNSET_TRAMITE_DATOS_CONTACTO';
export const SET_INCOMPLETEPROCESS = 'SET_INCOMPLETEPROCESS';

interface SetIncompleteProcess {
  type: typeof SET_INCOMPLETEPROCESS,
  payload: boolean
}

interface SetDatosContactoAction {
    type: typeof SET_DATOSCONTACTO;
    payload: DatosContactoState | null;
  }

interface SetNumeroSolicitudAction {
  type: typeof SET_NUMEROSOLICITUD;
  payload: string;
}

interface UnSetTramiteDatosContacto {
  type: typeof UNSET_TRAMITE_DATOS_CONTACTO;
}

export type DatosContactoActions =
  | SetDatosContactoAction
  | SetNumeroSolicitudAction
  | UnSetTramiteDatosContacto
  | SetIncompleteProcess;
