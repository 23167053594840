import { IonList, IonContent, IonPage, IonFooter } from '@ionic/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import AlertPopup from '../../../../../shared/components/popups/alerts/alert/AlertPopup';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import DateTime from '../../../../../shared/components/form/date-time/DateTime';
import InputForm from '../../../../../shared/components/form/inputForm/InputForm';
import TextareaForm from '../../../../../shared/components/form/textarea-form/TextareaForm';
import Header from '../../../../../shared/components/layout/header/Header';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { isDateGreater, isWeb } from '../../../../../shared/utils/utils';
import { RootState } from '../../../../../store';
import { setDatosPractica, setDetalle } from '../../../../../store/autorizaciones/AutorizacionesActions';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import { routes as AutorizacionesRoutes } from '../../router/routes';
import { DatosPractica } from '../../../../../store/autorizaciones/types/AutorizacionesData';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import analyticsService from '../../../../../services/analytics/analytics';
import { routes as homeRoutes } from '../../../../home/router/routes';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';
import GenericAlert from '../../../../../shared/components/popups/alerts/generic-alert/GenericAlert';
import PopUp72horas from '../../components/72horas-popUp/PopUp72horas';
interface InternacionFormValidate {
    fecha: string | undefined,
    observaciones: string | undefined,
    institucion: string | undefined,
}

const StepPracticasEstudiosPage: React.FC = () => {

    const [openExpiredDateModal, setExpiredDateOpenModal] = useState<boolean>(false);
    const [openWarningModal, setOpenWarningModal] = useState<boolean>(false)

    const { datosPractica, stepDetails, detalle } = useSelector((state: RootState) => state.autorizaciones);
    const {isKeyboardOn} = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation<{ from: string }>();


    const { handleSubmit, formState, control } = useForm<InternacionFormValidate>({
        mode: 'onChange',
        defaultValues: {
            fecha: datosPractica?.fechaRealizacion ?? '',
            institucion: datosPractica?.prestador ?? '',
            observaciones: detalle ?? '',
        }
    });


    const { isValid } = formState;


    useEffect(() => {
        if (!stepDetails) {
            history.push(homeRoutes.root);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const goToNextPage = () => {
        if (state?.from) {
            history.goBack();
        } else {
            return history.push(AutorizacionesRoutes.adjuntarArchivo);
        }
    }


    const onSubmit = (dataForm: any) => {
        if (new Date(dataForm.fecha).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
            setExpiredDateOpenModal(true);
            return;
        }

        const { fecha: fechaRealizacion, institucion: prestador, observaciones } = dataForm;

        const datosPracticaUpdated: DatosPractica = {
            ...datosPractica,
            fechaRealizacion,
            prestador
        };
        dispatch(SetStepperNavigation(Animations.next))
        dispatch(setDatosPractica(datosPracticaUpdated));
        dispatch(setDetalle(observaciones));

        if (!isDateGreater(dataForm.fecha.split("T")[0])) {
            analyticsService.trackPageView('/gestiones-online/autorizaciones?m=72hs-habiles');
            setOpenWarningModal(true);
            return;
        }

        goToNextPage();
    }

    const footer = () => (
        <IonFooter className='ion-no-border'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <NavigationButton text='Datos obligatorios(*)' type='submit' disabled={!isValid}>SIGUIENTE</NavigationButton>
            </form>
            {isWeb() && <Footer />}
        </IonFooter>
    )

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent>

                <PageTitle>{stepDetails?.titleAndLenght?.title}</PageTitle>

                <Stepper currentNumber={2} stepsLenght={stepDetails?.titleAndLenght?.lenghtOfPages}></Stepper>

                <StepTitle>
                    Ingresá los datos
                </StepTitle>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonList className='ion-margin'>

                        <Controller
                            render={({ onChange, value }) => (
                                <DateTime
                                    label="Fecha de realización (*)"
                                    onChange={(e: any) => { onChange(e.detail.value) }}
                                    value={value}
                                    max={(new Date().getFullYear() + 5) + "-12-31"}
                                    min={(new Date().toISOString())}
                                />
                            )}
                            rules={{ required: true }}
                            name='fecha'
                            control={control}
                        />

                        <Controller
                            render={({ onChange, value }) => (
                                <InputForm
                                    label="Institución / Profesional"
                                    onChange={(e: any) => { onChange(e.detail.value) }}
                                    value={value}
                                    maxLength={50}
                                />
                            )}
                            name='institucion'
                            control={control}
                        />

                        <Controller 
                            render={({onChange, value}) => 
                                <TextareaForm 
                                    onChange={onChange}
                                    value={value}
                                    name="observaciones" 
                                    label="Observaciones"
                                    placeholder="Cantidad máxima 200 caracteres" 
                                /> 
                            }
                            control={control}
                            name='observaciones'
                        />

                    </IonList>
                </form>

                {isKeyboardOn && footer()}
                
            </IonContent>
            
            {!isKeyboardOn && footer()}

            <AlertPopup
                isOpen={openExpiredDateModal}
                message="La fecha de realización debe ser igual o mayor al día de hoy"
                title="Aviso"
                buttons={[
                    { text: 'Aceptar', handler: () => setExpiredDateOpenModal(false) }
                ]}
            />

            <GenericAlert
                isOpen={openWarningModal}
                title='Aviso'
                buttons={[
                    { children: 'Continuar', onClick: () => { setOpenWarningModal(false); goToNextPage();}},
                    { children: 'Volver', onClick: () => setOpenWarningModal(false)},
                ]}
            >
                <PopUp72horas />
            </GenericAlert>
        </IonPage>
    );
}

export default StepPracticasEstudiosPage;