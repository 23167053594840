import AxiosInstance from "../AxiosService";
import mockGetDocumentos from "../../modules/tramites/facturacion/services/mocks/mockGetDocumentos.json"
import { isLocalDev } from "../../shared/utils/utils";

const baseUrlService = "/loginService";

export interface LoginService {
  getDocumentosFacturacion: () => Promise<any>;
  gestionesOnlineHabilitados: () => Promise<any>;
}

const loginService: LoginService = {
  getDocumentosFacturacion: () => AxiosInstance.post(`${baseUrlService}/obtenerDocumentosFacturacionNew`, {}),
  gestionesOnlineHabilitados: () => AxiosInstance.get(`${baseUrlService}/habilitados`),
};

const loginServiceMock: LoginService = {
  getDocumentosFacturacion:  () => Promise.resolve({data:mockGetDocumentos}),
  gestionesOnlineHabilitados: () => AxiosInstance.get(`${baseUrlService}/habilitados`),
};

export default isLocalDev() ? loginServiceMock : loginService;