import {
  Plugins,
  CameraResultType,
  CameraSource,
  CameraOptions,
} from "@capacitor/core";
import { Camera as CameraTas } from '@ionic-native/camera';

const { Camera } = Plugins;

export const getPhotoFromTas = async (): Promise<string> => {
    const options = {
        quality: 100,
        destinationType: CameraTas.DestinationType.DATA_URL,
        encodingType: CameraTas.EncodingType.JPEG,
        mediaType: CameraTas.MediaType.PICTURE
    };

    const image = await CameraTas.getPicture(options);
    return `${image}`;
};

export const getPhotoFromMobile = async (source?: boolean): Promise<{format: string, base64: string}> => {
    let defaultOpt: CameraOptions = {
        quality: 100,
        source: source ? CameraSource.Camera : CameraSource.Photos,
        width: 1200,
        allowEditing: false,
        resultType: CameraResultType.Base64,
    };

    const image = await Camera.getPhoto(defaultOpt);
    const format = image.format;
    const img = image.base64String;

    return {format: format, base64: `${img}`};
};

