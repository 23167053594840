import { IonLabel, IonListHeader, IonList, IonItem } from '@ionic/react';
import React from 'react';
import { IContacto } from '../../types/contactosTypes';
import Contacto from '../contacto/Contacto';
import style from './Section.module.scss'

interface SectionProps {
    title: string;
    subtitle?: any;
    contactos: IContacto[],
    styles: string;
}

const Section: React.FC<SectionProps> = ({ title, subtitle, contactos, styles }) => {
    return (
        <IonList className={style.contactosSection}>
            <IonListHeader>
                <IonLabel
                    className={[title.length > 25 ? style.longTitle : style.shortTitle, style[styles], "ion-text-center"].join(" ")}>
                    {title}
                </IonLabel>
            </IonListHeader>
            {subtitle &&
                <IonItem lines='none' className={` ion-text-center`}>
                    <IonLabel className={style.subtitle}>{subtitle}</IonLabel>
                </IonItem>
            }

            {contactos.map((contacto, index) => {
                return contacto.section === title && <Contacto key={index} {...contacto} />
            })}
        </IonList>
    )
}

export default Section;