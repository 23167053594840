import React, { useEffect, useMemo, useState } from 'react'
import { IonBackdrop, IonContent, IonFooter, IonList, IonPage } from '@ionic/react';
import { routes } from '../../router/routes';
import { routes as homeRoutes } from '../../../../home/router/routes';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import styles from './StepVerificaDatosPage.module.scss'
import CollapsibleListSection from '../../../../../shared/components/collapsible/collapsible-list-section/CollapsibleListSection';
import CollapsibleListItem from '../../../../../shared/components/collapsible/collapsible-list-item/CollapsibleListItem';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useHistory, useLocation } from 'react-router';
import { finalizarTramite } from '../../../../../store/reintegros/ReintegrosActions';
import Header from '../../../../../shared/components/layout/header/Header';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import StandardModal from '../../../../../shared/components/popups/modals/standard-modal/StandardModal';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import { isWeb } from '../../../../../shared/utils/utils';
import analyticsService from '../../../../../services/analytics/analytics';
import { IDComprobantes } from '../../../../../store/reintegros/types/ReintegrosData';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import useHttpErrorHandler from '../../../../../hooks/useHttpErrorHandler';


const StepVerificaDatosPage: React.FC = () => {
    const { nuevoReintegro, obtainedData } = useSelector((state: RootState) => state.reintegros);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [isSectionOpen, setIsSectionOpen] = useState<boolean>(false);
    const [showFieldErrorModal, setShowFieldErrorModal] = useState<boolean>(false);
    const [isFinished, setIsFinished] = useState<boolean>();
    const [wasSuccessful, setWasSuccessful] = useState<boolean>(undefined);
    const [resetButton, setResetButton] = useState<boolean>(false);
    const [numeroTramiteEmpty, setNumeroTramiteEmpty] = useState<boolean>(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const { httpError : error } = useHttpErrorHandler({ tramite: 'REINTEGROS', overrideAlertAction: () => httpErrorAlertButtonHandler() });


    let datosFacturacion = useMemo(() => {
        const isTicket = nuevoReintegro?.tipoComprobante?.id === IDComprobantes.Ticket;
        return [
            {
                label: 'Nro. de C.U.I.T. del emisor',
                content: nuevoReintegro?.profesional?.cuit,
                path: isTicket ? routes.datosTicket1 : routes.datosFacturacion1
            },
            {
                label: 'Tipo de documento',
                content: !isTicket && nuevoReintegro?.tipoDocumento?.descripcion,
                path: routes.datosFacturacion1
            },
            {
                label: 'Número de documento',
                content: !isTicket && nuevoReintegro?.numeroDocumento,
                path: routes.datosFacturacion1
            },
            {
                label: 'Punto de venta',
                content: nuevoReintegro?.nroComprobantePrefijo,
                path: isTicket ? routes.datosTicket1 : routes.datosFacturacion2
            },
            {
                label: 'Nro. de comprobante',
                content: nuevoReintegro?.nroComprobante,
                path: isTicket ? routes.datosTicket1 : routes.datosFacturacion2
            },
            {
                label: 'Comprobante',
                content: !isTicket && nuevoReintegro?.tipoComprobante?.descripcion,
                path: isTicket ? routes.seleccionComprobante : routes.datosFacturacion2
            },
            {
                label: 'Fecha de emisión',
                content: nuevoReintegro?.fechaEmision.substring(0, 10).split('-').reverse().join('/'),
                path: isTicket ? routes.datosTicket2 : routes.datosFacturacion2
            },
            {
                label: 'Código de autorización (CAE)',
                content: !isTicket && nuevoReintegro?.cae,
                path: routes.datosFacturacion3
            },
            {
                label: 'Fecha de vencimiento (CAE)',
                content: !isTicket && nuevoReintegro?.fechaVencimientoCae.substring(0, 10).split('-').reverse().join('/'),
                path: routes.datosFacturacion3
            },
            {
                label: 'Importe total',
                content: nuevoReintegro?.importe && '＄ ' + nuevoReintegro?.importe,
                path: isTicket ? routes.datosTicket2 : routes.datosFacturacion3
            },
            {
                label: 'Cantidad de sesiones',
                content: nuevoReintegro?.cantidadSesiones,
                path: routes.datosFacturacion3
            }
        ]
    }, [nuevoReintegro]);


    useEffect(() => {
        if (!nuevoReintegro) {
            history.push(homeRoutes.gestionesOnline);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        const timer = setTimeout(() => setIsSectionOpen(true), 500);
        return () => { clearTimeout(timer) };
    }, []);


    useEffect(() => {
        if (obtainedData) {
            setIsFinished(true);
            if (obtainedData.error) {
                setWasSuccessful(false);
                setShowFieldErrorModal(true);
            } else {
                if (!obtainedData.idTramite?.toString().trim()) {
                    setNumeroTramiteEmpty(true);
                    tramiteNotSuccesful();
                    analyticsService.trackEvent('formularios', 'reintegros - resumen', 'error - sin numero de tramite', routes.verificaDatos);
                    return;
                };
                setWasSuccessful(true);
                setTimeout(() => {
                    dispatch(SetStepperNavigation(Animations.next));
                    history.push(routes.finalizarTramite);
                }, 1000)
            }
        }
    }, [obtainedData, history, dispatch])


    useEffect(() => {
        if (error) {
            tramiteNotSuccesful();
            analyticsService.trackEvent('formularios', 'reintegros - resumen', 'error - ' + error, routes.verificaDatos);
        };
    }, [error])


    const tramiteNotSuccesful = () => {
        setIsFinished(true);
        setWasSuccessful(false);
    }


    const sendTramite = () => {
        setShowBackdrop(true);
        if (resetButton) setResetButton(false);
        dispatch(finalizarTramite(nuevoReintegro));
    }

    const httpErrorAlertButtonHandler = () => {
        setIsFinished(false);
        setWasSuccessful(false);
        setResetButton(true);
        setShowBackdrop(false);
    }

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles.verificaReintegros}>

                <PageTitle>
                    Reintegros online
                </PageTitle>

                <Stepper
                    stepsLenght={nuevoReintegro?.tipoComprobante?.id === IDComprobantes.Ticket ? 6 : 7}
                    currentNumber={nuevoReintegro?.tipoComprobante?.id === IDComprobantes.Ticket ? 5 : 6}
                />

                <StepTitle color="white">
                    Verificá los datos ingresados
                </StepTitle>

                {nuevoReintegro && <IonList className='ion-margin'>

                    <CollapsibleListSection title='Datos de la práctica' isOpen={isSectionOpen}>
                        <CollapsibleListItem
                            label='Integrante del grupo familiar'
                            content={nuevoReintegro.integrante ? nuevoReintegro!.integrante.nombre : ''}
                            editHandler={() => history.push(routes.seleccionSocio, { from: location.pathname })}
                        />
                        <CollapsibleListItem
                            label='Tipo de practica'
                            content={nuevoReintegro.tipoPractica ? nuevoReintegro!.tipoPractica.nombre : ''}
                            editHandler={() => history.push(routes.root)}
                        />
                    </CollapsibleListSection>

                    <CollapsibleListSection title='Datos de facturación' isOpen={isSectionOpen}>
                        {datosFacturacion.map(item => {
                            if (!item.content || item.content === 0) return null;
                            return <CollapsibleListItem
                                key={item.label}
                                label={item.label}
                                content={item.content}
                                editHandler={() => history.push(item.path, { from: location.pathname })}
                            />
                        })}
                    </CollapsibleListSection>

                    <CollapsibleListSection title='Archivos adjuntos' isOpen={isSectionOpen}>
                        <CollapsibleListItem
                            label='Comprobante'
                            content={nuevoReintegro.comprobanteFacturacion?.nombre}
                            editHandler={() => history.push(routes.archivosAdjuntos)}
                        />

                        {nuevoReintegro?.tipoPractica?.pedidoMedico &&
                            <CollapsibleListItem
                                label='Pedido Médico'
                                content={nuevoReintegro!.comprobantePrestacion?.nombre}
                                editHandler={() => history.push(routes.archivosAdjuntos)}
                            />
                        }
                    </CollapsibleListSection>

                    <CollapsibleListSection title='Datos de contacto' isOpen={isSectionOpen}>
                        <CollapsibleListItem
                            label='Correo electrónico'
                            content={nuevoReintegro.correoElectronico}
                            editHandler={() => history.push(routes.archivosAdjuntos)}
                        />
                    </CollapsibleListSection>

                </IonList>}

            </IonContent>

            <IonFooter className={`${styles.verificaReintegrosFooter} ion-no-border`}>
                <NavigationButton
                    onClick={sendTramite}
                    disabled={false}
                    isFinished={isFinished}
                    success={wasSuccessful}
                    reset={resetButton}
                >
                    Generar Trámite
                </NavigationButton>
                {isWeb() && <Footer />}
            </IonFooter>

            <StandardModal
                isOpen={showFieldErrorModal}
                title='Gestión de Reintegros'
                content={'Comprobá el campo ' + obtainedData?.errorField + ' y volvé a intentarlo.'}
                handler={() => setShowFieldErrorModal(false)}
            />

            <StandardModal
                isOpen={numeroTramiteEmpty}
                title='Importante'
                content={'Lo sentimos, tuvimos un problema. Por favor, intentá nuevamente más tarde.'}
                handler={() => history.replace(homeRoutes.root)}
            />

            {showBackdrop && <IonBackdrop style={{ zIndex: 99 }} />}

        </IonPage>
    );
}

export default StepVerificaDatosPage;