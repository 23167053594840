export interface IPhone {
    link: string;
    label: string;
}
export interface IPhones {
    asistenciaMedica: {
        whatsApp: IPhone;
        lineaUno: IPhone;
        lineaDos: IPhone;
    }
    atencionAlSocio: {
        whatsApp: IPhone;
        linea0810: IPhone;
        linea0800: IPhone;
    }
    riesgoDeVida: IPhone;
}

export const phones: IPhones = {
    asistenciaMedica: {
        whatsApp: {
            link: '5491148729100',
            label: '11.4872.9100'
        },
        lineaUno: {
            link: '08108887788',
            label: '0810.888.7788'
        },
        lineaDos: {
            link: '08109996300',
            label: '0810.999.6300'
        },
    },
    riesgoDeVida: { 
        link: '08106661111', 
        label: '0810.666.1111' 
    },
    atencionAlSocio: {
        whatsApp: { 
            link: '5491148729000', 
            label: '11.4872.9000' 
        },
        linea0810: { 
            link: '08105556733', 
            label: '0810.555.6733' 
        },
        linea0800: { 
            link: '08005556733', 
            label: '0800.555.6733' 
        }
    }
}