import {
  IonButton,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { ComponentProps } from 'react';
import styles from './ModuleModal.module.scss'

interface Props extends ComponentProps<any> {
  isOpen: boolean;
  closeModal: Function;
  title: string;
}

const ModuleModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  children,
  title,
}) => {
  return (
    <IonModal 
      isOpen={isOpen} 
      cssClass={styles['module-modal']}
      onDidDismiss={() => closeModal()}
    >
      <IonHeader>
        <IonToolbar color="primary">
          <IonLabel className="ion-margin-start">{title}</IonLabel>
          <IonButton
            onClick={() => {
              closeModal();
            }}
            slot="end"
            size="small"
            shape="round"
            className="ion-margin-end"
          >
            <IonIcon icon={close} color="light" />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      {children}
    </IonModal>
  );
};

export default ModuleModal;
