import React, { useEffect, useRef, useState } from 'react';
import { IonActionSheet, IonButton, IonCardSubtitle, IonChip, IonContent, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonToggle } from '@ionic/react';
import datosContactoService from './services/DatosContactoService';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setDatosContactoStore, setIncompleteProcess, setNumeroDeSolicitud } from '../../../store/datos-contacto/datosContactoActions';
import { RootState } from '../../../store/index';
import Loading from '../../../shared/components/popups/loading/Loading';
import styles from './DatosContactoPage.module.scss';
import { home, mail, close } from 'ionicons/icons';
import NavigationButton from '../../../shared/components/buttons/navigation/NavigationButton';
import { isWeb } from '../../../shared/utils/utils';
import analyticsService from '../../../services/analytics/analytics';
import PendingOrIncompleteProcessModal from './components/pending-or-incomplete-process-modal/PendingOrIncompleteProcessModal';
import { DatosContactoDataType, domicilioType, typeFromEnum } from '../../../store/datos-contacto/types/DatosContactoData';
import datosDeContactoService from './services/DatosContactoService';
import { routes } from './router/routes';
import { routes as homeRoutes } from '../../home/router/routes';
import Header from '../../../shared/components/layout/header/Header';
import PageTitle from '../../../shared/components/page-title/PageTitle';
import AddressBlock from './components/address-block/AddressBlock';
import Footer from '../../../shared/components/layout/footer/Footer';
import EmailsModal from './components/emails-modal/EmailsModal';
import StandardModal from '../../../shared/components/popups/modals/standard-modal/StandardModal';
import ProteccionDatos from '../../../shared/components/texts/proteccion-datos/ProteccionDatos';
import AlertPopup from '../../../shared/components/popups/alerts/alert/AlertPopup';
import { setEmail } from '../../../store/pagoEnLinea/actions';
import TramiteFooter from '../../../shared/components/tramite-footer/TramiteFooter';
import useHttpErrorHandler from '../../../hooks/useHttpErrorHandler';


const DatosContacto: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const datosContactoState = useSelector((state: RootState) => state.datosContacto);
  const { datosContacto, idTramite, incompleteProcess } = datosContactoState;
  const [showCorreoModal, setShowCorreoModal] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [showEmailChangedAlert, setShowEmailChangedAlert] = useState(false);
  const [showAddressSelectionActionSheet, setShowAddressSelectionActionSheet] = useState(false);
  const [domicilioEqual, setDomicilioEqual] = useState<boolean>(datosContacto?.domicilioEqual);
  const [equalAddressType, setEqualAddressType] = useState<domicilioType>();
  const firstRender = useRef(true);
  const [showProteccionDatosModal, setShowProteccionDatosModal] = useState(false);
  useHttpErrorHandler({tramite: 'DATOS_DE_CONTACTO'});


  useEffect(() => {
    (!datosContacto) ? buscarTramites() : setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if(domicilioEqual === undefined) return;
    firstRender.current ? firstRender.current = false : setShowAddressSelectionActionSheet(true);
  }, [domicilioEqual]); 


  useEffect(() => {
    if(!equalAddressType) return;
    setEqualAddress();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equalAddressType]);


  const buscarTramites = async () => {
    try {
      const { data: buscarTramiteResponse } = await datosContactoService.buscarTramites(!isWeb());
      if (buscarTramiteResponse.pendingProcess || buscarTramiteResponse.incompleteProcess) {
        dispatch(setDatosContactoStore({...datosContactoState, ...buscarTramiteResponse}));
        setDomicilioEqual(buscarTramiteResponse.datosContacto?.domicilioEqual);
      } else {
        const { data: getDatosContactoResponse } = await datosContactoService.getDatosContacto();
        dispatch(setDatosContactoStore({...datosContactoState, datosContacto: getDatosContactoResponse}));
        setDomicilioEqual(getDatosContactoResponse?.domicilioEqual);
      }
      analyticsService.trackPageView('tramites/datos-de-contacto');
    } catch (error) {
      console.log('DatosDeContactoPage', error);
    } finally {
      setIsLoading(false);
    }
  };


  const guardarDatos = async (datosDeContacto: DatosContactoDataType, idTramite: string) => {
    try {
      const { data: guardarDatosresponse } = await datosDeContactoService.guardarDatos({
        datosContacto: datosDeContacto,
        idTramite: idTramite,
        nativo: false
      });
      delete guardarDatosresponse.nativo;
      dispatch(setDatosContactoStore({...datosContactoState, ...guardarDatosresponse, incompleteProcess: true }));
      analyticsService.trackPageViewForm('trackPageviewForm', 'tramites/datos-de-contacto/formulario-enviado', 'datos de contacto', 1);
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };


  const setEqualAddress = () => {
    if (domicilioEqual) {
      setIsLoading(true);
      const datosContactoUpdated: DatosContactoDataType = {
        ...datosContacto,
        domicilioEqual: true,
      };
      if (equalAddressType === 'residencia') {
        datosContactoUpdated.correspondencia = { ...datosContacto!.residencia };
      } else {
        datosContactoUpdated.residencia = { ...datosContacto!.correspondencia };
      }
      guardarDatos(datosContactoUpdated, idTramite);
      setShowAddressSelectionActionSheet(false);
    } else {
      setShowAddressSelectionActionSheet(false);
      history.push(routes.editAddress.replace(':type', equalAddressType).replace(':typeFrom', typeFromEnum.toggle)); 
    }
  }


  const somethingHasChanged = () => {
    return datosContacto?.correspondencia?.barrioChanged ||
      datosContacto?.correspondencia?.calleChanged ||
      datosContacto?.correspondencia?.codigoPostalChanged ||
      datosContacto?.correspondencia?.departamentoChanged ||
      datosContacto?.correspondencia?.emailChanged ||
      datosContacto?.correspondencia?.localidadChanged ||
      datosContacto?.correspondencia?.numeroChanged ||
      datosContacto?.correspondencia?.pisoChanged ||
      datosContacto?.correspondencia?.provinciaChanged ||
      datosContacto?.correspondencia?.telefonoDefaultChanged ||
      datosContacto?.correspondencia?.telefonoMovilDefaultChanged ||
      datosContacto?.residencia?.barrioChanged ||
      datosContacto?.residencia?.calleChanged ||
      datosContacto?.residencia?.codigoPostalChanged ||
      datosContacto?.residencia?.departamentoChanged ||
      datosContacto?.residencia?.emailChanged ||
      datosContacto?.residencia?.localidadChanged ||
      datosContacto?.residencia?.numeroChanged ||
      datosContacto?.residencia?.pisoChanged ||
      datosContacto?.residencia?.provinciaChanged ||
      datosContacto?.residencia?.telefonoDefaultChanged ||
      datosContacto?.residencia?.telefonoMovilDefaultChanged;
  }


  const finalizarTramiteHandler = async () => {
    dispatch(setIncompleteProcess(false));
    if (!datosContacto.esBeneficiario) {
      const finalizarResponse = await (datosDeContactoService.finalizarTramite(idTramite));
      if (finalizarResponse) {
        dispatch(setNumeroDeSolicitud(finalizarResponse.data));
        history.push(routes.endPage);
      }
    } else {
      const guardarDatosresponse = await datosDeContactoService.guardarDatos({ idTramite, datosContacto, nativo: false });
      if (guardarDatosresponse) {
        dispatch(setEmail(datosContacto.emails.find(p => p.isDefault).email));
        setShowEmailChangedAlert(true);
      }
    }
  };


  const handleCloseAddressSelectionActionSheet = () => {
    setDomicilioEqual(domicilioEqual => !domicilioEqual);
    setShowAddressSelectionActionSheet(false);
  }


  return (
    <>
      {!isLoading && <PendingOrIncompleteProcessModal />}
      <IonPage>
        <Header showBackButton={true} />
        {datosContacto && 
          <>
            <IonContent className={`${styles.datosContactoMain} flexContent`}>
              <PageTitle>
                MIS DATOS DE CONTACTO
              </PageTitle>
              <IonList>
                <IonListHeader mode='md' lines="full" color="light">
                  <IonCardSubtitle color="primary">
                    Correo electronico
                  </IonCardSubtitle>
                </IonListHeader>
                <IonItem lines="full">
                  <IonLabel>
                    {datosContacto?.emails?.find((email) => email.isDefault)?.email}
                  </IonLabel>
                  <IonButton
                    children="EDITAR"
                    fill="clear"
                    slot="end"
                    onClick={() => {
                      setIsValidEmail(true);
                      setShowCorreoModal(true);
                    }}
                  />
                </IonItem>
                {!datosContacto.esBeneficiario &&
                  <>
                    <IonListHeader mode='md' lines="full" color="light">
                      <IonCardSubtitle color="primary">Domicilios</IonCardSubtitle>
                    </IonListHeader>
                    <IonItem lines="full" color='light'>
                      <IonLabel className='ion-text-wrap'>¿Tu domicilio de correspondencia coincide con el de residencia?</IonLabel>
                      <IonToggle
                        mode='md'
                        slot='end'
                        checked={domicilioEqual}
                        onIonChange={(e) => setDomicilioEqual(e.detail.checked)}
                      />
                    </IonItem>
                    <AddressBlock
                      data={datosContacto.residencia}
                      type="residencia"
                      same={datosContacto.domicilioEqual}
                    />
                    {!datosContacto.domicilioEqual && (
                      <AddressBlock
                        data={datosContacto.correspondencia}
                        type="correspondencia"
                      />
                    )}
                  </>
                }
              </IonList>
              <section className={styles.terms}>
                <IonChip color="primary">
                  Declaro bajo juramento que los datos que constan en esta solicitud son verdaderos
                  y que conozco y acepto las condiciones generales de asociación y de los servicios de atención y cobertura del plan.
                  Aceptando las consideraciones generales que previamente leí y acepté
                </IonChip>
                <IonLabel onClick={() => setShowProteccionDatosModal(true)}>
                  Protección de los datos personales. Ley 25.326 (*)
                </IonLabel>
              </section>
            </IonContent>
            <TramiteFooter gradientColor='white'>
              <NavigationButton disabled={!somethingHasChanged() && !incompleteProcess} onClick={finalizarTramiteHandler}>Confirmar</NavigationButton>
              {isWeb() && <Footer />}
            </TramiteFooter>

            <EmailsModal
              isOpen={showCorreoModal}
              setIsOpen={setShowCorreoModal}
              isValidEmail={isValidEmail}
              setIsValidEmail={setIsValidEmail}
            />

            <StandardModal
              isOpen={showProteccionDatosModal}
              title='PROTECCIÓN DE LOS DATOS PERSONALES'
              subtitle='Ley 25.326'
              content={<ProteccionDatos />}
              handler={() => setShowProteccionDatosModal(false)}
            />

            <AlertPopup
              isOpen={showEmailChangedAlert}
              title='Actualización'
              message='La modificación de tu correo electrónico ha sido exitosa'
              buttons={[
                {
                  text: 'Aceptar',
                  handler: () => history.push(homeRoutes.root)
                }
              ]}
            />

            <IonActionSheet
              mode='md'
              isOpen={showAddressSelectionActionSheet}
              onDidDismiss={() => setShowAddressSelectionActionSheet(false)}
              backdropDismiss={false}
              header='Domicilio'
              subHeader={domicilioEqual ? '¿Qué domicilio querés conservar?' : '¿Qué domicilio querés modificar?'}
              buttons={[
                {
                  text: 'Residencia',
                  icon: home,
                  cssClass: styles.actionSheetIcon,
                  handler: () => setEqualAddressType('residencia')
                },
                {
                  text: 'Correspondencia',
                  icon: mail,
                  cssClass: styles.actionSheetIcon,
                  handler: () => setEqualAddressType('correspondencia')
                },
                {
                  text: 'Volver',
                  icon: close,
                  cssClass: styles.actionSheetIcon,
                  handler: handleCloseAddressSelectionActionSheet
                }
              ]}
            />
          </>
        }
        <Loading isOpen={isLoading} message="Cargando..." />
      </IonPage>
    </>
  );
}

export default DatosContacto;