import { HomeLink } from "../types/HomeLink";
import homeButtonsData from "../data/homeButtonsData";

const {quieroSerSocio, cartilla, gestionesOnline, telefonos, informacionSocio, saludYBienestar, novedades} = homeButtonsData;

const getButtonsWeb = (): HomeLink[] => {
  return [
    quieroSerSocio,
    cartilla,
    gestionesOnline,
    telefonos,
    informacionSocio,
    saludYBienestar,
    novedades
  ];
};

export default getButtonsWeb;