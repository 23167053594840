import { IonButton, IonContent, IonList } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, SwiperOptions } from 'swiper';
import { Swiper as SwiperInterface } from 'swiper';

import getButtons from '../../helpers/getHomeButtonsWeb';
import Banners from '../banners/Banners';
import FooterHome from '../footer-home/FooterHome';
import HomeMenuButton from '../home-menu-button/HomeMenuButton';
import styles from './HomeWeb.module.scss';
import homeService, { bannerSlide } from '../../services/HomeService';
import environmentService from "../../../../services/environment/environmentService";

const slideOpts: SwiperOptions = {
  initialSlide: 0,
  slidesPerView: 1,
  speed: 500,
  autoplay: true,
  loop: true,
  coverflowEffect: {
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  },
};

const HomeWeb: React.FC = () => {
  const homeMenu = getButtons();
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
  const [bannersHome, setBannersHome] = useState(new Array<bannerSlide>());
  const [slidesHome, setSlidesHome] = useState(new Array<bannerSlide>());
  let counter = 1;

  useEffect(() => {
    window.location.href = environmentService.getCurrent().webBaseUrl
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  useEffect(() => {
    getBannersSlidesWebMobile();
  }, []);

  const getBannersSlidesWebMobile = async () => {
    const bannersSlides = await homeService.getBannersSlidesWebMobile();
    setSlidesHome(bannersSlides.slides);
    setBannersHome(bannersSlides.banners);
  };

  const openLinkSlide = (url: string) => window.location.href = url;

  const slideBackButton = () => swiperInstance?.slidePrev();

  const slideNextButton = () => swiperInstance?.slideNext();

  return (
    <IonContent className={styles.homeWebContent}>
      <div className={styles.homeWebSlides}>
        {slidesHome?.length > 0 ?
          <>
            <Swiper
              modules={[Autoplay]}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              id={styles.swiper}
              {...slideOpts}
            >
              {slidesHome.map((slide, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      onClick={() => openLinkSlide(slide.link)}
                      style={{ backgroundImage: `url(${slide.imgUrl})`}}
                      className={styles.swiperSlide}
                    >
                      <div className={styles.slideTextBox}>
                        <p>{slide.titulo}</p>
                      </div>
                    </SwiperSlide>
                  );               
              })}
            </Swiper>
            <div className={styles.divArrows}>
              <IonButton fill="clear" className={styles.backBtn}  >
                <i className="fas fa-chevron-left" onClick={slideBackButton} />
              </IonButton>
              <IonButton fill="clear" className={styles.nextBtn} onClick={slideNextButton}>
                <i className="fas fa-chevron-right" />
              </IonButton>
            </div>
          </>
          :
          <SwiperSlide id={styles.defaultSlide} className={styles.swiperSlide} />
        }
      </div>

      <IonList className="ion-no-padding">
        {homeMenu.map(
          (button: { title: string; url: string; class: string; externalLink: boolean; blank:boolean;}) => {

            let colorNumber = counter;
            counter = counter === 4 ? 1 : counter + 1;

            return (
              <HomeMenuButton
                key={button.title}
                title={button.title}
                url={button.url}
                class={`color${colorNumber}`}
                isWeb={true}
                blank={button?.blank}
                externalLink={button.externalLink}
              />
            );
          }
        )}
      </IonList>
      <Banners banners={bannersHome} />
      <FooterHome />
    </IonContent>
  );
};

export default HomeWeb;