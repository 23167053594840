import "core-js/stable";
import "regenerator-runtime/runtime";
import 'react-app-polyfill/stable';
import 'proxy-polyfill/proxy.min';
import '@webcomponents/custom-elements';
import 'get-root-node-polyfill/implement';
import 'globalthis/auto';
import './polyfill';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./store/index";
import './index.scss';
import { IonReactRouter } from "@ionic/react-router";
import HttpsRedirect from 'react-https-redirect';
import { isWebLocal } from "./shared/utils/utils";


ReactDOM.render(
  <Provider store={store}>
    <IonReactRouter>
      {
        ( isWebLocal())
        ?
          <App />
        :
        <HttpsRedirect>
          <App />
        </HttpsRedirect>
      }
      
    </IonReactRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();