import { IonLabel } from '@ionic/react';
import React from 'react';

const AuthText: React.FC = ({children}) => {
	return (
        <IonLabel color="secondary" style={{fontSize: "4vw"}}>
            {children}
		</IonLabel>
	);
};

export default AuthText;