import { IonCard, IonCardContent, IonContent, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonListHeader, IonPage, IonSkeletonText, IonThumbnail } from '@ionic/react'
import { calendarOutline, card, ticket } from 'ionicons/icons'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../../../shared/components/layout/header/Header'
import { RootState } from '../../../store'
import pagoEnLineaService from './services/PagoEnLineaService';
import { getPaymentIcon } from './helpers/getPaymentMethods'
import { useHistory } from 'react-router'
import { routes } from './router/routes'
import styles from './PagoEnLineaPage.module.scss'
import analyticsService from '../../../services/analytics/analytics'
import { Comprobante } from '../../../store/pagoEnLinea/types/PagoEnLineaTypes'
import VoucherInformationModal from './components/VoucherInformationModal/VoucherInformationModal'
import PageTitle from '../../../shared/components/page-title/PageTitle'
import { SetShowInitialMessage } from '../../../store/pagoEnLinea/actions'
import GenericAlert from '../../../shared/components/popups/alerts/generic-alert/GenericAlert'
import useHttpErrorHandler from '../../../hooks/useHttpErrorHandler'

const PagoEnLineaPage:React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user: { user }, pagoEnLinea: { showInitialMessage } } = useSelector((state: RootState) => state);
  const [comprobantes, setComprobantes] = useState<Comprobante[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalVoucher, setModalVoucher] = useState(null);
  useHttpErrorHandler();


  useEffect(() => {
    (async () => {
      const { listaComprobantes } = await pagoEnLineaService.getComprobantes(user.contrato) || {};
      if (listaComprobantes) {
        const size = listaComprobantes.length > 5 ? 5 : listaComprobantes.length;
        setComprobantes(listaComprobantes.slice(0, size))
      }
      setIsLoading(false)
    })()
  }, [user.contrato]);


  const selectOption = (option: string) => {
    analyticsService.trackEvent("gestiones online", "menu principal", ""); // ver si se agrega detalle evento
    history.push(option);
  };


  const onViewVoucherPress = (comprobante) => {
    setModalVoucher(comprobante);
    setShowModal(true);
  }


  return (
    <IonPage >
      <Header showBackButton={true} />
      <IonContent className={styles.pagoEnLineaContent} >
        <VoucherInformationModal setShowModal={setShowModal} showModal={showModal} voucher={modalVoucher} />
        <PageTitle lines>PAGO EN LINEA</PageTitle>
        <div className={styles.topMenu}>
          <IonCard
            button
            color=""
            onClick={() => { selectOption(routes.vouchers) }}
            className="ion-no-margin ion-margin-top">
            <IonCardContent>
              <IonIcon color='primary' icon={ticket} />
              <IonLabel color='primary'>Mis Pagos</IonLabel>
            </IonCardContent>
          </IonCard>
          <IonCard
            button
            color=""
            onClick={() => { selectOption(routes.payments) }}
            className="ion-no-margin ion-margin-top">
            <IonCardContent>
              <IonIcon color='primary' icon={card} />
              <IonLabel color='primary'>Realizar Pago</IonLabel>
            </IonCardContent>
          </IonCard>
        </div>

        <IonListHeader lines="none" mode="ios">
          Últimos Pagos
        </IonListHeader>
        <IonList>
          {!isLoading &&
            <>
              {comprobantes?.length > 0 ? comprobantes.map((comprobante, index) => {
                const [date] = comprobante.fecha.split(' ');
                return (
                  <Fragment key={index}>
                    <IonItem
                      data-testid={`historial-pago-${index}`}
                      onClick={() => { comprobante?.pagoInternoOsde && onViewVoucherPress(comprobante) }}
                      lines="none"
                    >
                      <IonThumbnail slot='start'>
                        <IonIcon
                          color='medium'
                          className={!comprobante?.pagoInternoOsde && styles.pagoExternoIcon}
                          src={getPaymentIcon(comprobante?.pagoInternoOsde && comprobante.medioDePago)}
                        />
                      </IonThumbnail>

                      <div className={styles.compMain}>
                        <h3 className={styles.detail}>{comprobante.mensajeDePago}</h3>
                        <h3 className={styles.ammount}>{comprobante.importe}</h3>
                        <div className={styles.compDate}>
                          {date && <><IonIcon icon={calendarOutline} /><p>{date}</p></>}
                        </div>
                      </div>
                    </IonItem>
                    {comprobantes.length - 1 !== index && <IonItemDivider />}
                  </Fragment>
                )
              }) :
                <IonItem lines="none" className='ion-padding-start'>
                  <IonLabel>
                    No hay pagos registrados
                  </IonLabel>
                </IonItem>
              }
            </>
          }
          {isLoading && Array.from({ length: 5 })?.map((_, index) => (
            <Fragment key={index}>
              <ComprobanteSkeletton />
              {index !== 4 && <IonItemDivider />}
            </Fragment>))
          }
        </IonList>
      </IonContent>

      <GenericAlert
        isOpen={showInitialMessage}
        title='Importante'
        buttons={[
          { children: 'Continuar', onClick: () => dispatch(SetShowInitialMessage(false)) }
        ]}
      >
        <>
          <p>En esta sección podés visualizar los pagos que tenemos registrados. Tené en cuenta que:</p>
          <ul className={styles.alertUl}>
            <li>Si pagaste desde nuestra web o de la app OSDE Móvil también es posible visualizar el comprobante.</li>
            <li>Si abonaste por otro medio, lo verás reflejado dentro de las 72 horas. Te sugerimos conservar el comprobante.</li>
            <li>Por el momento no es posible visualizar los pagos efectuados mediante débito automatico.</li>
          </ul>
        </>
      </GenericAlert>
    </IonPage>
  )
};

export default PagoEnLineaPage



const ComprobanteSkeletton = () => (
  <IonItem lines='none' className={styles.compSkeleton}>
    <IonThumbnail slot="start">
      <IonSkeletonText animated />
    </IonThumbnail>
    <IonLabel>
      <h3>
        <IonSkeletonText animated style={{ width: '50%' }} />
      </h3>
      <p>
        <IonSkeletonText animated style={{ width: '80%' }} />
      </p>
    </IonLabel>
  </IonItem>
);