import React from 'react'
import { IonContent, IonPage } from '@ionic/react';
import Header from '../../../../../shared/components/layout/header/Header';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import {  isWeb } from '../../../../../shared/utils/utils';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { leaveTramite } from '../../../../../store/datos-contacto/datosContactoActions';
import TramiteFooter from '../../../../../shared/components/tramite-footer/TramiteFooter';
import { routes } from '../../../../home/router/routes';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import Check from '../../../../../shared/components/stepper/final-step/check/Check';
import SuccessTitle from '../../../../../shared/components/stepper/final-step/success-title/SuccessTitle';
import SuccessDivider from '../../../../../shared/components/stepper/final-step/success-divider/SuccessDivider';
import SuccessText from '../../../../../shared/components/stepper/final-step/success-text/SuccessText';


const EndPage: React.FC = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const { numeroDeSolicitud } = useSelector((state: RootState) => state.datosContacto)
    const finishedTramite = async () => {
        history.replace(routes.root, false);
        setTimeout(() => {
            dispatch(leaveTramite());
        }, 1000);
    }

    return (
        <IonPage>
            <Header showBackButton={false} />
            <IonContent>
                <div className="ion-text-center ion-padding-top">
                    <Check />
                </div>

                <SuccessTitle>
                    La actualización se <br></br>registró correctamente
                </SuccessTitle>

                <SuccessDivider />

                <SuccessText>
                    Muchas gracias por actualizar tus datos. <br />
                    En 72hs podrás visualizar las modificaciones realizadas.
                </SuccessText>

                <SuccessDivider />

                <SuccessText>
                    Tu número de solicitud es: <b>{numeroDeSolicitud}</b>
                </SuccessText>

                <SuccessDivider />

                <SuccessText noLines>
                    Nos comunicaremos en caso de requerir información adicional.
                </SuccessText>
            </IonContent>
            <TramiteFooter>
                <NavigationButton onClick={finishedTramite}>FINALIZAR</NavigationButton>
                {isWeb() && <Footer /> }
            </TramiteFooter>
        </IonPage>
    )
}

export default EndPage
