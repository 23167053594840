import { useEffect } from 'react';

const useShowVersion = () => {
  const showVersion = () => fetch("./assets/pom.properties").then(e => e.text().then(console.log))
  
  useEffect(() => {
    (window as any).showVersion = showVersion;
  }, []);
};

export default useShowVersion;