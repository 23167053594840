import { IonLabel, IonList, IonListHeader } from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import analyticsService from "../../../../../services/analytics/analytics";
import AlertPopup from "../../../../../shared/components/popups/alerts/alert/AlertPopup";
import styles from './AntecedentesCheckList.module.scss';
import { routes } from "../../../../home/router/routes";
import AntecedenteItem from "../antecedente-item/AntecedenteItem";

interface Props {
  title: string;
  antecedentes: any[] | undefined;
}

const messageEmpadronamientoVigente = "Para continuar con esta solicitud, deberás presentar el formulario de empadronamiento, junto con un justificativo médico para su evaluación en un Centro de Atención Personalizada.";

const AntecedentesCheckList: React.FC<Props> = ({ title, antecedentes }) => {
  const history = useHistory();
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const checkedAction = (e: boolean) => {
    if (e) {
      setShowAlert(true);
      analyticsService.formNotSubmittedAndSubmitted('empadronamiento anticonceptivos > 2. antecedentes', messageEmpadronamientoVigente, 1, 0);
    }
  }

  return (
    <>
      <IonList className={styles.antecedentesCheckList}>
        <IonListHeader mode='md' lines="full" color="light">
          <IonLabel color="primary">{title}</IonLabel>
        </IonListHeader>
        {antecedentes?.map((antecedente, index) => 
          <AntecedenteItem key={index} antecedente={antecedente} checkedAction={checkedAction} />
        )}
      </IonList>

      <AlertPopup
        id="id-popup-antecedentes"
        isOpen={showAlert}
        title="Importante"
        backdropDismiss={true}
        message={messageEmpadronamientoVigente}
        buttons={[
          { text: 'Finalizar', role: 'cancel', handler: () => history.replace(routes.gestionesOnline) }
        ]}
      />
    </>
  );
};

export default AntecedentesCheckList;
