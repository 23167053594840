import { ApiKeyResponse, GetComprobantesResponse, GetFormResponse, PostFormResponse, tipoTalon } from "../../../../../store/pagoEnLinea/types/PagoEnLineaTypes";


export const getComprobantesMock: GetComprobantesResponse = {
  objetoMensaje: {
    codigo: 200,
    descripcion: "OK"
  },
  documentoArray: null,
  listaComprobantes: [
    {
      contratoSocio: "01202743701",
      fecha: "26/10/2021 11:06",
      importe: "$18.000,00",
      medioDePago: "VISA",
      mensajeDePago: "Plan de Pago 1 de 3",
      siteTransactionId: "2d4df1361e7842d",
      entidadDePago: "Pago Online OSDE",  //nuevas
      pagoInternoOsde: true //nuevas
    },
    {
      contratoSocio: "01202743701",
      fecha: "26/10/2021 10:06",
      importe: "$12.000,00",
      medioDePago: "MASTERCARD",
      mensajeDePago: "Plan de Pago 2 de 3",
      siteTransactionId: "2d4df1361e7842d",
      entidadDePago: "Pago Online OSDE",  //nuevas
      pagoInternoOsde: true //nuevas
    },
    {
      contratoSocio: "01202743701",
      fecha: "25/10/2021 00:00",
      importe: "$14.430,43",
      medioDePago: "VISA",
      mensajeDePago: "Cuota mensual 09 2021",
      siteTransactionId: "2d4df1361e7842d",
      entidadDePago: "LINK",
      pagoInternoOsde: false
    },
    {
      contratoSocio: "01202743701",
      fecha: "25/10/2021 00:00",
      importe: "$18.430,43",
      medioDePago: "",
      mensajeDePago: "Deuda vencida a 08 2021",
      siteTransactionId: "2d4df1361e7842d",
      entidadDePago: "Cam. de Ccio de Olavarria",
      pagoInternoOsde: false
    }
  ]
}


export const getPaymentsBillMock: GetFormResponse = {
  direccionMail: "asd@asd.com",
  observacion: { codigo: 123, descripcion: "asd" },
  talones: [
    {
      id: "6011908003409780",
      monto: "3800",
      periodo: "Cuota 08 2019",
      tipoTalon: tipoTalon.TS2,
      vencimiento: "20190908"
    },
    {
      id: "6012004003410289",
      monto: "11172.85",
      periodo: "Cuota 04 2020",
      tipoTalon: tipoTalon.TS2,
      vencimiento: "20200511"
    }
  ]
}


export const postFormMock: PostFormResponse = {
  idTransaccion: '000000000',
  observacion: {
    codigo: 200,
    descripcion: 'Mock post form'
  }
}


export const getPublicKeyMock: ApiKeyResponse = {
  observacion: {codigo: 123, descripcion: '123'},
  publicKey: '000000000'
}


export const callDecidirMock: {id: string} = {
  id: '000000000'
}