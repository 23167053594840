import environmentService from '../../../services/environment/environmentService';

const rootRoute = '/home';

export const routes = {
  root: rootRoute,
  gestionesOnline: '/gestiones-online',
  contactanos: "/contactanos",
  ayuda: "/ayuda",
  quieroSerSocio: "https://www.asociarmeaosde.com.ar/",
  informacionSocio:  environmentService.getCurrent().webBaseUrl + "/informacion-al-socio",
  saludYBienestar: environmentService.getCurrent().webBaseUrl + "/salud-y-bienestar",
  novedades: environmentService.getCurrent().webBaseUrl + "/novedades",
  activarCredencialRoute :  environmentService.getCurrent().urlWebMobile + "/portal/#Activame",
  credencialProvisoriaRoute :  environmentService.getCurrent().urlWebMobile + '/credencialProvisoria/#/home',
  ping: '/ping.html'
};