import vacunacionImg from "./img/vacunacion.jpg";
import centroVacImg from "./img/puerta-cap---celu-1.jpg";
import loginImg from "./img/nuevo-logincelu.jpg";
import bannerCredencial from "./img/nueva-credencial-celu.jpg"
import bannerMasOSDE from "./img/masosde-desta.jpg";
import bannerCanales from "./img/canales-de-atencion-nota.jpg";
import dayjs from 'dayjs';

const tomorrowDate = dayjs().add(1, 'day').format('DD-MM-YYYY');

const sliderWebMobile = {
  slider: [
    {
      tipo: 'slider',
      titulo: 'Vacunación contra COVID-19',
      copete: '',
      imgUrl: vacunacionImg,
      link:
        'http://osde-socios.grupoosdestatic.com.ar/novedades',
      publicacion: '19-2-2021',
      despublicacion: tomorrowDate,
      publico: false,
      btnTxt: '',
      urlMobile:
        'http://osde-socios.grupoosdestatic.com.ar/novedades',
      visibleMobile: false,
    },
    {
      tipo: 'slider',
      titulo: 'Centros de atención: solicitá tu turno online ',
      copete: '',
      imgUrl: centroVacImg,
      link:
        'https://www.osde.com.ar/index.html#!osde-caps-centros-atencion-personalizada.html',
      publicacion: '3-12-2020',
      despublicacion: tomorrowDate,
      publico: false,
      btnTxt: '',
      urlMobile:
        'https://www.osde.com.ar/index.html#!osde-caps-centros-atencion-personalizada.html',
      visibleMobile: false,
    },
    {
      tipo: 'slider',
      titulo: 'Ahora, acceder a tus gestiones online es más fácil  ',
      copete: '',
      imgUrl: loginImg,
      link:
        'https://www.osde.com.ar/novedades/ahora-acceder-gestiones-online-mas-facil-seguro-12675.html',
      publicacion: '17-12-2020',
      despublicacion: tomorrowDate,
      publico: false,
      btnTxt: '',
      urlMobile:
        'https://www.osde.com.ar/novedades/ahora-acceder-gestiones-online-mas-facil-seguro-12675.html',
      visibleMobile: false,
    },
    {
      tipo: 'destacado',
      titulo: 'Conocé nuestros canales de atención virtual   ',
      copete:
        '<div>\n\tEn esta nueva realidad, realiz&aacute; todas tus gestiones sin moverte de tu casa.</div>\n',
      imgUrl: bannerCanales,
      link: 'https://www.osde.com.ar/novedades/conoce-nuestros-canales-de-atencion-virtual-12604.html',
      publicacion: '22-10-2020',
      despublicacion: tomorrowDate,
      publico: false,
      btnTxt: 'INGRESÁ',
      urlMobile:
        'https://www.osde.com.ar/novedades/conoce-nuestros-canales-de-atencion-virtual-12604.html',
      visibleMobile: false,
    },
    {
      tipo: 'destacado',
      titulo: 'MÁS OSDE: una app llena de beneficios',
      copete:
        '<div>\n\tTe invitamos a disfrutar y a vivir experiencias saludables a trav&eacute;s de los descuentos que ofrece esta plataforma.</div>\n',
      imgUrl: bannerMasOSDE,
      link: 'https://www.osde.com.ar/masosde/',
      publicacion: '30-6-2020',
      despublicacion: tomorrowDate,
      publico: false,
      btnTxt: 'BAJÁ LA APP',
      urlMobile: 'https://www.osde.com.ar/masosde/',
      visibleMobile: false,
    },
    {
      tipo: 'banner',
      titulo: 'Obtené tu credencial digital',
      copete:
        '<div>\n\tYa no hace falta que ocupes lugar en tu billetera con la tarjeta de pl&aacute;stico. Baj&aacute; la app en tu celular y llevala siempre a mano para acceder a nuestros servcios.</div>\n',
      imgUrl: bannerCredencial,
      link: 'https://www.osde.com.ar/credencial-digital-osde/',
      publicacion: '30-6-2020',
      despublicacion: tomorrowDate,
      publico: false,
      btnTxt: 'DESCARGALA',
      urlMobile: 'https://www.osde.com.ar/credencial-digital-osde/',
      visibleMobile: false,
    },
  ],
};

const sliderNative = {
  slider: [
    {
      tipo: 'slider',
      titulo: '¡Ahora tu credencial es digital! Descargala desde tu store',
      copete: '',
      imgUrl: 'data/imgs/credencial-digital-osde-appv4.jpg',
      link: '',
      publicacion: '12-8-2020',
      despublicacion: '28-12-2099',
      publico: false,
      btnTxt: '',
      urlMobile: '',
      visibleMobile: false,
    },
  ],
};

const sliderMocks = {
  sliderWebMobile: sliderWebMobile,
  sliderNative: sliderNative,
};

export default sliderMocks;
