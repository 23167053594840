import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { callOutline, phonePortraitOutline } from 'ionicons/icons';
import * as React from 'react';
import PhoneNumber from '../../../../../shared/components/phone-number/PhoneNumber';
import { phones } from '../../../../../shared/phones';


const PopUp72horas: React.FC = () => {
  return (
    <div>
      <p id="head">
        El tiempo mínimo que necesitamos para autorizar tu solicitud es de 72
        horas hábiles.
      </p>
      <p>
        En caso de requrirla antes, te pedimos que nos envíes el trámite y luego
        nos contactes para verificar si es posible disminuir el tiempo de
        gestión.
      </p>
      <IonItem lines="none">
        <IonIcon
          color="tertiary"
          icon={callOutline}
          slot="start"
          className="ion-margin-end"
        />
        <IonLabel className='ion-text-wrap'>
          Escribinos por WhatsApp al 
          <PhoneNumber isWhatsapp phoneLink={phones.atencionAlSocio.whatsApp.link}> {phones.atencionAlSocio.whatsApp.label}</PhoneNumber>
        </IonLabel>
      </IonItem>
      <IonItem lines="none">
        <IonIcon
          color="tertiary"
          icon={phonePortraitOutline}
          slot="start"
          className="ion-margin-end"
        />
        <IonLabel className="ion-text-wrap">Llamanos al 
          <PhoneNumber phoneLink={phones.atencionAlSocio.linea0810.link}> {phones.atencionAlSocio.linea0810.label}</PhoneNumber>
        </IonLabel>
      </IonItem>
    </div>
  );
};

export default PopUp72horas;
