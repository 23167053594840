import authService from '../../../services/auth/AuthService';
import { SET_USER, UNSET_USER, UserAction, SET_LOGGED_IN, UNSET_LOGGED_IN, SET_CHECKSESSIONTAS, SET_EMAIL, UNSET_TRAMITES, SET_URL_AUTH_IOS, SET_REDIRECT_AFTER_LOGGED_IN_URL, SET_REDIRECT_LOGINWITHCI_ERROR, USER_NOT_LOGIN_IOS, USER_LOGIN_IOS } from '../types/actions';
import { cleanLoginData,  isLocalDev,  isWeb } from '../../../shared/utils/utils';
import { openBrowserApp } from '../../inAppBrowser/helpers/iabBrowserHelpers';
import { cleanUserCookies } from '../helpers/userHelpers';
import { ContratoFullData, User } from '../types/User';
import environmentService from '../../../services/environment/environmentService';

/**
 * Metodo que comienza el proceso de logueo abriendo el IDM y verificando la sesion. En caso de no loguearse o cerrar el browser, se redirige al home.
 * @author jcavilla
 * @returns {Function} async function para Redux-Thunk
 */
export const startLogin = (forcedRedirectURL?: string) => async (dispatch: Function, getState: Function) => {
  cleanLoginData();
  const url_idm = await authService.getRedirectAuthority(forcedRedirectURL);
  if (url_idm) {
    if (isWeb()) {
      window.location.href = url_idm
    }
  }
}

export const isRefreshTokenDateLimitValid = () => {
  if (isLocalDev()){
    return true;
  }
  const refreshTokenDateLimit = localStorage.getItem("refreshTokenDateLimit");
  if (!refreshTokenDateLimit) return false;
  const dateToken = parseInt(refreshTokenDateLimit);
  const dateHoy = Date.now();
  return dateHoy <= dateToken;
}


/**
 * Metodo que comienza el proceso de logout abriendo la pagina de logout IDM y llamando al logout del proxy.
 * @author jcavilla
 * @returns {Function} async function para Redux-Thunk
 */
export const startLogout = (showInAppBrowser = true) => async (dispatch: Function, getState: Function) => {
  const logout_url = await authService.getLogoutUrl();
  let target = undefined;
  let logoutUrl = logout_url;
    target = '_parent';
    logoutUrl = logoutUrl + '?redirectTo=https:%2F%2F' + window.location.host + '%2Fhome&themeId=' + environmentService.getCurrent().themeId;
    const ref = openBrowserApp(logoutUrl, target, !showInAppBrowser);
    setTimeout(() => {
      ref.close()
    }, showInAppBrowser ? 2500 : 0);
    setTimeout(() => {
      cleanUserCookies();
    }, 2500);
  authService.logout();
  dispatch(unSetUser());
  cleanLoginData();
}

export const setIsLogginIos = ():UserAction =>  ({
  type: USER_LOGIN_IOS
});

export const setIsNotLogginIos = ():UserAction =>  ({
  type: USER_NOT_LOGIN_IOS
});

/**
 * Metodo que setea el usuario en Redux.
 * @author jcavilla
 * @returns {UserAction:UserAction}  function para Redux
 */
export const setUser = (userFullData: { user: User, contratos?: ContratoFullData[] }): UserAction => ({
  type: SET_USER,
  payload: userFullData
});


export const setRedirectLoginWithCIError = (errorPath: string): UserAction => ({
  type: SET_REDIRECT_LOGINWITHCI_ERROR,
  payload: errorPath,
});


export const setEmail = (email: string): UserAction => ({
  type: SET_EMAIL,
  payload: email
});

/**
 * Metodo que quita el usuario de Redux.
 * 
 * @returns {UserAction:UserAction}  function para Redux
 */
export const unSetUser = (): UserAction => ({
  type: UNSET_USER
});

/**
 * Metodo que setea si esta logueado en Redux.
 * @author jcavilla
 * @returns {UserAction:UserAction}  function para Redux
 */
export const setLoggedIn = (): UserAction => ({
  type: SET_LOGGED_IN
});

/**
 * Metodo que quita si esta logueado en Redux.
 * @author jcavilla
 * @returns {UserAction:UserAction}  function para Redux
 */
export const unSetLoggedIn = (): UserAction => ({
  type: UNSET_LOGGED_IN
});

/**
 * Metodo que checkea si hay sesion iniciada, solo se usa en tas.
 */
export const SetCheckSessionTAS = (): UserAction => ({
  type: SET_CHECKSESSIONTAS
});

/**
 * Metodo que resetea todos los tramites.
 */
export const unSetTramites = (): UserAction => ({
  type: UNSET_TRAMITES,
})

export const setUrlAuthIos = (url: string): UserAction => ({
  type: SET_URL_AUTH_IOS,
  payload: url
});

export const setRedirectAfterLoggedInUrl = (redirectUrl: string): UserAction => ({
  type: SET_REDIRECT_AFTER_LOGGED_IN_URL,
  payload: redirectUrl
});