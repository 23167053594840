import React from 'react';
import { IonApp, IonRouterOutlet } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';

/* Swiper */
import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/autoplay/autoplay.min.css';
import 'swiper/modules/effect-fade/effect-fade.min.css';

import Menu from './shared/components/layout/menu/Menu';
import PhonesPopUp from './shared/components/popups/phones-popup/PhonesPopUp';
import Routes from './Routes';
import environmentService from './services/environment/environmentService';
import { useKeyboardScreenResize } from './hooks/useKeyboardScreenResize';
import { useResolutionResize } from './hooks/useResolutionResize';
import HttpErrorAlert from './shared/components/popups/alerts/http-error-alert/HttpErrorAlert';
import useShowVersion from './hooks/useShowVersion';

const App: React.FC = () => {
  useResolutionResize();
  useKeyboardScreenResize();
  useShowVersion();
  environmentService.clearOutdatedEnvironment();
  environmentService.cleanEnvironment();

  return (
    <IonApp>
      <PhonesPopUp />
      <Menu />
      <IonRouterOutlet id="main-content" />
      <Routes />
      <HttpErrorAlert />
    </IonApp>
  );
};

export default App;
