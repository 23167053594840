/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const TerminosAutorizaciones: React.FC = () => {

  return (

    <div style={{
      textAlign: "justify",
      lineHeight: 'normal',
    }}>

      A través de este trámite podés solicitar la autorización para realizar internaciones, 
      cirugías, prácticas o estudios con profesionales o instituciones de nuestra cartilla 
      médica y traslados programados prescriptos por tu médico..<br /><br />

      Para acceder al trámite, deberás estar registrado en la web de OSDE o en la
      app OSDE Móvil. Se podrán empadronar socias de 12 a 55 años de edad, titulares
      o beneficiarias. Es requisito no poseer deuda en la asociación al momento de
      empadronarse.<br /><br />

      Tené en cuenta que: <br />

      - Los trámites deben ser solicitados con, al menos, 72 horas hábiles de anticipación y 
      no debe existir deuda con la organización al momento de la gestión.<br />

      - OSDE podrá requerir información y tiempo adicional para evaluar las prestaciones 
      médicas que se solicitan, dependiendo de la complejidad de cada caso.<br />

      - Sólo se autorizarán prácticas y cirugías que se encuentren reconocidas por el Programa 
      Médico Obligatorio y/o en forma expresa por nuestra cartilla prestacional.<br /><br />

      En caso que la solicitud sea urgente, deberás comunicarte con nuestro Centro de Atención 
      Telefónica (0810-555-6733) o acercarte al Centro de Atención Personalizada más cercano 
      para que podamos gestionarla.<br /><br />

      Una vez finalizado el trámite, nos contactaremos con vos a tu teléfono o tu email de 
      contacto para informarte sobre su resolución.

    </div>
  );
};

export default TerminosAutorizaciones;
