import { IonButton, IonLabel } from '@ionic/react'
import React from 'react'
import { useHistory } from 'react-router'
import analyticsService from '../../../../../services/analytics/analytics'
import { isIos } from '../../../../../shared/utils/utils'
import { GestionesLink } from '../../types/GestionesLink'
import styles from './GestionesOnlineButton.module.scss'
import {routes as empadronamientoRoutes } from '../../../../tramites/empadronamiento/router/routes'
import { SetStepperNavigation } from '../../../../../store/ui/uiActions'
import Animations from '../../../../../shared/components/animations/Animations'
import { useDispatch } from 'react-redux'

export const GestionesOnlineButton: React.FC< GestionesLink > = ({ class: cssClass, icon, title, url, event, externalLink }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleOnPress = () => {
        analyticsService.trackEvent("gestiones online", "menu principal", event);
        url === empadronamientoRoutes.root && dispatch(SetStepperNavigation(Animations.fadeIn))
        if (externalLink) { 
            window.location.href = url ;
        } else { 
            history.push( url );
        }
    }

    return (
        <>
            {   isIos() 
                ?
                <div className={ styles["gestionesOnlineButtonIos"]  } onClick={ handleOnPress } >
                    <div className={ [styles['gestionesOnlineButtonIos__iconContainer'], styles[cssClass]].join(' ') }>
                        <img src={ icon } alt='button' />
                    </div>
                    <div className={ styles["gestionesOnlineButtonIos__labelContainer"] }>
                        {/* <p> { title } </p> */}
                        <IonLabel color='primary' className="ion-text-wrap" mode="md"> { title } </IonLabel>
                    </div>
                </div>

                :
                <IonButton
                    fill='clear'
                    expand='full'
                    className={ styles["gestionesOnlineButton"] }
                    onClick={ handleOnPress }   
                >
                    <div className={ [styles['gestionesOnlineButton__iconContainer'], styles[cssClass]].join(' ') }>
                        <img src={ icon } alt='button' />
                    </div>
                    <div className={ styles["gestionesOnlineButton__labelContainer"] }>
                        <IonLabel color='primary' className="ion-text-wrap"> { title } </IonLabel>
                    </div>
                </IonButton>
            }
        </>
    )
}