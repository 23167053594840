import AxiosInstance from '../../../../services/AxiosService';
import { isLocalDev } from '../../../../shared/utils/utils';
import { BuscarTramiteDataType, DatosContactoDataType, GuardarDatosDataType, NormalizarTelefonoDataType, PredictivoDataType } from '../../../../store/datos-contacto/types/DatosContactoData';
import {
  mockBuscarTramite2,
  mockCalle,
  mockDatosContactoDataType,
  mockGuardarDatosDataType,
  mockNormalizarTelefonoDataType,
  mockNumeroDeSolicitud,
  mockProvincias,
} from './mocks/mockDatosContactoService';

const baseUrlService = '/datosContactoService';

export interface DatosDeContactoService {
  normalizarTelefono: (data: NormalizarTelefonoDataType) => Promise<any>;
  getProvincias: () => Promise<any>;
  getPredictivo: (data: PredictivoDataType) => Promise<any>;
  buscarTramites: (data: boolean) => Promise<{ data: BuscarTramiteDataType }>;
  finalizarTramite: (data: string) => Promise<{ data: string }>;
  getDatosContacto: () => Promise<{ data: DatosContactoDataType }>;
  guardarDatos: (data: GuardarDatosDataType) => Promise<{ data: GuardarDatosDataType }>;
}

const datosDeContactoService: DatosDeContactoService = {
  normalizarTelefono: (data: NormalizarTelefonoDataType) => AxiosInstance.post(`${baseUrlService}/normalizarTelefono`, { body: data }),
  getProvincias: () => AxiosInstance.post(`${baseUrlService}/getProvincias`, {}),
  getPredictivo: (data) => AxiosInstance.post(`${baseUrlService}/getPredictivo`, { body: data }),
  buscarTramites: (data) => AxiosInstance.post(`${baseUrlService}/buscarTramitesNew`, { body: data }),
  finalizarTramite: (data) => AxiosInstance.post(`${baseUrlService}/finalizarTramiteNew`, { body: data }),
  getDatosContacto: () => AxiosInstance.post(`${baseUrlService}/getDatosContactoNew`, { body: false }),
  guardarDatos: (data) => AxiosInstance.post(`${baseUrlService}/guardarDatosNew`, { body: data }),
};

export const datosDeContactoServiceMock: DatosDeContactoService = {
  normalizarTelefono: (data: NormalizarTelefonoDataType) => Promise.resolve({ data: mockNormalizarTelefonoDataType }),
  getProvincias: () => Promise.resolve({ data: mockProvincias }),
  getPredictivo: (data) => Promise.resolve({ data: mockCalle }),
  buscarTramites: (data) => Promise.resolve({ data: mockBuscarTramite2 }),
  finalizarTramite: (data) => Promise.resolve({ data: mockNumeroDeSolicitud }),
  getDatosContacto: () => Promise.resolve({ data: mockDatosContactoDataType }),
  guardarDatos: (data) => Promise.resolve({ data: mockGuardarDatosDataType }),
};

export default isLocalDev() ? datosDeContactoServiceMock : datosDeContactoService;
