import visaIcon from '../assets/img/logo_visa.svg';
import mastercardIcon from '../assets/img/logo_master.svg';
import americanIcon from '../assets/img/logo_amex.svg';

import visaDebitoIcon from '../assets/img/logo_visa_debito.svg';
import maestroIcon from '../assets/img/logo_maestro.svg';
import visaCard from '../assets/img/visa-flat-card.png';
import mastercardCard from '../assets/img/mastercard-flat-card.png';
import americanCard from '../assets/img/american-flat-card.png';
import visaDebitoCard from '../assets/img/visa-debito-flat-card.png';
import maestroCard from '../assets/img/maestro-debito-flat-card.png';
import americanFlatCard from '../assets/img/american-flat-card.png';
import genericCardBack from '../assets/img/card-back.png';
import { PaymentMethod } from '../../../../store/pagoEnLinea/types/PagoEnLineaTypes';
import { card as pagoExterno } from 'ionicons/icons'


export const getPaymentMethods: PaymentMethod[] = [
  {
    id: 1,
    name: 'VISA',
    pathImageIcon: visaIcon,
    pathImageCard: visaCard,
    pathImageCardBack: genericCardBack,
    maxCharsCardNumber: 16,
    minCharsCardNumber: 16,
    maskCardNumber: '#### #### #### ####',
    maxCharsCodSecurity: 3,
    maskCodSecurity: 'XXX',
    maxCharsCardExpirationMonth: 2,
    maxCharsCardExpirationYear: 2,
    firstCardNumber: 4,
    regex: /^4/,
    placeHolder: 'XXXX XXXX XXXX XXXX',
  },
  {
    id: 118,
    name: 'MASTERCARD',
    pathImageIcon: mastercardIcon,
    pathImageCard: mastercardCard,
    pathImageCardBack: genericCardBack,
    maxCharsCardNumber: 16,
    minCharsCardNumber: 16,
    maskCardNumber: '#### #### #### ####',
    maxCharsCodSecurity: 3,
    maskCodSecurity: 'XXX',
    maxCharsCardExpirationMonth: 2,
    maxCharsCardExpirationYear: 2,
    firstCardNumber: 5,
    regex: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    placeHolder: 'XXXX XXXX XXXX XXXX',
  },
  {
    id: 65,
    name: 'AMEX',
    pathImageIcon: americanIcon,
    pathImageCard: americanCard,
    pathImageCardBack: americanFlatCard,
    maskCardNumber: '#### ###### #####',
    maxCharsCardNumber: 15,
    minCharsCardNumber: 15,
    maxCharsCodSecurity: 4,
    maskCodSecurity: 'XXXX',
    maxCharsCardExpirationMonth: 2,
    maxCharsCardExpirationYear: 2,
    firstCardNumber: 3,
    regex: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    placeHolder: 'XXXX XXXXXX XXXXX',
  },
  {
    id: 31,
    name: 'VISADEBITO',
    pathImageIcon: visaDebitoIcon,
    pathImageCard: visaDebitoCard,
    pathImageCardBack: genericCardBack,
    maxCharsCardNumber: 16,
    minCharsCardNumber: 16,
    maskCardNumber: '#### #### #### ####',
    maxCharsCodSecurity: 3,
    maskCodSecurity: 'XXX',
    maxCharsCardExpirationMonth: 2,
    maxCharsCardExpirationYear: 2,
    firstCardNumber: 4,
    regex: /^(4|49603900|454621|450913)/,
    placeHolder: 'XXXX XXXX XXXX XXXX',
  },
  {
    id: 136,
    name: 'MAESTRO',
    pathImageIcon: maestroIcon,
    pathImageCard: maestroCard,
    pathImageCardBack: genericCardBack,
    maxCharsCardNumber: 19,
    minCharsCardNumber: 15,
    maskCardNumber: '########## ##### ####',
    maxCharsCodSecurity: 3,
    maskCodSecurity: 'XXX',
    maxCharsCardExpirationMonth: 2,
    maxCharsCardExpirationYear: 2,
    firstCardNumber: 5,
    regex: /^(5010|5018|51051|5020|5038|6304|6703|6708|6759|676[1-3])/,
    placeHolder: 'XXXXXXXXXX XXXXX XXXX'
  },
  {
    id: 0,
    name: 'PAGOEXTERNO',
    pathImageIcon: pagoExterno,
    pathImageCard: '',
    pathImageCardBack: '',
    maxCharsCardNumber: 0,
    minCharsCardNumber: 0,
    maskCardNumber: '0',
    maxCharsCodSecurity: 0,
    maskCodSecurity: '',
    maxCharsCardExpirationMonth: 0,
    maxCharsCardExpirationYear: 0,
    firstCardNumber: 0,
    regex: /()/,
    placeHolder: ''
  }
];


export const getPaymentIcon = (medioDePago?: string): string => {
  return getPaymentMethods.find(card => {
      let medioPago: string;
      if (medioDePago) {
          medioPago = medioDePago === 'VISA DEBITO' ? 'VISADEBITO' : medioDePago;
      } else {
          medioPago = 'PAGOEXTERNO';
      }
      return medioPago === card.name;
  })?.pathImageIcon
}