import { ContratoFullData, User } from "../types/User";

export const userDataMock: User = {
    aceptoTerminosYCondiciones: true,
    apellidos: "FERNANDEZ MONTERO",
    autorizacionesHabilitado: true,
    codigoTipoDocumento: "DNI",
    contrato: {
        nroActividad: "60397230503",
        numeroAfiliado: "3972305",
        numeroBeneficiario: "03",
        numeroFilial: "60",
        valid: true
    },
    cuil: "20080433523",
    diasDesdeRegistro: 75,
    diasDesdeUltimoLogin: 0,
    documento: "31732758",
    edad: 35,
    email: "parremartin@yahoo.com.ar",
    emailAutenticacion: "parremartin@yahoo.com.ar",
    empadronamientosHabilitado: true,
    esBeneficiario: true,
    esSocio: true,
    fechaActualizacionDatosPersonales: null,
    fechaNacimiento: new Date(),
    filial: "35",
    gestionesOnlineHabilitado: true,
    habilitado: true,
    hasTurnosEnabled: false,
    id: 346410,
    interlocutorComercial: "20030346752" , // QUITAR EL 2 para que entre en la whitelist
    mailEnviado: null,
    medicamentosHabilitado: true,
    metodoLogin: "cloudIdentityRealm",
    nombres: "JULIAN",
    pagoEnLineaHabilitado: true,
    password: null,
    pendienteActualizacion: true,
    perfil: "O",
    plan: "2 210",
    realizoImportacionMC: true,
    refreshToken: "HCtpppvZZCvoCyLSdS5tLNfM9TFjTrwru8vSgwYl9KtTwRF2mi",
    refreshTokenTimeout:"2030-03-03",
    refreshTokenTimeLeft: 1235465,
    reintegrosHabilitado: true,
    sexo: "M",
    telefono: "",
    tienePlanComercial: true,
    tipoDocumento: "1",
    titular: true,
    token: "",
    userName: "31732758",
    xmlRequest: ""
}

export const contratosMock: ContratoFullData[] = [
    {
        codigoContrato: {
            numeroAfiliado: "3972305",
            numeroBeneficiario: "03",
            numeroFilial: "60",
        },
        descripcionPlan: "PLAN 2 210",
        existeEnPadron: true,
        filialDeServicio: 1,
        filialDescripcion: "FILIAL CATAMARCA",
        perfil: "D",
        plan: "2 210"
    },
    {
        codigoContrato: {
            numeroAfiliado: "2034247",
            numeroBeneficiario: "01",
            numeroFilial: "01"
        },
        descripcionPlan: "PLAN 6 030",
        existeEnPadron: true,
        filialDeServicio: 24,
        filialDescripcion: "FILIAL MAR DEL PLATA",
        perfil: "D",
        plan: "6 030"
    }
]