import {IonContent, IonFooter, IonGrid, IonList, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import Filepicker from '../../../../../shared/components/form/filepicker/Filepicker';
import TextareaForm from '../../../../../shared/components/form/textarea-form/TextareaForm';
import Header from '../../../../../shared/components/layout/header/Header';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import { getFileExtension, isWeb, tamanoMaximoArchivos } from '../../../../../shared/utils/utils';
import { RootState } from '../../../../../store';
import { setArchivosAdjuntos, setDetalle } from '../../../../../store/autorizaciones/AutorizacionesActions';
import { PedidoMedicoDataType } from '../../../../../store/autorizaciones/types/AutorizacionesData';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import AttachForm from '../../components/attach-form/attachForm';
import {routes as AutorizacionesRoutes} from '../../router/routes';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import { routes as homeRoutes } from '../../../../home/router/routes';
import {AutorizacionesTramites as tramite} from './../../helpers/TramitesEnum';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';

const StepArchivosAdjuntos: React.FC = () => {
    const {archivosAdjuntos, tipoTramite, stepDetails, detalle } = useSelector((state: RootState) => state.autorizaciones);
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {state} = useLocation<{from: string}>();

    const [documentacionAdicional, setDocumentacionAdicional] = useState<PedidoMedicoDataType[] | null>(archivosAdjuntos?.documentosExtra ?? []);
    const [pedidoMedicoUploaded, setPedidoMedicoUploaded] = useState<boolean>(archivosAdjuntos?.pedidoMedico ? false : true);
    const [showPedidoMedico, setShowPedidoMedico] = useState<boolean>(false);
    const [pedidoMedico, setPedidoMedico] = useState<string>("");

    useEffect(() => {   
        if(!stepDetails){
            history.push(homeRoutes.root);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(archivosAdjuntos?.pedidoMedico?.valor) {
            setPedidoMedico(archivosAdjuntos?.pedidoMedico?.valor);
        }
    }, [archivosAdjuntos]);


    const { handleSubmit, setValue, formState, control } = useForm({
        mode: 'onChange',
        defaultValues: {
            pmedico: archivosAdjuntos?.pedidoMedico ?? null,
            observaciones: detalle ?? ''
        }
    });
    
    const getPageIndex = (route : string): number => {
        return stepDetails?.editOrder?.find(step => step.path === route)?.page;
    }

    const currentPageIndex = getPageIndex(location.pathname);

    const getNextPage = (): string => {
        return stepDetails?.editOrder.find((step: { page: number; }) => step.page === currentPageIndex + 1)?.path;
    }

    const { isValid } = formState;

    const onSubmit = (dataForm: any) => {
        dispatch(SetStepperNavigation(Animations.next))
        const {pmedico, observaciones} = dataForm;
        const archivosAdjuntosUpdated = { ...archivosAdjuntos! };

        if(pmedico.base64){
            archivosAdjuntosUpdated.pedidoMedico = { nombre: `pedido_medico.${getFileExtension(dataForm.pmedico.nombre)}`, valor: dataForm.pmedico.base64 };
        }

        if (documentacionAdicional?.length > 0) {
            archivosAdjuntosUpdated.documentosExtra = documentacionAdicional;
        } else {
            archivosAdjuntosUpdated.documentosExtra = []
        }

        tipoTramite === tramite.Medicamentos && dispatch(setDetalle(observaciones));
        dispatch(setArchivosAdjuntos(archivosAdjuntosUpdated));

        if(state?.from){
            history.goBack();
        } else {
            const nextPagePath = getNextPage();
            history.push(nextPagePath);
        }
          
    }

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent>
                <IonGrid className='ion-no-padding'>
                    <PageTitle>{stepDetails?.titleAndLenght?.title}</PageTitle>

                    {getPageIndex(AutorizacionesRoutes.adjuntarArchivo) &&
                        <Stepper currentNumber={getPageIndex(AutorizacionesRoutes.adjuntarArchivo)} stepsLenght={stepDetails?.titleAndLenght?.lenghtOfPages} />
                    }
                    
                    <StepTitle>
                        Adjuntá el pedido del médico
                    </StepTitle>
                    
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <IonList className="ion-margin-horizontal">
                            <Controller
                                render={() =>
                                    <Filepicker
                                        label='Pedido médico (*)'
                                        name='pmedico'
                                        placeHolder={`Tamaño máximo ${tamanoMaximoArchivos}MB. PDF o JPG`}
                                        setValue={(selectedFile) => {
                                            setPedidoMedicoUploaded(false);
                                            setValue('pmedico', selectedFile, { shouldValidate: true })
                                            setPedidoMedico(selectedFile.base64);
                                        }}
                                        overrideFileName={'Pedido médico'}
                                        storedFileName={archivosAdjuntos?.pedidoMedico?.nombre && `Pedido médico.${getFileExtension(archivosAdjuntos.pedidoMedico.nombre)}`}
                                        showImage={ pedidoMedico && (() => setShowPedidoMedico(true))}
                                    />
                                }
                                name='pmedico'
                                control={control}
                                rules={{required:true}}
                            />

                            <AttachForm
                                documentacionAdicional={documentacionAdicional}
                                setDocumentacionAdicional={setDocumentacionAdicional}
                                pedidoMedico={pedidoMedicoUploaded}
                                pedidoMedicoImage={pedidoMedico}
                                setShowPedidoMedico={setShowPedidoMedico}
                                showPedidoMedico={showPedidoMedico}
                            />

                            {tipoTramite === tramite.Medicamentos &&
                                <Controller 
                                    render={({onChange, value}) => 
                                        <TextareaForm 
                                            onChange={onChange}
                                            value={value}
                                            name="observaciones" 
                                            label="Observaciones"
                                            placeholder="Cantidad máxima 200 caracteres" 
                                        /> 
                                    }
                                    control={control}
                                    name='observaciones'
                            />}
                        </IonList>
                    </form> 
                </IonGrid>
            </IonContent>
            <IonFooter className='ion-no-border'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <NavigationButton text='Datos Obligatorios(*)' type='submit' disabled={!isValid}>SIGUIENTE</NavigationButton>
                </form> 
                {isWeb() && <Footer /> }
            </IonFooter>
        </IonPage>
    );
}

export default StepArchivosAdjuntos;