import AxiosInstance from "../../../../services/AxiosService";
import { isLocalDev } from "../../../../shared/utils/utils";
import { GetDatosAutorizacionesDataType, FormularioAutorizacionesDataType } from "../../../../store/autorizaciones/types/AutorizacionesData";
import { getFormMock, postformMock } from "./mock/AutorizacionesMock";

const baseUrlService = "/autorizacionesService";

export interface AutorizacionesService {
  getForm: (data: GetDatosAutorizacionesDataType) => Promise<any>;
  postForm: (data: FormularioAutorizacionesDataType) => Promise<any>;
}

const autorizacionesService: AutorizacionesService = {
  getForm: (data) => AxiosInstance.post(`${baseUrlService}/getFormNew2`, {body: data}),
  postForm: (data) => AxiosInstance.post(`${baseUrlService}/postFormNew2`, {body: data})
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const autorizacionesServiceMock: AutorizacionesService = {
  getForm: (data) => Promise.resolve({data:getFormMock}),
  postForm: (data) => Promise.resolve({data:postformMock})
};

export default isLocalDev() ? autorizacionesServiceMock : autorizacionesService;
