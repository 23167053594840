import React from 'react';
import PrivateRoute from '../../../../shared/components/privateRoute/PrivateRoute';
import ReintegrosPage from '../ReintegrosPage';
import StepTipoComprobante from "../pages/step-tipo-comprobante/StepTipoComprobantePage";
import StepSeleccionarSocioPage from "../pages/step-seleccionar-socio/StepSeleccionarSocioPage";
import StepDatosFacturacion1Page from "../pages/step-datos-facturacion-1/StepDatosFacturacion1Page";
import StepDatosFacturacion2Page from "../pages/step-datos-facturacion-2/StepDatosFacturacion2Page";
import StepDatosFacturacion3Page from "../pages/step-datos-facturacion-3/StepDatosFacturacion3Page";
import StepArchivosAdjuntosPage from "../pages/step-archivos-adjuntos/StepArchivosAdjuntosPage";
import StepVerificaDatosPage from "../pages/step-verifica-datos/StepVerificaDatosPage";
import StepDatosTicket1Page from "../pages/step-datos-ticket-1/StepDatosTicket1Page";
import StepDatosTicket2Page from "../pages/step-datos-ticket-2/StepDatosTicket2Page";
import StepFinalPage from "../pages/step-final/StepFinalPage";
import Animations from '../../../../shared/components/animations/Animations';

export const rootRoute = '/gestiones-online/reintegros';
export const routes = {
  root: rootRoute,
  seleccionSocio: `${rootRoute}/beneficiario`,
  seleccionComprobante: `${rootRoute}/seleccion-comprobante`,
  datosFacturacion1: `${rootRoute}/datos-de-facturacion`,
  datosFacturacion2: `${rootRoute}/datos-del-comprobante`,
  datosFacturacion3: `${rootRoute}/importe`,
  datosTicket1: `${rootRoute}/datos-de-ticket`,
  datosTicket2: `${rootRoute}/importe-ticket`,
  archivosAdjuntos: `${rootRoute}/archivos-adjuntos`,
  verificaDatos: `${rootRoute}/resumen`,
  finalizarTramite: `${rootRoute}/formulario-enviado`
};

const ReintegrosRoutes: React.ReactNode[] = [
  <PrivateRoute
    key={rootRoute}
    path={rootRoute}
    exact
    animation={Animations.fadeIn}
    component={ReintegrosPage}
  />,
  <PrivateRoute
    key={routes.seleccionSocio}
    path={routes.seleccionSocio}
    exact
    component={StepSeleccionarSocioPage}
  />,
  <PrivateRoute
    key={routes.seleccionComprobante}
    path={routes.seleccionComprobante}
    exact
    animation={Animations.fadeIn}
    component={StepTipoComprobante}
  />,
  <PrivateRoute
    key={routes.datosFacturacion1}
    path={routes.datosFacturacion1}
    exact
    component={StepDatosFacturacion1Page}
  />,
  <PrivateRoute
    key={routes.datosFacturacion2}
    path={routes.datosFacturacion2}
    exact
    component={StepDatosFacturacion2Page}
  />,
  <PrivateRoute
    key={routes.datosFacturacion3}
    path={routes.datosFacturacion3}
    exact
    component={StepDatosFacturacion3Page}
  />,
  <PrivateRoute
    key={routes.datosTicket1}
    path={routes.datosTicket1}
    exact
    component={StepDatosTicket1Page}
  />,
  <PrivateRoute
    key={routes.datosTicket2}
    path={routes.datosTicket2}
    exact
    component={StepDatosTicket2Page}
  />,
  <PrivateRoute
    key={routes.archivosAdjuntos}
    path={routes.archivosAdjuntos}
    exact
    component={StepArchivosAdjuntosPage}
  />,
  <PrivateRoute
    key={routes.verificaDatos}
    path={routes.verificaDatos}
    exact
    component={StepVerificaDatosPage}
  />,
  <PrivateRoute
    key={routes.finalizarTramite}
    path={routes.finalizarTramite}
    exact
    component={StepFinalPage}
  />
]

export default ReintegrosRoutes;