import { History } from 'history';
import { routes } from "../modules/home/router/routes";


export interface IException {
  code?: number,
  name: string,
  field: string,
  title: string,
  message: string,
  internalMessage: string,
  showAlert: boolean,
  alertConfirmAction?: (...args: any[]) => void
}

export interface IExceptions {
  [name: string]: IException
}


export const getGenericExceptions = (history: History, ...args: any[]): IExceptions => {
  const goToHome = () => history.replace(routes.root);
  return (
    {
      BadRequest: {
        code: 400,
        name: 'BadRequest',
        field: 'exception',
        title: 'Importante',
        message:
          'Lo sentimos, hubo un error. Por favor, intentá nuevamente en unos minutos.',
        internalMessage: 'Error en el request',
        showAlert: true,
        alertConfirmAction: goToHome
      },

      Unauthorized: {
        code: 401,
        name: 'Unauthorized',
        field: 'exception',
        title: 'Importante',
        message:
          'Parece que hubo un error. Por favor, cerrá sesión, ingresá de nuevo y volvé a intentarlo.',
        internalMessage: 'La solicitud requiere autenticación de usuario',
        showAlert: true,
        alertConfirmAction: goToHome
      },

      Forbidden: {
        code: 403,
        name: 'Forbidden',
        field: 'exception',
        title: 'Importante',
        message: 'Lo sentimos, hubo un error. Por favor, intentá más tarde.',
        internalMessage: 'Solicitud válida, pero el servidor se niega a responder',
        showAlert: true,
        alertConfirmAction: goToHome
      },

      NotFound: {
        code: 404,
        name: 'NotFound',
        field: 'exception',
        title: 'Importante',
        message: 'Lo sentimos, hubo un error. Por favor, intentá más tarde.',
        internalMessage: 'El servidor no pudo encontrar el contenido solicitado.',
        showAlert: true,
        alertConfirmAction: goToHome
      },

      MethodNotAllowed: {
        code: 405,
        name: 'MethodNotAllowed',
        field: 'exception',
        title: 'Importante',
        message: 'Lo sentimos, hubo un error. Por favor, intentá más tarde.',
        internalMessage:
          'El tipo de método especificado en la solicitud no es correcto',
        showAlert: true,
        alertConfirmAction: goToHome
        },

      RequestTimeout: {
        code: 408,
        name: 'RequestTimeout',
        field: 'exception',
        title: 'Importante',
        message: 'Lo sentimos, hubo un error. Por favor, intentá más tarde.',
        internalMessage: 'El servidor agotó el tiempo de espera para la solicitud',
        showAlert: true,
        alertConfirmAction: goToHome
      },

      InternalServerError: {
        code: 500,
        name: 'InternalServerError',
        field: 'exception',
        title: 'Importante',
        message:
          'Lo sentimos, en este momento no podemos atender tu solicitud. Por favor, intentá nuevamente más tarde.',
        internalMessage: 'Error del lado del servidor',
        showAlert: true,
        alertConfirmAction: goToHome
      },

      ServiceUnavailable: {
        code: 503,
        name: 'ServiceUnavailable',
        field: 'exception',
        title: 'Importante',
        message:
          'Lo sentimos, tuvimos un problema. Por favor, intentá nuevamente más tarde.',
        internalMessage: 'El servidor no está disponible temporalmente',
        showAlert: true,
        alertConfirmAction: goToHome
      },

      UnexpectedErrorException: {
        name: 'UnexpectedErrorException',
        field: 'exception',
        title: 'Sin conexión',
        message:
          'Lo sentimos, tuvimos un problema. Por favor, intentá nuevamente más tarde.',
        internalMessage: 'Error no especificado',
        showAlert: true,
        alertConfirmAction: goToHome
      }
    })
};


export const getExceptionFromList = (exceptions: IExceptions, errorResponse, compareFunction: (...args: any[]) => boolean) => {
  for (const key in exceptions) {
    if (compareFunction(errorResponse, exceptions[key])) {
      return { ...exceptions[key] };
    }
  }
  return { ...exceptions['UnexpectedErrorException'] };
};


export const compareGenericException = (errorResponse, exception: IException): boolean => {
  return errorResponse?.status === exception.code;
};