import { IonCheckbox, IonContent, IonFooter, IonGrid, IonLabel, IonPage, IonToolbar } from '@ionic/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import Header from '../../../../../shared/components/layout/header/Header';
import StandardModal from '../../../../../shared/components/popups/modals/standard-modal/StandardModal';
import SociosList from '../../../../../shared/components/stepper/socios-list/SociosList';
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import Stepper from '../../../../../shared/components/stepper/Stepper';
import ProteccionDatos from '../../../../../shared/components/texts/proteccion-datos/ProteccionDatos';
import { isWeb } from '../../../../../shared/utils/utils';
import { RootState } from '../../../../../store';
import { setSocioSeleccionado } from '../../../../../store/autorizaciones/AutorizacionesActions';
import { IntegranteAutorizacionesDataType } from '../../../../../store/autorizaciones/types/AutorizacionesData';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import TerminosAutorizaciones from '../../components/terminos-autorizaciones/TerminosAutorizaciones';
import styles from './SeleccionarSocioPage.module.scss'
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import { routes as homeRoutes } from '../../../../home/router/routes';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';

const SeleccionarSocioPage: React.FC = () => {
    const {obtainedData, stepDetails, integrante} = useSelector((state: RootState) => state.autorizaciones);
    const {state} = useLocation<{from: string}>();
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedSocio, setSelectedSocio] = useState<string>(integrante?.beneficiario);
    const [agreeTerms, setAgreeTerms] = useState(state?.from ? true : false);
    const [showTerminosModal, setShowTerminosModal] = useState(false);
    const [showProteccionDatosModal, setShowProteccionDatosModal] = useState(false);
    const [disabledButton, setDisabledButton] = useState<boolean>(true);

    const getNextPage = (page: number): string => {
        const nextStep = stepDetails.editOrder.find((step: any) => step.page === (page + 1));
        return nextStep.path;
    }
    

    useEffect(() => {
        if(!stepDetails){
            history.push(homeRoutes.root);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        if(selectedSocio && agreeTerms){
            setDisabledButton(false);
        }else{
            setDisabledButton(true);
        }
    }, [selectedSocio, agreeTerms])
    
   
    const goToNextPage = () => {
        if(selectedSocio){
            dispatch(SetStepperNavigation(Animations.next))
            const selected = obtainedData.listaIntegrantes.find((integrante) => integrante.beneficiario === selectedSocio)
            const socio: IntegranteAutorizacionesDataType = {...selected};
            dispatch(setSocioSeleccionado(socio));
            if(state?.from){
                history.goBack();
            } else {
                const nextPagePath = getNextPage(1);
                history.push(nextPagePath);
            }
        }
    }

    return ( 
        <IonPage>
            <Header showBackButton={true} />
            <IonContent>
                <IonGrid className={[styles['autorizaciones-grid'], 'ion-no-padding'].join(' ')}>

                    <PageTitle>{stepDetails?.titleAndLenght?.title}</PageTitle>

                    <Stepper currentNumber={1} stepsLenght={stepDetails?.titleAndLenght?.lenghtOfPages} />

                    <StepTitle>¿Para quién es?</StepTitle>

                    {obtainedData?.listaIntegrantes &&
                        <SociosList selectedSocio={selectedSocio} list={obtainedData?.listaIntegrantes} setSelectedSocio={setSelectedSocio} />
                    }

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-no-border'>
                <IonToolbar>
                    <div className={styles.checkbox}>
                        <IonCheckbox checked={agreeTerms} onIonChange={e => setAgreeTerms(e.detail.checked)} />
                        <IonLabel onClick={() => setShowTerminosModal(true)}>Términos y condiciones</IonLabel>
                    </div>
                    <IonLabel onClick={() => setShowProteccionDatosModal(true)} className={styles['datos-personales']}>
                        Protección de los datos personales. Ley 25.326 (*)
                    </IonLabel>
                </IonToolbar>

                <NavigationButton onClick={() => {goToNextPage()}} disabled={disabledButton} >SIGUIENTE</NavigationButton>
                {isWeb() && <Footer />}
                
            </IonFooter>
            
            <StandardModal
                isOpen={showTerminosModal}
                title='TÉRMINOS Y CONDICIONES'
                content={<TerminosAutorizaciones />}
                handler={() => setShowTerminosModal(false)}>
            </StandardModal>
            
            <StandardModal
                isOpen={showProteccionDatosModal}
                title='PROTECCIÓN DE LOS DATOS PERSONALES'
                subtitle='Ley 25.326'
                content={<ProteccionDatos />}
                handler={() => setShowProteccionDatosModal(false)}>
            </StandardModal>
        </IonPage>
    );
}
 
export default SeleccionarSocioPage;