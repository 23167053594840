import { IonCard, IonCol, IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonPage, IonRadio, IonRadioGroup, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import StepTitle from '../../../../../shared/components/stepper/step-title/StepTitle';
import styles from './StepTipoComprobantePage.module.scss'
import Invoice from '../../assets/img/invoice.svg';
import Ticket from '../../assets/img/ticket.svg';
import Header from '../../../../../shared/components/layout/header/Header';
import PageTitle from '../../../../../shared/components/page-title/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { setNuevoReintegro } from '../../../../../store/reintegros/ReintegrosActions';
import { useHistory } from 'react-router';
import { routes } from '../../router/routes';
import NavigationButton from '../../../../../shared/components/buttons/navigation/NavigationButton';
import Footer from '../../../../../shared/components/layout/footer/Footer';
import { isWeb } from '../../../../../shared/utils/utils';
import { IDComprobantes } from '../../../../../store/reintegros/types/ReintegrosData';
import { SetStepperNavigation } from '../../../../../store/ui/uiActions';
import Animations from '../../../../../shared/components/animations/Animations';


const StepTipoComprobante: React.FC = () => {

    const nuevoReintegro = useSelector((state: RootState) => state.reintegros.nuevoReintegro);
    const history = useHistory();
    const dispatch = useDispatch();
    const [comprobante, setComprobante] = useState<'Factura' | 'Ticket'>();

    useEffect(() => {
        if(!nuevoReintegro?.tipoPractica) {
            history.push(routes.root);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const next = () => {
        if(comprobante === 'Ticket') {
            dispatch(setNuevoReintegro({...nuevoReintegro!, tipoDocumento: null, numeroDocumento: '', tipoComprobante: {id: 0, descripcion: comprobante}}));
        } else {
            dispatch(setNuevoReintegro({...nuevoReintegro!, tipoComprobante: (nuevoReintegro.tipoComprobante?.id === IDComprobantes.Ticket) ? null : nuevoReintegro.tipoComprobante}));
        }
        dispatch(SetStepperNavigation(Animations.next))
        history.push(routes.seleccionSocio);
    }

    return (
        <IonPage>
            <Header showBackButton={true} />
            <IonContent className={styles['tipo-comprobante']}>
                <IonRow className="ion-margin-top ion-align-items-center">
                    <IonCol>
                        <PageTitle>
                            Reintegros online
                        </PageTitle>
                    </IonCol>
                </IonRow>

                <StepTitle>
                    Seleccioná el tipo de comprobante
                </StepTitle>

                <IonRadioGroup
                    className='ion-margin'
                    value={comprobante}
                    onIonChange={e => setComprobante(e.detail.value)}
                    >   
                    <IonRow className='ion-margin'>
                        <IonCol size='6'>
                            <IonCard>
                                <IonItem lines="none" className={ [styles["radio-item"], comprobante === 'Ticket' && styles["radio-item-selected"]].join(' ')}>
                                    <IonLabel>
                                        <IonIcon src={Ticket} />
                                        Ticket
                                    </IonLabel>
                                    <IonRadio value={'Ticket'} />
                                </IonItem>
                            </IonCard>
                        </IonCol>
                        <IonCol size='6'>
                            <IonCard>
                                <IonItem lines="none" className={[styles["radio-item"], comprobante === 'Factura' && styles["radio-item-selected"]].join(' ')}>
                                    <IonLabel>
                                        <IonIcon src={Invoice} />
                                        Factura
                                    </IonLabel>
                                    <IonRadio value={'Factura'} />
                                </IonItem>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonRadioGroup>

            </IonContent>
            <IonFooter className='ion-no-border'>
                <NavigationButton onClick={ next } disabled={!comprobante}>SIGUIENTE</NavigationButton>
                {isWeb() && <Footer /> }
            </IonFooter>
         </IonPage>
    );
}

 
export default StepTipoComprobante;