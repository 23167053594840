import React, { ReactNode } from 'react';
import styles from './Voucher.module.scss';
import OsdeHeader from '../../../../../shared/components/layout/header/assets/img/Header-Logo.svg';
import { IonIcon, IonSkeletonText, IonText, IonThumbnail } from '@ionic/react';
import { calendarOutline, warningOutline } from 'ionicons/icons';

type voucherType = 'talon' | 'comprobante'

export interface VoucherProps extends React.ComponentPropsWithoutRef<'div'> {
  paymentIcon?: string;
  description: string;
  amount: string;
  date: string;
  entidadDePago?: string;
  pagoInternoOsde?: boolean;
  expired?: boolean;
  type?: voucherType;
  children: ReactNode;
}

const Voucher: React.FC<VoucherProps> = (props) => {
  const { children, paymentIcon, description, entidadDePago, pagoInternoOsde, amount, date, expired, type = 'talon', ...rest } = props;
  return (
    <div className={styles.voucherShadow} {...rest}>
      <div className={[styles.voucherTemplate, styles[type], "ion-activatable"].join(' ')}>
        <div className={styles.talonLogo}>
          <IonIcon
            color='medium'
            icon={paymentIcon ?? OsdeHeader}
            className={type === 'comprobante' && !pagoInternoOsde && styles.pagoExternoIcon}
          />
        </div>
        <div className={styles.talonMain}>
          <h3 className={`${styles.talonDescription} ${!children && styles.noActionBtn}`}>{description}</h3>
          <h2 className={styles.talonAmount}>{amount}</h2>
          {entidadDePago && <p className={styles.entidad}>{entidadDePago}</p> }
          <div className={styles.talonDate}>
            {date && <> <IonIcon icon={calendarOutline} /><p>{date}</p></>}
            {expired && <>
              <IonIcon icon={warningOutline} color='danger' />
              <p id="deuda-inhabilitadora">
                <IonText color='danger'>
                  Esta deuda inhabilita el acceso al servicio
                </IonText>
              </p>
            </>}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Voucher;

type skeletonProps = { type?: voucherType };

export const VoucherSkeleton: React.FC<skeletonProps> = ({ type = 'talon' }) => (
  <div className={styles.voucherShadow}>
    <div className={[styles.voucherTemplate, "ion-activatable"].join(' ')}>
      <div className={styles.skeleton}>
        <div>
          <IonThumbnail >
            <IonSkeletonText animated />
          </IonThumbnail>
        </div>
        <div>
          <h3><IonSkeletonText animated style={{ width: '60%' }} /></h3>
          <p><IonSkeletonText animated style={{ width: '90%' }} /></p>
          <p><IonSkeletonText animated style={{ width: '70%' }} /></p>
        </div>
        <div>
          <IonThumbnail className={`${type === 'comprobante' ? styles.skeletonVerBtn : styles.checkbox}`}>
            <IonSkeletonText animated />
          </IonThumbnail>
        </div>
      </div>
    </div>
  </div>
);