import { IException } from "../../services/Exceptions";
import Animations, { IAnimation } from "../../shared/components/animations/Animations";
import { SET_RESET_SCREENSAVER_TIME, SET_STEPPER_NAVIGATION, SET_RESET_INACTIVITY_LOGOUT, UIAction, SET_UPDATE_AVAILABLE, SET_SHOW_SPLASH, SET_KEYBOARD, SET_HTTP_ERROR } from "./uiActionTypes";

export interface UIState {
    resetInactivityLogout?: boolean | null;
    resetScreenSaverTime?: boolean | null;
    stepperNavigation?: IAnimation | null;
    updateAvailable: boolean;
    showSplash: boolean;
    isKeyboardOn: boolean;
    httpError?: IException | null
}

const initialState: UIState = {
    resetScreenSaverTime: false,
    stepperNavigation: Animations.back,
    resetInactivityLogout: false,
    updateAvailable: undefined,
    showSplash: true,
    isKeyboardOn: false,
    httpError: null
}

export const uiReducer = (
    state: UIState = initialState,
    action: UIAction
) => {
    switch (action.type) {
        case SET_RESET_SCREENSAVER_TIME:
            return {
                ...state,
                resetScreenSaverTime: action.payload
            }
        case SET_RESET_INACTIVITY_LOGOUT:
            return {
                ...state,
                resetInactivityLogout: action.payload
            }
        case SET_STEPPER_NAVIGATION:
            return {
                ...state,
                stepperNavigation: action.payload
            }
        case SET_UPDATE_AVAILABLE:
            return {
                ...state,
                updateAvailable: action.payload
            }
        case SET_SHOW_SPLASH:
            return {
                ...state,
                showSplash: action.payload
            }
        case SET_KEYBOARD:
            return {
                ...state,
                isKeyboardOn: action.payload
            }
        case SET_HTTP_ERROR:
            return {
                ...state,
                httpError: action.payload
            }
        default:
            return { ...state };
    }
}