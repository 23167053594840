import React, { useEffect, useState } from 'react';
import { IonCheckbox, IonRippleEffect } from '@ionic/react';
import { addTalon, removeTalon } from '../../../../../../../store/pagoEnLinea/actions';
import { useDispatch } from 'react-redux';
import { formatImport } from '../../../../../../../shared/utils/utils';
import { Talon, tipoTalon } from '../../../../../../../store/pagoEnLinea/types/PagoEnLineaTypes';
import Voucher from '../../../../components/Voucher/Voucher';

interface PaymentItemProps {
  talon: Talon;
}

const PaymentItem: React.FC<PaymentItemProps> = ({ talon }) => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (checked) {
      dispatch(addTalon(talon));
    } else {
      dispatch(removeTalon(talon.id + ''));
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const calculateImport = () => {
    setChecked(!checked);
  };

  const formatVencimientoDate = (vto: string): string => {
    const year = vto.substring(0, 4);
    const month = vto.substring(4, 6);
    const day = vto.substring(6, 8);
    const fecha = new Date(+year, (+month - 1), +day);
    return fecha.toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const checkValidDate = (talon:Talon) => {
    return talon.tipoTalon !== tipoTalon.TS1 && +talon.vencimiento>0 && `Vto ${formatVencimientoDate(talon.vencimiento)}`
  }

  return (
    <Voucher
      onClick={calculateImport}
      amount={formatImport(`$${parseFloat(talon.monto).toFixed(2)}`)}
      description={talon.periodo}
      date={checkValidDate(talon)}
      expired={talon.tipoTalon === tipoTalon.TS1}
    >
      <IonRippleEffect />
      <IonCheckbox
        mode='md'
        checked={checked}
      />
    </Voucher>
  )
};

export default PaymentItem;