import React from 'react';
import { IonLoading } from '@ionic/react';

interface Props {
  isOpen: boolean;
  message: string;
}

const Loading: React.FC<Props> = ({ isOpen, message }) => {
  return (
    <IonLoading
      isOpen={isOpen}
      message={message}
    />
  );
};

export default Loading;
